import React, {useEffect, useRef} from 'react';
import styled from "styled-components";

const InputContainer = styled.div`
   width: 100%;
   position: relative;
`
const CustomInput = styled.input`
   width: ${props => props.width || '100%'};
   padding-top: 10px;
   padding-bottom: 10px;
   text-align: left;
   border: none;
   border-radius: 16px;
   padding-left: 54px;
`
const CustomIcon = styled.i`

`
const IconContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 12px;
    z-index: 1;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    background-color: white;
    place-content: center;
`
export default function CustomSearch({
                                         type = 'text',
                                         className,
                                         labelClassName,
                                         label,
                                         withoutDaley = false,
                                         placeholder,
                                         defaultValue,
                                         value,
                                         min,
                                         max,
                                         rows,
                                         onChange,
                                         disabled,
                                         readOnly,
                                         invalid,
                                         errorMessage,
                                         onKeyPress,
                                         checked,
                                         clear,
                                         setClear,
                                         width,
                                         inputWidth
                                     }) {
    const inputRef = useRef()

    useEffect(()=>{
        if (clear) {
            inputRef.current.value  = ''
            setClear()
        }
    },[clear])

    const searchData = (event) => {
        if (withoutDaley) {
            onChange({target: {value: event}})
        } else {
            if (inputRef.current.typingTimeout) {
                clearTimeout(inputRef.current.typingTimeout);
            }
            inputRef.current = {
                name: event,
                typing: false,
                typingTimeout: setTimeout(() => {
                    onChange({ target: { value: event } });
                }, 700),
            };
         }
    }
    return (
        <div className={'d-flex'} style={{width: width}}>
            <label className={`form-control-label ${labelClassName}`}>{label}</label>
            <InputContainer>
                <IconContainer>
                    <CustomIcon className='fa fa-search'/>
                </IconContainer>
                <CustomInput
                    ref={inputRef}
                    type={type}
                    width={inputWidth}
                    className={className}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    value={value}
                    min={min}
                    max={max}
                    rows={rows}
                    disabled={disabled}
                    readOnly={readOnly}
                    onChange={(e)=>searchData(e.target.value)}
                    invalid={invalid}
                    onKeyPress={onKeyPress}
                    checked={checked}
                />
            </InputContainer>

            <div className="invalid-feedback">
                {errorMessage}
            </div>
        </div>
    )
}