import React, {Fragment, useEffect, useState} from 'react'
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";
import {
    CrossIconContainer,
    CustomArea,
    CustomHeader,
    CustomInput, FleetButton, IconContainer, OptionContainer, OptionsContainer,
    UploadedDocumentInformationContainer
} from "@pages/new-design-components/custom-components";
import {Col, Modal, ModalBody, ModalHeader, Row, UncontrolledPopover} from "reactstrap";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {chooseFileIcon, formatTimestamp} from "../../../../constants";
import ThreeDots from "@assets/images/mi_options-vertical.svg";
import EditorComponent from "@pages/components/template";
import CreateTemplateModal from "@pages/components/DebtManagement/CustomerOverview/TemplateListModal";

export default function EmailChat({data,isOpen,setShowSendQuoteEmail,templates, sendEmail,transformData}) {
    const [templateData,setTemplateData] = useState({});
    const [editorText,setEditorText] = useState('');
    const [templatesModal,setShowTemplatesModal] = useState(false);
    useEffect(()=>{
        if (data) {
            setTemplateData({...templateData,email:data.email})
        }
    },[data])

    const setData = ({value,key}) => {
        setTemplateData({...templateData,[key]:value})
    }
    const handleButtonClick = (id) => {
        document.getElementById(id).click();
    };

    const handleFileChange = async (event,index) => {
        const file = event.target.files[0];
        if (file) {
            await onFileSelect(file,index);
        }
    };
    const onFileSelect = async (file) => {
        if (file) {
            let tempData = {...templateData}
            let reader = new FileReader();
            reader.readAsDataURL(file)
            const preview =  URL.createObjectURL(file)
            if (!tempData.documents) {
                tempData.documents = [];
            }
            tempData.documents.push({
                preview,
                file,
            });
            setTemplateData({...tempData});
        }
    }
    const removeDocument = (index) => {
        let tempData = {...templateData}
        templateData.documents.splice(index, 1);
        setTemplateData({...tempData});
    }
    const selectTemplate = (index) => {
        const selected = templates[index];
        setEditorText(selected.message)
        setData({value:selected.message,key:'message'})
        setShowTemplatesModal(false)
    }
    return (  <Modal
        isOpen={isOpen}
        toggle={setShowSendQuoteEmail}
        modalClassName={'slide-in-from-right'}
        backdrop={false}
        className={'modal-dialog-half-right modal-responsible bottom-modal'}
    >

            <ModalHeader toggle={setShowSendQuoteEmail} className={'bg-white separated-title'}>
                <IcoMoon
                    onClick={()=>{
                        transformData({data: templateData})
                    }}
                    className={'cursor-pointer'}
                    iconSet={IconSet}
                    icon={'is_fullscreen'}
                    size={28}
                />
               <div className={'w-100'}>
                   <CustomHeader weight={'600'} fontSize={'14px'}  color={'#74787E'}>
                       New Email
                   </CustomHeader>
               </div>
            </ModalHeader>
            <ModalBody>
                <CreateTemplateModal isOpen={templatesModal} closeModal={()=>{
                    setShowTemplatesModal(false)
                }} localData={templates} selectReminder={selectTemplate}/>
                <Col lg={12}>
                    <div className={'mb-3'}>
                        <div className={'d-flex justify-content-between'}>
                            <CustomHeader lineHeight={'10px'} fontSize={'14px'} weight={'700'} color={'#74787E'}>
                                Recipient:
                            </CustomHeader>
                            <div>
                                <CustomHeader
                                    className={'cursor-pointer'}
                                    lineHeight={'none'}
                                    fontSize={'14px'}
                                    onClick={()=>{
                                        setShowTemplatesModal(true)
                                    }}
                                    weight={'700'}
                                    color={'#3F8CFF'} >
                                    Templates
                                </CustomHeader>

                            </div>
                        </div>
                        <CustomInput radius={'8px'}
                                     onChange={(e)=>{
                                         setData({value:e.target.value,key:'email'})
                                     }
                                     }
                                     border={'1px solid #C0D9FF59'}
                                     value={templateData?.email} />
                    </div>
                    <div className={'mb-3'}>
                        <CustomHeader lineHeight={'10px'} fontSize={'14px'} weight={'700'} color={'#74787E'}>
                            Subject:
                        </CustomHeader>
                        <CustomInput radius={'8px'}
                                     onChange={(e)=>{
                                         setData({value:e.target.value,key:'subject'})
                                     }
                                     }
                                     border={'1px solid #C0D9FF59'}
                                     value={templateData?.subject} />
                    </div>
                    <div className={'mb-3'}>
                        <div className={'d-flex justify-content-between'}>
                            <CustomHeader lineHeight={'10px'} fontSize={'14px'} weight={'700'} color={'#74787E'}>
                                Attachments:
                            </CustomHeader>
                                <input
                                    type="file"
                                    id={`input_`}
                                    style={{ display: 'none' }}
                                    accept="image/png, image/gif, image/jpeg, application/pdf"
                                    onChange={handleFileChange}
                                />
                        </div>
                        <div className={'d-flex g-10 flex-wrap'}>
                            {
                                templateData.hasOwnProperty('documents') && templateData.documents.length ?
                                    templateData.documents.map((document,index)=>{
                                        return (<Fragment key={index}>
                                                <UploadedDocumentInformationContainer>
                                                    <div className={'d-flex justify-content-between'}>
                                                        <div className={'d-flex g-10'}>
                                                            <IcoMoon
                                                                className={'cursor-pointer'}
                                                                iconSet={IconSet}
                                                                icon={chooseFileIcon(document.file.type)}
                                                                size={28}
                                                            />
                                                            <div>
                                                                <CustomHeader lineHeight={'15px'}
                                                                              fontSize={'14px'}
                                                                              overFlow={'hidden'}
                                                                              maxWidth={'160px'}
                                                                              color={'#324054'}
                                                                              weight={'500'}>
                                                                    {document.file.name}
                                                                </CustomHeader>
                                                                <div className={'d-flex g-5'}>
                                                                    <CustomHeader lineHeight={'0px'}
                                                                                  fontSize={'12px'}
                                                                                  color={'#71839B'}
                                                                                  weight={'500'}>
                                                                        {(document.file.size / 1024).toFixed(2)} KB
                                                                    </CustomHeader>
                                                                    <CustomHeader lineHeight={'0px'}
                                                                                  fontSize={'12px'}
                                                                                  color={'#71839B'}
                                                                                  weight={'500'}>
                                                                        {formatTimestamp(document.file?.lastModified)}
                                                                    </CustomHeader>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'d-flex g-5 align-items-center'}>
                                                            {
                                                                document.hasOwnProperty('preview') ?
                                                                    <IcoMoon
                                                                        className={'cursor-pointer'}
                                                                        iconSet={IconSet}
                                                                        icon={'trash-alt'}
                                                                        size={16}
                                                                        onClick={()=>{
                                                                            removeDocument(index)
                                                                        }}
                                                                    />
                                                                    : <IcoMoon
                                                                        className={'cursor-pointer'}
                                                                        iconSet={IconSet}
                                                                        icon={'download'}
                                                                        size={16}
                                                                    />
                                                            }

                                                        </div>
                                                    </div>
                                                </UploadedDocumentInformationContainer>
                                            </Fragment>
                                        )
                                    })
                                    : null
                            }

                        </div>

                    </div>
                    <div className={'mb-3'}>
                        <CustomHeader lineHeight={'10px'} fontSize={'14px'} weight={'700'} color={'#74787E'}>
                            Message:
                        </CustomHeader>
                        <EditorComponent
                            editorStateInitial={editorText}
                            wrapperClassName={'quoteWrapperClassName'}
                            onEditorStateChange={(content)=>{
                                setData({value:content,key:'message'})
                            }}
                        />
                    </div>
                    <div className={'d-flex justify-content-between'}>
                        <IconContainer width={'40px'} height={'40px'} backgroundColor={'#C0D9FFC7'}>
                            <IcoMoon
                                className={'cursor-pointer '}
                                iconSet={IconSet}
                                icon={'ic_attachment'}
                                size={20}
                                onClick={()=>handleButtonClick(`input_`)}
                            />
                        </IconContainer>

                        <FleetButton
                            height={'43px'}
                            width={'154px'}
                            backgroundColor={'#F3994A'}
                            color={'#fff'}
                            fontSize={'14px'}
                            radius={'8px'}
                            onClick={()=>{
                                sendEmail({data: templateData})
                            }}
                        >
                            Send
                        </FleetButton>
                    </div>
                </Col>
            </ModalBody>

        </Modal>
    )
}