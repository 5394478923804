import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const ArrayDropDownComponent = ({ items, onSelect, defaultText = "Select an Option" }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const toggle = () => setDropdownOpen(!dropdownOpen);

    const handleSelect = (item) => {
        setSelectedItem(item);
        onSelect(item);
        setDropdownOpen(false);
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>
                {selectedItem || defaultText}
            </DropdownToggle>
            <DropdownMenu>
                {items.map((item, index) => (
                    <DropdownItem key={index} onClick={() => handleSelect(item)}>
                        {item}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default ArrayDropDownComponent;
