import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import axios from '../../config/axios';
import * as constants from '../../constants';

const URL = `${constants.API_URL}/settings/emails`;

function* createEmailTemplate(action) {
  try {
    const { data } = action;
    const response = yield axios.post(`${URL}`, {
      ...data,
    });

    if (response?.status === 200) {
      const {
        data: {
          data: { items },
        },
      } = response;
      yield put({ type: actions.CREATE_EMAIL_TEMPLATE_SUCCESS, items });
    }
  } catch (error) {
    console.log(error, 'createEmailTemplate');
    yield put({ type: actions.CREATE_EMAIL_TEMPLATE_FAILURE, error });
  }
}

function* getEmailTemplates(data) {
  try {
    const response = yield axios.get(`${URL}`,{params: data.data});

    if (response?.status === 200) {
      const {
        data: {
          data
        },
      } = response;
      yield put({ type: actions.GET_EMAIL_TEMPLATES_SUCCESS, items: data });
    }
  } catch (error) {
    console.log(error, 'getEmailTemplates');
    yield put({ type: actions.GET_EMAIL_TEMPLATES_FAILURE, error });
  }
}

function* deleteEmailTemplate(action) {
  try {
    const { id } = action;
    const response = yield axios.delete(`${URL}/${id}`);

    if (response?.status === 204) {
      yield put({ type: actions.DELETE_EMAIL_TEMPLATE_SUCCESS, id });
    }
  } catch (error) {
    console.log(error, 'deleteEmailTemplate');
    yield put({ type: actions.DELETE_EMAIL_TEMPLATE_FAILURE, error });
  }
}

function* updateEmailTemplate(action) {
  try {
    const { id, data } = action;
    const keys = [
      'dealer_id',
      'subject',
      'slug',
      'created_at',
      'updated_at',
    ];
    keys.forEach((key) => {
      delete data[key];
    });

    const response = yield axios.put(`${URL}/${id}`, { ...data });
    if (response?.status === 200) {
      yield put({ type: actions.UPDATE_EMAIL_TEMPLATE_SUCCESS, id });
    }
  } catch (error) {
    console.log(error, 'updateEmailTemplate');
    yield put({ type: actions.UPDATE_EMAIL_TEMPLATE_FAILURE, error });
  }
}

function* sendEmail(action) {
  try {
    const { data } = action;
    const response = yield axios.post(`${constants.API_URL}/processor-emails`, {
      ...data,
    });
    if (response?.status === 200) {
      yield put({ type: actions.SEND_EMAIL_SUCCESS });
    }
  } catch (error) {
    console.log(error, 'sendEmail');
    yield put({ type: actions.SEND_EMAIL_FAILURE, error });
  }
}

function* getSentEmails() {
  try {
    const response = yield axios.get(`${constants.API_URL}/processor-emails`);
    if (response?.status === 200) {
      const {
        data: { data: items },
      } = response;
      yield put({ type: actions.GET_SENT_EMAILS_SUCCESS, items });
    }
  } catch (error) {
    console.log(error, 'getSentEmails');
    yield put({ type: actions.GET_SENT_EMAILS_FAILURE, error });
  }
}

function* getDealerRoles() {
  try {
    const response = yield axios.get(`${constants.API_URL}/role`);
    if (response?.status === 200) {
      const {
        data: { data: items },
      } = response;
      yield put({ type: actions.GET_ROLES_SUCCESS, items });
    }
  } catch (error) {
    yield put({ type: actions.GET_ROLES_FAILURE, error });
  }
}

function* getShortcodes() {
  try {
    const response = yield axios.get(`${URL}/shortcodes`);
    if (response?.status === 200) {
      const {
        data: { data: items },
      } = response;
      yield put({ type: actions.GET_SHORTCODES_SUCCESS, items });
    }
  } catch (error) {
    console.log(error, 'getDealerRoles');
    yield put({ type: actions.GET_SHORTCODES_FAILURE, error });
  }
}

function* getEmailResourceDetails({payload}) {
  try {
    const response = yield axios.get(`${URL}/resource`, { params: {...payload} });
    if (response?.status === 200) {
      const {
        data: { data: {details, validated} },
      } = response;
      yield put({ type: actions.GET_EMAIL_DETAILS_BY_RESOURCE_SUCCESS, details, validated });
    }
  } catch (error) {
    yield put({ type: actions.GET_EMAIL_DETAILS_BY_RESOURCE_FAILURE, error });
  }
}

function* getResourceEmails({payload}) {
  try {
    const response = yield axios.get(`${URL}/resource-emails`, { params: {...payload} });
    if (response?.status === 200) {
      const {
        data: { data: {emails} },
      } = response;
      yield put({ type: actions.GET_RESOURCE_EMAILS_SUCCESS, emails });
    }
  } catch (error) {
    yield put({ type: actions.GET_RESOURCE_EMAILS_FAILURE, error });
  }
}
function* getTemplateTypes() {
  try {
    const response = yield axios.get(`${URL}/template-types`);
    if (response?.status === 200) {
      const {
        data,
      } = response;
      yield put({ type: actions.GET_TEMPLATE_TYPES_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actions.GET_TEMPLATE_TYPES_FAILURE, error });
  }
}

function* getSystemTemplates({payload}) {
  try {
    const response = yield axios.get(`${constants.API_CORE_URL}/get-system-templates`,{ params: {...payload} });

    if (response?.status === 200) {
      const {
        data,
      } = response;
      yield put({ type: actions.GET_SYSTEM_TEMPLATES_SUCCESS, data: data.data });
    }
  } catch (error) {
    yield put({ type: actions.GET_SYSTEM_TEMPLATES_FAILURE, error });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actions.CREATE_EMAIL_TEMPLATE_REQUEST, createEmailTemplate);
  yield takeLatest(actions.GET_EMAIL_TEMPLATES_REQUEST, getEmailTemplates);
  yield takeLatest(actions.DELETE_EMAIL_TEMPLATE_REQUEST, deleteEmailTemplate);
  yield takeLatest(actions.UPDATE_EMAIL_TEMPLATE_REQUEST, updateEmailTemplate);
  yield takeLatest(actions.SEND_EMAIL_REQUEST, sendEmail);
  yield takeLatest(actions.GET_SENT_EMAILS_REQUEST, getSentEmails);
  yield takeLatest(actions.GET_ROLES_REQUEST, getDealerRoles);
  yield takeLatest(actions.GET_SHORTCODES_REQUEST, getShortcodes);
  yield takeLatest(actions.GET_EMAIL_DETAILS_BY_RESOURCE_REQUEST, getEmailResourceDetails);
  yield takeLatest(actions.GET_RESOURCE_EMAILS_REQUEST, getResourceEmails);
  yield takeLatest(actions.GET_TEMPLATE_TYPES_REQUEST, getTemplateTypes);
  yield takeLatest(actions.GET_SYSTEM_TEMPLATES_REQUEST, getSystemTemplates);
}
