import React, {Fragment, useEffect, useRef, useState} from 'react'
import {
    CustomHeader,
    CustomTD,
    CustomTableRow,
    StyledTable,
    StyledTableHeaderCell, StyledTableHeader, Dot, CustomReactStrapInput
} from "@pages/new-design-components/custom-components";
import CustomSearch from "@pages/components/CustomSearch";
import {Col, DropdownItem, Modal} from "reactstrap";
import { API_URL } from 'constants/index.js';
import axios from "axios";
import {useHistory} from "react-router-dom";
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import DebtManagementTable from "@pages/components/DebtManagement/DebtManagementTable";
import {GET_CUSTOMERS_DEBT_REQUEST} from "@redux/Customer/actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import usePrevious from "../../../hooks/useprevious";
import {Notification} from "@pages/components/Notification";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import Picker from "@pages/components/DatePicker";


export default function DebtRecovery() {
    const dispatch = useDispatch();
    const [showQuickView, setShowQuickView] = useState(false)
    const [loading,setLoading] = useState(true);
    const [tableData,setTableData] = useState([]);
    const [activeData,setActiveData] = useState(null);
    const [filter,setFilter] = useState({
        debt_status:'sent_to_debt_recovery'
    });
    const history = useHistory();
    const {
        customersDebts,
        getCustomersDebtError,
        getCustomersDebtSuccess,
    } = useSelector((state) => state.customer, shallowEqual);
    const notificationAlert = useRef(null)
    const getCustomersDebtSuccessPrev = usePrevious(getCustomersDebtSuccess);
    const getCustomersDebtErrorPrev = usePrevious(getCustomersDebtError);
    const keys = [
        {
            key: 'customer_name_deep',
            label: 'Customer'
        },
        {
            key: 'vehicle',
            label: 'Vehicle'
        } ,
        {
            key: 'amount_due',
            label: 'Amount'
        } ,
        {
            key: 'due_date',
            label: 'Due Date'
        },

        {
            key: 'overdue_duration',
            label: 'Overdue duration'
        },
        {
            object:'customer_debt',
            key: 'debt_status',
            label: 'Status'
        },
        {
            key: 'view',
            label: 'View',
        },
    ]
    const fetchData = (localFilter = {}) => {
        dispatch({
            type: GET_CUSTOMERS_DEBT_REQUEST,
            payload: {...localFilter}
        });
    }
    useEffect(()=>{
        fetchData({debt_status:'sent_to_debt_recovery'});
    },[])
    useEffect(()=>{
        if (getCustomersDebtSuccess && getCustomersDebtSuccessPrev === false) {
            setTableData(customersDebts)
            setLoading(false)
        }
    },[getCustomersDebtSuccess,getCustomersDebtSuccessPrev])
    useEffect(()=>{
        if (getCustomersDebtError && getCustomersDebtErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[getCustomersDebtError,getCustomersDebtErrorPrev])

    const filterData = ({key,value}) => {
        let tempFilter = {...filter}
        tempFilter[key] = value
        setFilter(tempFilter)
        fetchData(tempFilter)
    }
    const viewDetails = (index) => {
        const data = tableData[index];
        const queryParams = new URLSearchParams({
            'customer_id': data.customer_id,
        }).toString();
        history.push({
            pathname: `/admin/debt-recovery-overview`,
            search: queryParams
        })
    }

    const toggleShowQuickViewModal = () => {
        setShowQuickView(!showQuickView)
    }

    return (

            <div className={'container-fluid debt-background pt-3'}>
                <Modal isOpen={showQuickView}
                       toggle={toggleShowQuickViewModal}
                       modalClassName={'slide-in-from-right'}
                       className={'modal-dialog-right'}
                >
                    <div className={'p-3'}>
                        <h6 className="modal-title text-center">Outstanding Payment</h6>
                    </div>
                    <div className={'modal-body'}>
                        <div>
                            <CustomHeader color={'#74787E'} fontSize={'14px'}>
                                Customer:
                            </CustomHeader>
                            <CustomHeader color={'#000000'} fontSize={'14px'} weight={'400'}>
                                {activeData?.first_name}  {activeData?.last_name}
                            </CustomHeader>
                        </div>
                        <div>
                            <CustomHeader color={'#74787E'} fontSize={'14px'}>
                                Vehicle:
                            </CustomHeader>
                            <CustomHeader color={'#000000'} fontSize={'14px'} weight={'400'}>
                                NA
                            </CustomHeader>
                        </div>
                        <div>
                            <CustomHeader color={'#74787E'} fontSize={'14px'}>
                                Type:
                            </CustomHeader>
                            <CustomHeader color={'#000000'} fontSize={'14px'} weight={'400'}>
                                NA
                            </CustomHeader>
                        </div>
                        <div>
                            <CustomHeader color={'#74787E'} fontSize={'14px'}>
                                Status:
                            </CustomHeader>
                            <CustomHeader color={'#000000'} fontSize={'14px'} weight={'400'}>
                                <Dot />
                                text
                            </CustomHeader>
                        </div>
                        <div>
                            <CustomHeader color={'#74787E'} fontSize={'14px'}>
                                Amount:
                            </CustomHeader>
                            <CustomHeader color={'#000000'} fontSize={'14px'} weight={'400'}>
                                345`
                            </CustomHeader>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <ButtonContainer
                            reverse
                            onClick={toggleShowQuickViewModal}
                        >
                            {`Send a Reminder`}
                        </ButtonContainer>
                        <ButtonContainer
                            onClick={toggleShowQuickViewModal}
                        >
                            {`Close`}
                        </ButtonContainer>
                    </div>
                </Modal>
                <CustomHeader lineHeight={'unset'} >Debt Recovery</CustomHeader>

                <div className={'d-flex g-10 flex-wrap debt-datePicker white-background'}>
                    <Col lg={2} sm={12} md={6}>
                        <CustomSearch
                            onChange={(e) => {
                                filterData({key:'customer_name',value: e.target.value})
                            }}
                            placeholder="Search"
                        />
                    </Col>
                    <Col lg={2} sm={12} md={6}>
                        <Picker
                            value={filter?.from}
                            placeholderText={'From'}
                            onChange={(date) => {
                                filterData({key:'from',value:date})
                            }}
                        />

                    </Col>
                    <Col lg={2} sm={12} md={6}>
                        <Picker
                            value={filter?.to}
                            placeholderText={'To'}
                            onChange={(date) => {
                                filterData({key:'to',value:date})
                            }}
                        />
                    </Col>

                    <Col lg={2} sm={12} md={6}>
                        <CustomDropdownComponent
                            withoutDot
                            buttonHeight={'100%'}
                            border={'null'}
                            selectedTitle={filter?.debt_status}
                            statusArrayKey={'debtStatuses'}
                            noBadge={true}
                            handleChange={(value)=>{
                                filterData({key:'debt_status',value:value})
                            }}
                        />
                    </Col>
                </div>
                <div className={'table-container'}>
                    <DebtManagementTable keys={keys} loading={loading} tableData={tableData} viewDetails={viewDetails}/>
                </div>
            </div>
    )
}