import React, {Fragment, useEffect, useRef, useState} from 'react'
import {
    CustomHeader,
    CustomTD,
    CustomTableRow,
    StyledTable,
    StyledTableHeaderCell, StyledTableHeader, Dot
} from "@pages/new-design-components/custom-components";
import CustomSearch from "@pages/components/CustomSearch";
import {Col, DropdownItem, Modal} from "reactstrap";
import { API_URL } from 'constants/index.js';
import axios from "axios";
import {useHistory} from "react-router-dom";
import {ButtonContainer, LoaderContainer, override} from "@pages/reusable-components/styled-components";
import DebtManagementTable from "@pages/components/DebtManagement/DebtManagementTable";
import {ClipLoader} from "react-spinners";
import SendQuoteEmail from "@pages/components/DebtManagement/SendQuoteEmail";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {GET_EMAIL_TEMPLATES_REQUEST, GET_SYSTEM_TEMPLATES_REQUEST, SEND_EMAIL_REQUEST} from "@redux/Email/actions";
import usePrevious from "../../../hooks/useprevious";
import * as constants from "../../../constants";
import {GET_CUSTOMERS_DEBT_REQUEST, UPDATE_CUSTOMER_DEBT_REQUEST} from "@redux/Customer/actions";
import {Notification} from "@pages/components/Notification";


export default function RepairQuotes() {
    const {
        customersDebts,
        getCustomersDebtError,
        getCustomersDebtSuccess,
        updateCustomerDebtsError,
        updateCustomerDebtSuccess,
    } = useSelector((state) => state.customer, shallowEqual);
    const notificationAlert = useRef(null)
    const getCustomersDebtSuccessPrev = usePrevious(getCustomersDebtSuccess);
    const getCustomersDebtErrorPrev = usePrevious(getCustomersDebtError);

    const updateCustomerDebtSuccessPrev = usePrevious(updateCustomerDebtSuccess);
    const updateCustomerDebtsErrorPrev = usePrevious(updateCustomerDebtsError);

    const [showSendEmail, setShowSendEmail] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(true);
    const [tableData,setTableData] = useState([]);
    const [filter,setFilter] = useState({});
    const [activeData,setActiveData] = useState(null);
    const [activeDebt,setActiveDebt] = useState(null);
    const [activeDebtIndex,setActiveDebtIndex] = useState(null);
    const {systemTemplates} = useSelector(
        (state) => state.emailTemplateState,
        shallowEqual
    );

    const keys = [
        {
            key: 'customer_name_deep',
            label: 'Customer'
        } ,
        {
            key: 'amount_due',
            label: 'Amount'
        } ,
        {
            key: 'due_date',
            label: 'Due Date'
        },

        {
            key: 'overdue_duration',
            label: 'Overdue duration'
        },
        {
            key: 'debt_status',
            label: 'Status'
        },

        {
            key: 'options',
        },
    ]
    const fetchData = (localFilter = {}) => {
        dispatch({
            type: GET_CUSTOMERS_DEBT_REQUEST,
            payload: {...localFilter}
        });
    }
    useEffect(()=>{
        fetchData();
        dispatch({
            type: GET_SYSTEM_TEMPLATES_REQUEST,
            payload: {
                type: 'email'
            }
        });
    },[])
    useEffect(()=>{
        if (getCustomersDebtSuccess && getCustomersDebtSuccessPrev === false) {
            setTableData(customersDebts)
            setLoading(false)
        }
    },[getCustomersDebtSuccess,getCustomersDebtSuccessPrev])
    useEffect(()=>{
        if (getCustomersDebtError && getCustomersDebtErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[getCustomersDebtError,getCustomersDebtErrorPrev])

    useEffect(()=>{
        if (updateCustomerDebtSuccess && updateCustomerDebtSuccessPrev === false) {
            let tempDebts = [...tableData]
            tempDebts[activeDebtIndex] = activeDebt
            setTableData(tempDebts)
            setActiveDebtIndex(null)
            setActiveDebt({})
            setOpenModal(false)
            setLoading(false)
        }
    },[updateCustomerDebtSuccessPrev,updateCustomerDebtSuccess])

    useEffect(()=>{
        if (updateCustomerDebtsError && updateCustomerDebtsErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[updateCustomerDebtsErrorPrev,updateCustomerDebtsError])

    const searchData = async (key,value) => {
        const tempFilter = {...filter}
        tempFilter[key] = value
        setFilter(tempFilter)
        setLoading(true)
        await fetchData(tempFilter)
    }
    const viewDetails = (index) => {
        const data = tableData[index];
        setActiveData(data)
        setShowSendEmail(true)
    }
    const sendToDebt = (index) => {
        let data = {...tableData[index]};
        data['debt_status'] = 'sent_to_debt_recovery'
        setActiveDebt(data)
        setActiveDebtIndex(index)
        setOpenModal(true)
    }
    const saveDebt = () => {
        setLoading(true)
        dispatch({
            type: UPDATE_CUSTOMER_DEBT_REQUEST,
            payload: activeDebt
        });
    }
    const sendEmail = ({data}) => {
        let formData = new FormData();
        formData.append('message',data?.message);
        formData.append('subject',data?.subject);
        formData.append('to[]',data.email);
        if (data.hasOwnProperty('documents') && data.documents.length) {
            for (const document of data?.documents) {
                if (document.file) {
                    formData.append('fileLists[]', document.file);
                }
            }
        }

        formData.append('resource_id',activeData.id);
        formData.append('resource_type','Customer');
        axios({
            method: 'POST',
            url: constants.API_URL + '/processor-emails',
            headers: {
                'content-type': 'multipart/form-data',
            },
            data: formData
        }).then(res=>{
            console.log('res:',res)
        }).catch(err=>{
            console.log('err:',err)
        })

    }
    const toggleModal = () => {
        setOpenModal(!openModal)
    }
    return (

            <div className={'container-fluid debt-background pt-3'}>
                <Modal
                    isOpen={openModal}
                    toggle={toggleModal}
                    className="modal-dialog-centered"
                >
                    <div className={'p-3'}>
                        <h6 className="modal-title main-color">Update Debt Status</h6>
                    </div>

                    <div className="modal-footer justify-content-end">
                        <ButtonContainer
                            onClick={() => {
                                setActiveDebtIndex(null)
                                setActiveDebt({})
                                setOpenModal(false)
                            }}
                        >
                            {`Close`}
                        </ButtonContainer>
                        <ButtonContainer
                            reverse
                            onClick={() => {
                                saveDebt(activeDebt)
                            }}
                        >
                            {`Update`}
                        </ButtonContainer>

                    </div>
                </Modal>
                {
                    showSendEmail ? <SendQuoteEmail sendEmail={sendEmail} templates={systemTemplates} data={activeData} setShowSendQuoteEmail={()=>{setShowSendEmail(false)}}/> :
                        <>
                            <CustomHeader lineHeight={'unset'} >Debt Recovery</CustomHeader>
                            <Col lg={4} sm={12} md={5}>
                                <CustomSearch
                                    onChange={(e) => {
                                        searchData('search', e.target.value)
                                    }}
                                    placeholder="Search"
                                />
                            </Col>
                            {
                                loading ?
                                    <LoaderContainer>
                                        <ClipLoader
                                            css={override}
                                            size={40}
                                            color={`#7B61E4`}
                                            loading={loading}
                                        />
                                    </LoaderContainer> :
                                    <div className={'table-container'}>
                                        <DebtManagementTable keys={keys} tableData={tableData} optionValues={[
                                            {
                                                label: 'Send to Debt Recovery',
                                                action: sendToDebt
                                            },
                                            {
                                                label: 'Resend Statement',
                                                action: viewDetails
                                            }
                                        ]}/>
                                    </div>
                            }


                        </>
                }

            </div>
    )
}