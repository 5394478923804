import React, {useState, Fragment, useEffect} from 'react'
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    AddImageButton,
    CrossIconContainer,
    CustomHeader, CustomInput, CustomTD, FleetButton, IconContainer,
    InspectionButton, StyledImage, StyledTable, StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import InspectionReportComponentForPrint
    from "@pages/components/JobDetailsComponents/InspectionReportComponentForPrint";

export default function InspectionReport({   selectedInspection,
                                             inspectionItems,
                                             selectInspectionItem,
                                             activeIndex,
                                             inspectionValues,
                                             handleUpdate,
                                             changedValues,
                                             data,
                                             addNewRow,
                                             deleteRow,
                                             changeData,
                                             vehicle,
                                             onFileSelect,
                                             deleteDocument
                                         }) {

    const [values, setValues] = useState([]);
    useEffect(()=>{
        if (inspectionValues.length) {
            setValues([...inspectionValues])
        }
    },[inspectionValues])
    const handleChange = (value, index, idx) => {
        const updatedValues = [...values];
        updatedValues[index][idx].status = value;
        const tempChangedValues = [...changedValues];
        const changedValueIndex = tempChangedValues.findIndex(item=>item.id === updatedValues[index][idx].id)
        if (changedValueIndex === -1) {
            tempChangedValues.push(updatedValues[index][idx]);
        } else {
            tempChangedValues[changedValueIndex] = updatedValues[index][idx]
        }
        setValues(updatedValues);
        handleUpdate(tempChangedValues)
    };

    const handleButtonClick = (id) => {
        document.getElementById(id).click();
    };
    const handleFileChange = (event,index) => {
        const files = event.target.files;
        if (files) {
            onFileSelect(files,index);
        }
    };
    const handleCapture = (event,index) => {
        const file = event.target.files[0];
        if (file) {
            onFileSelect([file],index);
        }
    };
    return (
        <Col lg={9}>
            <InspectionReportComponentForPrint vehicle={vehicle} inspectionData={inspectionItems} parsable={true}/>
            <div className="inspection-grid">
                {inspectionItems.map((item,index)=>{
                    return (
                        <Fragment key={`${index}__`}>
                            <InspectionButton
                                height={'37px'}
                                width={'220px'}
                                backgroundColor={selectedInspection === index ? '#000000' : null}
                                color={selectedInspection === index ? '#ffffff' : null}
                                radius={'32px'}
                                onClick={()=>selectInspectionItem(index)}
                            >
                                {item.name}
                            </InspectionButton>
                        </Fragment>
                    )
                })}
            </div>
            <Card className={'mt-4'}>
                <CardHeader className={'border-bottom-0'}>
                    <CustomHeader fontSize={'16px'}>
                       Item Status
                    </CustomHeader>
                </CardHeader>
                    {inspectionItems[activeIndex]?.type === 'image' ?
                       <CardBody>
                           <div className={'d-flex g-5'}>
                               <div className={'position-relative'}>
                                   {inspectionItems[activeIndex]?.options.length ?
                                       inspectionItems[activeIndex]?.options.map((photo,idx)=>{
                                           return   <Fragment key={'f_'+idx}>
                                               <StyledImage width={'40px'}
                                                            height={'40px'}
                                                            src={photo?.path ?
                                                                photo?.path :
                                                                photo?.preview } alt=""/>
                                               <CrossIconContainer>
                                                   <IcoMoon
                                                       iconSet={IconSet}
                                                       icon={'cross'}
                                                       size={13}
                                                       onClick={() => {
                                                           deleteDocument(idx,activeIndex)
                                                       }}
                                                   />
                                               </CrossIconContainer>
                                           </Fragment>
                                       }) : null
                                   }

                               </div>
                               <AddImageButton onClick={()=>handleButtonClick(`input_${activeIndex}`)}>
                                   +
                                   <input
                                       type="file"
                                       multiple
                                       id={`input_${activeIndex}`}
                                       style={{ display: 'none' }}
                                       onChange={(e)=>{
                                           handleFileChange(e,activeIndex)
                                       }}
                                   />
                               </AddImageButton>
                               <AddImageButton backgroundColor={'#fff'} onClick={()=>handleButtonClick(`camera_${activeIndex}`)}>
                                   &#128247;
                                   <input
                                       type="file"
                                       accept="image/*"
                                       id={`camera_${activeIndex}`}
                                       style={{ display: 'none' }}
                                       capture="camera"
                                       onChange={(e)=>{
                                           handleCapture(e,activeIndex)
                                       }}
                                   />
                               </AddImageButton>

                           </div>
                       </CardBody>
                        :  values.length ? <CardBody>
                            <Row>
                                {values.map((arr,index)=>{
                                    return <Fragment key={`8_${index}`}>
                                        <Col lg={6}>
                                            <StyledTable className={'table-borderless'}>
                                                <thead className={'header-white-text'}>
                                                <tr key={`0_${index}`}>
                                                    <StyledTableHeaderCell className={'text-center'} width={'50%'} color={'#0000008C'}>
                                                        Item
                                                    </StyledTableHeaderCell>

                                                    <StyledTableHeaderCell className={'text-center'} width={'20%'} color={'#0000008C'}>
                                                        Status
                                                    </StyledTableHeaderCell>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    arr.map((item,idx)=>{
                                                        return <tr key={`1_${idx}`}>
                                                            <CustomTD className={'text-left'} width={'50%'}>
                                                                <CustomHeader fontSize={'16px'}>
                                                                    {item.name}
                                                                </CustomHeader>
                                                            </CustomTD>
                                                            <CustomTD className={'text-center'} width={'50%'}>
                                                                <CustomDropdownComponent
                                                                    selectedTitle={item.status}
                                                                    handleChange={(data)=>{
                                                                        handleChange(data,index,idx)
                                                                    }}

                                                                />
                                                            </CustomTD>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </StyledTable>
                                        </Col>
                                    </Fragment>
                                })}

                            </Row>
                        </CardBody> : null
                    }
            </Card>
            <Card>
                <CardHeader className={'border-bottom-0'}>
                    <CustomHeader fontSize={'16px'}>
                        Work Required
                    </CustomHeader>
                </CardHeader>
                <CardBody>
                    <StyledTable className={'table-borderless'}>
                        <thead className={'header-white-text'}>
                        <tr>
                            <StyledTableHeaderCell className={'text-center'} width={'80%'} color={'#0000008C'}>
                                Task
                            </StyledTableHeaderCell>

                            <StyledTableHeaderCell width={'10%'}>

                            </StyledTableHeaderCell>
                        </tr>
                        </thead>
                        <tbody>
                        {data.length ?
                            data.map((item, index)=>{
                                return <tr key={`4_${index}`}>
                                    <CustomTD width={'80%'}>
                                        <CustomInput radius={'8px'}
                                                     onChange={(e)=>{
                                                         changeData({
                                                             index,
                                                             key: 'title',
                                                             value: e.target.value,
                                                             inspection: true
                                                         })
                                                     }
                                                     }
                                                     border={'1px solid #C0D9FF59'}
                                                     value={item.title} />
                                    </CustomTD>

                                    <CustomTD width={'10%'}>
                                        <IconContainer>
                                            <IcoMoon
                                                className={'cursor-pointer'}
                                                iconSet={IconSet}
                                                icon={'delete_new_outline'}
                                                size={20}
                                                onClick={() => {
                                                    deleteRow(index)
                                                }}
                                            />
                                        </IconContainer>

                                    </CustomTD>
                                </tr>
                            })
                            : null
                        }
                        <tr>
                            <td colSpan="2" className={'text-center'}>
                                <FleetButton
                                    height={'43px'}
                                    width={'114px'}
                                    backgroundColor={'#FDF0E4'}
                                    color={'#F3994A'}
                                    fontSize={'14px'}
                                    radius={'32px'}
                                    onClick={addNewRow}
                                >
                                    &#43; Add new
                                </FleetButton>
                            </td>
                        </tr>

                        </tbody>
                    </StyledTable>
                </CardBody>
            </Card>
        </Col>
    )
}