import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';
import * as constants from '../../constants';
import { requestSuccess } from '../../actions/general.actions';

function* getVehicle(action) {
  try {
    const response = yield axios.get(`${constants.LIVE_VEHICLES_MS_API_URL}/${action.payload.id}`);
    const optionDataResponse = yield axios.get(`${constants.API_URL}/live-vehicles-detail/${action.payload.vehicle}`);

    if (response?.status === 200 && optionDataResponse?.status === 200) {
      const data = response.data.data;
      const { dealStatuses, soldTypes, settlementCost } = optionDataResponse.data.data;
      yield put({ type: actionTypes.LIVE_VEHICLE_EDIT_SUCCESS, data, dealStatuses, soldTypes, settlementCost });
    }

  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.LIVE_VEHICLE_EDIT_ERROR, payload });
  }
}

function* getVehicleJobs(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/fleet-management/${action.payload.vehicleId}/jobs`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.FLEET_MANAGEMENT_VEHICLE_JOB_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.FLEET_MANAGEMENT_VEHICLE_JOB_ERROR, payload });
  }
}

function* fetchIntervalStats(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/live-vehicles-stats?min=${action.payload.min}&max=${action.payload.max}`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.LIVE_VEHICLE_STATS_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.LIVE_VEHICLE_STATS_ERROR, payload });
  }
}

function* fetchMonthlyTargets(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/live-vehicles-stats/target?month=${action.payload.month}&year=${action.payload.year}`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.MONTHLY_TARGETS_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.MONTHLY_TARGETS_ERROR, payload });
  }
}

function* fetchMonthSummary(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/live-vehicles-stats/summary?month=${action.payload.month}&year=${action.payload.year}`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.FETCH_MONTHLY_SUMMARY_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.FETCH_MONTHLY_SUMMARY_ERROR, payload });
  }
}

function* uploadReportToGoogle(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/upload-to-google-sheet`,{
      month: action.payload.month,
      year: action.payload.year,
    });
    if (response?.status === 200) {
      yield put({ type: actionTypes.UPLOAD_MONTHLY_REPORT_TO_DRIVE_SUCCESS });
    }
  } catch (error) {
    yield put({ type: actionTypes.UPLOAD_MONTHLY_REPORT_TO_DRIVE_ERROR, });
  }
}
function* fetchVehicleReport(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/live-vehicles-stats/vehicle-report?month=${action.payload.month}&year=${action.payload.year}`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.FETCH_VEHICLE_REPORT_SUCCESS, data: data.report });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.FETCH_VEHICLE_REPORT_ERROR, payload });
  }
}

function* saveIntervalStats(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/live-vehicles-stats`, {
      stats: action.payload.stats
    });
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.LIVE_VEHICLE_SAVE__STATS_SUCCESS, data });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.LIVE_VEHICLE_STATS_ERROR, payload });
  }
}


function* updateVehicle(action) {
  try {
    let data = { ...action.payload.data };
    Object.keys(data).forEach(key => {
      if (data[key] === '-') data[key] = "";
    });

    // update old app
    const response = yield axios.post(`${constants.API_URL}/live-vehicles-detail/${action.payload.vehicleId}`, {
      ...data,
    });

    console.log(response);
    // update microservices
    const responseMS = yield axios.put(`${constants.LIVE_VEHICLES_MS_API_URL}/${action.payload.liveId}`, {
      ...data,
    });

    console.info('RESPONSE FROM LIVE MS UPDATE ::: ', responseMS);
    if (responseMS?.status === 201) {
      const vehicle = responseMS.data.data;
      let message = responseMS.data.message ?? 'Succesfully saved';
      yield put({ type: actionTypes.UPDATE_VEHICLE_SUCCESS, vehicle, message });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_VEHICLE_ERROR, payload });
  }
}

function* saveMonthlyTargets(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/live-vehicles-stats/target`, {
      ...action.payload.inputs,
      month: action.payload.month,
      year: action.payload.year,

    });
    if (response?.status === 200) {
      yield put(requestSuccess(action.type, "Successfully saved data"));
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.LIVE_VEHICLE_STATS_ERROR, payload });
  }
}
function* uploadVehicleReport(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/upload-vehicle-to-google`);
    if (response?.status === 200) {
      yield put({ type: actionTypes.UPLOAD_VEHICLES_REPORT_TO_DRIVE_SUCCESS });
    }
  } catch (error) {
    yield put({ type: actionTypes.UPLOAD_VEHICLES_REPORT_TO_DRIVE_ERROR, });
  }
}
function* uploadDeliveryReport(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/delivery-monthly-report-to-google`,{
      month: action.payload.month,
      year: action.payload.year,
    });

    if (response?.status === 200) {
      yield put({ type: actionTypes.UPLOAD_DELIVERY_REPORT_TO_DRIVE_SUCCESS});
    }
  } catch (error) {
    yield put({ type: actionTypes.UPLOAD_DELIVERY_REPORT_TO_DRIVE_ERROR });
  }
}
function* uploadFleetStatsReport() {
  try {
    const response = yield axios.post(`${constants.API_URL}/upload-fleet-stats-to-google`);

    if (response?.status === 200) {
      yield put({ type: actionTypes.FLEET_STATS_REPORT_TO_DRIVE_SUCCESS});
    }
  } catch (error) {
    yield put({ type: actionTypes.FLEET_STATS_REPORT_TO_DRIVE_ERROR});
  }
}

function* uploadVehicleCheck(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/upload-future-delivery-deals-to-google`,{
      month: action.payload.month,
      year: action.payload.year,
    });

    if (response?.status === 200) {
      yield put({ type: actionTypes.UPLOAD_VEHICLE_CHECK_TO_DRIVE_SUCCESS});
    }
  } catch (error) {
    yield put({ type: actionTypes.UPLOAD_VEHICLE_CHECK_TO_DRIVE_ERROR});
  }
}

function* generateTasksRequest(action) {
  const { payload: { taskType } } = action;
  try {
    const response = yield axios.post(`${constants.API_URL}/tasks-export/${taskType}`);

    if (response?.status === 200) {
      yield put({ type: actionTypes.GENERATE_TASKS_SUCCESS});
    }
  } catch (error) {
    yield put({ type: actionTypes.GENERATE_TASKS_ERROR});
  }
}
function* getVehicleMakes() {
  try {
    const response = yield axios.get(`${constants.BASE_URL}/api/front-v1/makes-and-models`);

    if (response?.status === 200) {
      const { data } = response.data;
      yield put({ type: actionTypes.GET_VEHICLE_MAKES_SUCCESS, data: data.makes });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_VEHICLE_MAKES_ERROR});
  }
}


export default function* watcherSaga() {
  yield takeLatest(actionTypes.LIVE_VEHICLE_EDIT_REQUEST, getVehicle);
  yield takeLatest(actionTypes.FLEET_MANAGEMENT_VEHICLE_JOB_REQUEST, getVehicleJobs);
  yield takeLatest(actionTypes.UPDATE_VEHICLE_REQUEST, updateVehicle);
  yield takeLatest(actionTypes.LIVE_VEHICLE_STATS_REQUEST, fetchIntervalStats);
  yield takeLatest(actionTypes.LIVE_VEHICLE_SAVE_STATS_REQUEST, saveIntervalStats);
  yield takeLatest(actionTypes.MONTHLY_TARGETS_REQUEST, fetchMonthlyTargets);
  yield takeLatest(actionTypes.SAVE_MONTHLY_TARGETS_REQUEST, saveMonthlyTargets);
  yield takeLatest(actionTypes.FETCH_VEHICLE_REPORT_REQUEST, fetchVehicleReport);
  yield takeLatest(actionTypes.FETCH_MONTHLY_SUMMARY_REQUEST, fetchMonthSummary);
  yield takeLatest(actionTypes.UPLOAD_MONTHLY_REPORT_TO_DRIVE_REQUEST, uploadReportToGoogle);
  yield takeLatest(actionTypes.UPLOAD_VEHICLES_REPORT_TO_DRIVE_REQUEST, uploadVehicleReport);
  yield takeLatest(actionTypes.UPLOAD_DELIVERY_REPORT_TO_DRIVE_REQUEST, uploadDeliveryReport);
  yield takeLatest(actionTypes.FLEET_STATS_REPORT_TO_DRIVE_REQUEST, uploadFleetStatsReport);
  yield takeLatest(actionTypes.UPLOAD_VEHICLE_CHECK_TO_DRIVE_REQUEST, uploadVehicleCheck);
  yield takeLatest(actionTypes.GENERATE_TASKS_REQUEST, generateTasksRequest);
  yield takeLatest(actionTypes.GET_VEHICLE_MAKES_REQUEST, getVehicleMakes);
}
