import React from 'react'
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import DatePicker from "react-datepicker";
import moment from "moment";
export default function Picker({onChange,value,placeholderText})
{
    return (
        <div className={'d-flex g-5 align-items-center'}>
            <DatePicker
                dateFormat="YYYY/MM/DD"
                value={value}
                toggleCalendarOnIconClick
                showIcon
                placeholderText={placeholderText}
                onChange={(date)=>onChange(moment(date).format('YYYY-MM-DD'))}
                icon={
                    <IcoMoon
                        className={'cursor-pointer'}
                        iconSet={IconSet}
                        icon={'ic_calendar'}
                        size={24}/>
                }
            />
            {
               value && <IcoMoon
                    className={'cursor-pointer'}
                    iconSet={IconSet}
                    icon={'close-svgrepo-com'}
                    size={24}
                    onClick={()=>{
                        onChange('')
                    }}
                />
            }
        </div>
    )
}