import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
  CustomHeader,
  CustomInput,
  FleetButton, CustomContainer,
  RequiredItemsBadge, ProfileImage
} from "@pages/new-design-components/custom-components";
import {ClipLoader} from "react-spinners";
import {LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  GET_COMMUNICATION_LOGS_REQUEST,
  GET_CUSTOMER_DEBT_REQUEST,
  GET_CUSTOMER_REQUEST,
  GET_SYSTEM_NOTES_REQUEST,
  SEND_SYSTEM_NOTE_REQUEST, UPDATE_CUSTOMER_DEBT_REQUEST
} from "@redux/Customer/actions";
import customer from "@redux/Customer/reducer";
import usePrevious from "../../../hooks/useprevious";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {Notification} from "@pages/components/Notification";
import NotificationAlert from "react-notification-alert";
import {API_URL, parseToWord} from "../../../constants";
import NotesComponent from "@pages/components/DebtManagement/CustomerOverview/NotesComponent";
import DebtListComponent from "@pages/components/DebtManagement/CustomerOverview/DebtListComponent";
import CreateEditDebtModal from "@pages/components/DebtManagement/CustomerOverview/CreateEditDebtModal";
import PaymentsComponent from "@pages/components/DebtManagement/CustomerOverview/PaymentsComponent";
import CreateEditPaymentModal from "@pages/components/DebtManagement/CustomerOverview/CreateEditPaymentModal";
import CommunicationsLog from "@pages/components/DebtManagement/CustomerOverview/CommunicationsLog";
import {GET_EMAIL_TEMPLATES_REQUEST, GET_SYSTEM_TEMPLATES_REQUEST} from "@redux/Email/actions";
import SendQuoteEmail from "@pages/components/DebtManagement/SendQuoteEmail";
import EmailChat from "@pages/components/DebtManagement/EmailChat";
import axios from "axios";
import * as constants from "../../../constants";
import MessagingComponent from "@pages/components/DebtManagement/MessagingComponent";

export default function CustomerOverview() {
  const location = useLocation()
  const notificationAlert = useRef(null)

  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);

  const customerId = queryParams.get('customer_id');
  const [loading,setLoading] = useState(true);

  const [customerData,setCustomerData] = useState({});
  const [filter,setFilter] = useState({
    from: '',
    to: ''
  });
  const [debtFilter,setDebtFilter] = useState({

  });


  const [error,setError] = useState({
    message: {
      show: false,
      message: ''
    }
  });

  const [newMessage,setNewMessage] = useState(null);
  const [activeTab, setActiveTab] = useState('notes');
  const [activeNoteTab, setActiveNoteTab] = useState('all');

  const [notes, setNotes] = useState([]);
  const [debts, setDebts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [deal, setDeal] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([
    {
      cardNumber: '5105 1051 0510 5100',
      expirationDate: '11/25',
      cvv: '456',
    },
    {
      cardNumber: '4111 1111 1111 1111',
      expirationDate: '12/25',
      cvv: '123',
    },
    {
      cardNumber: '4222 2222 2222 2222',
      expirationDate: '05/26',
      cvv: '789',
    },
    {
      cardNumber: '5100 1234 5678 9010',
      expirationDate: '09/24',
      cvv: '321',
    },
  ]);
  const [activeCommunicationTab, setActiveCommunicationTab] = useState('all');
  const communicationTabs = [
    {
      key: 'all',
      label: 'All',
    },
    {
      key: 'ProcessedEmail',
      label: 'Email',
    },
    {
      key: 'SystemNotes',
      label: 'Text',
    },
  ]
  const [tempDebt,setTempDebt] = useState({});
  const [tempPayment,setTempPayment] = useState({});
  const [tempDebtIndex,setTempDebtIndex] = useState(null);
  const [openDebtModal,setOpenDebtModal] = useState(false);
  const [templateData,setTemplateData] = useState({});
  const [openEmailChat,setOpenEmailChat] = useState(false);
  const [openMessaging,setOpenMessaging] = useState(false);
  const [openSentQuoteEmail,setOpenSentQuoteEmail] = useState(false);
  const [openPaymentModal,setOpenPaymentModal] = useState(false);
  const {systemTemplates} = useSelector(
      (state) => state.emailTemplateState,
      shallowEqual
  );
  const { getCustomerSuccess,
          getCustomerError,
          customer,
          sendSystemNoteSuccess,
          sendSystemNoteError,
          getSystemNotesError,
          getSystemNotesSuccess,
          systemNotes,
          customerDebts,
          getCustomerDebtError,
          getCustomerDebtSuccess,
          updateCustomerDebtsError,
          updateCustomerDebtSuccess,
          customerDebt,
          getCommunicationLogsSuccess,
          getCommunicationLogsError,
          communicationLogs,
  } = useSelector((state) => state.customer, shallowEqual);
  const profileData = useSelector((state) => state.getState).profileData;

  const getCustomerSuccessPrev = usePrevious(getCustomerSuccess);
  const getCustomerErrorPrev = usePrevious(getCustomerError);

  const sendSystemNoteSuccessPrev = usePrevious(sendSystemNoteSuccess);
  const sendSystemNoteErrorPrev = usePrevious(sendSystemNoteError);

  const getSystemNotesSuccessPrev = usePrevious(getSystemNotesSuccess);
  const getSystemNotesErrorPrev = usePrevious(getSystemNotesError);

  const getCommunicationLogsSuccessPrev = usePrevious(getCommunicationLogsSuccess);
  const getCommunicationLogsErrorPrev = usePrevious(getCommunicationLogsError);

  const getCustomerDebtSuccessPrev = usePrevious(getCustomerDebtSuccess);
  const getCustomerDebtErrorPrev = usePrevious(getCustomerDebtError);

  const updateCustomerDebtSuccessPrev = usePrevious(updateCustomerDebtSuccess);
  const updateCustomerDebtsErrorPrev = usePrevious(updateCustomerDebtsError);


  const tabs = [
    {
      key: 'notes',
      label: 'Notes',
    },
    {
      key: 'debt_list',
      label: 'Debt List',
    },
    {
      key: 'payments',
      label: 'Payments',
    },
    {
      key: 'communication_log',
      label: 'Communication Log',
    },
  ]

  const filterNotes = useCallback(()=>{
     if (activeNoteTab === 'all') {
       return notes
     } else {
      return  notes.filter(note=>note.noteable_type === activeNoteTab)
     }
  },[activeNoteTab,notes])
  const filterCommunicationLogs = useCallback(()=>{
    if (activeCommunicationTab === 'all') {
      return communicationLogs
    } else {
      return  communicationLogs.filter(note=>note.source_type === activeCommunicationTab)
    }
  },[activeCommunicationTab,communicationLogs])

  const noteTabs = [
    {
      key: 'all',
      label: 'All',
    },
    {
      key: 'Customer',
      label: 'Customer',
    },
    {
      key: 'Payment',
      label: 'Payment',
    },
    {
      key: 'Deal',
      label: 'Deal',
    },
  ]

  useEffect(()=>{
    if (getCustomerSuccess && getCustomerSuccessPrev === false) {
      setCustomerData(customer)
        dispatch({
          type: GET_SYSTEM_NOTES_REQUEST,
          payload: { id: profileData.id, type: 'User' }
        });
      }
  },[getCustomerSuccess,getCustomerSuccessPrev])

  useEffect(()=>{
    if (getSystemNotesSuccess && getSystemNotesSuccessPrev === false) {
      setNotes(systemNotes)
      fetchDebts()
    }
  },[getSystemNotesSuccess,getSystemNotesSuccessPrev])

  useEffect(()=>{
    if (getCustomerDebtSuccess && getCustomerDebtSuccessPrev === false) {
      const tDebts = customerDebts.customer_debt;
      setDebts([...tDebts]);
      const allPayments = tDebts.flatMap(debt =>
          (debt.payments || []).map(payment => ({
            ...payment,
            ['fleet']: debt.vehicle
                ? `${debt.vehicle.stock_no} ${debt.vehicle.derivative}`
                : "No Vehicle"
          }))
      );
      setPayments(allPayments);
      setDeal(customerDebts.deal[0]);
      if (loading) {
        fetchCommunicationLogs()
      }
    }
  },[getCustomerDebtSuccessPrev,getCustomerDebtSuccess,loading])
  const downloadInvoice = (index) => {
    axios({
      method: 'GET',
      url: API_URL + '/deal/' + deal.id + '/debt-invoice/' + payments[index].customer_invoice_debt_id,

    })
        .then(response => {
          if (response.status === 200) {
            let data = response.data;
            let invoice = data.data.invoice;
            window.open(invoice, "_blank");
          }
        })
        .catch(error => {
          console.error('error :: ', error.response);
          if (error.response.status === 422) {
          }
          Notification('warning', 'Error', 'Something went wrong', notificationAlert);
        })
  }
  useEffect(()=>{
    if (getCommunicationLogsSuccess && getCommunicationLogsSuccessPrev === false) {
       setLoading(false)
    }
  },[getCommunicationLogsSuccessPrev,getCommunicationLogsSuccess])

  useEffect(()=>{
    if (getCommunicationLogsError && getCommunicationLogsErrorPrev === false) {
      setLoading(false)
      Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
    }
  },[getCommunicationLogsErrorPrev,getCommunicationLogsError])

  useEffect(()=>{
    if (updateCustomerDebtSuccess && updateCustomerDebtSuccessPrev === false) {
      let tempDebts = [...debts]
      if (tempDebtIndex !== null) {
        tempDebts[tempDebtIndex] = customerDebt
      } else {
        tempDebts.push(customerDebt)
      }
      setDebts(tempDebts)
      setTempDebtIndex(null)
      setTempDebt({})
      setOpenDebtModal(false)
      setLoading(false)
    }
  },[updateCustomerDebtSuccessPrev,updateCustomerDebtSuccess])

  useEffect(()=>{
    if (updateCustomerDebtsError && updateCustomerDebtsErrorPrev === false) {
       setLoading(false)
       Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
    }
  },[updateCustomerDebtsErrorPrev,updateCustomerDebtsError])

  useEffect(()=>{
    if (getCustomerDebtError && getCustomerDebtErrorPrev === false) {
       setLoading(false)
    }
  },[getCustomerDebtError,getCustomerDebtErrorPrev])

  useEffect(()=>{
    if (getSystemNotesError && getSystemNotesErrorPrev === false) {
      setLoading(false)
      Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
    }
  },[getSystemNotesError,getSystemNotesErrorPrev])

  useEffect(()=>{
    if (sendSystemNoteSuccess && sendSystemNoteSuccessPrev === false) {
       Notification('success', 'Success!', 'Sent successfully',notificationAlert);
    }
  },[sendSystemNoteSuccess,sendSystemNoteSuccessPrev])

  useEffect(()=>{
    if (sendSystemNoteError && sendSystemNoteErrorPrev === false) {
       Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
    }
  },[sendSystemNoteError,sendSystemNoteErrorPrev])

  useEffect(()=>{
    if (getCustomerError && getCustomerErrorPrev === false) {
       setLoading(false)
      Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
    }
  },[getCustomerError,getCustomerErrorPrev])

  useEffect(()=>{
    if (customerId) {
      dispatch({
        type: GET_CUSTOMER_REQUEST,
        payload: { id: customerId }
      });
      dispatch({
        type: GET_SYSTEM_TEMPLATES_REQUEST,
        payload: {
          type: 'email'
        }
      });
    }
  },[customerId])

  const fetchDebts = (tempFilter = {}) => {
    dispatch({
      type: GET_CUSTOMER_DEBT_REQUEST,
      payload: { id: customer.id, filter: tempFilter }
    });
  }
  const fetchCommunicationLogs = (tempFilter = {}) => {
    dispatch({
      type: GET_COMMUNICATION_LOGS_REQUEST,
      payload: { id: customer.id, type: 'Customer', ...tempFilter }
    });
  }
  const sendMessage = () => {
    let obj = {
      'creator_id': profileData.id,
      'creator_type': 'User',
      'note': newMessage.message,
      'write_log': true,
    }
    if (activeNoteTab === 'all' || activeNoteTab === 'Customer') {
        obj['noteable_id'] = customerData.id
        obj['noteable_type'] = 'Customer'
    }
    dispatch({
      type: SEND_SYSTEM_NOTE_REQUEST,
      payload: obj
    });
  }

  const createDebt = () => {
    let obj = {
      'deal_type': '',
      'vehicle': {},
      'due_date': '',
      'overdue_duration': '',
      'expected_payment_date': '',
      'status': '',
      'amount': ''
    }
    setTempDebt(obj)
    setOpenDebtModal(true)
  }

  const createPayment = () => {
    let obj = {
      'invoice_to': '',
      'payment_method': '',
      'status': '',
      'fleet': '',
      'amount': '',
      'deal_type': '',
      'due_date': '',
      'debt': ''
    }
    setTempPayment(obj)
    setOpenPaymentModal(true)
  }

  const openNewEmail = () => {
    setOpenEmailChat(true)
  }
  const openNewMessage = () => {
    setOpenMessaging(true)
  }

  const editDebt = (index) => {
    setTempDebtIndex(index)
    const tempObj = {...debts[index]}
    setTempDebt(tempObj)
    setOpenDebtModal(true)
  }

  const closeDebtModal = () => {
    setOpenDebtModal(false)
    setTempDebtIndex(null)
    setTempDebt({})
  }

  const closePaymentModal = () => {
    setOpenPaymentModal(false)
    setTempDebtIndex(null)
    setTempPayment({})
  }

  const savePayment = (obj) => {
    let tempPayments = [...payments]
    if (tempDebtIndex !== null) {
      tempPayments[tempDebtIndex] = obj
    } else {
      tempPayments.push(obj)
    }
    setPayments(tempPayments)
    setTempDebtIndex(null)
    setTempPayment({})
    setOpenPaymentModal(false)
  }

  const saveDebt = (obj) => {
    dispatch({
      type: UPDATE_CUSTOMER_DEBT_REQUEST,
      payload: obj
    });
  }

  const saveNewCard = (newCard) => {
    let tempMethods = [...paymentMethods]
    tempMethods.push(newCard)
    setPaymentMethods(tempMethods)
  }

  const transformData = (data) => {
    setTemplateData(data.data)
    setOpenEmailChat(false)
    setOpenSentQuoteEmail(true)
  }
  function sendEmail({data}) {
    let formData = new FormData();
    formData.append('message',data?.message);
    formData.append('subject',data?.subject);
    formData.append('write_log',true);
    formData.append('to[]',data.email);
    if (data.hasOwnProperty('documents') && data.documents.length) {
      for (const document of data?.documents) {
        if (document.file) {
          formData.append('fileLists[]', document.file);
        }
      }
    }

    formData.append('resource_id',customer.id);
    formData.append('resource_type','Customer');
    axios({
      method: 'POST',
      url: constants.API_URL + '/processor-emails',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData
    }).then(res=>{
      console.log('res:',res)
    }).catch(err=>{
      console.log('err:',err)
    })

  }
  const updateFilter = ({key,value}) => {
    let overviewTemp = {...filter}
    overviewTemp[key] = value
    setFilter(overviewTemp)
    fetchCommunicationLogs(overviewTemp)
  }
  const updateDebtFilter = ({key,value}) => {
    let overviewTemp = {...debtFilter}
    overviewTemp[key] = value
    if (key === 'order_by') {
      overviewTemp['order'] = !overviewTemp['order']
    }
    setDebtFilter(overviewTemp)
    fetchDebts(overviewTemp)
  }
  return (
      loading ? <LoaderContainer>
            <ClipLoader
                css={override}
                size={40}
                color={`#7B61E4`}
                loading={loading}
            />
          </LoaderContainer>
          :
          <div className={'container-fluid debt-background pt-3'}>
            {
              openSentQuoteEmail ? <SendQuoteEmail templates={systemTemplates} sendEmail={sendEmail} data={templateData} setShowSendQuoteEmail={()=>{
                    setOpenSentQuoteEmail(false)
                  }}/>  :
              <>

            <CreateEditDebtModal data={tempDebt}
                                 isOpen={openDebtModal}
                                 cancel={closeDebtModal}
                                 dataIndex={tempDebtIndex}
                                 updateData={saveDebt}/>

            <CreateEditPaymentModal data={tempPayment}
                                 isOpen={openPaymentModal}
                                 paymentMethods={paymentMethods}
                                 saveCard={saveNewCard}
                                 cancel={closePaymentModal}
                                 dataIndex={tempDebtIndex}
                                 updateData={savePayment}/>
            <EmailChat transformData={transformData} isOpen={openEmailChat} sendEmail={sendEmail} templates={systemTemplates} data={customer} setShowSendQuoteEmail={()=>{setOpenEmailChat(false)}}/>
            <div className="rna-wrapper">
              <NotificationAlert ref={notificationAlert} />
            </div>
            <Row>
              <Col lg={3}>
                <Card className={'fleet-details-container'}>
                  <CardHeader className={'border-bottom-0'}>
                    <div className={'d-flex justify-content-between'}>

                      <CustomHeader fontSize={'16px'}>
                        Customer Details
                      </CustomHeader>

                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className={'d-flex g-5'}>
                      <IcoMoon
                          className={'cursor-pointer'}
                          iconSet={IconSet}
                          icon={'re_user'}
                          size={25}
                      />
                      <CustomHeader fontSize={'14px'}  weight={'700'}>
                        Contact Information:
                      </CustomHeader>
                    </div>
                    <div className={'ml-3'}>
                      <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                          Title:
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                          {customerData.title.toUpperCase()}
                        </CustomHeader>
                      </div>
                      <div className={'d-flex flex-column'}>
                        <CustomHeader fontSize={'14px'} weight={'700'} color={'#74787E'} className={'mb-0'}>
                          Name:
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                          {customerData.first_name}
                        </CustomHeader>
                      </div>
                      <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                          Email:
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                          {customerData?.email}
                        </CustomHeader>
                      </div>
                      <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                          Mobile:
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                          {customerData?.mobile_number}
                        </CustomHeader>
                      </div>
                      <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                          Landline:
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                          {customerData?.land_line_number ?? '-'}
                        </CustomHeader>
                      </div>
                    </div>
                    <div className={'d-flex g-5'}>
                      <IcoMoon
                          className={'cursor-pointer department-icon'}
                          iconSet={IconSet}
                          icon={'location'}
                          size={25}
                      />
                      <CustomHeader fontSize={'14px'}  weight={'700'}>
                        Address Information:
                      </CustomHeader>
                    </div>
                     <div className={'ml-3'}>
                       <div className={'d-flex flex-column'}>
                         <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                           Address:
                         </CustomHeader>
                         <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                           {customerData?.address ?? '-'}
                         </CustomHeader>
                       </div>
                       <div className={'d-flex flex-column'}>
                         <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                           Property Status:
                         </CustomHeader>
                         <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                           {'-'}
                         </CustomHeader>
                       </div>
                     </div>
                    <div className={'d-flex g-5'}>
                      <IcoMoon
                          className={'cursor-pointer'}
                          iconSet={IconSet}
                          icon={'re_bank'}
                          size={25}
                      />
                      <CustomHeader fontSize={'14px'}  weight={'700'}>
                        Bank Information:
                      </CustomHeader>
                    </div>

                    <div className={'d-flex flex-column ml-3'}>
                      <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                        {customerData?.bank_name}
                      </CustomHeader>
                      <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                        {customerData?.bank_account_name}
                      </CustomHeader>
                      <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                        {customerData?.bank_account_number}
                      </CustomHeader>
                      <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                        {customerData?.bank_sort_code}
                      </CustomHeader>
                    </div>
                    <div className={'d-flex g-5'}>
                      <IcoMoon
                          className={'cursor-pointer'}
                          iconSet={IconSet}
                          icon={'re_Car'}
                          size={25}
                      />
                      <CustomHeader fontSize={'14px'}  weight={'700'}>
                        Driver License:
                      </CustomHeader>
                    </div>

                    <div className={'ml-3'}>
                      <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                          Number:
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                          {customerData?.driver_license_number}
                        </CustomHeader>
                      </div>
                      <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                          Country of Issue:
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                          {customerData?.country_of_issue}
                        </CustomHeader>
                      </div>
                      <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                          Issue Date:
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                          {customerData?.licence_issue_date}
                        </CustomHeader>
                      </div>
                      <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                          Expiry Date:
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                          {customerData?.licence_expiry_date}
                        </CustomHeader>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={9}>
                {
                  openMessaging ? <MessagingComponent  notes={filterNotes()}
                                                       sendMessage={sendMessage}
                                                       closeComponent={()=>{
                                                         setOpenMessaging(false)
                                                       }}
                                                       newMessage={newMessage}
                                                       setNewMessage={setNewMessage}
                                                       error={error}
                                                       setError={setError}
                                                       picture={profileData?.picture}
                      /> :
                      <div className={'d-flex g-10 mb-3 flex-wrap'}>
                        {
                          tabs.map((tab,index) => {
                            return (
                                <Fragment key={'idx_'+index}>
                                  <RequiredItemsBadge type={tab.key} onClick={()=> {
                                    setActiveTab(tab.key);
                                  }
                                  } active={activeTab === tab.key}>
                                    {tab.label}
                                  </RequiredItemsBadge>
                                </Fragment>
                            )
                          })
                        }
                        <Col lg={12}>
                          {activeTab === 'notes' ?
                              <NotesComponent
                                  notes={filterNotes()}
                                  sendMessage={sendMessage}
                                  newMessage={newMessage}
                                  setNewMessage={setNewMessage}
                                  error={error}
                                  setError={setError}
                                  picture={profileData?.picture}
                                  noteTabs={noteTabs}
                                  activeNoteTab={activeNoteTab}
                                  setActiveNoteTab={setActiveNoteTab}
                              />
                              : activeTab === 'debt_list' ?
                                  <DebtListComponent createDebt={createDebt} debtsData={debts} editDebt={editDebt} filter={debtFilter} updateFilter={updateDebtFilter}/>
                                  : activeTab === 'payments' ?
                                      <PaymentsComponent tableData={payments} createPayment={createPayment} downloadInvoice={downloadInvoice}/>
                                      : activeTab === 'communication_log' ?
                                          <CommunicationsLog tableData={filterCommunicationLogs()} customer={customer}
                                                             activeCommunicationTab={activeCommunicationTab}
                                                             filter={filter}
                                                             updateFilter={updateFilter}
                                                             setActiveCommunicationTab={setActiveCommunicationTab}
                                                             tabs={communicationTabs}
                                                             openNewEmail={openNewEmail} openNewMessage={openNewMessage}/> : <></>
                          }
                        </Col>
                      </div>
                }
              </Col>
            </Row>
              </>
            }
          </div>

  )
}