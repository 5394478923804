import React, {useContext} from 'react'
import { LiveVehicleContext } from "./context";

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputKeySelect
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap'

import ChangeHandler from './ChangeHandler';
import { FilterButtons } from "@pages/reusable-components/styled-components";
import {parseToWord} from "../../../constants";
import {ButtonContainer} from "@pages/reusable-components/fleet-styled-components";
import {useHistory} from "react-router-dom";

export default function FleetVehicleList({home = null}) {
  const vehicleContext = useContext(LiveVehicleContext);
  const {
    notify,
    token,
    loading,
    items,
    vehicleStatuses,
    dealStatuses,
    filter, setFilter,
    pagination,
    setPageNumber,
    updateDataChanges,
    fetchData,
    filterVehicle,
    resetVehicle,
    submitItem,
  } = vehicleContext;
  Object.keys(dealStatuses)
    .filter(key => !['sold', 'available'].includes(key))
    .forEach(key => delete dealStatuses[key]);

  const history = useHistory()
  return (
    <>
      <Row>
        <Col xl="12">
          <ChangeHandler notify={notify} updateData={(vehicle) => updateDataChanges(vehicle)} />
        </Col>
        <Col xl="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs={8}>
                  <h1 className="mb-0">Fleet Management</h1>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container>
                <Row className="m-2 justify-content-md-center">
                  <Col md={3} sm={12} xs={12}>
                    <InputCustom
                      label={`Search`}
                      value={filter && filter.reg_no ? filter.reg_no : ''}
                      onChange={e => setFilter({
                        ...filter,
                        reg_no: e.target.value
                      })}
                    />
                  </Col>
                  <Col md={3} sm={12}>
                    <InputKeySelect
                      newLabel={'Vehicle Status'}
                      options={vehicleStatuses}
                      value={filter.vehicle_status ?? ''}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          vehicle_status: e.target.value
                        })
                      }}
                      defaultOption={true}
                    />
                  </Col>
                </Row>
                <Row className={'justify-content-center'}>
                  <Col lg={3} md={6} sm={12}>
                    <FilterButtons background="#fafafa" color="#ec6409" style={{ margin: 5 }} onClick={() => filterVehicle()}>Filter</FilterButtons>
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <FilterButtons reverse background="#ec6409" color="#fafafa" style={{ margin: 5 }} onClick={() => resetVehicle()}>Reset</FilterButtons>
                  </Col>
                </Row>
              </Container>
              {items.length > 0 && <Row>
                <Col xs={8}>
                  <h2 className="mb-0">Number of vehicles: {items.length}</h2>
                </Col>
              </Row>
              }
              {items.length > 0 &&
                <TableCustom
                   noOverflow
                  loading={loading}
                  items={items}
                  // stickyContent={(
                  //   !loading && items && items.length > 0 && <Row>
                  //     <Col sm={12}>
                  //       <Button className="w-100" color="success" style={{ margin: 5 }} type="button" onClick={() => submitItem()}>Save</Button>
                  //     </Col>
                  //   </Row>
                  // )}
                  pagination={pagination}
                  onChange={pageNumber => {
                    setPageNumber(pageNumber)
                    fetchData(pageNumber, token, filter)
                  }
                  }
                  columns={[

                    {
                      dataField: "stock_no",
                      text: "Stock No",
                      style: {
                        "fontWeight": 800
                      },
                      formatter: (rowContent, row) => {
                        return `${row.stock_no}`
                      }
                    },
                    {
                      dataField: "vehicle",
                      text: "Vehicle",
                      formatter: (rowContent, row) => {
                        let vehicle = `${row.vehicle}, ${row.derivative}`;
                        return <a href={`/admin/vehicle/${row.vehicle_id}/edit`} target='_blank'>{vehicle.length > 35 ? `${vehicle.substring(0, 35)}...` : vehicle}</a>;
                      }
                    },
                    {
                      dataField: "color",
                      text: "Color",
                      formatter: (rowContent, row) => {
                        return `${row.colour}`
                      }
                    },
                    {
                      dataField: "current_mileage",
                      text: "Mileage"
                    },
                    {
                      dataField: "sold_type",
                      text: "Sold Type",
                      formatter: (rowContent, row) => {
                        return (
                          `${row.sold_type ?? 'Rental Deal'}`
                        )
                      }
                    },
                    {
                      dataField: "deal_id",
                      text: "Deal",
                      formatter: (rowContent, row) => {
                        return row.deal_id ? <a className="text-center" href={`/admin/deals/${row.deal_id}/edit`} target='_blank'>#{row.deal_id}</a> : '-';
                      }
                    },
                    {
                      dataField: "grs_collection_date",
                      text: "GRS Collection Date",
                      formatter: (rowContent, row) => {
                        return row.grs_collection_date ?? '-';
                      }
                    },
                    {
                      dataField: "body shop",
                      text: "Body Shop",
                      formatter: (rowContent, row) => {
                        if (row.departments && row.departments.length) {
                          let department = row.departments.find(item=>item.name === 'body shop')
                          let color = department.status === 'in_review' ? '#FFC231' :
                              department.status === 'in_progress' ? '#000' :
                                  department.status === 'completed' ? '#00B170' :
                                      department.status === 'needed' ? '#FF1E03' : '#000'
                          return  department ? <div style={{color: color}}>
                            {parseToWord(department.statusLabel)}
                          </div> : '-'
                        } else return <div>-</div>
                      }
                    },
                    {
                      dataField: "valeting",
                      text: "Valeting",
                      formatter: (rowContent, row) => {
                        if (row.departments && row.departments.length) {
                          let department = row.departments.find(item=>item.name === 'valeting')
                          let color = department.status === 'in_review' ? '#FFC231' :
                              department.status === 'in_progress' ? '#000' :
                                  department.status === 'completed' ? '#00B170' :
                                      department.status === 'needed' ? '#FF1E03' : '#000'
                          return  department ? <div style={{color: color}}>
                            {parseToWord(department.statusLabel)}
                          </div> : '-'
                        } else return <div>-</div>
                      }
                    },
                    {
                      dataField: "mechanical",
                      text: "Mechanical",
                      formatter: (rowContent, row) => {
                        if (row.departments && row.departments.length) {
                          let department = row.departments.find(item=>item.name === 'mechanical')
                          let color = department.status === 'in_review' ? '#FFC231' :
                              department.status === 'in_progress' ? '#000' :
                                  department.status === 'completed' ? '#00B170' :
                                      department.status === 'needed' ? '#FF1E03' : '#000'
                          return  department ? <div style={{color: color}}>
                            {parseToWord(department.statusLabel)}
                          </div> : '-'
                        } else return <div>-</div>
                      }
                    },
                    {
                      dataField: "miscellaneous",
                      text: "Miscellaneous",
                      formatter: (rowContent, row) => {
                        if (row.departments && row.departments.length) {
                          let department = row.departments.find(item=>item.name === 'workshop')
                          let color = department.status === 'in_review' ? '#FFC231' :
                              department.status === 'in_progress' ? '#000' :
                                  department.status === 'completed' ? '#00B170' :
                                      department.status === 'needed' ? '#FF1E03' : '#000'
                          return  department ? <div style={{color: color}}>
                            {parseToWord(department.statusLabel)}
                          </div> : '-'
                        } else return <div>-</div>
                      }
                    },
                    {
                      dataField: "Ply",
                      text: "Ply",
                      formatter: (rowContent, row) => {
                        if (row.departments && row.departments.length) {
                          let department = row.departments.find(item=>item.name === 'ply lining')
                          let color = department.status === 'in_review' ? '#FFC231' :
                              department.status === 'in_progress' ? '#000' :
                                  department.status === 'completed' ? '#00B170' :
                                      department.status === 'needed' ? '#FF1E03' : '#000'
                          return  department ? <div style={{color: color}}>
                            {parseToWord(department.statusLabel)}
                          </div> : '-'
                        } else return <div>-</div>
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                            <div className="flex-no-wrap">
                              <ButtonContainer onClick={() => {
                                history.push({
                                  pathname: `/admin/live-vehicle/${row.live_id}/edit/${row.vehicle_id}/vehicle`,
                                  state: {home: home}
                                })
                              }}>View</ButtonContainer>
                            </div>
                        )
                      }
                    }
                  ]}
                />}
              {!loading && items && items.length > 0 && <Row>
                  <ButtonContainer  color="success"  type="button" onClick={() => submitItem()}>Save</ButtonContainer>
              </Row>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}