import React from 'react';
import styled from "styled-components";
export default function CustomRadio({
                                              checked,
                                              onClick,
                                              backgroundColor = null,
                                              height = null,
                                              width = null,
                                              dotHeight = null,
                                              dotWidth = null,
                                          }) {
    return (
        <RadioButtonContainer
            onClick={onClick}
            height={height}
            width={width}
            checked={checked}
            backgroundColor={backgroundColor}
        >
            <Dot
                height={dotHeight}
                width={dotWidth}
                checked={checked}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                {checked && <circle cx="12" cy="12" r="6" fill="#3F8CFF" />}
            </Dot>
        </RadioButtonContainer>
    );
}

const RadioButtonContainer = styled.div`
  display: inline-block;
  border-radius: 50%;
  border:1px solid ${props => props.checked ? '#3F8CFF' : '#49454F'};
  background-color: transparent;
  height: ${props => props.height || '24px'};
  width: ${props => props.width || '24px'};
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.svg`
  display: ${props => (props.checked ? 'block' : 'none')};
  position: absolute;
`;

