import React from 'react'
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import {
    CheckboxContainer,
    Checkmark,
    CustomHeader, CustomInput,
    CustomTD, FleetButton,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";


export default function Valeting({data, addNewRow, changeData, deleteRow, department = 'valeting'}) {
    return (
        <Col lg={8}>
            <Card>
                <CardHeader className={'border-bottom-0'}>
                    <CustomHeader fontSize={'16px'}>
                        Work Records
                    </CustomHeader>
                </CardHeader>
                <CardBody>
                    <StyledTable className={'table-borderless'}>
                        <thead className={'header-white-text'}>
                        <tr>
                            <StyledTableHeaderCell className={'text-center'} width={'60%'} color={'#0000008C'}>
                                Job Performed
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'20%'} color={'#0000008C'}>
                                Completion
                            </StyledTableHeaderCell>
                            {department === 'valeting' && <StyledTableHeaderCell width={'20%'}>

                            </StyledTableHeaderCell>}
                        </tr>
                        </thead>
                        <tbody>
                        {data.length ?
                            data.map((item, index)=>{
                                return <tr key={`i_${index}`}>
                                    <CustomTD width={'60%'}>
                                        <CustomInput radius={'8px'}
                                                     onChange={(e)=>{
                                                         changeData({
                                                             index,
                                                             key: 'title',
                                                             value: e.target.value
                                                         })
                                                        }
                                                     }
                                                     border={'1px solid #C0D9FF59'}
                                                     value={item.title} />
                                    </CustomTD>
                                    <CustomTD className={'text-center'} width={'20%'}>
                                        <CheckboxContainer checked={item.status == 'completed'} onClick={()=>{
                                            changeData({
                                                index,
                                                key: 'status'
                                            })
                                        }}>
                                            <Checkmark checked={item.status == 'completed'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M20.285 6.285a1 1 0 00-1.415 0L10 15.585 5.715 11.3a1 1 0 00-1.415 1.415l5 5a1 1 0 001.415 0l10-10a1 1 0 000-1.415z" />
                                            </Checkmark>
                                        </CheckboxContainer>
                                    </CustomTD>

                                </tr>
                            })
                            : null
                        }
                        {department === 'valeting' && <tr>
                            <td colSpan="3" className={'text-center'}>
                                <FleetButton
                                    height={'43px'}
                                    width={'114px'}
                                    backgroundColor={'#FDF0E4'}
                                    hoverColor={'#F3994A'}
                                    color={'#F3994A'}
                                    fontSize={'14px'}
                                    radius={'32px'}
                                    onClick={addNewRow}
                                >
                                    &#43; Add new
                                </FleetButton>
                            </td>
                        </tr>}

                        </tbody>

                    </StyledTable>

                </CardBody>
            </Card>
        </Col>
    )
}