import React from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import axios from 'axios'

import * as constants from 'constants/index.js'

import {
    Col,
} from 'reactstrap'

import AsyncSelect from "react-select/async/dist/react-select.esm";

export default function CheckInCreate() {

    const history = useHistory();

    const fetchData = async (reg_no) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/fleet-management/vehicles/available`,
                params: {
                    reg_no: reg_no,
                    withMedia: 1
                },
            })
            const data = response.data

            let _regNos = data.data.regNos;
            let _regNos_array = [];
            _regNos.map(_regNo => {
                return _regNos_array.push({
                    ..._regNo,
                    value: _regNo.id,
                    label: _regNo.stock_no,
                })
            })
            return _regNos_array;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }
    const promiseOptions = inputValue => {
        if (inputValue.length < 2) {
            return
        } else {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(fetchData(inputValue));
                }, 1000);
            });
        }
    }
    const selectVehicle = (value) =>{
        history.push({
            pathname: `/admin/vehicle-check-in`,
            state: {
                vehicleId: value.id,
            }
        })
    }


    return (
        <div className={'container-fluid mt-3'}>
            <Col md={6} sm={12} xs={12}>
                <AsyncSelect
                    loadOptions={promiseOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={selectVehicle}
                    placeholder="Search..."
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                />
            </Col>
        </div>
    )
}