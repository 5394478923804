import React, {Fragment, useState} from "react";
import {
    CustomContainer,
    CustomHeader,
    CustomReactStrapInput, CustomTD, FleetButton, FlexInnerComponent,
    RequiredItemsBadge, StyledTable
} from "@pages/new-design-components/custom-components";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {Col, Row} from "reactstrap";
import DebtManagementTable from "@pages/components/DebtManagement/DebtManagementTable";
import CustomSearch from "@pages/components/CustomSearch";

export default function PaymentsComponent({createPayment,tableData = [],downloadInvoice}) {

    const keys = [
        {
            key: 'date',
            label: 'Date'
        } ,

        {
            key: 'fleet',
            label: 'Fleet'
        },
        {
            key: 'status',
            label: 'Status'
        },
        // {
        //     key: 'payment_method',
        //     label: 'Payment Method'
        // },
        {
            key: 'amount',
            label: 'Amount'
        },
        {
            key: 'download',
            label: 'Invoice'
        },
    ]
    return (
        <div className={'container-fluid debt-background pt-3'}>
            <div className={'d-flex justify-content-between mb-3 align-items-center'}>
                <CustomHeader lineHeight={'16px'} weight={'700'} color={'#000'} fontSize={'20px'}>Payments</CustomHeader>
                {/*<FleetButton  height={'40px'}*/}
                {/*              width={'60px'}*/}
                {/*              backgroundColor={'#000'}*/}
                {/*              color={'#FFF'}*/}
                {/*              fontSize={'16px'}*/}
                {/*              radius={'16px'}*/}
                {/*              onClick={createPayment}>*/}
                {/*    +*/}
                {/*</FleetButton>*/}
            </div>

            <div className={'table-container'}>
                <DebtManagementTable keys={keys} tableData={tableData} download={downloadInvoice} optionValues={[
                    {
                        label: 'View',
                        action: ()=>{}
                    },
                    {
                        label: 'Delete',
                        action: ()=>{}
                    }
                ]}/>
            </div>
        </div>
    );
}