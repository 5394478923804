import { all } from 'redux-saga/effects';
import apiSaga from 'redux/Auth/api-saga.js';
import email from 'redux/Email/saga.js';
import task from 'redux/Task/saga';
import dealer from 'redux/Dealer/saga';
import deal from 'redux/Deal/saga';
import fleet from 'redux/Fleet/saga';
import personalisation from 'redux/Personalisation/saga';
import lead from 'redux/Lead/saga';
import report from 'redux/Report/saga';
import payment from 'redux/Payments/saga';
import signatureSaga from 'redux/Signature/saga';
import vehicleSaga from 'redux/Vehicle/saga';
import customerSaga from 'redux/Customer/saga';

export default function* rootSaga() {
  yield all([task(), apiSaga(), dealer(), personalisation(), lead(), report(), email(), deal(), payment(), signatureSaga(), vehicleSaga(), fleet(),customerSaga()]);
}
