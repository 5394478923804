import React, { useEffect, useState, useRef, useMemo } from 'react';
import NotificationAlert from "react-notification-alert";
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
} from 'reactstrap';
import { ButtonContainer } from "@pages/reusable-components/styled-components";
import { UPLOAD_DEALS_TO_GOOGLE_SHEETS_REQUEST } from "@redux/Deal/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import usePrevious from "../../../hooks/useprevious";
import {
    UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_REQUEST,
    UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_REQUEST
} from "@redux/Payments/actions";
import { Notification } from "@pages/components/Notification";
import * as actionsTypes from "@redux/Vehicle/actions";
import {
    UPLOAD_MONTHLY_REPORT_TO_DRIVE_REQUEST,
    UPLOAD_DELIVERY_REPORT_TO_DRIVE_REQUEST,
    FLEET_STATS_REPORT_TO_DRIVE_REQUEST,
    UPLOAD_VEHICLE_CHECK_TO_DRIVE_REQUEST,
    GENERATE_TASKS_REQUEST
} from "@redux/Vehicle/actions";

import { UPLOAD_LEADS_TO_GOOGLE_SHEETS_REQUEST } from "@redux/Lead/actions";

import { InputArraySelect } from "@pages/components/InputSelect";
import { monthsObj } from "constants/index.js";
import moment from "moment";

const MOT_TASKS = 'mot';
const MID_TASKS = 'mid';
const VEHICLE_CHECK_TASKS = 'vehicle-check';
const RFL_TASKS = 'rfl';
const DEAL_EXPIRY_TASKS = 'deal-expiry';
const RENEWALS_TASKS = 'renewals';
const BREAKDOWN_TASKS = 'breakdown';
const WARRANTY_TASKS = 'warranty';

export default function GoogleReports() {
    const dispatch = useDispatch();
    const notificationAlert = useRef(null)

    const [disableButtons, setDisableButtons] = useState(false);

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    const [selectedVehicleCheckMonth, setSelectedVehicleCheckMonth] = useState('');
    const [selectedVehicleCheckYear, setSelectedVehicleCheckYear] = useState('');

    const [selectedLeadMonth, setSelectedLeadMonth] = useState('');
    const [selectedLeadYear, setSelectedLeadYear] = useState('');

    const [selectedDeliveryMonth, setSelectedDeliveryMonth] = useState('');
    const [selectedDeliveryYear, setSelectedDeliveryYear] = useState('');

    const { uploadDealsToGoogleSheetSuccess, uploadDealsToGoogleSheetError } = useSelector((state) => state.dealState, shallowEqual)

    const { uploadLeadsToGoogleDriveSuccess, uploadLeadsToGoogleDriveError } = useSelector((state) => state.leadState, shallowEqual)

    const { uploadVehiclesReportSuccess, uploadVehiclesReportError,
        uploadDeliveryReportError, uploadDeliveryReportSuccess,
        fleetStatsReportError, fleetStatsReportSuccess,
        uploadVehicleCheckError, uploadVehicleCheckSuccess,
        generateTasksSuccess, generateTasksError
     } = useSelector((state) => state.vehicle, shallowEqual);

    const {
        uploadPaymentPlansToGoogleDriveSuccess,
        uploadPaymentPlansToGoogleDriveError,
        uploadMissedPaymentsToGoogleDriveError,
        uploadMissedPaymentsToGoogleDriveSuccess
    } = useSelector((state) => state.paymentState, shallowEqual);

    const { uploadSuccess, uploadError } = useSelector((state) => state.vehicle);

    const uploadDealsToGoogleSheetSuccessPrev = usePrevious(uploadDealsToGoogleSheetSuccess)
    const uploadDealsToGoogleSheetErrorPrev = usePrevious(uploadDealsToGoogleSheetError)

    const uploadVehicleCheckSuccessPrev = usePrevious(uploadVehicleCheckSuccess)
    const uploadVehicleCheckErrorPrev = usePrevious(uploadVehicleCheckError)

    const generateTasksSuccessPrev = usePrevious(generateTasksSuccess)
    const generateTasksErrorPrev = usePrevious(generateTasksError)

    const uploadLeadsToGoogleDriveSuccessPrev = usePrevious(uploadLeadsToGoogleDriveSuccess)
    const uploadLeadsToGoogleDriveErrorPrev = usePrevious(uploadLeadsToGoogleDriveError)

    const uploadVehiclesReportSuccessPrev = usePrevious(uploadVehiclesReportSuccess)
    const uploadVehiclesReportErrorPrev = usePrevious(uploadVehiclesReportError)

    const uploadDeliveryReportSuccessPrev = usePrevious(uploadDeliveryReportSuccess)
    const uploadDeliveryReportErrorPrev = usePrevious(uploadDeliveryReportError)

    const fleetStatsReportSuccessPrev = usePrevious(fleetStatsReportSuccess)
    const fleetStatsReportErrorPrev = usePrevious(fleetStatsReportError)

    const uploadPaymentPlansToGoogleDriveSuccessPrev = usePrevious(uploadPaymentPlansToGoogleDriveSuccess)
    const uploadPaymentPlansToGoogleDriveErrorPrev = usePrevious(uploadPaymentPlansToGoogleDriveError)

    const uploadMissedPaymentsToGoogleDriveSuccessPrev = usePrevious(uploadMissedPaymentsToGoogleDriveSuccess)
    const uploadMissedPaymentsToGoogleDriveErrorPrev = usePrevious(uploadMissedPaymentsToGoogleDriveError)

    const uploadSuccessPrev = usePrevious(uploadSuccess);
    const uploadErrorPrev = usePrevious(uploadError);

    const yearsOptions = useMemo(() => {
        let options = [];
        let year = moment().toDate().getFullYear();
        if (year) {
            for (let i = year - 2; i <= year; i++) {
                options.push({
                    id: i,
                    name: i
                })
            }
        }

        return options;
    }, []);
    const yearsOptionsWithFuture = useMemo(() => {
        let options = [];
        let year = moment().toDate().getFullYear();
        if (year) {
            for (let i = year - 2; i <= year + 10; i++) {
                options.push({
                    id: i,
                    name: i
                })
            }
        }

        return options;
    }, []);

    useEffect(() => {
        let month = moment().toDate().getMonth() + 1;
        setSelectedMonth(month);
        let year = moment().toDate().getFullYear();
        setSelectedYear(year);
    }, [])

    useEffect(() => {
        if (uploadVehicleCheckSuccess && uploadVehicleCheckSuccessPrev === false) {
            setDisableButtons(false)
        }
    }, [uploadVehicleCheckSuccess, uploadVehicleCheckSuccessPrev])


    useEffect(() => {
        if (uploadVehicleCheckError && uploadVehicleCheckErrorPrev === false) {
            setDisableButtons(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!', notificationAlert)
        }
    }, [uploadVehicleCheckError, uploadVehicleCheckErrorPrev])

    useEffect(() => {
        if (uploadDealsToGoogleSheetSuccess && uploadDealsToGoogleSheetSuccessPrev === false) {
            setDisableButtons(false)
        }
    }, [uploadDealsToGoogleSheetSuccess, uploadDealsToGoogleSheetSuccessPrev])


    useEffect(() => {
        if (uploadDealsToGoogleSheetError && uploadDealsToGoogleSheetErrorPrev === false) {
            setDisableButtons(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!', notificationAlert)
        }
    }, [uploadDealsToGoogleSheetError, uploadDealsToGoogleSheetErrorPrev])

    useEffect(() => {
        if (fleetStatsReportSuccess && fleetStatsReportSuccessPrev === false) {
            setDisableButtons(false)
        }
    }, [fleetStatsReportSuccess, fleetStatsReportSuccessPrev])


    useEffect(() => {
        if (fleetStatsReportError && fleetStatsReportErrorPrev === false) {
            setDisableButtons(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!', notificationAlert)
        }
    }, [fleetStatsReportError, fleetStatsReportErrorPrev])


    useEffect(() => {
        if (uploadLeadsToGoogleDriveSuccess && uploadLeadsToGoogleDriveSuccessPrev === false) {
            setDisableButtons(false)
        }
    }, [uploadLeadsToGoogleDriveSuccess, uploadLeadsToGoogleDriveSuccessPrev])


    useEffect(() => {
        if (uploadLeadsToGoogleDriveError && uploadLeadsToGoogleDriveErrorPrev === false) {
            setDisableButtons(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!', notificationAlert)
        }
    }, [uploadLeadsToGoogleDriveError, uploadLeadsToGoogleDriveErrorPrev])

    useEffect(() => {
        if (uploadPaymentPlansToGoogleDriveSuccess && uploadPaymentPlansToGoogleDriveSuccessPrev === false) {
            setDisableButtons(false)
        }
    }, [uploadPaymentPlansToGoogleDriveSuccess])

    useEffect(() => {
        if (uploadPaymentPlansToGoogleDriveError && uploadPaymentPlansToGoogleDriveErrorPrev === false) {
            setDisableButtons(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!', notificationAlert)
        }
    }, [uploadPaymentPlansToGoogleDriveError])

    useEffect(() => {
        if (uploadMissedPaymentsToGoogleDriveSuccess && uploadMissedPaymentsToGoogleDriveSuccessPrev === false) {
            setDisableButtons(false)
        }
    }, [uploadMissedPaymentsToGoogleDriveSuccess])

    useEffect(() => {
        if (uploadMissedPaymentsToGoogleDriveError && uploadMissedPaymentsToGoogleDriveErrorPrev === false) {
            setDisableButtons(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!', notificationAlert)
        }
    }, [uploadMissedPaymentsToGoogleDriveError])

    useEffect(() => {
        if (uploadVehiclesReportSuccess && uploadVehiclesReportSuccessPrev === false) {
            setDisableButtons(false)
        }
    }, [uploadVehiclesReportSuccess, uploadVehiclesReportSuccessPrev])

    useEffect(() => {
        if (uploadVehiclesReportError && uploadVehiclesReportErrorPrev === false) {
            Notification('warning', 'Warning', 'Something went wrong please try again later!', notificationAlert)
            setDisableButtons(false)
        }
    }, [uploadVehiclesReportError, uploadVehiclesReportErrorPrev])

    useEffect(() => {

        if (uploadDeliveryReportSuccess && uploadDeliveryReportSuccessPrev === false) {
            setDisableButtons(false)
        }
    }, [uploadDeliveryReportSuccess, uploadDeliveryReportSuccessPrev])

    useEffect(() => {
        if (uploadDeliveryReportError && uploadDeliveryReportErrorPrev === false) {
            Notification('warning', 'Warning', 'Something went wrong please try again later!', notificationAlert)
            setDisableButtons(false)
        }
    }, [uploadDeliveryReportError, uploadDeliveryReportErrorPrev])

    useEffect(() => {
        if (uploadSuccessPrev === false && uploadSuccess) {
            setDisableButtons(false)
        }
    }, [uploadSuccess, uploadSuccessPrev])

    useEffect(() => {
        if (uploadErrorPrev === false && uploadError) {
            Notification('warning', 'Warning', 'Something went wrong please try again later!', notificationAlert)
            setDisableButtons(false)
        }
    }, [uploadError, uploadErrorPrev])


    useEffect(() => {
        if (generateTasksSuccess && generateTasksSuccessPrev === false) {
            setDisableButtons(false)
        }
    }, [generateTasksSuccess, generateTasksSuccessPrev])


    useEffect(() => {
        if (generateTasksError && generateTasksErrorPrev === false) {
            setDisableButtons(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!', notificationAlert)
        }
    }, [generateTasksError, generateTasksErrorPrev])

    const uploadPaymentPlan = () => {
        setDisableButtons(true)
        dispatch({
            type: UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_REQUEST,
        })
    }

    const uploadDealsToGoogleSheet = (finished_payment) => {
        setDisableButtons(true)
        dispatch({
            type: UPLOAD_DEALS_TO_GOOGLE_SHEETS_REQUEST,
            payload: { finished_payment: finished_payment }
        })
    }

    const uploadVehiclesReport = () => {
        setDisableButtons(true)
        dispatch({ type: actionsTypes.UPLOAD_VEHICLES_REPORT_TO_DRIVE_REQUEST })
    }

    const uploadToGoogleDrive = () => {
        setDisableButtons(true)
        dispatch({
            type: UPLOAD_MONTHLY_REPORT_TO_DRIVE_REQUEST,
            payload: { month: selectedMonth, year: selectedYear }
        })
    }

    const uploadLeadToGoogleDrive = () => {
        setDisableButtons(true)
        dispatch({
            type: UPLOAD_LEADS_TO_GOOGLE_SHEETS_REQUEST,
            payload: { month: selectedLeadMonth, year: selectedLeadYear }
        })
    }

    const uploadDeliveryToGoogleDrive = () => {
        setDisableButtons(true)
        dispatch({
            type: UPLOAD_DELIVERY_REPORT_TO_DRIVE_REQUEST,
            payload: { month: selectedDeliveryMonth, year: selectedDeliveryYear }
        })
    }
    const uploadMissedPaymentsToGoogleDrive = () => {
        setDisableButtons(true)
        dispatch({
            type: UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_REQUEST,
        })
    }
    const uploadVehicleTasksToGoogle = () => {
        setDisableButtons(true)
        dispatch({
            type: UPLOAD_VEHICLE_CHECK_TO_DRIVE_REQUEST,
            payload: { month: selectedVehicleCheckMonth, year: selectedVehicleCheckYear }
        })
    }

    const uploadFleetStatsToGoogleDrive = () => {
        setDisableButtons(true)
        dispatch({
            type: FLEET_STATS_REPORT_TO_DRIVE_REQUEST,
        })
    };

    const exportTasks = (taskType) => {
        setDisableButtons(true)
        dispatch({
            type: GENERATE_TASKS_REQUEST,
            payload: { taskType }
        })
    }

    return (
        <>
            <CardsHeader name="Uploads!" parentName="Business Activities Report" currentName="List" />
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Container className="mt--5 admin-main-body d-flex flex-wrap " fluid >
                <Col lg={6} sm="12">
                    <Card className={'reports-card-height'}>
                        <CardHeader>
                            <h2 className="title">Upload Deal Reports To Google</h2>
                        </CardHeader>
                        <CardBody>
                            <Col>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            Rent 2 Buy Deals
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && uploadDealsToGoogleSheet(1)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            Rental Deals
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && uploadDealsToGoogleSheet(0)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            Delivered Deals
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && uploadDealsToGoogleSheet(2)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} sm="12">
                    <Card className={'reports-card-height'}>
                        <CardHeader>
                            <h2 className="title">Upload Payments Reports To Google</h2>
                        </CardHeader>
                        <CardBody>
                            <Row className={'mb-3'}>
                                <Col>
                                    <h4>
                                        Upload Payment Plans
                                    </h4>
                                </Col>
                                <Col>
                                    <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && uploadPaymentPlan()}>Upload</ButtonContainer>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} sm="12">
                    <Card className={'reports-card-height'}>
                        <CardHeader>
                            <h2 className="title">Upload Vehicles Reports To Google</h2>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h4>
                                        Upload Vehicles report
                                    </h4>
                                </Col>
                                <Col>
                                    <ButtonContainer disabled={disableButtons} onClick={() => {
                                        !disableButtons && uploadVehiclesReport()
                                    }}>Upload</ButtonContainer>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} sm="12">
                    <Card className={'reports-card-height'}>
                        <CardHeader>
                            <h2 className="title">Upload Live-Vehicles Monthly Report To Google</h2>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={12} lg={3} sm={6}>
                                    <InputArraySelect
                                        newLabel={`Target Month`}
                                        options={monthsObj}
                                        value={selectedMonth}
                                        onChange={(e) => setSelectedMonth(e.target.value)}
                                        valueKey={`id`}
                                        labelKey={`name`}
                                    />
                                </Col>
                                <Col xs={12} lg={3} sm={6}>
                                    <InputArraySelect
                                        newLabel={`Target Year`}
                                        options={yearsOptions}
                                        value={selectedYear}
                                        onChange={(e) => setSelectedYear(e.target.value)}
                                        valueKey={`id`}
                                        labelKey={`name`}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>
                                        Upload to Google Drive
                                    </h4>
                                </Col>
                                <Col>
                                    <ButtonContainer disabled={disableButtons} onClick={() => {
                                        !disableButtons && uploadToGoogleDrive()
                                    }}>Upload</ButtonContainer>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} sm="12">
                    <Card className={'reports-card-height'}>
                        <CardHeader>
                            <h2 className="title">Lead Monthly Report</h2>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={12} lg={3} sm={6}>
                                    <InputArraySelect
                                        newLabel={`Target Month`}
                                        options={monthsObj}
                                        value={selectedLeadMonth}
                                        onChange={(e) => setSelectedLeadMonth(e.target.value)}
                                        valueKey={`id`}
                                        labelKey={`name`}
                                    />
                                </Col>
                                <Col xs={12} lg={3} sm={6}>
                                    <InputArraySelect
                                        newLabel={`Target Year`}
                                        options={yearsOptions}
                                        value={selectedLeadYear}
                                        onChange={(e) => setSelectedLeadYear(e.target.value)}
                                        valueKey={`id`}
                                        labelKey={`name`}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>
                                        Upload to Google Drive
                                    </h4>
                                </Col>
                                <Col>
                                    <ButtonContainer disabled={disableButtons} onClick={() => {
                                        !disableButtons && uploadLeadToGoogleDrive()
                                    }}>Upload</ButtonContainer>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} sm="12">
                    <Card className={'reports-card-height'}>
                        <CardHeader>
                            <h2 className="title">Delivery Monthly Report </h2>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={12} lg={3} sm={6}>
                                    <InputArraySelect
                                        newLabel={`Target Month`}
                                        options={monthsObj}
                                        value={selectedDeliveryMonth}
                                        onChange={(e) => setSelectedDeliveryMonth(e.target.value)}
                                        valueKey={`id`}
                                        labelKey={`name`}
                                    />
                                </Col>
                                <Col xs={12} lg={3} sm={6}>
                                    <InputArraySelect
                                        newLabel={`Target Year`}
                                        options={yearsOptions}
                                        value={selectedDeliveryYear}
                                        onChange={(e) => setSelectedDeliveryYear(e.target.value)}
                                        valueKey={`id`}
                                        labelKey={`name`}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>
                                        Upload to Google Drive
                                    </h4>
                                </Col>
                                <Col>
                                    <ButtonContainer disabled={disableButtons} onClick={() => {
                                        !disableButtons && uploadDeliveryToGoogleDrive()
                                    }}>Upload</ButtonContainer>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} sm="12">
                    <Card className={'reports-card-height'}>
                        <CardHeader>
                            <h2 className="title">Missed Payments Monthly Report</h2>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h4>
                                        Upload to Google Drive
                                    </h4>
                                </Col>
                                <Col>
                                    <ButtonContainer disabled={disableButtons} onClick={() => {
                                        !disableButtons && uploadMissedPaymentsToGoogleDrive()
                                    }}>Upload</ButtonContainer>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} sm="12">
                    <Card className={'reports-card-height'}>
                        <CardHeader>
                            <h2 className="title">Fleet Stats Monthly Report</h2>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h4>
                                        Upload to Google Drive
                                    </h4>
                                </Col>
                                <Col>
                                    <ButtonContainer disabled={disableButtons} onClick={() => {
                                        !disableButtons && uploadFleetStatsToGoogleDrive()
                                    }}>Upload</ButtonContainer>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} sm="12">
                    <Card className={'reports-card-height'}>
                        <CardHeader>
                            <h2 className="title">Vehicle Check Download</h2>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={12} lg={3} sm={6}>
                                    <InputArraySelect
                                        newLabel={`Target Month`}
                                        options={monthsObj}
                                        value={selectedVehicleCheckMonth}
                                        onChange={(e) => setSelectedVehicleCheckMonth(e.target.value)}
                                        valueKey={`id`}
                                        labelKey={`name`}
                                    />
                                </Col>
                                <Col xs={12} lg={3} sm={6}>
                                    <InputArraySelect
                                        newLabel={`Target Year`}
                                        options={yearsOptionsWithFuture}
                                        value={selectedVehicleCheckYear}
                                        onChange={(e) => setSelectedVehicleCheckYear(e.target.value)}
                                        valueKey={`id`}
                                        labelKey={`name`}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>
                                        Upload to Google Drive
                                    </h4>
                                </Col>
                                <Col>
                                    <ButtonContainer disabled={disableButtons} onClick={() => {
                                        !disableButtons && uploadVehicleTasksToGoogle()
                                    }}>Upload</ButtonContainer>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6} sm="12">
                    <Card className={'reports-card-height'}>
                        <CardHeader>
                            <h2 className="title">Generate tasks</h2>
                        </CardHeader>
                        <CardBody>
                            <Col>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            MIDs
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && exportTasks(MID_TASKS)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            MOTs
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && exportTasks(MOT_TASKS)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            Vehicle Check
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && exportTasks(VEHICLE_CHECK_TASKS)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            RFL
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && exportTasks(RFL_TASKS)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            Deal Expiry
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && exportTasks(DEAL_EXPIRY_TASKS)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            Renewals
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && exportTasks(RENEWALS_TASKS)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            Warranty
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && exportTasks(WARRANTY_TASKS)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4>
                                            Breakdown
                                        </h4>
                                    </Col>
                                    <Col>
                                        <ButtonContainer disabled={disableButtons} onClick={() => !disableButtons && exportTasks(BREAKDOWN_TASKS)}>Upload</ButtonContainer>
                                    </Col>
                                </Row>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </>
    )
}
