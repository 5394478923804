import React, {useEffect, useMemo, useState} from 'react';
import CustomTabs from "@pages/components/CustomTabs";
import {Card, CardBody, CardHeader} from "reactstrap";
import LeadSources from 'views/pages/types/sources/Index';
import Countries from 'views/pages/types/countries/Index';
import Regions from 'views/pages/types/regions/Index';
import {shallowEqual, useSelector} from "react-redux";

export default function Types() {

    const [activeTab,setActiveTab] = useState(null)
    const authState = useSelector(state => state.getState, shallowEqual);
    const tabs = useMemo(() => [
        {
            slug: 'lead',
            name: 'Lead Sources',
            permission: ['read-lead-sources'],
        },
        {
            slug: 'countries',
            name: 'Countries',
            permission: ['read-countries'],
        },
        {
            slug: 'regions',
            name: 'Regions',
            permission: ['read-regions'],
        }
    ].filter(tab => tab.permission.some(permission => authState.permissions.includes(permission))), [authState.permissions]);

    useEffect(()=>{
        if(tabs.length) {
           setActiveTab(tabs[0].slug)
        }
    },[tabs])
    return (
        <>
            <Card>
                <CardHeader>
                    <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

                </CardHeader>
              <CardBody>
                  {
                      activeTab === 'lead' ?

                            <LeadSources/> :

                      activeTab === 'countries' ?

                         <Countries/> : <Regions/>
                  }
              </CardBody>
            </Card>
        </>
    );
}