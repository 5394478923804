import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import { Editor } from '@tinymce/tinymce-react';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputArraySelect
} from 'views/pages/components/InputSelect';

import FieldsForm from './Fields/FieldsForm';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup, Button,
} from 'reactstrap';
import InputToggle from 'views/pages/components/InputToggle';
import { ButtonContainer } from "@pages/reusable-components/styled-components";
import EditorComponent from "@pages/components/template";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { FleetButton } from "@pages/new-design-components/custom-components";

function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export default function DocumentForm() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [editorStateSignContent, setEditorStateSignContent] = useState(null);
  const [editorStateContent, setEditorStateContent] = useState(null);
  const [editorStateContentRaw, setEditorStateContentRaw] = useState(null);

  const authUserTeam = useSelector(state => state.getState).authUserTeam;

  const [token, setToken] = useState('');
  const [dealers, setDealers] = useState([]);
  const [documentFields, setDocumentFields] = useState([]);
  const [documentEl, setDocument] = useState([]);
  const [types, setTypes] = useState('');
  const [fieldTypes, setFieldTypes] = useState([]);
  const [errors, setErrors] = useState('');

  const addField = ({ type, is_required, options, unique_identifier_on_doc, id }) => {
    setDocumentFields([...documentFields, {
      type, is_required, options, unique_identifier_on_doc, id
    }])
  }

  const addDocumentFields = () => {
    addField({
      frontend_name: "",
      field_type: "1",
      is_required: false,
      options: {},
      unique_identifier_on_doc: '',
      id: guidGenerator()
    })
  }

  const handleDocumentFieldsChange = (type, value, index, item, nestedItem = false) => {
    const newDocumentFields = [...documentFields];

    let documentFieldItem;

    if (!nestedItem) {
      documentFieldItem = {
        ...item,
        [type]: value,
      };
    } else {
      documentFieldItem = {
        ...item,
        [nestedItem]: {
          ...item[nestedItem],
          [type]: value
        },
      };
    }

    newDocumentFields[index] = documentFieldItem;
    setDocumentFields(newDocumentFields);
  }

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const url = id ? `${constants.API_URL}/dealer/documents/${id}/edit` : `${constants.API_URL}/dealer/documents/create`

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: url,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setDealers(data.data.dealers ? data.data.dealers : []);
        let { fields, ...documentData } = data.data.document;
        setDocument(documentData);
        setEditorStateSignContent(documentData?.local_signable_content)
        setEditorStateContent(documentData?.content)
        setEditorStateContentRaw(documentData?.content_raw)
        if (fields) {
          let defaults = fields.map(item => {
            return {
              frontend_name: item.frontend_name,
              field_type: item.type,
              is_required: item.is_required,
              options: item.options ?? {},
              unique_identifier_on_doc: item.unique_identifier_on_doc,
              id: item.id
            }
          });

          setDocumentFields(defaults);
        }

        setTypes(data.data.types);
        setFieldTypes(data.data.fieldTypes);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData();

    return () => {
      source.cancel()
    }
  }, [])

  const goList = () => {
    history.push({
      pathname: `/admin/settings/documents`
    })
  }

  const addDocument = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/dealer/documents`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        documentEl,
        documentFields
      }
    })
      .then(response => {
        setErrors('')
        console.log('res :: ', response.data)
        let data = response.data
        notify('success', 'Success', data.message)

        setTimeout(() => {
          history.push({
            pathname: `/admin/settings/documents`
          })
        }, 1500);
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const updateDocument = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/dealer/documents/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...documentEl, documentFields
      }
    })
      .then(response => {
        setErrors('')
        console.log('res :: ', response.data)
        let data = response.data
        notify('success', 'Success', data.message)

        setTimeout(() => {
          history.push({
            pathname: `/admin/settings/documents`
          })
        }, 1500);
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }
  const onEditorStateChange = (key, content) => {
    setDocument({
      ...documentEl,
      [key]: content
    })
  };


  return (
    <>
      <CardsHeader name="Settings" parentName="Document Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title">Available fields</h1>
                <p className="text-sm mb-0">List of available shortcodes</p>
              </CardHeader>
              <CardBody>
                <Row>
                  {types &&
                    Object.keys(types).map(key => {
                      return (
                        <Col md={12} key={key} className="mt-3">
                          <h3 className="mb-0">{key}</h3>
                          <Col md={12}>
                            {types[key] && types[key].length > 0 &&
                              types[key].map((type, index) => {
                                return <span key={index}>{type}{" "}</span>
                              })
                            }
                          </Col>
                        </Col>
                      )
                    })
                  }
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Documents Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer onClick={() => goList()}>Back to list</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <InputCustom
                  label={`Document name`}
                  value={documentEl && documentEl.document_name ? documentEl.document_name : ''}
                  onChange={e => setDocument({
                    ...documentEl,
                    document_name: e.target.value
                  })}
                  invalid={errors && errors.document_name ? true : false}
                  errorMessage={errors.document_name}
                />
                {!authUserTeam &&
                  <InputArraySelect
                    label={`Dealer`}
                    value={documentEl && documentEl.dealer_id ? documentEl.dealer_id : ''}
                    options={dealers}
                    onChange={e => setDocument({
                      ...documentEl,
                      dealer_id: e.target.value
                    })}
                    valueKey={`id`}
                    labelKey={`name`}
                    defaultOption={true}
                    invalid={errors && errors.dealer_id ? true : false}
                    errorMessage={errors.dealer_id}
                  />}
                <InputCustom
                  type={`textarea`}
                  label={`Description`}
                  rows={5}
                  value={documentEl && documentEl.description ? documentEl.description : ''}
                  onChange={e => setDocument({
                    ...documentEl,
                    description: e.target.value
                  })}
                  invalid={errors && errors.description ? true : false}
                  errorMessage={errors.description}
                />
                <FormGroup>
                  <div className={'d-flex justify-content-between mb-3'}>
                    <label
                      className="form-control-label"
                    >
                      Local Signing Pdf
                    </label>
                  </div>
                  <Editor
                    value={documentEl && documentEl.local_signable_content ? documentEl.local_signable_content : ''}
                    apiKey={constants.TINYMCE_KEY}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: ['code', 'preview', 'pagebreak', 'image', 'fullpage'],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright alignjustify | \
                                outdent indent'
                    }}
                    onEditorChange={(content, editor) => setDocument({
                      ...documentEl,
                      local_signable_content: content
                    })}
                  />
                </FormGroup>
                <FormGroup>
                  <div className={'d-flex justify-content-between mb-3'}>
                    <label
                      className="form-control-label"
                    >
                      Pdf content
                    </label>
                  </div>
                  <Editor
                    value={documentEl && documentEl.content ? documentEl.content : ''}
                    apiKey={constants.TINYMCE_KEY}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: ['code', 'preview', 'pagebreak', 'image', 'fullpage'],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright alignjustify | \
                                outdent indent'
                    }}
                    onEditorChange={(content, editor) => setDocument({
                      ...documentEl,
                      content: content
                    })}
                  />

                </FormGroup>
                <FormGroup>
                  <div className={'d-flex justify-content-between mb-3'}>
                    <label
                      className="form-control-label"
                    >
                      Pdf content raw
                    </label>
                  </div>
                  <Editor
                    value={documentEl && documentEl.content_raw ? documentEl.content_raw : ''}
                    apiKey={constants.TINYMCE_KEY}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: ['code', 'preview', 'pagebreak', 'image', 'fullpage'],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright alignjustify | \
                                outdent indent'
                    }}
                    onEditorChange={(content, editor) => setDocument({
                      ...documentEl,
                      content_raw: content
                    })}
                  />
                </FormGroup>
                <InputCustom
                  type={`number`}
                  label={`Sort ( position )`}
                  value={documentEl && documentEl.sort && {}.toString.call(documentEl.sort) !== '[object Function]' ? documentEl.sort : ''}
                  onChange={e => setDocument({
                    ...documentEl,
                    sort: e.target.value
                  })}
                  invalid={errors && errors.sort ? true : false}
                  errorMessage={errors.sort}
                />
                <InputToggle
                  label={`Is recurrent`}
                  checked={documentEl && documentEl.is_recurrent ? true : false}
                  onChange={e => setDocument({
                    ...documentEl,
                    is_recurrent: documentEl.is_recurrent === 1 ? 0 : 1
                  })}
                  invalid={errors && errors.is_recurrent ? true : false}
                  errorMessage={errors.is_recurrent}
                />
                <InputToggle
                  label={`Is document going to be sent to customer`}
                  checked={documentEl && documentEl.is_sendable ? true : false}
                  onChange={e => setDocument({
                    ...documentEl,
                    is_sendable: documentEl.is_sendable === 1 ? 0 : 1
                  })}
                  invalid={errors && errors.is_sendable ? true : false}
                  errorMessage={errors.is_sendable}
                />
                <InputToggle
                  label={`Is active`}
                  checked={documentEl && documentEl.is_active ? true : false}
                  onChange={e => setDocument({
                    ...documentEl,
                    is_active: documentEl.is_active === 1 ? 0 : 1
                  })}
                  invalid={errors && errors.is_active ? true : false}
                  errorMessage={errors.is_active}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <FieldsForm
          addField={addField}
          handleDocumentFieldsChange={handleDocumentFieldsChange}
          addDocumentFields={addDocumentFields}
          documentFields={documentFields}
          removeDocumentField={(index) => {
            const items = documentFields.filter((t, key) => key !== index);
            setDocumentFields(items);
          }}
          fieldTypes={fieldTypes} />
        <Card>
          <div className="d-flex flex-row justify-content-center">
            <ButtonContainer reverse className="w-100" onClick={() => id ? updateDocument() : addDocument()}>Save</ButtonContainer>
          </div>
        </Card>
      </Container>
    </>
  )
}