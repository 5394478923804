import React, {useEffect, useState} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {
    CustomContainer,
    CustomHeader,
    CustomInput,
    CustomTextContainer,
    FleetButton
} from "@pages/new-design-components/custom-components";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import {identifyCardType, maskCardNumber} from "../../../../../constants";
import CardInput from "@pages/components/DebtManagement/CustomerOverview/CardInput";
import CVVInput from "@pages/components/DebtManagement/CustomerOverview/CVVInput";


export default function CreateEditPaymentModal({isOpen,data,updateData,cancel,dataIndex, paymentMethods, saveCard}) {

    const [localData, setLocalData] = useState(null)
    const [showAddCard, setShowAddCard] = useState(false)
    const [newCard, setNewCard] = useState({
        cvv:'',
        cardNumber:'',
        expirationDate:''
    })
    const [errors, setErrors] = useState({});


    useEffect(()=>{
        if (data) {
            setLocalData(data)
        }
    },[data])
    const saveData = () => {
        updateData(localData)
    }
    const updateValue = ({key,value}) => {
        let tempData= {...localData}
        tempData[key] = value
        setLocalData(tempData)
    }
    const updateCardValue = ({key,value}) => {
        let tempData= {...newCard}
        tempData[key] = value
        setNewCard(tempData)
    }
    const closeAddNewCard = () => {
        setNewCard(null)
        setShowAddCard(false)
    }

    const validateCardForm = (card) => {
        const errors = {};

        const cardNumberPattern = /^\d{16}$/;
        if (!card?.cardNumber || !cardNumberPattern.test(card?.cardNumber)) {
            errors.cardNumber = 'Card number must be exactly 16 digits';
        }

        const cvvPattern = /^[0-9]{3,4}$/;
        if (!card?.cvv || !cvvPattern.test(card?.cvv)) {
            errors.cvv = 'CVV must be 3 or 4 digits';
        }

        const currentDate = new Date();
        const [year,month,day] = card?.expirationDate.split('-');
        const expirationDate = new Date(year, month - 1,day);
        if (!card?.expirationDate) {
            errors.expirationDate = 'Expiration date is required';
        } else if (expirationDate < currentDate) {
            errors.expirationDate = 'Expiration date cannot be in the past';
        }

        return errors;
    };

    const checkCardAndSave = () => {
        const validationErrors = validateCardForm(newCard);

        if (Object.keys(validationErrors).length === 0) {
            saveCard(newCard)
            closeAddNewCard()
        } else {
            setErrors(validationErrors);
        }

    }
    return (
        <Modal
            isOpen={isOpen}
            toggle={cancel}
            modalClassName={'slide-in-from-right'}
            backdrop={false}
            className={'modal-dialog-right modal-responsible'}
        >
            <ModalHeader toggle={cancel} className={'bg-white centered-title'}>
                <CustomHeader weight={'600'} fontSize={'14px'}  color={'#74787E'}>
                    Charge
                </CustomHeader>
            </ModalHeader>
            <ModalBody>
                <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#74787E'}>
                    Associated Debt
                </CustomTextContainer>
                <CustomInput radius={'8px'}
                             onChange={(e)=>{
                                 updateValue({ key:'debt', value: e.target.value})
                             }
                             }
                             border={'1px solid #979797'}
                             borderBottom={'1px solid #979797'}
                             value={localData?.debt} />


                <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#74787E'}>
                    Amount £
                </CustomTextContainer>
                <CustomInput radius={'8px'}
                             onChange={(e)=>{
                                 updateValue({ key:'amount', value: e.target.value})
                              }
                             }
                             border={'1px solid #979797'}
                             borderBottom={'1px solid #979797'}
                             value={localData?.amount} />

                <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#74787E'}>
                    Send receipt to
                </CustomTextContainer>
                <CustomInput radius={'8px'}
                             onChange={(e)=>{
                                 updateValue({ key:'invoice_to', value: e.target.value})
                              }
                             }
                             border={'1px solid #979797'}
                             borderBottom={'1px solid #979797'}
                             value={localData?.invoice_to} />

                <CustomHeader fontSize={'16px'} weight={'700'} color={'#000'}>
                    Payment Method
                </CustomHeader>
                {
                    paymentMethods.map(item=>{
                        const cardType = identifyCardType(item.cardNumber);
                        return (
                           <CustomContainer shadow={'0 6px 12px rgba(0, 0, 0, 0.2)'} className={'d-flex g-10 mb-3'} backgroundColor={'#FFF'} borderRadius="8px" padding="10px 16px">
                               <img width={'50px'} height={'30px'} src={require(`assets/images/credit-cards/${cardType}.png`)} alt=""/>
                               <div>
                                   <CustomHeader  fontSize={'14px'} weight={'400'} color={'#000'}>
                                       {maskCardNumber(item.cardNumber)}

                                   </CustomHeader>
                                   <CustomHeader  fontSize={'14px'} weight={'400'} color={'#000'}>
                                       {item.expirationDate}
                                   </CustomHeader>
                               </div>
                           </CustomContainer>
                        )
                    })
                }
                <div className={'d-flex justify-content-between'}>

                    {showAddCard ? <CustomHeader onClick={closeAddNewCard} weight={'600'} className={'cursor-pointer'} fontSize={'14px'} color={'#EB5855'}>
                        Cancel
                    </CustomHeader> :
                        <CustomHeader weight={'700'} onClick={()=>{
                            setShowAddCard(true)
                        }} fontSize={'14px'} className={'text-underline cursor-pointer'}  color={'#3F8CFF'}>
                            {'or add new card'}
                        </CustomHeader>
                    }
                </div>
                {showAddCard ?
                    <div>
                        <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                           Card number
                        </CustomTextContainer>
                        <CardInput
                            value={newCard?.cardNumber}
                            onChange={(value) => updateCardValue({ key: 'cardNumber', value })}
                        />
                        {
                            errors.cardNumber && <CustomHeader onClick={closeAddNewCard} weight={'600'} className={'cursor-pointer'} fontSize={'14px'} color={'#EB5855'}>
                                {errors.cardNumber}
                            </CustomHeader>
                        }
                        <div className={'d-flex g-10 justify-content-between'}>
                           <div>
                               <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                   Expiry Date
                               </CustomTextContainer>
                               <CustomInput radius={'8px'}
                                            type={'date'}
                                            onChange={(e)=>{
                                                updateCardValue({ key:'expirationDate', value: e.target.value})
                                            }
                                            }
                                            border={'1px solid #979797'}
                                            borderBottom={'1px solid #979797'}
                                            value={newCard?.expirationDate} />
                               {
                                   errors.expirationDate && <CustomHeader onClick={closeAddNewCard} weight={'600'} className={'cursor-pointer'} fontSize={'14px'} color={'#EB5855'}>
                                       {errors.expirationDate}
                                   </CustomHeader>
                               }
                           </div>
                            <div>
                                <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                    CVV
                                </CustomTextContainer>
                                <CVVInput onChange={(value)=>{
                                                  updateCardValue({ key:'cvv', value})
                                                }
                                              }
                                          value={newCard?.cvv} />
                                {
                                    errors.cvv && <CustomHeader onClick={closeAddNewCard} weight={'600'} className={'cursor-pointer'} fontSize={'14px'} color={'#EB5855'}>
                                        {errors.cvv}
                                    </CustomHeader>
                                }
                            </div>
                        </div>
                    </div>
                    : null}
            </ModalBody>
            <ModalFooter>
                {showAddCard ?

                    <div className={'w-100'}>
                        <FleetButton
                            className={'mb-3'}
                            height={'43px'}
                            width={'90%'}
                            backgroundColor={'#000'}
                            color={'#FFF'}
                            fontSize={'14px'}
                            radius={'32px'}
                            onClick={()=>{
                              checkCardAndSave()
                            }}
                        >
                            Save
                        </FleetButton>

                    </div>
                :
                    <div className={'w-100'}>
                        <FleetButton
                            className={'mb-3'}
                            height={'43px'}
                            width={'90%'}
                            backgroundColor={'#c9c9c978'}
                            hoverColor={'#c9c9c978'}
                            color={'#000'}
                            fontSize={'14px'}
                            radius={'32px'}
                            onClick={()=>{
                                closeAddNewCard()
                                saveData()
                            }}
                        >
                            {dataIndex !== null ? 'Update Charge' : 'Create Charge'}
                        </FleetButton>

                        <FleetButton
                            height={'43px'}
                            width={'90%'}
                            backgroundColor={'#000'}
                            color={'#FFF'}
                            fontSize={'14px'}
                            radius={'32px'}
                            onClick={()=>{
                                closeAddNewCard()
                                cancel()
                            }}
                        >
                            Close
                        </FleetButton>

                    </div>
                }
            </ModalFooter>
        </Modal>
    )
}