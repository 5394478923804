import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import Draggable from "react-draggable";
import * as constants from "../../../../constants";
import {
    AddImageButton, CustomContainer,
    CustomDropdown, CustomHeader,
    CustomInput,
    CustomTextContainer,
    FleetButton, FlexInnerComponent, StatusSpan
} from "@pages/new-design-components/custom-components";
import ArrayDropDownComponent from "@pages/components/ArrayDropDownComponent";
import {useParams} from "react-router";
import {parse} from "query-string";
import {Col} from "reactstrap";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import CustomDamageDropDown from "@pages/components/JobDetailsComponents/CustomDropDown";
import {pdfFontStyleList, pdfFontStyling, tyreConditions} from "../../../../constants";
import NotificationAlert from "react-notification-alert";
import {Notification} from "@pages/components/Notification";
import {useHistory} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {LoaderContainer, override} from "@pages/reusable-components/styled-components";

pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfEditor() {
    const [file, setFile] = useState(null);
    const [dimensions, setDimensions] = useState({
        width: '',
        height: ''
    });
    let { id } = useParams();
    const notificationAlert = useRef(null);
    const [loading,setLoading] = useState(true);
    const history = useHistory();
    const [selectedShortCodeMenu, setSelectedShortCodeMenu] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [customShortCode, setCustomShortCode] = useState('');
    const [shortCodes, setShortCodes] = useState([]);
    const [shortCodeStyleArray, setShortCodeStyleArray] = useState([]);
    const [fields, setFields] = useState([]);
    const parentObjectNames = ['customer', 'deal', 'dealer', 'vehicle', 'custom'];

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setPreviewUrl(URL.createObjectURL(selectedFile));
    };

    const addShortcode = (e) => {
        setFields([
            ...fields,
            { id: Date.now(), positionX: 100, positionY: 100, name: e, font_size: 10, shortcodeStyle: '', styling: {} },
        ]);
    };

    const handleFileFromUrl = async (fileUrl = 'https://api.grsmotorgroup.co.uk/generated.pdf',documentName = 'downloaded-file.pdf') => {

        try {
            const response = await fetch(fileUrl);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();

            const file = new File([blob], documentName, { type: blob.type });

            setFile(file);

            setPreviewUrl(URL.createObjectURL(file));
        } catch (error) {
            Notification('warning', 'Warning', 'Please try again later!',notificationAlert);
            console.error("Error fetching the file from URL:", error);
        }
    };


    useEffect(()=>{
        if (id) {
            const fetchData = async () => {
              try {
                  const response = await axios({
                      method: 'GET',
                      url: `${constants.API_URL}/get-custom-document/${id}`,
                  })
                  const val = response.data
                  setFields(val.data.short_codes)
                  setShortCodes(val?.types)
                  await handleFileFromUrl(val.url,val.data.document_name)
                  if (response.status == "200") {
                      setShortCodes(response.data?.types)
                      setLoading(false)
                  }
              } catch (e) {
                  Notification('warning', 'Warning', 'Please try again later!',notificationAlert);
              }
            }
            fetchData()
        } else {
            const getData = async () => {
               try {
                   const response = await axios({
                       method: 'GET',
                       url: `${constants.API_URL}/get-shortcodes`,
                   })
                   if (response.status == "200") {
                       setShortCodes(response.data?.types)
                       setLoading(false)
                   }
               } catch (e) {
                   Notification('warning', 'Warning', 'Please try again later!',notificationAlert);
               }

            }
            getData()
        }
    },[id])
    const saveFields = async () => {
        const formData = new FormData();
        formData.append("pdf", file);
        formData.append("width", dimensions.width);
        formData.append("height", dimensions.height);
        formData.append("fields", JSON.stringify(fields));

        try {
            let url = 'create-custom-document'
            if (id) {
                url = `update-custom-document/${id}`
            }
            const response = await axios.post(`${constants.API_CORE_URL}/${url}`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType: "blob",
            });
        if (response.status == '200') {
            history.push({
                pathname: `/admin/settings/custom-pdfs`,
            })
        }
        } catch (error) {
            Notification('warning', 'Warning', 'Please try again later!',notificationAlert);
        }
    };
    const handleButtonClick = (id) => {
        document.getElementById(id).click();
    };
    const previewCustomDocument = async () => {
            const formData = new FormData();
            formData.append("pdf", file);
            formData.append("width", dimensions.width);
            formData.append("height", dimensions.height);
            formData.append("fields", JSON.stringify(fields));

            try {
                const response = await axios.post(`${constants.API_CORE_URL}/preview-custom-document`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    responseType: "blob",
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "processed-document.pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error("Upload failed", error);
            }
    }
    const updateShortCodeValue = ({value,key}) => {
        let tempFields = [...fields]
        if (key === 'shortcodeStyle') {
            let tempArray = [...shortCodeStyleArray];

            if (tempArray.includes(value)) {
                tempArray = tempArray.filter(item => item !== value);
            } else {
                tempArray.push(value);
            }
            setShortCodeStyleArray(tempArray);

            const styles = {
                'B': tempArray.includes('B'),
                'I': tempArray.includes('I'),
                'U': tempArray.includes('U'),
            };
            if (styles.B && styles.I && styles.U) {
                tempFields[activeIndex]['shortcodeStyle'] = 'BIU';
            } else if (styles.B && styles.I) {
                tempFields[activeIndex]['shortcodeStyle'] = 'BI';
            } else if (styles.B && styles.U) {
                tempFields[activeIndex]['shortcodeStyle'] = 'BU';
            } else if (styles.I && styles.U) {
                tempFields[activeIndex]['shortcodeStyle'] = 'IU';
            } else if (styles.B) {
                tempFields[activeIndex]['shortcodeStyle'] = 'B';
            } else if (styles.I) {
                tempFields[activeIndex]['shortcodeStyle'] = 'I';
            } else if (styles.U) {
                tempFields[activeIndex]['shortcodeStyle'] = 'U';
            }

            else {
                tempFields[activeIndex]['shortcodeStyle'] = '';
            }
        } else {
            tempFields[activeIndex][key] = value
        }
        setFields(tempFields);
    }

    const updateShortCodeArray = (index) => {
        const tempField = {...fields[index]};

        let tempArray = [];

        const shortcodeStyle = tempField['shortcodeStyle'];

        if (['BIU', 'BI', 'BU', 'IU', 'B', 'I', 'U'].includes(shortcodeStyle)) {
            if (shortcodeStyle === 'BIU') {
                tempArray = ['B', 'I', 'U'];
            } else if (shortcodeStyle === 'BI') {
                tempArray = ['B', 'I'];
            } else if (shortcodeStyle === 'BU') {
                tempArray = ['B', 'U'];
            } else if (shortcodeStyle === 'IU') {
                tempArray = ['I', 'U'];
            } else if (shortcodeStyle === 'B' || shortcodeStyle === 'I' || shortcodeStyle === 'U') {
                tempArray = [shortcodeStyle]
            }
        }

        setShortCodeStyleArray(tempArray);
    }

    return (
        <div className={'position-relative table-container min-vh-100'}>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            {
                loading ? <LoaderContainer>
                        <ClipLoader
                            css={override}
                            size={40}
                            color={`#7B61E4`}
                            loading={loading}
                        />
                    </LoaderContainer> :
                    <>
                        <div className={'d-flex g-10 mt-2'}>
                            <AddImageButton onClick={() => handleButtonClick(`input_`)}>
                                +
                                <input
                                    type="file"
                                    id={`input_`}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </AddImageButton>
                            <ArrayDropDownComponent
                                items={parentObjectNames}
                                defaultText={'Menu'}
                                onSelect={(e) => setSelectedShortCodeMenu(e)}
                            />
                            {selectedShortCodeMenu &&
                            (
                                selectedShortCodeMenu !== 'custom' ?
                                    <ArrayDropDownComponent
                                        items={shortCodes[selectedShortCodeMenu]}
                                        defaultText={selectedShortCodeMenu}
                                        onSelect={(e) => addShortcode(e)}
                                    /> :
                                      <div className={'d-flex g-5 align-items-center'}>
                                          <FlexInnerComponent width={'60'}>
                                              <CustomInput radius={'8px'}
                                                           padding={'4px'}
                                                           placeholder={'Custom Shortcode'}
                                                           onChange={(e)=>{
                                                               setCustomShortCode(e.target.value)
                                                           }
                                                           }
                                                           border={'1px solid #C0D9FF59'}
                                                           value={customShortCode} />
                                          </FlexInnerComponent>
                                          <FlexInnerComponent width={'40'}>
                                              <FleetButton
                                                  backgroundColor={'#319936'}
                                                  color={'#FFF'}
                                                  onClick={()=>{
                                                      addShortcode(customShortCode)
                                                  }}>Add Text</FleetButton>
                                          </FlexInnerComponent>
                                      </div>
                            )

                            }

                            <FleetButton
                                backgroundColor={'#F3994A'}
                                color={'#FFF'}
                                onClick={saveFields}>Save PDF with Fields</FleetButton>
                            <FleetButton
                                backgroundColor={'#F3994A'}
                                color={'#FFF'}
                                onClick={previewCustomDocument}>Preview</FleetButton>
                            <div className={'d-flex g-5 align-items-center'}>
                                    <CustomTextContainer weight={'600'} fontSize={'18px'}  color={'#3F8CFF'} className={'cursor-pointer'}
                                                         onClick={()=>{
                                                             if (activeIndex !== null) {
                                                                 if (fields[activeIndex]?.font_size > 0) {
                                                                     updateShortCodeValue({value: Number(fields[activeIndex]?.font_size) - 1,key:'font_size'})
                                                                 }
                                                             }
                                                         }}
                                    >
                                        -
                                    </CustomTextContainer>

                                    <CustomInput radius={'8px'}
                                                 padding={'4px'}
                                                 type={"number"}
                                                 disabled={activeIndex === null}
                                                 placeholder={'Font Size'}
                                                 onChange={(e)=>{
                                                     updateShortCodeValue({value:e.target.value,key:'font_size'})
                                                   }
                                                 }
                                                 border={'1px solid #C0D9FF59'}
                                                 value={fields[activeIndex]?.font_size} />

                                    <CustomTextContainer weight={'600'} fontSize={'18px'}  color={'#3F8CFF'} className={'cursor-pointer'}
                                                         onClick={()=>{
                                                             if (activeIndex !== null) {
                                                                 updateShortCodeValue({value: Number(fields[activeIndex]?.font_size) + 1,key:'font_size'})
                                                             }
                                                         }}
                                    >
                                        +
                                    </CustomTextContainer>
                                </div>

                            <div className={'d-flex g-10 cursor-pointer align-items-center'}>
                                <CustomContainer padding={'4px'} height={'fit-content'} backgroundColor={shortCodeStyleArray.includes('B') ? '#ededed' : ''}  onClick={()=>{
                                    if (activeIndex !== null) {
                                        updateShortCodeValue({value:'B',key:'shortcodeStyle'})
                                    }
                                }}>
                                    <CustomTextContainer fontSize={'15px'} weight={'600'} color={'#5B5B5B'}>
                                        B
                                    </CustomTextContainer>
                                </CustomContainer>

                                <CustomContainer onClick={()=>{
                                    if (activeIndex !== null) {
                                        updateShortCodeValue({value:'I',key:'shortcodeStyle'})
                                    }
                                }} padding={'4px'} height={'fit-content'} backgroundColor={shortCodeStyleArray.includes('I') ? '#ededed' : ''}>
                                    <CustomTextContainer className={'font-italic'} fontSize={'15px'} weight={'600'} color={'#5B5B5B'} >
                                        I
                                    </CustomTextContainer>
                                </CustomContainer>

                                <CustomContainer padding={'4px'} height={'fit-content'} backgroundColor={shortCodeStyleArray.includes('U') ? '#ededed' : ''}  onClick={()=>{
                                    if (activeIndex !== null) {
                                        updateShortCodeValue({value:'U',key:'shortcodeStyle'})
                                    }
                                }}>
                                    <CustomTextContainer className={'text-underline'} fontSize={'15px'} weight={'600'} color={'#5B5B5B'}>
                                        U
                                    </CustomTextContainer>
                                </CustomContainer>

                            </div>
                        </div>
                        {previewUrl && (
                            <div className={'mt-3 d-flex justify-content-center'}>
                                <Document file={previewUrl} className={'canvas-relative'}>

                                    <Page pageNumber={1}
                                          onLoadSuccess={(page) => {
                                              setDimensions({ width: page.width, height: page.height });
                                          }}
                                    >
                                        {fields.map((field,index) => (
                                            <Draggable
                                                key={field.id}
                                                onStart={()=>{
                                                    setActiveIndex(index)
                                                    updateShortCodeArray(index)
                                                }}
                                                defaultPosition={{ x: parseFloat(field.positionX), y: parseFloat(field.positionY) }}
                                                onStop={(e, data) =>  {
                                                    setFields((prevFields) =>
                                                        prevFields.map((f) =>
                                                            f.id === field.id
                                                                ? { ...f, positionX: data.x, positionY: data.y }
                                                                : f
                                                        )
                                                    );
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: `${field.positionY}px`,
                                                        left: `${field.positionX}px`,
                                                        cursor: "move",
                                                        fontSize: `${field.font_size}px`,
                                                        zIndex: 2,
                                                        ...pdfFontStyling[field.shortcodeStyle]
                                                    }}
                                                >
                                                    {field?.name}
                                                </div>
                                            </Draggable>
                                        ))}
                                    </Page>
                                </Document>


                            </div>
                        )}
                    </>

            }

        </div>
    );
}
