import React, { useEffect,useRef, useState} from 'react'
import {useHistory, useLocation} from "react-router-dom";
import * as constants from "../../../constants";
import cloneDeep from 'lodash/cloneDeep';
import {
    damageOptions,
    damagesPosition,
} from "../../../constants";
import {
    Button,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Modal,
    ModalFooter,
    Row
} from "reactstrap";
import JobCard from "@pages/components/JobDetailsComponents/JobCard";
import {
    CustomHeader,
    DamageIconContainer, FleetButton, RequiredItemsBadge,
} from "@pages/new-design-components/custom-components";
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";
import {
    LoaderContainer,
    override,
} from "@pages/reusable-components/styled-components";
import FleetBodyWorkDamageComponent from "@pages/fleet-management/FleetBodyWorkDamageComponent";
import axios from "axios";
import RequiredInspectionList from "@pages/fleet-management/RequiredInspectionList";
import {BodyworkDamageFrom, TyreDamagesForm} from "@pages/fleet-management/BodyworkComponents";
import CheckInParts from "@pages/new-design-components/CheckInParts";
import {ClipLoader} from "react-spinners";

export default function  VehicleCheckIn() {
    const location = useLocation();
    const history = useHistory();
    const inputRef = useRef()
    const { vehicleId } = location.state || {};
    const [loading,setLoading] = useState(true);
    const [showDamageModal,setShowDamageModal] = useState(false);
    const [data,setData] = useState([]);
    const [departments,setDepartments] = useState([]);
    const [wheelDamage, setWheelDamage] = useState();
    const [wheelDamageOriginal, setWheelDamageOriginal] = useState();
    const [damageData, setDamageData] = useState({})
    const [damageDataDraft, setDamageDataDraft] = useState({})
    const [showTyreModal, setShowTyreModal] = useState(false)
    const [vehicleDamageModal, setVehicleDamageModal] = useState(false)
    const [modalCurrentDamageData, setModalCurrentDamageData] = useState({})
    const [damageModalHeaderText, setDamageModalHeaderText] = useState(null)
    const [damageComponent, setDamageComponent] = useState(null)
    const [updatingDamageIndex, setUpdatingDamageIndex] = useState(null)
    const [damageSelectedOptions, setDamageSelectedOptions] = useState([])
    const [requiredInspectionList, setRequiredInspectionList] = useState([]);
    const [requiredInspectionListDraft, setRequiredInspectionListDraft] = useState([]);
    const [deleteDocumentIds,setDeleteDocumentIds] = useState([]);
    const [activeTab, setActiveTab] = useState('body_work');
    const [activeIndex, setActiveIndex] = useState(0);
    const [deleteIds, setDeleteIds] = useState([])
    const tabs = [
        {
            key: 'body_work',
            label: 'Body Work',
            type: 'body_work',
        },
        {
            key: 'mechanical',
            label: 'Mechanics',
            type: 'mechanics',
        },
        {
            key: 'miscellaneous',
            label: 'Miscellaneous',
            type: 'miscellaneous',
        },
        {
            key: 'valeting',
            label: 'Valeting',
            type: 'valeting',
        },
        {
            key: 'parts',
            label: 'Parts',
            type: 'parts',
        },

    ]
    const [deletePartIds,setDeletePartIds] = useState([]);
    const [partsData,setPartsData] = useState([]);
    const [partsDataDraft,setPartsDataDraft] = useState([]);
    const toggleTyreModal = () => setShowTyreModal(!showTyreModal);
    const toggleVehicleDamageModal = () => setVehicleDamageModal(!vehicleDamageModal);
    const open = () => {
        setWheelDamage({...wheelDamageOriginal})
        toggleVehicleDamageModal()
    }
    const openDamageModal = (key,index) => {
        const activeDamage = damageData[key][index]
        setDamageSelectedOptions(damageOptions[key] ? damageOptions[key] : damageOptions.body)
        setUpdatingDamageIndex(index)
        setDamageComponent(key)
        setModalCurrentDamageData(activeDamage)
        toggleVehicleDamageModal()
        setShowDamageModal(true)
    }
    const openTyreModal = () => {
        toggleVehicleDamageModal()
        setDamageComponent('tyre_wear')
        selectTyre();
    }
    const changeRequiredData = ({index, idx, value}) => {
        let tempData = [...requiredInspectionList];
        if (tempData[index]?.list?.[idx]) {
            tempData[index].list[idx].required = value;
            tempData[index].list[idx].department_id = tempData[index].department_id;
        }
        setRequiredInspectionList(tempData);
    }
    const deleteDamage = (key,index) => {
        const updatedDamageData = { ...damageData };
        if (updatedDamageData[key] && updatedDamageData[key][index]) {
            if (updatedDamageData[key][index].id) {
                setDeleteIds([...deleteIds,updatedDamageData[key][index].id])
            }
            updatedDamageData[key].splice(index, 1);
        }
        setDamageData(updatedDamageData);
    }

    const fetchData = () => {
        axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-vehicle`,
            params: {
                vehicleId,
            }
        }).then(res=>{
            const tempData = res.data.data
            setData(tempData)
            setPartsData(cloneDeep(tempData.checkInParts))
            setPartsDataDraft(cloneDeep(tempData.checkInParts))
            let tempObj = {}
            tempData.damages.forEach(damage=>{
                let index = null
                index = Object.values(damagesPosition).findIndex(item=>item === damage.type)
                if (index !== null) {
                    const key = Object.keys(damagesPosition)[index]
                    tempObj[key] = []
                    tempObj[key].push(damage)
                }
            })
            // if (tempData.signature) {
            //     setSignature(tempData.signature)
            // }
            setDamageData(cloneDeep(tempObj))
            setDamageDataDraft(cloneDeep(tempObj))
            const output = tempData.checkTyreDamages.reduce((acc, item) => {
                acc[item.tyre] = {
                    depth: item.depth,
                    position: item.tyre,
                    condition: item.condition
                };
                return acc;
            }, {});
            setWheelDamage(cloneDeep(output))
            setWheelDamageOriginal(cloneDeep(output))
            axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/get-required-inspection`,
            }).then(resource=>{
                let requiredData = resource.data.data;
                requiredData = requiredData.map(item => {
                    item.list = item.list.map(listItem => {
                        tempData.requiredInspectionResult.map(res=>{
                            if (res?.required_inspection_id === listItem.id) {
                                listItem.option_id = res.id;
                                listItem.required = res?.required === 1;
                            }
                        })
                        return listItem;
                    });
                    return item;
                });
                setRequiredInspectionList(cloneDeep(requiredData))
                setRequiredInspectionListDraft(cloneDeep(requiredData))
                setLoading(false)
            }).catch(err=>{
                setLoading(false)
            })
        }).catch(err=>{
            setLoading(false)
        })
    }

    const fetchRequiredData = async () => {
       const res = await axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/get-required-inspection`,
        })
        if (res.status === 200) {
            let requiredData = res.data.data;
            setRequiredInspectionList(cloneDeep(requiredData))
            setRequiredInspectionListDraft(cloneDeep(requiredData))
        }
      const resource = await axios({
                 method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quote-vehicle`,
                params: {
                    vehicleId,
                },
        })
        if (resource.status === 200) {
             setData(resource.data.data.vehicle)
             setDepartments(resource.data.data.departments)
        }
        setLoading(false)
    }
    const redirectToManagement = () => {
        history.push({
            pathname: '/admin/fleet-vehicles-list'
        })
    }
    useEffect(()=>{
        fetchRequiredData()
    },[vehicleId])

    // const clear = () => {
    //     eSign.current.clear()
    // }

    const selectWheelDamage = (tyre, tyre_damage_type, val) => {
        let tempDamage = {...wheelDamage};
        if (!Object.keys(tempDamage).includes(tyre)) {
            tempDamage[tyre] = {};
        }
        tempDamage[tyre][tyre_damage_type] = val;
        tempDamage[tyre]['position'] = tyre;
        setWheelDamage(tempDamage);
    };

    const saveChanges = () => {
        let tempData = {...damageData};
        let modalTempData = {...modalCurrentDamageData};
        modalTempData.type = damageModalHeaderText;
        if (!tempData[damageComponent]) {
            tempData[damageComponent] = []
        }
        if (updatingDamageIndex !== null) {
            tempData[damageComponent][updatingDamageIndex] = modalTempData
            setUpdatingDamageIndex(null)
        } else {
            tempData[damageComponent].push(modalTempData)
        }
        setDamageData(tempData)
        setModalCurrentDamageData({})
        toggleVehicleDamageModal()
    }
    const sendData = async () => {
        setLoading(true)
        let fleet_id = null
        axios({
            method: 'POST',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet`,
            data: {
                vehicle_id: vehicleId,
            },
        }).then( response=>{
            fleet_id = response.data.data.id
             axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/create-simple-quote`,
                data: {
                    vehicle_id: vehicleId,
                    fleet_id: response.data.data.id,
                },
            }).then(async quoteResponse=>{
                 const quoteId = quoteResponse.data.data.id
                 if (JSON.stringify(requiredInspectionListDraft) !== JSON.stringify(requiredInspectionList)) {
                     try {
                         const mergedList = requiredInspectionList.reduce((acc, item) => {
                             return acc.concat(item.list);
                         }, []);
                         await axios({
                             method: 'POST',
                             url: `${constants.FLEET_MS_API_URL}/fleet-management/update-required-inspection`,
                             data: {
                                 data: mergedList,
                                 vehicleId,
                                 fleet_id,
                                 quoteId,
                             },
                         });
                     } catch (error) {
                         setLoading(false)
                         console.error('Error updating require inspection:', error);
                     }
                 }
                 if (JSON.stringify(partsDataDraft) !== JSON.stringify(partsData)) {
                     try {
                         const damageRequests = partsData.map((item) => {
                             let formData = new FormData();
                             formData.append('part', item.part);
                             formData.append('part_number', item.part_number);
                             formData.append('cost', item.cost);
                             formData.append('department', item.department?.id);
                             formData.append('status', item.status);
                             if (item.id) {
                                 formData.append('id', item.id);
                             }
                             formData.append('fleet_id', fleet_id);
                             formData.append('quote_id', quoteId);
                             formData.append('vehicle_id', vehicleId);
                             if (item?.documents?.length > 0) {
                                 let index = 0;
                                 item.documents.forEach((image) => {
                                     if (!image.hasOwnProperty('image_link')) {
                                         formData.append(`files[${index}]`, image.file);
                                         index++;
                                     }
                                 });
                             }
                             return axios({
                                 method: 'POST',
                                 url: `${constants.FLEET_MS_API_URL}/fleet-management/update-parts-data`,
                                 data: formData
                             });
                         });
                         await Promise.all(damageRequests);
                     } catch (error) {
                         setLoading(false)
                         console.error('Error updating require inspection:', error);
                     }
                 }
                 if (wheelDamageOriginal) {
                     wheelDamageOriginal.fleetId = fleet_id;
                     wheelDamageOriginal.vehicleId = vehicleId;
                     wheelDamageOriginal.quoteId = quoteId;
                     try {
                         await axios({
                             method: 'POST',
                             url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-tyre-damage`,
                             data: wheelDamageOriginal,
                         });
                     } catch (error) {
                         setLoading(false)
                         console.error('Error sending wheel damage data:', error);
                     }
                 }
                 if (deleteIds.length) {
                     try {
                         const res = await axios({
                             method: 'POST',
                             url: `${constants.FLEET_MS_API_URL}/fleet-management/damages-delete`,
                             data: deleteIds,
                         });
                         if (res) {
                             setDeleteIds([])
                         }
                     } catch (error) {
                         setLoading(false)
                         console.error('Error sending wheel damage data:', error);
                     }
                 }
                 let currentDamages = Object.values(damageData).flat();
                 let draftDamages = Object.values(damageDataDraft).flat();
                 let newDamages = currentDamages.filter(currentDamage => !currentDamage.id);
                 let updatedDamages = currentDamages.filter(currentDamage => {
                     return draftDamages.some(draftDamage => {
                         return draftDamage.id === currentDamage.id &&
                             JSON.stringify(draftDamage) !== JSON.stringify(currentDamage);
                     });
                 });
                 let tempDamageData = [...newDamages,...updatedDamages];

                 const damageRequests = tempDamageData.map((item) => {
                     let formData = new FormData();
                     formData.append('damage', item.damage);
                     formData.append('position', item.position);
                     formData.append('note', item.note);
                     formData.append('type', item.type);
                     formData.append('fleetId', fleet_id);
                     formData.append('vehicleId', vehicleId);
                     formData.append('quote_id', quoteId);
                     if (item.id) {
                         formData.append('id', item.id);
                     }
                     if (item?.images?.length > 0) {
                         item.images.forEach((image) => {
                             if (!image.hasOwnProperty('image_link')) {
                                 formData.append(`documents[]`, image);
                             }
                         });
                     }
                     return axios({
                         method: 'POST',
                         url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-vehicle-damage`,
                         data: formData,
                     });
                 });

                 try {
                     await Promise.all(damageRequests);
                     await fetchData()
                     await redirectToManagement()
                     console.log('All damage data sent successfully');
                 } catch (error) {
                     console.error('Error sending damage data:', error);
                 }
             }).catch(err=>{
                 console.log('err:',err)
             });
        }).catch(err=>{
            console.log('err',err)
        });


    };
    const onFilesChange = (e) => {
        let images = []
        let imagesPreview = []
        if (modalCurrentDamageData?.images?.length) {
            images = [...modalCurrentDamageData.images]
        }
        if (modalCurrentDamageData?.imagesPreview?.length) {
            imagesPreview = [...modalCurrentDamageData.imagesPreview]
        }

        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i];
            images.push(file)
            let reader = new FileReader();
            reader.readAsDataURL(file)
            imagesPreview.push(URL.createObjectURL(file))
        }
        setModalCurrentDamageData({...modalCurrentDamageData, images: images, imagesPreview: imagesPreview})
    }
    const selectDamage = (damage) => {
        setDamageModalHeaderText(damagesPosition[damage])
        setDamageComponent(damage)
        setDamageSelectedOptions(damageOptions[damage] ? damageOptions[damage] : damageOptions.body)
        let data = {
            position: '',
            damage: '',
            note: '',
            images: [],
            imagesPreview: []
        }
        setModalCurrentDamageData({...data})
        if (!showDamageModal) {
            setShowDamageModal(true)
        }
    }

    const selectTyre = () => {
        if (showDamageModal) {
            setShowDamageModal(false)
        }
        setShowTyreModal(true)
    }
    const checkBorder = (position) => (position === damageComponent)

    const addNewPartsRow = () => {
        let tempData = [...partsData]
        let row =  {
            part_number: null,
            part: null,
            cost: null,
            department: null,
            status: 'to_do',
            fleet_id: null,
            vehicle_id: vehicleId,
        }
        tempData.push(row)
        setPartsData(tempData)
    }
    const deletePartsRow = (index) => {
        let tempData = [...partsData];
        let taskToDelete = tempData[index];
        let deleteIdsTemp = [...deletePartIds];
        if (taskToDelete?.id) {
            deleteIdsTemp.push(taskToDelete.id);
        }
        tempData.splice(index, 1);
        setDeletePartIds(deleteIdsTemp)
        setPartsData(tempData)
    }
    const handleFileSelect = async (file, index) => {
        if (file) {
            let tempData = [...partsData];
            if (!tempData[index].documents) {
                tempData[index].documents = [];
            }
            let reader = new FileReader();
            reader.readAsDataURL(file)
            const preview =  URL.createObjectURL(file)
            if (tempData[index]?.id) {
                tempData[index]['edited'] = true
            }
            tempData[index].documents.push({
                preview,
                file,
            });

            setPartsData(tempData);

        }
    }
    const deleteTaskDocument = (id,index) => {
        let tempData = [...partsData];
        if (id) {
            tempData[index].documents = [];

            const docs = [...deleteDocumentIds]
            docs.push(id)
            setDeleteDocumentIds(docs)
        } else {
            tempData[index].documents = [];
        }
        setPartsData(tempData)
    }
    const changePartsData = ({index,key,value = null}) => {
        let tempData = [...partsData]
        tempData[index][key] = value
        if (tempData[index]?.id) {
            tempData[index]['edited'] = true
        }
        setPartsData(tempData)
    }

    const nextTab = () => {
       setActiveTab(tabs[activeIndex + 1].key)
       setActiveIndex(activeIndex + 1)
    }
    return (
        loading ?  <LoaderContainer>
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={loading}
                />
            </LoaderContainer>
            :
            <div className={'container-fluid mt-3'}>
                <Modal
                    className={'modal-dialog-right'}
                    isOpen={vehicleDamageModal}
                    toggle={toggleVehicleDamageModal}
                    backdrop="static"
                >
                    <div>
                        <CardHeader>
                            <div className={'d-flex justify-content-between'}>
                                <CustomHeader weight={'600'} fontSize={'14px'}>
                                    Record Damage
                                </CustomHeader>
                                <CustomHeader color={'red'} className={'cursor-pointer'} onClick={toggleVehicleDamageModal} weight={'600'} fontSize={'14px'}>
                                    Close
                                </CustomHeader>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <CustomHeader weight={'700'} fontSize={'14px'}>
                                Area
                            </CustomHeader>
                            <div>
                                <div className={'grid-container mb-3'}>
                                    <div className={'grid-item'}>
                                        <DamageIconContainer active={checkBorder('front')}>
                                            <img onClick={() => selectDamage('front')}
                                                 alt={''}
                                                 className={'cursor-pointer mb-3' }
                                                 src={require("assets/images/VehicleInspection/car_front.png").default}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                    </div>
                                    <div className={'grid-item'}>
                                        <DamageIconContainer active={checkBorder('back')}>
                                            <img onClick={() => selectDamage('back')}
                                                 alt={''}
                                                 className={'cursor-pointer mb-3'}
                                                 src={require("assets/images/VehicleInspection/car_back.png").default}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                    </div>

                                  <div className={'grid-item'}>
                                      <DamageIconContainer active={checkBorder('top')}>
                                          <img onClick={() => selectDamage('top')}
                                               alt={''}
                                               className={'cursor-pointer mb-3'}
                                               src={require("assets/images/VehicleInspection/car_top.png").default}
                                               width={72}
                                               height={72}/>

                                      </DamageIconContainer>
                                  </div>

                                </div>
                                <div className={'grid-container mb-3'}>
                                    <div className={'grid-item'}>
                                        <DamageIconContainer active={checkBorder('left')}>
                                            <img onClick={() => selectDamage('left')}
                                                 alt={''}
                                                 className={'cursor-pointer'}
                                                 src={require("assets/images/VehicleInspection/car_left.png").default}
                                                 width={72}
                                                 height={72}/>
                                        </DamageIconContainer>

                                    </div>
                                    <div className={'grid-item'}>
                                        <DamageIconContainer active={checkBorder('right')}>
                                            <img onClick={() => selectDamage('right')}
                                                 alt={''}
                                                 className={'cursor-pointer'}
                                                 src={require("assets/images/VehicleInspection/car_right.png").default}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                    </div>
                                      <div className={'grid-item'}>
                                          <DamageIconContainer active={checkBorder('doors')}>
                                              <img onClick={() => selectDamage('doors')}
                                                   alt={''}
                                                   className={'cursor-pointer'}
                                                   src={require("assets/images/VehicleInspection/car_doors.png").default}
                                                   width={72}
                                                   height={72}/>

                                          </DamageIconContainer>
                                      </div>
                                </div>
                                <div className={'grid-container'}>
                                    <div className={'grid-item'}>
                                        <DamageIconContainer active={checkBorder('inside')}>
                                            <img onClick={() => selectDamage('inside')}
                                                 alt={''}
                                                 className={'cursor-pointer mb-3'}
                                                 src={require("assets/images/VehicleInspection/car_inside.png").default}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                    </div>
                                </div>

                            </div>
                            {showDamageModal && <div>
                                <BodyworkDamageFrom
                                    damageComponent={damageComponent}
                                    modalCurrentDamageData={modalCurrentDamageData}
                                    setModalCurrentDamageData={setModalCurrentDamageData}
                                    damageSelectedOptions={damageSelectedOptions}
                                    onFilesChange={onFilesChange}
                                    inputRef={inputRef}
                                />
                                <CardFooter>
                                    <Button onClick={() => {
                                        setDamageSelectedOptions([])
                                        setDamageComponent(null)
                                        setModalCurrentDamageData({})
                                        setShowDamageModal(false)
                                        toggleVehicleDamageModal()
                                        if (updatingDamageIndex >= 0) {
                                            setUpdatingDamageIndex(null)
                                        }
                                    }} variant="secondary">
                                        Close
                                    </Button>
                                    <Button onClick={saveChanges}
                                            disabled={!modalCurrentDamageData?.damage}
                                            variant="primary">
                                        Save Changes
                                    </Button>
                                </CardFooter>
                            </div>}
                            {  showTyreModal &&
                            <div className={'mt-3'}>
                                <TyreDamagesForm selectWheelDamage={selectWheelDamage} wheelDamage={wheelDamage}/>
                                <CardFooter>
                                    <ModalFooter>
                                        <Button onClick={() => {
                                            toggleTyreModal()
                                            setDamageSelectedOptions([])
                                            setDamageComponent(null)
                                            setModalCurrentDamageData({})
                                            setShowDamageModal(false)
                                            toggleVehicleDamageModal()
                                        }} variant="secondary">
                                            Close
                                        </Button>
                                        <Button onClick={()=>{
                                            setWheelDamageOriginal({...wheelDamage})
                                            toggleTyreModal();
                                        }} variant="primary">
                                            Save Changes
                                        </Button>
                                    </ModalFooter>
                                </CardFooter>
                            </div>
                            }
                        </CardBody>
                    </div>
                </Modal>
                <BackActionComponent backAction={redirectToManagement} text={'Back to Vehicles List'}/>
                <div className={'d-flex justify-content-between'}>
                    <CustomHeader lineHeight={'unset'} >Vehicle Check In</CustomHeader>
                    <FleetButton
                        height={'40px'}
                        width={'164px'}
                        backgroundColor={'#F3994A'}
                        radius={'8px'}
                        onClick={()=> {
                            if (activeIndex === tabs.length -1) {
                                sendData()
                            } else {
                                nextTab()
                            }
                        }}
                    >
                        {activeIndex === tabs.length -1 ? 'Save' : 'Next'}
                    </FleetButton>
                </div>
                <Row className={'mt-3'}>
                    <Col lg={3}>
                        <JobCard workerDetails={false} data={data} saveVehicleDetails={()=>{}}/>
                    </Col>
                    <Col lg={9}>
                        <div className={'d-flex g-10 mb-3 flex-wrap'}>
                            {
                                tabs.map((tab,index) => {
                                    return (
                                        <RequiredItemsBadge type={tab.type} onClick={()=> {
                                            setActiveTab(tab.key);
                                            setActiveIndex(index);
                                        }
                                        } active={activeTab === tab.key}>
                                            {tab.label}
                                        </RequiredItemsBadge>
                                    )
                                })
                            }
                        </div>
                        {
                            activeTab === 'body_work' ?
                                <Col lg={12}>
                                    <FleetBodyWorkDamageComponent
                                        showDamageModal={open}
                                        openDamageModal={openDamageModal}
                                        data={damageData}
                                        tyreData={wheelDamageOriginal}
                                        openTyreModal={openTyreModal}
                                        deleteRow={deleteDamage}/>
                                </Col>
                                : activeTab ==='parts' ?
                                        <CheckInParts deleteRow={deletePartsRow}
                                                      changeData={changePartsData}
                                                      deleteDocument={deleteTaskDocument}
                                                      addNewRow={addNewPartsRow}
                                                      departments={departments}
                                                      onFileSelect={handleFileSelect}
                                                      data={partsData}/>
                                :
                                <Row>
                                    <RequiredInspectionList activeTab={activeTab} data={requiredInspectionList} changeData={changeRequiredData}/>
                                </Row>
                        }


                    </Col>
                </Row>
            </div>
    )
}