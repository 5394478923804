import React from 'react'
import {Route} from "react-router-dom";
import fleetRedirectRoutes from "./FleetRedirectRoutes";

export default function FleetRoutes() {
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
              return <Route
                  exact
                  path={prop.path}
                  component={prop.component}
                  key={key}
              />
        });
    }
    return (
            <>
                {getRoutes(fleetRedirectRoutes)}
            </>
    );
}