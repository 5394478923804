import React, { useEffect,useState} from 'react'
import {useHistory, useLocation} from "react-router-dom";
import * as constants from "../../../constants";

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import JobCard from "@pages/components/JobDetailsComponents/JobCard";
import {
    CustomHeader, CustomInput, CustomTD,
    FleetButton, IconContainer,
   StyledTable, StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import axios from "axios";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";

export default function  FleetVehicleTasks() {
    const location = useLocation();
    const history = useHistory();

    const { vehicleId, fleetId } = location.state || {};
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [deleteIds, setDeleteIds] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [vehicle, setVehicle] = useState({});
    const getVehicle = async () => {
        try {
            const res = await axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-vehicle`,
                params: { vehicleId, fleetId },
            });
            setVehicle(res.data.data);
        } catch (err) {
            console.log('Error in getVehicle:', err);
        }
    };

    const getDepartments = async () => {
        try {
            const res = await axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/departments`,
            });
            setDepartments(res.data.data?.departments);
        } catch (err) {
            console.log('Error in getDepartments:', err);
        }
    };

    const fetchData = async () => {
        try {
            const res = await axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/task`,
                params: { fleetId },
            });
            setData(res.data.data);
        } catch (err) {
            console.log('Error in fetchData:', err);
        }
    };

    useEffect(() => {
        if (fleetId && vehicleId) {
            const getData = async () => {
                try {
                    setLoading(true);
                    await Promise.all([getVehicle(), getDepartments(), fetchData()]);
                } catch (err) {
                    console.log('Error in getData:', err);
                } finally {
                    setLoading(false);
                }
            };

            getData();
        }
    }, [fleetId, vehicleId]);

    const redirectToManagement = () => {
        history.push({
            pathname: '/admin/fleet-vehicles-list'
        })
    }
    const updateTasks = async (updated) => {
        if (updated.length) {
            const promises = updated.map(async (item) => {
                const tempItem = {...item,job_category: item.department.name,department_id: item.department.id}
                try {
                    const response = await axios.post(
                        `${constants.FLEET_MS_API_URL}/fleet-management/fleet/task/${item?.id}`,
                        tempItem,
                    );
                    return response.data;
                } catch (error) {
                    console.error('Error while updating task:', error);
                    throw error;
                }
            });
            try {
                await Promise.all(promises);
                console.log('All tasks have been updated successfully.');
            } catch (error) {
                console.error('Error while updating tasks:', error);
            }
        }
    };

    const deleteTasks = async (deleted) => {
        const promises = deleted.map(item =>
            axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/fleet/task/${item}/archive`)
        );

        try {
            await Promise.all(promises);
            setDeleteIds([])
            console.log('All delete tasks have been archived successfully.');
        } catch (error) {
            console.error('Error while deleting tasks:', error);
        }
    }
    const sendData = async () => {
        let updated = data.filter(item=>item.edited)
        try {
            if (deleteIds.length) {
                await deleteTasks(deleteIds)
            }
            await Promise.all([
                updateTasks(updated),
            ]);
            await fetchData()
        } catch (error) {
            console.error('Error while processing tasks:', error);
        } finally {
            setLoading(false);
        }
    }
    const changeData = ({index,key,value}) => {
        let tempData = [...data];
        tempData[index]['edited'] = true;
        tempData[index][key] = value;
        setData(tempData);
    }
    const deleteRow = ({index,id}) => {
        let tempIds = [...deleteIds]
        if (!tempIds.includes(id)) {
            tempIds.push(id)
            setDeleteIds(tempIds)
        }
        const newData = data.filter((_, i) => i !== index);
        setData(newData)
    }
    return (
        loading ? <></>
            :
             <div className={'container-fluid mt-3'}>
                 <BackActionComponent backAction={redirectToManagement} text={'Back to Vehicles List'}/>
                 <div className={'d-flex justify-content-between'}>
                     <CustomHeader lineHeight={'unset'} >Job Card</CustomHeader>
                     <FleetButton
                         height={'40px'}
                         width={'164px'}
                         backgroundColor={'#F3994A'}
                         radius={'8px'}
                         onClick={sendData}
                     >
                         Save
                     </FleetButton>
                 </div>
                 <Row className={'mt-3'}>
                     <Col lg={3}>
                         <JobCard data={vehicle}/>
                     </Col>
                     <Col lg={9}>
                         <Card>
                             <CardHeader className={'border-bottom-0'}>
                                 <CustomHeader fontSize={'16px'}>
                                   Tasks List
                                 </CustomHeader>
                             </CardHeader>
                             <CardBody>
                                 <StyledTable className={'table-borderless'}>
                                     <thead className={'header-white-text'}>
                                     <tr>
                                         <StyledTableHeaderCell className={'text-center'} width={'25%'} color={'#0000008C'}>
                                             Department
                                         </StyledTableHeaderCell>

                                         <StyledTableHeaderCell className={'text-center'} width={'25%'} color={'#0000008C'}>
                                             Description
                                         </StyledTableHeaderCell>
                                         <StyledTableHeaderCell className={'text-center'} width={'25%'} color={'#0000008C'}>
                                            Status
                                         </StyledTableHeaderCell>

                                         <StyledTableHeaderCell className={'text-center'} width={'10%'} color={'#0000008C'}>

                                         </StyledTableHeaderCell>
                                     </tr>
                                     </thead>
                                     <tbody>
                                     {data.length ?
                                         data.map((item, index)=>{
                                             return <tr key={`i_${index}`}>
                                                 <CustomTD width={'20%'}>
                                                     <CustomDropdownComponent
                                                         withoutDot
                                                         border={'1px solid #C0D9FF59'}
                                                         selectedTitle={item.department.name}
                                                         statusArrayKey={'departments'}
                                                         handleChange={(value)=>{
                                                             const department = departments.find(item=>item.name === value)
                                                             changeData({
                                                                 index,
                                                                 key: 'department',
                                                                 value: department
                                                             })
                                                         }}
                                                     />
                                                 </CustomTD>
                                                 <CustomTD className={'text-center'} width={'25%'}>
                                                     <CustomInput radius={'8px'}
                                                                  onChange={(e)=>{
                                                                      changeData({
                                                                          index,
                                                                          key: 'description',
                                                                          value: e.target.value
                                                                      })
                                                                  }
                                                                  }
                                                                  border={'1px solid #C0D9FF59'}
                                                                  value={item?.description} />
                                                 </CustomTD>
                                                 <CustomTD className={'text-center'} width={'25%'}>
                                                     <CustomDropdownComponent
                                                         border={'1px solid #C0D9FF59'}
                                                         selectedTitle={item.status}
                                                         statusArrayKey={'fleetTasksStatuses'}
                                                         handleChange={(value)=>{
                                                             changeData({
                                                                 index,
                                                                 key: 'status',
                                                                 value
                                                             })
                                                         }}
                                                     />

                                                 </CustomTD>
                                                 <CustomTD width={'10%'}>
                                                     <IconContainer>
                                                         <IcoMoon
                                                             className={'cursor-pointer'}
                                                             iconSet={IconSet}
                                                             icon={'delete_new_outline'}
                                                             size={20}
                                                             onClick={() => {
                                                                 deleteRow({index,id:item.id})
                                                             }}
                                                         />
                                                     </IconContainer>
                                                 </CustomTD>
                                             </tr>
                                         })
                                         : null
                                     }
                                     </tbody>
                                 </StyledTable>
                             </CardBody>
                         </Card>

                    </Col>
                 </Row>
             </div>
    )
}