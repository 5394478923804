import {
    CustomContainer,
    CustomHeader,
    CustomReactStrapInput,
    FleetButton
} from "@pages/new-design-components/custom-components";
import React, {Fragment} from "react";
import {Col} from "reactstrap";
import {calculateDateDifference, checkAndFormatShortGb, formatNumber, parseToWord} from "../../../../../constants";
import CustomSearch from "@pages/components/CustomSearch";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import IconSet from "@assets/selection.json";
import IcoMoon from "react-icomoon";
import moment from "moment";
import Picker from "@pages/components/DatePicker";

export default function DebtListComponent({ debtsData = [],createDebt,editDebt, updateFilter, filter }) {
   return (
       <>

          <div className={'d-flex justify-content-between mb-3 align-items-center'}>
              <CustomHeader lineHeight={'16px'} weight={'700'} color={'#000'} fontSize={'20px'}>Debt List({debtsData.length})</CustomHeader>

          </div>
           <div className={'d-flex mb-3 g-10 flex-wrap'}>
                   <div className={'d-flex g-5'}>
                       <IcoMoon
                           className={'cursor-pointer'}
                           iconSet={IconSet}
                           icon={'ic_order'}
                           size={40}
                           onClick={() => {
                               updateFilter({key:'order_by',value:'amount_due'})
                           }}
                       />
                       <CustomSearch type={'number'} placeholder="Amount" inputWidth={'unset'} value={filter?.amount} onChange={(e)=>{
                           updateFilter({key:'amount',value:e.target.value})

                       }}/>
                   </div>
                   <div className={'d-flex g-5 debt-datePicker white-background'}>
                       <IcoMoon
                           className={'cursor-pointer'}
                           iconSet={IconSet}
                           icon={'ic_order'}
                           size={40}
                           onClick={() => {
                               updateFilter({key:'order_by',value:'due_date'})
                           }}
                       />
                       <Picker
                           value={filter?.due_date}
                           placeholderText={'Due Date'}
                           onChange={(date) => {
                               updateFilter({key:'due_date',value:date})
                           }}
                       />

                   </div>
                   <div className={'d-flex g-5'}>
                       <IcoMoon
                           className={'cursor-pointer'}
                           iconSet={IconSet}
                           icon={'ic_order'}
                           size={40}
                           onClick={() => {
                               updateFilter({key:'order_by',value:'overdue_duration'})
                           }}
                       />
                       <CustomDropdownComponent
                           withoutDot
                           buttonHeight={'100%'}
                           border={'null'}
                           containerMinWidth={'150px'}
                           selectedTitle={filter?.overdue_duration}
                           statusArrayKey={'overdueDuration'}
                           noBadge={true}
                           handleChange={(value)=>{
                               updateFilter({key:'overdue_duration',value:value})
                           }}
                       />
                   </div>
                   <div className={'flex-grow-1'}>
                       <CustomDropdownComponent
                           withoutDot
                           buttonHeight={'100%'}
                           border={'null'}
                           selectedTitle={filter?.debt_status}
                           statusArrayKey={'debtStatuses'}
                           noBadge={true}
                           handleChange={(value)=>{
                               updateFilter({key:'debt_status',value:value})
                           }}
                       />
                   </div>
           </div>
           <div>
               {debtsData.length ?
                   debtsData.map((item,index)=>{
                       return (
                           <Fragment key={index}>
                               <CustomContainer className={'mb-3'} backgroundColor={'#FFF'} borderRadius="8px" padding="10px 16px">
                                   <div className={'d-flex justify-content-between'}>
                                       <Col>

                                           <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'}>Vehicle</CustomHeader>
                                           <CustomHeader weight={'400'} color={'#000000'} fontSize={'14px'}>{item?.vehicle?.stock_no || item?.vehicle?.label}</CustomHeader>
                                       </Col>
                                       <Col>
                                           <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'}>Overdue Duration</CustomHeader>
                                           <CustomHeader weight={'400'} color={'#EB5855'} fontSize={'14px'}>{item?.overdue_duration}</CustomHeader>
                                       </Col>
                                       <Col>
                                           <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'}>Amount</CustomHeader>
                                           <CustomHeader weight={'400'} color={'#000000'} fontSize={'14px'}>{formatNumber(item?.amount_due)}</CustomHeader>
                                       </Col>
                                       <Col>
                                           <img className={'cursor-pointer'} height='25px' width='25px'
                                                onClick={()=>{
                                                    editDebt(index)
                                                }} src={require(`assets/images/edit.svg`).default} alt=""/>
                                       </Col>

                                   </div>
                                   <div className={'d-flex justify-content-between'}>
                                       <Col>
                                           <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'}>Due</CustomHeader>
                                           <CustomHeader weight={'400'} color={'#000000'} fontSize={'14px'}>{checkAndFormatShortGb(item?.due_date) || '-'}</CustomHeader>
                                       </Col>
                                       <Col>
                                           <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'}>Expected Payment Date:</CustomHeader>
                                           <CustomHeader weight={'400'} color={'#000000'} fontSize={'14px'}>{checkAndFormatShortGb(item?.expected_payment_date) || '-'}</CustomHeader>
                                       </Col>
                                       <Col/>
                                       <Col/>
                                   </div>
                                   <div className={'d-flex justify-content-between'}>
                                       <Col/>
                                       <Col>
                                           <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'}>Status</CustomHeader>
                                           <CustomHeader weight={'400'} color={'#EB5855'} fontSize={'14px'}>{parseToWord(item?.debt_status)}</CustomHeader>
                                       </Col>
                                       <Col/>
                                       <Col/>
                                   </div>
                               </CustomContainer>
                           </Fragment>
                       )
                   })
                   : <></>
               }
           </div>
       </>

   )
}