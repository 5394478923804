import * as actionTypes from './actions';
import produce from 'immer';
import {GET_DEBT_CUSTOMERS_REQUEST} from "./actions";

const initialState = {
  getCustomerSuccess: false,
  getCustomerError: false,
  customer: null,

  sendSystemNoteSuccess: false,
  sendSystemNoteError: false,
  systemNote: null,

  getSystemNotesSuccess: false,
  getSystemNotesError: false,
  systemNotes: [],

  addSystemReminderSuccess: false,
  addSystemReminderError: false,
  reminder: null,

  getSystemRemindersSuccess: false,
  getSystemRemindersError: false,
  systemReminders: [],

  editSystemReminderSuccess: false,
  editSystemReminderError: false,
  editedReminder: null,

  deleteSystemReminderError: false,
  deleteSystemReminderSuccess: false,

  getCustomersDebtSuccess: false,
  getCustomersDebtError: false,
  customersDebts: [],

  getDebtStatsSuccess: false,
  getDebtStatsError: false,
  debtStats: [],

  getDebtCustomersSuccess: false,
  getDebtCustomersError: false,
  debtCustomers: [],

  getCustomerDebtSuccess: false,
  getCustomerDebtError: false,
  customerDebts: [],

  updateCustomerDebtSuccess: false,
  updateCustomerDebtsError: false,
  customerDebt: {},

  getCommunicationLogsSuccess: false,
  getCommunicationLogsError: false,
  communicationLogs: [],
};

const customerSignatureState = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CUSTOMER_REQUEST:
      draft.getCustomerSuccess = false;
      draft.getCustomerError = false;
      draft.customer = null;
      break;
    case actionTypes.GET_CUSTOMER_SUCCESS:
      draft.getCustomerSuccess = true;
      draft.getCustomerError = false;
      draft.customer = action.data?.data;
      break;
    case actionTypes.GET_CUSTOMER_ERROR:
      draft.getCustomerSuccess = false;
      draft.getCustomerError = true;
      draft.customer = null;
      break;
    case actionTypes.SEND_SYSTEM_NOTE_REQUEST:
      draft.sendSystemNoteSuccess = false;
      draft.sendSystemNoteError = false;
      draft.systemNote = null;
      break;
    case actionTypes.SEND_SYSTEM_NOTE_SUCCESS:
      draft.sendSystemNoteSuccess = true;
      draft.sendSystemNoteError = false;
      draft.systemNote = action.data?.data;
      break;
    case actionTypes.SEND_SYSTEM_NOTE_ERROR:
      draft.sendSystemNoteSuccess = false;
      draft.sendSystemNoteError = true;
      draft.systemNote = null;
      break;
    case actionTypes.GET_SYSTEM_NOTES_REQUEST:
      draft.getSystemNotesSuccess = false;
      draft.getSystemNotesError = false;
      draft.systemNotes = [];
      break;
    case actionTypes.GET_SYSTEM_NOTES_SUCCESS:
      draft.getSystemNotesSuccess = true;
      draft.getSystemNotesError = false;
      draft.systemNotes = action.data?.data;
      break;
    case actionTypes.GET_SYSTEM_NOTES_ERROR:
      draft.getSystemNotesSuccess = false;
      draft.getSystemNotesError = true;
      draft.systemNotes = [];
      break;
    case actionTypes.ADD_SYSTEM_REMINDER_REQUEST:
      draft.addSystemReminderSuccess = false;
      draft.addSystemReminderError = false;
      draft.reminder = null;
      break;
    case actionTypes.ADD_SYSTEM_REMINDER_SUCCESS:
      draft.addSystemReminderSuccess = true;
      draft.addSystemReminderError = false;
      draft.reminder = action.data?.data;
      break;
    case actionTypes.ADD_SYSTEM_REMINDER_ERROR:
      draft.addSystemReminderSuccess = false;
      draft.addSystemReminderError = true;
      draft.reminder = null;
      break;
    case actionTypes.GET_SYSTEM_REMINDER_REQUEST:
      draft.getSystemRemindersSuccess = false;
      draft.addSystemReminderError = false;
      draft.reminder = null;
      break;
    case actionTypes.GET_SYSTEM_REMINDER_SUCCESS:
      draft.getSystemRemindersSuccess = true;
      draft.getSystemRemindersError = false;
      draft.systemReminders = action.data?.data;
      break;
    case actionTypes.GET_SYSTEM_REMINDER_ERROR:
      draft.getSystemRemindersSuccess = false;
      draft.getSystemRemindersError = true;
      draft.systemReminders = null;
      break;
    case actionTypes.EDIT_SYSTEM_REMINDER_REQUEST:
      draft.editSystemReminderSuccess = false;
      draft.editSystemReminderError = false;
      draft.editedReminder = null;
      break;
    case actionTypes.EDIT_SYSTEM_REMINDER_SUCCESS:
      draft.editSystemReminderSuccess = true;
      draft.editSystemReminderError = false;
      draft.editedReminder = action.data?.data;
      break;
    case actionTypes.EDIT_SYSTEM_REMINDER_ERROR:
      draft.editSystemReminderSuccess = false;
      draft.editSystemReminderError = true;
      draft.editedReminder = null;
      break;
    case actionTypes.DELETE_SYSTEM_REMINDER_REQUEST:
      draft.deleteSystemReminderSuccess = false;
      draft.deleteSystemReminderError = false;
      break;
    case actionTypes.DELETE_SYSTEM_REMINDER_SUCCESS:
      draft.deleteSystemReminderSuccess = true;
      draft.deleteSystemReminderError = false;
      break;
    case actionTypes.DELETE_SYSTEM_REMINDER_ERROR:
      draft.deleteSystemReminderSuccess = false;
      draft.deleteSystemReminderError = true;
      break;
    case actionTypes.GET_CUSTOMERS_DEBT_REQUEST:
      draft.getCustomersDebtSuccess = false;
      draft.getCustomersDebtError = false;
      break;
    case actionTypes.GET_CUSTOMERS_DEBT_SUCCESS:
      draft.getCustomersDebtSuccess = true;
      draft.getCustomersDebtError = false;
      draft.customersDebts = action.data?.data;
      break;
    case actionTypes.GET_CUSTOMERS_DEBT_ERROR:
      draft.getCustomersDebtSuccess = false;
      draft.getCustomersDebtError = true;
      draft.customersDebts = []
      break;

    case actionTypes.GET_CUSTOMER_DEBT_REQUEST:
      draft.getCustomerDebtSuccess = false;
      draft.getCustomerDebtError = false;
      break;
    case actionTypes.GET_CUSTOMER_DEBT_SUCCESS:
      draft.getCustomerDebtSuccess = true;
      draft.getCustomerDebtError = false;
      draft.customerDebts = action.data?.data;
      break;
    case actionTypes.GET_CUSTOMER_DEBT_ERROR:
      draft.getCustomerDebtSuccess = false;
      draft.getCustomerDebtError = true;
      draft.customerDebts = []
      break;
    case actionTypes.GET_DEBT_STATS_REQUEST:
      draft.getDebtStatsSuccess = false;
      draft.getDebtStatsError = false;
      break;
    case actionTypes.GET_DEBT_STATS_SUCCESS:
      draft.getDebtStatsSuccess = true;
      draft.getDebtStatsError = false;
      draft.debtStats = action.data?.data;
      break;
    case actionTypes.GET_DEBT_STATS_ERROR:
      draft.getDebtStatsSuccess = false;
      draft.getDebtStatsError = true;
      draft.debtStats = []
      break;
    case actionTypes.GET_DEBT_CUSTOMERS_REQUEST:
      draft.getDebtCustomersSuccess = false;
      draft.getDebtCustomersError = false;
      break;
    case actionTypes.GET_DEBT_CUSTOMERS_SUCCESS:
      draft.getDebtCustomersSuccess = true;
      draft.getDebtCustomersError = false;
      draft.debtCustomers = action.data?.data;
      break;
    case actionTypes.GET_DEBT_CUSTOMERS_ERROR:
      draft.getDebtCustomersSuccess = false;
      draft.getDebtCustomersError = true;
      draft.debtCustomers = []
      break;
    case actionTypes.UPDATE_CUSTOMER_DEBT_REQUEST:
      draft.updateCustomerDebtSuccess = false;
      draft.updateCustomerDebtError = false;
      break;
    case actionTypes.UPDATE_CUSTOMER_DEBT_SUCCESS:
      draft.updateCustomerDebtSuccess = true;
      draft.updateCustomerDebtError = false;
      draft.customerDebt = action.data?.data;
      break;
    case actionTypes.UPDATE_CUSTOMER_DEBT_ERROR:
      draft.updateCustomerDebtSuccess = false;
      draft.updateCustomerDebtsError = true;
      draft.customerDebt = {}
      break;

    case actionTypes.GET_COMMUNICATION_LOGS_REQUEST:
      draft.getCommunicationLogsSuccess = false;
      draft.getCommunicationLogsError = false;
      break;
    case actionTypes.GET_COMMUNICATION_LOGS_SUCCESS:
      draft.getCommunicationLogsSuccess = true;
      draft.getCommunicationLogsError = false;
      draft.communicationLogs = action.data?.data;
      break;
    case actionTypes.GET_COMMUNICATION_LOGS_ERROR:
      draft.getCommunicationLogsSuccess = false;
      draft.getCommunicationLogsError = true;
      draft.communicationLogs = []
      break;
    default:
      return draft;
  }
});

export default customerSignatureState;
