import React, { useState } from 'react';
import styled from 'styled-components';
import {CustomInput} from "@pages/new-design-components/custom-components";

export default function CVVInput ({ value, onChange, radius = '8px', border = '1px solid #979797', borderBottom = '1px solid #979797' }) {
    const [inputValue, setInputValue] = useState(value || '');

    const handleInputChange = (e) => {
        let input = e.target.value;
        let rawValue = input.replace(/\D/g, '');
        let formattedValue = rawValue.replace(/(\d{4})(?=\d)/g, '$1 ');
        setInputValue(formattedValue);
        onChange(rawValue);
    };

    return (
        <CustomInput
            radius={radius}
            onChange={handleInputChange}
            maxLength="3"
            border={border}
            borderBottom={borderBottom}
            value={inputValue}
            placeholder="###"
        />
    );
};

