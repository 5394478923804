import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    Checkmark,
    CustomContainer,
    CustomHeader,
    CustomReactStrapInput, CustomTD, CustomTextContainer, FlexInnerComponent,
    RequiredItemsBadge, StyledTable
} from "@pages/new-design-components/custom-components";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {Col, Row} from "reactstrap";
import DebtManagementTable from "@pages/components/DebtManagement/DebtManagementTable";
import {checkAndFormatShortGb, CURRENCY, formatDateShortGB} from "../../../constants";
import CustomSearch from "@pages/components/CustomSearch";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {GET_CUSTOMERS_DEBT_REQUEST, GET_DEBT_STATS_REQUEST} from "@redux/Customer/actions";
import usePrevious from "../../../hooks/useprevious";
import NotificationAlert from "react-notification-alert";
import {Notification} from "@pages/components/Notification";
import {LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";
import DatePicker from "react-datepicker";
import moment from "moment";
import {useHistory} from "react-router";
import Picker from "@pages/components/DatePicker";

export default function DebtDashboard() {
    const {
        customersDebts,
        getCustomersDebtError,
        getCustomersDebtSuccess,
        getDebtStatsSuccess,
        getDebtStatsError,
        debtStats,
    } = useSelector((state) => state.customer, shallowEqual);

    const notificationAlert = useRef(null)
    const history = useHistory();
    const [loading,setLoading] = useState(true);
    const [tableLoading,setTableLoading] = useState(false);
    const [activeTab,setActiveTab] = useState('today');
    const [overviewFilter,setOverviewFilter] = useState({from: '',to:''});
    const [filter,setFilter] = useState({
        page: 1
    });
    console.log('debtStats',debtStats)
    const [tableData,setTableData] = useState([]);

    const getCustomersDebtSuccessPrev = usePrevious(getCustomersDebtSuccess);
    const getCustomersDebtErrorPrev = usePrevious(getCustomersDebtError);

    const getDebtStatsSuccessPrev = usePrevious(getDebtStatsSuccess);
    const getDebtStatsErrorPrev = usePrevious(getDebtStatsError);

    const dispatch = useDispatch()
    const fetchData = (localFilter = {}) => {
        dispatch({
            type: GET_CUSTOMERS_DEBT_REQUEST,
            payload: {...localFilter}
        });
    }

    const getOverviewData = (localFilter = {}) => {
        dispatch({
            type: GET_DEBT_STATS_REQUEST,
            payload: {...localFilter}
        });
    }
    useEffect(()=>{
            fetchData(filter)
    },[])

    useEffect(()=>{
        if (getCustomersDebtSuccess && getCustomersDebtSuccessPrev === false) {
            setTableData(customersDebts?.data)
            setTableLoading(false)
            if (loading) {
                getOverviewData({range: activeTab})
            }
        }
    },[getCustomersDebtSuccess,getCustomersDebtSuccessPrev])
    useEffect(()=>{
        if (getCustomersDebtError && getCustomersDebtErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[getCustomersDebtError,getCustomersDebtErrorPrev])

    useEffect(()=>{
        if (getDebtStatsSuccess && getDebtStatsSuccessPrev === false) {
            setLoading(false)
         }
        },
 [getDebtStatsSuccessPrev,getDebtStatsSuccess])
    useEffect(()=>{
        if (getDebtStatsError && getDebtStatsErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong while getting stats!',notificationAlert);
        }
    },[getDebtStatsError,getDebtStatsErrorPrev])
    const filterData = ({key,value}) => {
        let tempFilter = {...filter}
        tempFilter[key] = value
        setFilter(tempFilter)
        setTableLoading(true)
        fetchData(tempFilter)
    }
    const tabs = [
        {
            key: 'today',
            label: 'Today',
        },
        {
            key: 'month',
            label: 'Month',
        },
        {
            key: 'year',
            label: 'Year',
        },

    ]
    const keys = [
        {
            key: 'customer_name_deep',
            label: 'Customer'
        } ,
        {
            key: 'amount_due',
            label: 'Amount'
        } ,
        {
            key: 'due_date',
            label: 'Due Date'
        },

        {
            key: 'overdue_duration',
            label: 'Overdue duration'
        },

        {
            key: 'options',
        },
    ]
    const filterOverviewStats = ({key,value}) => {
        let overviewTemp = {...overviewFilter}
        overviewTemp[key] = value
        setOverviewFilter(overviewTemp)
        getOverviewData(overviewTemp)
    }
    function calculatePercentageChange(current, previous) {
        if (previous === 0) {
            return current > 0 ? 100 : 0;
        }
        let val = ((current - previous) / previous) * 100
        return parseFloat(val).toFixed(2);
    }
    const showPercentage = ({stat,prevStat}) => {
        const value = calculatePercentageChange(stat,prevStat)
        if (value > 0) return   <div className={'d-flex g-5'}>
            <CustomHeader color={'#219852'} fontSize={'16px'} weight={'600'}>
                {value}%
            </CustomHeader>
            <IcoMoon
                iconSet={IconSet}
                icon={'trend_up'}
                size={24}
            />
        </div>

       return <div className={'d-flex g-5'}>
            <CustomHeader color={'#F3994A'} fontSize={'16px'} weight={'600'}>
                {value}%
            </CustomHeader>
            <IcoMoon
                iconSet={IconSet}
                icon={'trend_down'}
                size={24}
            />
        </div>

    }
    return (
        loading ?
            <LoaderContainer>
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={loading}
                />

            </LoaderContainer> :
        <div className={'container-fluid debt-background pt-3'}>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <CustomHeader fontSize={'24px'} weight={'700'} color={'#000000'}>
                Dashboard
            </CustomHeader>
            <CustomContainer backgroundColor={'#FFF'} borderRadius="16px" padding="16px">
                    <CustomHeader fontSize={'20px'} weight={'600'} color={'#000000'}>
                        Overview
                    </CustomHeader>
                <div className={'d-flex justify-content-between flex-wrap'}>
                <div className={'d-flex g-10 mb-3 flex-wrap'}>
                    {
                        tabs.map((tab,index) => {
                            return (
                                <Fragment key={'n_'+index}>
                                    <RequiredItemsBadge  backgroundColor={'#F2F2F2'} fontSize={'16px'} width={'100px'}  type={tab.key} onClick={()=> {
                                        setActiveTab(tab.key);
                                        getOverviewData({range: tab.key})
                                    }
                                    } active={activeTab === tab.key}>
                                        {tab.label}
                                    </RequiredItemsBadge>
                                </Fragment>
                            )
                        })
                    }
                </div>
                 <div className={'d-flex g-10 debt-datePicker'}>
                     <Picker
                         value={formatDateShortGB(overviewFilter?.from)}
                         placeholderText={'From'}
                         onChange={(date) => {
                             filterOverviewStats({key:'from',value: date})
                         }}
                     />

                     <Picker
                         value={formatDateShortGB(overviewFilter?.to)}
                         placeholderText={'To'}
                         onChange={(date) => {
                             filterOverviewStats({key:'to',value: date})
                         }}
                     />

                 </div>
                </div>
                <div className={'d-flex flex-wrap g-20 mt-2'}>
                        <CustomContainer width={'330px'} height={'132px'} backgroundColor={'#FEFCE8'} borderRadius="16px" padding="16px">
                            <div className={'d-flex g-10 mb-4 align-items-center'}>
                                <CustomCheckbox width={'40px'} height={'40px'}
                                                noPointer
                                                checkMarkHeight={'39px'}
                                                checkMarkWidth={'27px'}
                                                checkMarkLeft={'7px'}
                                                checkMarkTop={'0px'}
                                                backgroundColor={'#F3994A'}
                                                checked={true}/>
                                <CustomTextContainer fontSize={'16px'} weight={'600'} color={'#5B5B5B'}>
                                    Overdue Payments
                                </CustomTextContainer>
                            </div>
                            <div className={'d-flex justify-content-between'}>
                                {debtStats?.previous && showPercentage({stat: debtStats?.current?.total_overdue, prevStat: debtStats?.previous?.total_overdue})}

                                <CustomHeader color={'#000'} fontSize={'36px'} weight={'700'}>
                                    {debtStats?.current?.total_overdue || debtStats?.total_overdue}
                                </CustomHeader>
                            </div>
                        </CustomContainer>
                        <CustomContainer width={'330px'} height={'132px'} backgroundColor={'#EB585526'} borderRadius="16px" padding="16px">
                            <div className={'d-flex g-10 mb-4 align-items-center'}>
                                <CustomCheckbox
                                                noPointer
                                                width={'40px'} height={'40px'}
                                                checkMarkHeight={'39px'}
                                                checkMarkWidth={'27px'}
                                                checkMarkLeft={'7px'}
                                                checkMarkTop={'0px'}
                                                backgroundColor={'#EB5855'}
                                                checked={true}/>
                                <CustomTextContainer fontSize={'16px'} weight={'600'} color={'#5B5B5B'}>
                                    Total Dept
                                </CustomTextContainer>
                            </div>
                            <div className={'d-flex justify-content-between'}>
                                {debtStats?.previous && showPercentage({stat: debtStats?.current?.total_debt, prevStat: debtStats?.previous?.total_debt})}
                                {
                                    debtStats?.current ?  <CustomHeader color={'#000'} fontSize={'36px'} weight={'700'}>
                                        £{parseFloat(debtStats.current?.total_debt)?.toFixed(2)}
                                    </CustomHeader> :
                                    <CustomHeader color={'#000'} fontSize={'36px'} weight={'700'}>
                                    £{parseFloat(debtStats?.total_debt)?.toFixed(2)}
                                    </CustomHeader>
                                }

                            </div>

                        </CustomContainer>
                        <CustomContainer width={'330px'} height={'132px'} backgroundColor={'#F1FEF5'} borderRadius="16px" padding="16px">
                            <div className={'d-flex g-10 mb-4 align-items-center'}>
                                <CustomCheckbox width={'40px'} height={'40px'}
                                                noPointer={true}
                                                checkMarkHeight={'39px'}
                                                checkMarkWidth={'27px'}
                                                checkMarkLeft={'7px'}
                                                checkMarkTop={'0px'}
                                                checked={true}/>
                                <CustomTextContainer fontSize={'16px'} weight={'600'} color={'#5B5B5B'}>
                                    Total Debt Collected
                                </CustomTextContainer>
                            </div>
                            <div className={'d-flex justify-content-between'}>
                                {debtStats?.previous && showPercentage({stat: debtStats?.current?.total_collected, prevStat: debtStats?.previous?.total_collected})}
                                {debtStats.current ? <CustomHeader color={'#000'} fontSize={'36px'} weight={'700'}>
                                    £{parseFloat(debtStats.current?.total_collected)?.toFixed(2)}
                                </CustomHeader>
                                :
                                    <CustomHeader color={'#000'} fontSize={'36px'} weight={'700'}>
                                        £{parseFloat(debtStats?.total_collected)?.toFixed(2)}
                                    </CustomHeader>
                                }
                            </div>

                        </CustomContainer>
                </div>
            </CustomContainer>
            <Row className={'mt-3'}>
               <Col lg={8}>
                   <div className={'d-flex justify-content-between'}>
                       <CustomHeader color={'#000000'} fontSize={'20px'} weight={'600'}>
                           Debt List
                       </CustomHeader>
                       <CustomHeader onClick={()=>{
                           history.push('/admin/debt-customers');
                       }} className={'text-underline cursor-pointer'} color={'#3F8CFF'} fontSize={'14px'} weight={'500'}>
                           View All
                       </CustomHeader>
                   </div>
                   <Row className={'debt-datePicker white-background'}>
                       <Col lg={4} md={6} sm={8} className={'mb-3'}>
                           <CustomSearch
                               onChange={(e) => {
                                   filterData({key:'customer_name',value: e.target.value})
                               }}
                               placeholder="Customer Name"
                           />
                       </Col>
                       <Col lg={4} md={6} sm={8}>
                           <Picker
                               value={formatDateShortGB(filter.due_date)}
                               placeholderText={'Due Date'}
                               onChange={(date) => {
                                   filterData({key:'due_date',value:date})
                               }}
                           />
                       </Col>
                       <Col lg={4} md={6} sm={8}>
                           <CustomDropdownComponent
                               withoutDot
                               buttonHeight={'100%'}
                               border={'null'}
                               containerMinWidth={'150px'}
                               selectedPlaceHolder={'Overdue Duration'}
                               selectedTitle={filter?.overdue_duration}
                               statusArrayKey={'overdueDuration'}
                               noBadge={true}
                               handleChange={(value)=>{
                                   filterData({key:'overdue_duration',value:value})
                               }}
                           />
                       </Col>

                   </Row>

                   <div className={'table-container'}>
                       <DebtManagementTable loading={tableLoading} keys={keys} tableData={tableData} optionValues={[
                           {
                               label: 'View',
                               action: ()=>{}
                           },
                           {
                               label: 'Delete',
                               action: ()=>{}
                           }
                       ]}/>
                   </div>
               </Col>
                <Col lg={4}>
                    <CustomContainer width={'100%'} backgroundColor={'#fff'} borderRadius="16px" padding="16px">
                        <div className={'d-flex justify-content-between'}>
                            <CustomHeader color={'#000000'} fontSize={'20px'} weight={'600'}>
                                Priority List
                            </CustomHeader>
                            <CustomHeader className={'text-underline cursor-pointer'} color={'#3F8CFF'} fontSize={'14px'} weight={'500'}>
                                View All
                            </CustomHeader>
                        </div>
                        <StyledTable>
                            <tbody>
                            <tr>
                                <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#0000008C'}>
                                        Customer
                                    </CustomHeader>
                                </CustomTD>
                                <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#0000008C'}>
                                        Deal Amount
                                    </CustomHeader>
                                </CustomTD>

                            </tr>
                            <tr>
                                <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'14px'} weight={'400'} color={'#000000'}>
                                        Customer Name
                                    </CustomHeader>
                                </CustomTD>
                                <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'14px'} weight={'400'} color={'#219852'}>
                                        £1,200.00
                                    </CustomHeader>
                                </CustomTD>
                            </tr>
                            </tbody>
                        </StyledTable>
                    </CustomContainer>
                </Col>
            </Row>
        </div>
    );
}