import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store/index.js';

import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from 'App.js';

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
// plugins styles downloaded
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';

// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0';

// bryntum styles
// import 'bryntum-react-shared/resources/shared.scss';

import 'leaflet-control-geocoder/dist/Control.Geocoder.js';

import './App.scss';

const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
