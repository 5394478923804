import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import {
    CustomHeader,
    CustomTD, FleetButton, IconContainer,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function FleetBodyWorkDamageComponent({data = [],tyreData = [],deleteRow, showDamageModal, openDamageModal, openTyreModal}) {
 return (
    <>
        <Card>
            <CardHeader className={'border-bottom-0'}>
                <CustomHeader fontSize={'16px'}>
                    Bodywork Damage
                </CustomHeader>
            </CardHeader>
            <CardBody minWidth={'750px'} className={'overflow-auto'}>
                <StyledTable className={'table-borderless'}>
                    <thead className={'header-white-text'}>
                    <tr>
                        <StyledTableHeaderCell className={'text-center'} width={'25%'} color={'#0000008C'}>
                            Position
                        </StyledTableHeaderCell>

                        <StyledTableHeaderCell className={'text-center'} width={'25%'} color={'#0000008C'}>
                            Damage
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell className={'text-center'} width={'25%'} color={'#0000008C'}>

                        </StyledTableHeaderCell>

                        <StyledTableHeaderCell className={'text-center'} width={'25%'} color={'#0000008C'}>

                        </StyledTableHeaderCell>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.values(data).length ?
                        Object.keys(data).map((key, idx)=>{
                            return data[key].map((item,index)=>{
                                return <tr key={`4_${index}`}>
                                    <CustomTD width={'25%'}>
                                        {item.position}
                                    </CustomTD>
                                    <CustomTD className={'text-center'} width={'20%'}>
                                        {item.damage}
                                    </CustomTD>
                                    <CustomTD color={'#3F8CFF'} onClick={()=>{
                                        openDamageModal(key,index)
                                    }}
                                              className={'text-center cursor-pointer'}
                                              width={'20%'}>
                                        View
                                    </CustomTD>
                                    <CustomTD className={'text-center'} width={'20%'}>

                                    </CustomTD>
                                    <CustomTD width={'20%'}>
                                        <IconContainer>
                                            <IcoMoon
                                                className={'cursor-pointer'}
                                                iconSet={IconSet}
                                                icon={'delete_new_outline'}
                                                size={20}
                                                onClick={() => {
                                                    deleteRow(key,index)
                                                }}
                                            />
                                        </IconContainer>

                                    </CustomTD>

                                </tr>
                            })
                        })
                        : null
                    }
                    <tr>
                        <td colSpan="4" className={'text-center'}>
                            <FleetButton
                                height={'43px'}
                                width={'114px'}
                                backgroundColor={'#FDF0E4'}
                                color={'#F3994A'}
                                fontSize={'14px'}
                                radius={'32px'}
                                onClick={showDamageModal}
                            >
                                &#43; Add new
                            </FleetButton>
                        </td>
                    </tr>

                    </tbody>
                </StyledTable>
            </CardBody>
        </Card>
    </>
 )
}