import * as actionTypes from './actions';
import produce from 'immer';

const initialState = {
  templates: [],
  templateCreated: false,
  templateCreatedError: false,
  templateUpdated: false,
  getTemplatesSuccess: false,
  deleteTemplateSuccess: false,
  sendEmailSuccess: false,
  getSentEmailsSuccess: false,
  getRolesSuccess: false,
  getTemplateTypesSuccess: false,
  templateTypes: [],
  getTemplateTypesFailure: false,
  sentEmails: [],
  roles: [],
  resourceForEmail: {},
  getResourceEmailSuccess: false,
  getResourceEmailError: false,
  resourceEmails: [],
  shortcodes: {},
  getSystemTemplatesSuccess: false,
  getSystemTemplatesError: false,
  systemTemplates: []
};

const emailTemplateState = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_EMAIL_TEMPLATE_REQUEST:
      draft.templateCreated = false;
      draft.templateCreatedError = false;
      break;
    case actionTypes.CREATE_EMAIL_TEMPLATE_SUCCESS:
      draft.templateCreated = true;
      draft.templateCreatedError = false;
      break;
    case actionTypes.CREATE_EMAIL_TEMPLATE_FAILURE:
      draft.templateCreated = false;
      draft.templateCreatedError = true;
      break;
    case actionTypes.UPDATE_EMAIL_TEMPLATE_REQUEST:
      draft.templateUpdated = false;
      break;
    case actionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS:
      draft.templateUpdated = true;
      break;
    case actionTypes.UPDATE_EMAIL_TEMPLATE_FAILURE:
      draft.templateUpdated = false;
      break;
    case actionTypes.GET_EMAIL_TEMPLATES_REQUEST:
      draft.getTemplatesSuccess = false;
      break;
    case actionTypes.GET_EMAIL_TEMPLATES_SUCCESS:
      draft.getTemplatesSuccess = true;
      draft.templates = action.items.map((item) => {
        return {...item, assignee: item.roles?.map(item => {
          return {
            value: item.id,
            label: item.name,
          }
        })}
      });
      break;
    case actionTypes.GET_EMAIL_TEMPLATES_FAILURE:
      draft.getTemplatesSuccess = false;
      break;
    case actionTypes.DELETE_EMAIL_TEMPLATE_REQUEST:
      draft.deleteTemplateSuccess = false;
      break;
    case actionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS:
      draft.deleteTemplateSuccess = true;
      draft.templates = draft.templates.filter((it) => it.id !== action.id);
      break;
    case actionTypes.DELETE_EMAIL_TEMPLATE_FAILURE:
      draft.deleteTemplateSuccess = false;
      break;
    case actionTypes.SEND_EMAIL_REQUEST:
      draft.sendEmailSuccess = false;
      break;
    case actionTypes.SEND_EMAIL_SUCCESS:
      draft.sendEmailSuccess = true;
      break;
    case actionTypes.SEND_EMAIL_FAILURE:
      draft.sendEmailSuccess = false;
      break;
    case actionTypes.GET_SENT_EMAILS_REQUEST:
      draft.getSentEmailsSuccess = false;
      break;
    case actionTypes.GET_SENT_EMAILS_SUCCESS:
      draft.getSentEmailsSuccess = true;
      draft.sentEmails = action.items;
      break;
    case actionTypes.GET_SENT_EMAILS_FAILURE:
      draft.getSentEmailsSuccess = false;
      break;
    case actionTypes.GET_ROLES_SUCCESS:
      draft.getRolesSuccess = true;
      draft.roles = action.items;
      break;
    case actionTypes.GET_ROLES_FAILURE:
      draft.getRolesSuccess = false;
      break;
    case actionTypes.GET_EMAIL_DETAILS_BY_RESOURCE_REQUEST:
      draft.getResourceEmailSuccess = false
      draft.getResourceEmailError = false
      draft.resourceForEmail = {}
      break;
    case actionTypes.GET_EMAIL_DETAILS_BY_RESOURCE_SUCCESS:
      draft.getResourceEmailSuccess = true
      draft.getResourceEmailError = false
      draft.resourceForEmail = {
        ...action.details,
        validated: action.validated
      };
      break;
    case actionTypes.GET_EMAIL_DETAILS_BY_RESOURCE_FAILURE:
      draft.getResourceEmailSuccess = false
      draft.getResourceEmailError = true
      break;
    case actionTypes.GET_RESOURCE_EMAILS_SUCCESS:
        draft.resourceEmails = action.emails;
        break;
    case actionTypes.GET_SHORTCODES_SUCCESS:
      draft.shortcodes = action.items.items;
      break;
    case actionTypes.GET_SHORTCODES_FAILURE:
      draft.getRolesSuccess = false;
      break;
    case actionTypes.GET_TEMPLATE_TYPES_REQUEST:
      draft.getTemplateTypesSuccess = false
      draft.getTemplateTypesFailure = false
      break;
    case actionTypes.GET_TEMPLATE_TYPES_SUCCESS:
      draft.templateTypes = action.data.data;
      draft.getTemplateTypesSuccess = true
      draft.getTemplateTypesFailure = false
      break;
    case actionTypes.GET_TEMPLATE_TYPES_FAILURE:
      draft.getTemplateTypesSuccess = false
      draft.getTemplateTypesFailure = true
      break;
    case actionTypes.GET_SYSTEM_TEMPLATES_REQUEST:
      draft.getSystemTemplatesSuccess = false;
      draft.getSystemTemplatesError = false;
      draft.systemTemplates = [];
      break;
    case actionTypes.GET_SYSTEM_TEMPLATES_SUCCESS:
      draft.getSystemTemplatesSuccess = true;
      draft.getSystemTemplatesError = false;
      draft.systemTemplates = action.data;
      break;
    case actionTypes.GET_SYSTEM_TEMPLATES_FAILURE:
      draft.getSystemTemplatesError = true;
      draft.getSystemTemplatesSuccess = false;
      draft.systemTemplates = [];
      break;
    default:
      return draft;
  }
});

export default emailTemplateState;
