import React, {useState, useEffect, useRef} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import * as constants from "../../../../constants";
import EmailsTable from "@pages/components/Table/EmailsTable";
import {ButtonContainer,} from "@pages/reusable-components/styled-components";
import { Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import CalendarFilter from "@pages/components/CalendarFilter";
import CustomSearch from "@pages/components/CustomSearch";
import {InputArraySelect} from "@pages/components/InputSelect";
import NotificationAlert from "react-notification-alert";
import {Notification} from "@pages/components/Notification";

export default function  UserMailList() {
    const notificationAlert = useRef(null);
    const location =  useLocation()
    const history =  useHistory()
    const [token,setToken] = useState(null)
    const [emails,setEmails] = useState([])
    const [filter,setFilter] = useState({})
    const [userEmail,setUserEmail] = useState(null)
    const [loading, setLoading] = useState(true)
    const [emailsNextPageToken, setEmailsNextPageToken] = useState(null);
    const emailKeys = [
        {
            key: 'From',
            label: 'From'
        },
        {
            key: 'Delivered-To',
            label: 'Deliver to'
        },
        {
            key: 'Subject',
            label: 'Subject'
        },
        {
            key: 'Date',
            label: 'Received'
        }
    ];
    const options = [
        {
            label: 'Read',
            value: 'read',
        },
        {
            label: 'Unread',
            value: 'unread',
        },
        {
            label: 'Starred',
            value: 'starred',
        },
        {
            label: 'Snoozed',
            value: 'snoozed',
        },
    ]
    useEffect(()=>{
        if (location.state?.email) {
            setUserEmail(location.state?.email)
            const grs_token = Cookies.get('grs_token')
            setToken(grs_token)
            axios({
                method: 'GET',
                url: constants.API_URL + `/get-user-gmail`,
                params: {
                    email: location.state.email,
                    nextPageToken: emailsNextPageToken

                },
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data.data
                        setEmails(data.emails)
                        setEmailsNextPageToken(data.nextPageToken)
                        setLoading(false)
                        Notification('success', 'Success','',notificationAlert);
                    }
                })
                .catch(() => {
                    setLoading(false);
                    Notification('warning', 'Error', 'Something went wrong',notificationAlert);
                })
        }
    },[location])

    useEffect(() => {
        const enter = (ev: KeyboardEvent) => {
            if (!loading && (ev.key === "Enter" || ev.key === "Return")) {
                fetchCustomerEmails();
            }
        };
        window.addEventListener("keyup", enter, false);
        return () => {
            window.removeEventListener("keyup", enter, false);
        };
    }, [filter,loading]);

    const fetchCustomerEmails = async (nextToken = null) => {
       await setLoading(true)
       await axios({
            method: 'GET',
            url: constants.API_URL + `/get-user-gmail`,
            params: {
                email: userEmail,
                nextPageToken: nextToken,
                ...filter
            },
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.status === 200) {
                    const data = response.data.data
                    setEmails(data.emails)
                    setEmailsNextPageToken(data.nextPageToken)
                    setLoading(false)
                }
            })
            .catch(() => {
                setLoading(false);
            })
    }
    const getGmailOtherPages = async () => {
        await fetchCustomerEmails(emailsNextPageToken)
    }
    const goToEmailDetails = (id) => {
        history.push({
            pathname: `/admin/customer-gmail/${id}/details`,
            state: {
                email: userEmail
            }
        });
    }

    return (
            <Card>
                <div className="rna-wrapper">
                    <NotificationAlert ref={notificationAlert} />
                </div>
                <CardHeader>
                 <Row>
                     <Col lg={2} sm={10} className={'p-2'}>
                         <h1 className="mb-0">Emails</h1>
                     </Col>
                     <Col lg={2} sm={10} className={'p-2'}>
                         <CustomSearch
                             withoutDaley={true}
                             onChange={(e) => {
                                 setFilter({...filter,search: e.target.value})}
                             }
                         />
                     </Col>
                     <Col lg={2} sm={10} className={'p-2'}>
                         <InputArraySelect
                             fullWidth
                             defaultOptionText={'Select status'}
                             onChange={(e) => {
                                 setFilter({...filter,status: e.target.value})
                             }}
                             withBorder
                             options={options}
                             labelKey={'label'}
                             valueKey={'value'}
                             defaultOption={true}
                         />
                     </Col>
                    <Col lg={2} sm={10}  className={'p-2'}>
                          <CalendarFilter filterByDate={(data) => {
                            setFilter({...filter,...data})
                        }} />
                    </Col>
                     <Col lg={2} sm={10}  className={'p-2'}>
                         <ButtonContainer disabled={loading} onClick={()=> !loading && fetchCustomerEmails()}>
                             Search
                         </ButtonContainer>
                     </Col>
                 </Row>
                </CardHeader>
                <CardBody>
                    <EmailsTable loading={loading}
                                 searchValue={filter?.search}
                                 nextPageToken={emailsNextPageToken}
                                 paginate={getGmailOtherPages}
                                 goToDetails={goToEmailDetails}
                                 tableData={emails}
                                 keys={emailKeys}/>
                </CardBody>
            </Card>
    )
}