import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';
import * as constants from '../../constants';
import { toggleLoader } from 'actions/LoaderActions';
import {UPDATE_CUSTOMER_DEBT_REQUEST} from "./actions";

function* getCustomer(action) {
  try {
    const response = yield axios.get(`${constants.API_CORE_URL}/customer/${action.payload.id}`);
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.GET_CUSTOMER_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_CUSTOMER_ERROR });
  }
}
function* getSystemNotes(action) {
  try {
    const response = yield axios.get(`${constants.API_CORE_URL}/system-notes`,{
      params: {
          id: action.payload.id,
          type: action.payload.type
      }
    });
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.GET_SYSTEM_NOTES_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_SYSTEM_NOTES_ERROR });
  }
}
function* sendSystemNote(action) {
  try {
    const response = yield axios.post(`${constants.API_CORE_URL}/system-notes/write`,{
    ...action.payload,
    });
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.SEND_SYSTEM_NOTE_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.SEND_SYSTEM_NOTE_ERROR });
  }
}
function* addSystemReminder(action) {
  try {
    const response = yield axios.post(`${constants.API_CORE_URL}/system-reminder/add`,{
      ...action.payload,
    });
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.ADD_SYSTEM_REMINDER_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.ADD_SYSTEM_REMINDER_ERROR });
  }
}
function* editSystemReminder(action) {
  try {

    const response = yield axios.post(`${constants.API_CORE_URL}/system-reminder/edit/${action.payload.id}`,{
      ...action.payload,
    });
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.EDIT_SYSTEM_REMINDER_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.EDIT_SYSTEM_REMINDER_ERROR });
  }
}
function* deleteSystemReminder(action) {
  try {

    const response = yield axios.delete(`${constants.API_CORE_URL}/system-reminder/delete/${action.id}`);
    if (response?.status === 204) {
      const { data } = response;
      yield put({ type: actionTypes.DELETE_SYSTEM_REMINDER_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.DELETE_SYSTEM_REMINDER_ERROR });
  }
}

function* getSystemReminders() {
  try {
    const response = yield axios.get(`${constants.API_CORE_URL}/system-reminders`);
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.GET_SYSTEM_REMINDER_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_SYSTEM_REMINDER_ERROR });
  }
}

function* getCustomersDebt(action) {
  try {
    const response = yield axios.get(`${constants.API_CORE_URL}/customers-debt`,{
      params: {...action.payload},
    });
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.GET_CUSTOMERS_DEBT_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_CUSTOMERS_DEBT_ERROR });
  }
}
function* getCustomerDebt(action) {
  try {
    const response = yield axios.get(`${constants.API_CORE_URL}/customer-debt/${action.payload.id}`,{
      params: {...action.payload.filter},
    });
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.GET_CUSTOMER_DEBT_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_CUSTOMER_DEBT_ERROR });
  }
}

function* getDebtStats(action) {
  try {
    const response = yield axios.get(`${constants.API_CORE_URL}/debt-stats`,{
      params: {...action.payload},
    });
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.GET_DEBT_STATS_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DEBT_STATS_ERROR});
  }
}
function* getDebtCustomers(action) {
  try {
    const response = yield axios.get(`${constants.API_CORE_URL}/debt-customers`,{
      params: {...action.payload},
    });
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.GET_DEBT_CUSTOMERS_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DEBT_CUSTOMERS_ERROR });
  }
}
function* getCommunicationLogs(action) {
  try {
    const response = yield axios.get(`${constants.API_CORE_URL}/communication-logs`,{
      params: {...action.payload},
    });
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.GET_COMMUNICATION_LOGS_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_COMMUNICATION_LOGS_ERROR });
  }
}
function* updateCustomerDebt(action) {
  try {

    const response = yield axios.post(`${constants.API_CORE_URL}/update-customer-debt/${action.payload.id}`,{
      ...action.payload
    });
    if (response?.status === 200) {
      const { data } = response;
      yield put({ type: actionTypes.UPDATE_CUSTOMER_DEBT_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_CUSTOMER_DEBT_ERROR });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actionTypes.GET_CUSTOMER_REQUEST, getCustomer);
  yield takeLatest(actionTypes.SEND_SYSTEM_NOTE_REQUEST, sendSystemNote);
  yield takeLatest(actionTypes.GET_SYSTEM_NOTES_REQUEST, getSystemNotes);
  yield takeLatest(actionTypes.ADD_SYSTEM_REMINDER_REQUEST, addSystemReminder);
  yield takeLatest(actionTypes.GET_SYSTEM_REMINDER_REQUEST, getSystemReminders);
  yield takeLatest(actionTypes.EDIT_SYSTEM_REMINDER_REQUEST, editSystemReminder);
  yield takeLatest(actionTypes.DELETE_SYSTEM_REMINDER_REQUEST, deleteSystemReminder);
  yield takeLatest(actionTypes.GET_CUSTOMERS_DEBT_REQUEST, getCustomersDebt);
  yield takeLatest(actionTypes.GET_CUSTOMER_DEBT_REQUEST, getCustomerDebt);
  yield takeLatest(actionTypes.GET_DEBT_STATS_REQUEST, getDebtStats);
  yield takeLatest(actionTypes.GET_DEBT_CUSTOMERS_REQUEST, getDebtCustomers);
  yield takeLatest(actionTypes.GET_COMMUNICATION_LOGS_REQUEST, getCommunicationLogs);
  yield takeLatest(actionTypes.UPDATE_CUSTOMER_DEBT_REQUEST, updateCustomerDebt);

}

