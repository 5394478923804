import React, {useState} from 'react'
import {Card, CardBody} from "reactstrap";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import axios from "axios";
import * as constants from "../../../../constants";
import {
    CurrencySymbol,
    CustomHeader,
    CustomInput,
    CustomInputWrapper, CustomTextContainer, FleetButton, IconContainer, PartsContainer
} from "@pages/new-design-components/custom-components";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import {CURRENCY} from "../../../../constants";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
export default function AddNewPartComponent({departments,changeData,setVehicle,vehicle, deleteRow, parts, getQuoteParts, addNewRow}) {
    const [regNos, setRegNos] = useState([])
    const promiseOptions = inputValue => {
        if (inputValue.length < 2) {
            return
        } else {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(fetchData(inputValue));
                }, 1000);
            });
        }
    }
    const fetchData = async (reg_no) => {
        try {
            const source = axios.CancelToken.source()
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet`,
                params: {
                    search: reg_no,
                    withQuote: true
                },
                cancelToken: source.token,
            })
            const data = response.data
            let fleets = data.data.fleet;

            if (fleets.length) {
                let _regNos_array = [];
                fleets.map(fleet => {
                    return _regNos_array.push({
                        id: fleet.vehicle.id,
                        fleet_id: fleet.id,
                        check_id: fleet.quotes[0].id,
                        label: fleet.vehicle.stock_no,
                    })
                });
                setRegNos(_regNos_array);
                return _regNos_array;
            }
         return [];

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }
    return (
        <Card>
           <CardBody>
               <CustomHeader fontSize={'14px'} weight={'700'}>
                  Select Vehicle
               </CustomHeader>
               <AsyncSelect
                   loadOptions={promiseOptions}
                   classNamePrefix="select"
                   value={vehicle && vehicle.id ? (regNos.find((element) => element.id === vehicle.id) || '') : ''}
                   onChange={value => {
                       setVehicle(value);
                       getQuoteParts(value.check_id)
                   }}
                   placeholder="Search..."
                   components={{
                       DropdownIndicator: () => null,
                       IndicatorSeparator: () => null,
                   }}
               />
               {
                   vehicle ?
                      <div className={'d-flex flex-column g-20'}>
                          {
                              parts.length ?
                                  parts?.map((data,index) =>{
                                      return  <PartsContainer key={index}>
                                          <div className={'d-flex justify-content-between align-items-center mt-3'}>
                                              <div className={'w-50 d-flex g-10 align-items-center'}>
                                                  <CustomCheckbox checked={data?.status === 'completed'} onClick={()=>{
                                                      changeData({
                                                          index,
                                                          key: 'status',
                                                          value: data?.status === 'completed' ? 'to_do' : 'completed',
                                                      });
                                                  }
                                                  }/>
                                                  <CustomTextContainer fontSize={'14px'} weight={'600'}
                                                                       color={'#0000008C'}>Ordered</CustomTextContainer>
                                              </div>
                                              <IconContainer>
                                                  <IcoMoon
                                                      className={'cursor-pointer'}
                                                      iconSet={IconSet}
                                                      icon={'delete_new_outline'}
                                                      size={20}
                                                      onClick={() => {
                                                          deleteRow({index,id: data?.id})
                                                      }}
                                                  />
                                              </IconContainer>
                                          </div>
                                          <CustomTextContainer marginTop={'6px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                              Part Name
                                          </CustomTextContainer>
                                          <CustomInput radius={'8px'}
                                                       onChange={(e)=>{
                                                           changeData({
                                                               index,
                                                               key: 'part',
                                                               value: e.target.value
                                                           })
                                                       }
                                                       }
                                                       border={'1px solid #C0D9FF59'}
                                                       value={data?.part} />
                                          <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                              Department
                                          </CustomTextContainer>
                                          <CustomDropdownComponent
                                              withoutDot
                                              maxWidth={'100%'}
                                              border={'1px solid #C0D9FF59'}
                                              selectedTitle={data.department?.name}
                                              statusArrayKey={'departments'}
                                              noBadge={true}
                                              handleChange={(value)=>{
                                                  const department = departments.find(item=>item.name === value)
                                                  changeData({
                                                      index,
                                                      key: 'department',
                                                      value: department
                                                  })
                                              }}
                                          />
                                          <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                              Part No.
                                          </CustomTextContainer>
                                          <CustomInput radius={'8px'}
                                                       onChange={(e)=>{
                                                           changeData({
                                                               index,
                                                               key: 'part_number',
                                                               value: e.target.value
                                                           })
                                                       }
                                                       }
                                                       border={'1px solid #C0D9FF59'}
                                                       value={data?.part_number} />
                                          <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                              Cost
                                          </CustomTextContainer>
                                          <CustomInputWrapper>
                                              <CurrencySymbol>{CURRENCY}</CurrencySymbol>
                                              <CustomInput
                                                  radius={'8px'}
                                                  padding={'10px 10px 10px 30px'}
                                                  onChange={(e) => {
                                                      changeData({
                                                          index,
                                                          key: 'cost',
                                                          value: e.target.value,
                                                      });
                                                  }}
                                                  border={'1px solid #C0D9FF59'}
                                                  value={data?.cost}
                                                  placeholder="Enter cost"
                                              />
                                          </CustomInputWrapper>
                                      </PartsContainer>
                                  })
                                  : null
                          }
                          <FleetButton
                              height={'43px'}
                              width={'100%'}
                              backgroundColor={'#000'}
                              color={'#FFF'}
                              fontSize={'14px'}
                              radius={'32px'}
                              onClick={()=>{
                                  addNewRow(vehicle.check_id)
                              }}
                          >
                              &#43; Add new
                          </FleetButton>
                      </div>
                       : null
               }
           </CardBody>

        </Card>

    )
}