import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useLocation, useHistory} from "react-router-dom";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    CustomHeader,
    CustomInput,
    FleetButton, CustomContainer,
    RequiredItemsBadge, ProfileImage
} from "@pages/new-design-components/custom-components";
import {ClipLoader} from "react-spinners";
import {LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    GET_CUSTOMER_DEBT_REQUEST,
    GET_CUSTOMER_REQUEST,
    GET_SYSTEM_NOTES_REQUEST,
    SEND_SYSTEM_NOTE_REQUEST
} from "@redux/Customer/actions";
import customer from "@redux/Customer/reducer";
import usePrevious from "../../../hooks/useprevious";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {Notification} from "@pages/components/Notification";
import NotificationAlert from "react-notification-alert";
import {checkAndFormatShortGb, parseToWord} from "../../../constants";
import NotesComponent from "@pages/components/DebtManagement/CustomerOverview/NotesComponent";


export default function DebtRecoveryOverview() {
    const location = useLocation()
    const notificationAlert = useRef(null)
    const [debts, setDebts] = useState([]);
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);

    const customerId = queryParams.get('customer_id');
    const [loading,setLoading] = useState(true);
    const history = useHistory();
    const [customerData,setCustomerData] = useState({});
    const [error,setError] = useState({
        message: {
            show: false,
            message: ''
        }
    });

    const [newMessage,setNewMessage] = useState(null);
    const [activeNoteTab, setActiveNoteTab] = useState('all');

    const [notes, setNotes] = useState([]);

    const { getCustomerSuccess, getCustomerError,
        customer, sendSystemNoteSuccess,
        getCustomerDebtError,
        getCustomerDebtSuccess,
        customerDebts,
        sendSystemNoteError, getSystemNotesError,getSystemNotesSuccess,systemNotes} = useSelector((state) => state.customer, shallowEqual);
    const profileData = useSelector((state) => state.getState).profileData;

    const getCustomerSuccessPrev = usePrevious(getCustomerSuccess);
    const getCustomerErrorPrev = usePrevious(getCustomerError);

    const sendSystemNoteSuccessPrev = usePrevious(sendSystemNoteSuccess);
    const sendSystemNoteErrorPrev = usePrevious(sendSystemNoteError);

    const getSystemNotesSuccessPrev = usePrevious(getSystemNotesSuccess);
    const getSystemNotesErrorPrev = usePrevious(getSystemNotesError);

    const getCustomerDebtSuccessPrev = usePrevious(getCustomerDebtSuccess);
    const getCustomerDebtErrorPrev = usePrevious(getCustomerDebtError);

    useEffect(()=>{
        if (getCustomerSuccess && getCustomerSuccessPrev === false) {
            setCustomerData(customer)
            dispatch({
                type: GET_SYSTEM_NOTES_REQUEST,
                payload: { id: customer.id, type: 'Customer' }
            });
        }
    },[getCustomerSuccess,getCustomerSuccessPrev])

    useEffect(()=>{
        if (getSystemNotesSuccess && getSystemNotesSuccessPrev === false) {
            setNotes(systemNotes)
            dispatch({
                type: GET_CUSTOMER_DEBT_REQUEST,
                payload: { id: customer.id }
            });
        }
    },[getSystemNotesSuccess,getSystemNotesSuccessPrev])
    useEffect(()=>{
        if (getCustomerDebtError && getCustomerDebtErrorPrev === false) {
            setLoading(false)
        }
    },[getCustomerDebtError,getCustomerDebtErrorPrev])
    useEffect(()=>{
        if (getCustomerDebtSuccess && getCustomerDebtSuccessPrev === false) {
            setDebts([...customerDebts.customer_debt])
            setLoading(false)
        }
    },[getCustomerDebtSuccessPrev,getCustomerDebtSuccess])

    useEffect(()=>{
        if (getSystemNotesError && getSystemNotesErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[getSystemNotesError,getSystemNotesErrorPrev])

    useEffect(()=>{
        if (sendSystemNoteSuccess && sendSystemNoteSuccessPrev === false) {
            Notification('success', 'Success!', 'Sent successfully',notificationAlert);
        }
    },[sendSystemNoteSuccess,sendSystemNoteSuccessPrev])

    useEffect(()=>{
        if (sendSystemNoteError && sendSystemNoteErrorPrev === false) {
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[sendSystemNoteError,sendSystemNoteErrorPrev])

    useEffect(()=>{
        if (getCustomerError && getCustomerErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[getCustomerError,getCustomerErrorPrev])

    useEffect(()=>{
        if (customerId) {
            dispatch({
                type: GET_CUSTOMER_REQUEST,
                payload: { id: customerId }
            });
        }
    },[customerId])

    const sendMessage = () => {
        let obj = {
            'creator_id': profileData.id,
            'creator_type': 'User',
            'noteable_id': customerData.id,
            'noteable_type': 'Customer',
            'note': newMessage.message
        }
        dispatch({
            type: SEND_SYSTEM_NOTE_REQUEST,
            payload: obj
        });
    }
    console.log('customerData',customerData)
    return (
        loading ? <LoaderContainer>
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={loading}
                />
            </LoaderContainer>
            :
            <div className={'container-fluid debt-background pt-3'}>
                <div className="rna-wrapper">
                    <NotificationAlert ref={notificationAlert} />
                </div>
                <Row>
                    <Col lg={4} md={4}>
                        <Card className={'fleet-details-container'}>
                            <CardHeader className={'border-bottom-0'}>
                                <div className={'d-flex justify-content-between'}>

                                    <CustomHeader fontSize={'16px'}>
                                        Details
                                    </CustomHeader>

                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className={'d-flex g-5'}>
                                    <IcoMoon
                                        className={'cursor-pointer'}
                                        iconSet={IconSet}
                                        icon={'re_user'}
                                        size={25}
                                    />
                                    <CustomHeader fontSize={'14px'}  weight={'700'}>
                                        Customer Details:
                                    </CustomHeader>
                                </div>
                                <div className={'ml-3'}>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                                            Title:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                                            {customerData.title?.toUpperCase()}
                                        </CustomHeader>
                                    </div>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader fontSize={'14px'} weight={'700'} color={'#74787E'} className={'mb-0'}>
                                            Name:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                                            {customerData.first_name}
                                        </CustomHeader>
                                    </div>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                                            Email:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                                            {customerData?.email}
                                        </CustomHeader>
                                    </div>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                                            Mobile:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                                            {customerData?.mobile_number}
                                        </CustomHeader>
                                    </div>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                                            Landline:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                                            {customerData?.land_line_number ?? '-'}
                                        </CustomHeader>
                                    </div>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                                            Address:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                                            {customerData?.address ?? '-'}
                                        </CustomHeader>
                                    </div>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                                            Licence Number:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                                            {customerData?.driver_license_number ?? '-'}
                                        </CustomHeader>
                                    </div>
                                    <div className={'d-flex justify-content-center'}>
                                        <FleetButton
                                            height={'43px'}
                                            backgroundColor={'#E1E9F7'}
                                            color={'#3F8CFF'}
                                            fontSize={'14px'}
                                            radius={'32px'}
                                            onClick={()=>{
                                                history.push({
                                                    pathname: '/admin/customer/' + customerData.id + '/edit',
                                                })
                                            }}
                                        >
                                            View Customer Page
                                        </FleetButton>
                                    </div>
                                </div>
                                <div className={'d-flex g-5'}>
                                    <IcoMoon
                                        className={'cursor-pointer department-icon'}
                                        iconSet={IconSet}
                                        icon={'re_debt'}
                                        size={25}
                                    />
                                    <CustomHeader fontSize={'14px'}  weight={'700'}>
                                        Debt Information:
                                    </CustomHeader>
                                </div>
                                <div className={'ml-3'}>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                                            Vehicle:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                                            {debts[0].vehicle?.stock_no.toUpperCase() || '-'}
                                        </CustomHeader>
                                    </div>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader fontSize={'14px'} weight={'700'} color={'#74787E'} className={'mb-0'}>
                                            Payment Due:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                                            {checkAndFormatShortGb(debts[0].due_date)}
                                        </CustomHeader>
                                    </div>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                                            Debt Status:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#000000'}>
                                            {debts[0]?.debt_status}
                                        </CustomHeader>
                                    </div>
                                    <div className={'d-flex flex-column'}>
                                        <CustomHeader weight={'700'} color={'#74787E'} fontSize={'14px'} className={'mb-0'}>
                                            Amount:
                                        </CustomHeader>
                                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#219852'}>
                                            £{debts[0]?.amount_due}
                                        </CustomHeader>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={8}>
                        <div className={'d-flex g-10 mb-3 flex-wrap'}>

                            <Col lg={12}>
                                    <NotesComponent
                                        notes={notes}
                                        sendMessage={sendMessage}
                                        newMessage={newMessage}
                                        setNewMessage={setNewMessage}
                                        error={error}
                                        setError={setError}
                                        picture={profileData?.picture}
                                        noteTabs={[]}
                                        activeNoteTab={activeNoteTab}
                                        setActiveNoteTab={setActiveNoteTab}
                                    />
                            </Col>
                        </div>
                    </Col>
                </Row>
            </div>
    )
}