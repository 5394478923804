import React, {useEffect, useState} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {
    CustomArea,
    CustomHeader,
    CustomInput,
    CustomTextContainer,
    FleetButton
} from "@pages/new-design-components/custom-components";
import axios from "axios";
import * as constants from "../../../../../constants";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";
import CustomRadio from "@pages/components/JobDetailsComponents/CustomRadio";
import CreateTemplateModal from "@pages/components/DebtManagement/CustomerOverview/TemplateListModal";


export default function CreateReminderModal({isOpen, data = null, updateData,closeModal,textTemplates,emailTemplates}) {

    const [localData, setLocalData] = useState({source_type: 'sms'})
    const [templatesModal,setShowTemplatesModal] = useState(false);

    useEffect(()=>{
        if (!!data) {
            setLocalData(data)
        }
    },[data])
    const saveData = () => {
        updateData(localData)
    }
    const updateValue = ({key,value}) => {
        let tempData= {...localData}
        tempData[key] = value
        setLocalData(tempData)
    }
    const selectTemplate = (index) => {
        const templates = localData?.source_type === 'sms' ? textTemplates : emailTemplates
        const selected = templates[index];
        updateValue({ key:'message',value: selected.message})
        setShowTemplatesModal(false)
    }
    return (
        <Modal
            isOpen={isOpen}
            toggle={closeModal}
            modalClassName={'slide-in-from-right'}
            backdrop={false}
            className={'modal-dialog-right modal-responsible'}
        >
            <CreateTemplateModal isOpen={templatesModal} closeModal={()=>{
                setShowTemplatesModal(false)
            }} localData={localData?.source_type === 'sms' ? textTemplates : emailTemplates} selectReminder={selectTemplate}/>
            <ModalHeader toggle={closeModal} className={'bg-white centered-title'}>
                <CustomHeader weight={'600'} fontSize={'14px'}  color={'#74787E'}>
                    Add reminder
                </CustomHeader>
            </ModalHeader>
            <ModalBody>
                <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                   Type
                </CustomTextContainer>
                <div className={'d-flex justify-content-between'}>
                   <div className={'d-flex g-5'}>
                       <CustomRadio checked={localData?.type === 'one_time'} onClick={(e)=>{
                           updateValue({ key:'type',value: 'one_time'})
                       }}/>
                       <CustomHeader weight={'400'} color={'#74787E'} fontSize={'14px'}>
                           One-time
                       </CustomHeader>
                   </div>
                   <div className={'d-flex g-5'}>
                       <CustomRadio checked={localData?.type === 'reoccurring'} onClick={(e)=>{
                           updateValue({ key:'type',value: 'reoccurring'})
                       }}/>
                       <CustomHeader weight={'400'} color={'#74787E'} fontSize={'14px'}>
                           Reoccurring
                       </CustomHeader>
                   </div>
                </div>
                <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                    Payment Type:
                </CustomTextContainer>
                <CustomDropdownComponent
                    withoutDot
                    maxWidth={'100%'}
                    border={'1px solid #979797'}
                    statusArrayKey={'paymentTypeStatuses'}
                    selectedTitle={localData?.payment_type}
                    noBadge={true}
                    handleChange={(value)=>{
                        updateValue({ key:'payment_type', value})
                    }}
                />
                <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                    Overdue Time:
                </CustomTextContainer>
                <CustomDropdownComponent
                    withoutDot
                    maxWidth={'100%'}
                    border={'1px solid #979797'}
                    statusArrayKey={'overdueDays'}
                    selectedTitle={localData?.overdue_time}
                    noBadge={true}
                    handleChange={(value)=>{
                        updateValue({ key:'overdue_time', value})
                    }}
                />
                <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                    Type
                </CustomTextContainer>
                <div className={'d-flex justify-content-between'}>
                    <div className={'d-flex g-5'}>
                        <CustomRadio checked={localData?.source_type === 'sms'} onClick={(e)=>{
                            updateValue({ key:'source_type',value: 'sms'})
                        }}/>
                        <CustomHeader weight={'400'} color={'#74787E'} fontSize={'14px'}>
                            Text
                        </CustomHeader>
                    </div>
                    <div className={'d-flex g-5'}>
                        <CustomRadio checked={localData?.source_type === 'email'} onClick={(e)=>{
                            updateValue({ key:'source_type',value: 'email'})
                        }}/>
                        <CustomHeader weight={'400'} color={'#74787E'} fontSize={'14px'}>
                            Email
                        </CustomHeader>
                    </div>
                </div>
                <CustomHeader
                    className={'cursor-pointer text-right'}
                    lineHeight={'none'}
                    fontSize={'14px'}
                    onClick={()=>{
                        setShowTemplatesModal(true)
                    }}
                    weight={'700'}
                    color={'#3F8CFF'} >
                    Templates
                </CustomHeader>
                {
                    localData?.source_type === 'email' &&
                     <>
                        <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                            Subject
                        </CustomTextContainer>
                        <CustomInput radius={'8px'}
                                    onChange={(e)=>{
                                        updateValue({ key:'subject', value: e.target.value})
                                    }
                                    }
                                    border={'1px solid #979797'}
                                    borderBottom={'1px solid #979797'}
                                    value={localData?.subject} />
                    </>
                }
                <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                    Message
                </CustomTextContainer>
                <CustomArea
                    onChange={(e) => {
                        updateValue({ key:'message',value: e.target.value})
                    }}
                    radius={'8px'}
                    border={'1px solid #979797'}
                    value={localData?.message}
                />
            </ModalBody>
            <ModalFooter>
                <div className={'w-100'}>
                    <FleetButton
                        className={'mb-3'}
                        height={'43px'}
                        width={'90%'}
                        backgroundColor={'#F3994A'}
                        hoverColor={'#F3994A'}
                        color={'#fff'}
                        fontSize={'14px'}
                        radius={'32px'}
                        onClick={saveData}
                    >
                        Save
                    </FleetButton>

                    <FleetButton
                        height={'43px'}
                        width={'90%'}
                        backgroundColor={'#000'}
                        color={'#FFF'}
                        fontSize={'14px'}
                        radius={'32px'}
                        onClick={closeModal}
                    >
                        Close
                    </FleetButton>

                </div>
            </ModalFooter>
        </Modal>
    )
}