import React, {Fragment, useEffect, useRef, useState} from 'react'
import {
    CustomHeader,
    CustomTD,
    CustomTableRow,
    StyledTable,
    StyledTableHeaderCell, StatusSpan, CustomReactStrapInput, Dot
} from "@pages/new-design-components/custom-components";
import CustomSearch from "@pages/components/CustomSearch";
import {Col} from "reactstrap";
import {
    API_URL,
    badgeColors,
    calculateDateDifference,
    checkAndFormatShortGb,
    formatDateShortGB, formatNumber,
    parseToWord
} from 'constants/index.js';
import axios from "axios";
import {useHistory} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {GET_DEBT_CUSTOMERS_REQUEST} from "@redux/Customer/actions";
import usePrevious from "../../../hooks/useprevious";
import NotificationAlert from "react-notification-alert";
import {Notification} from "@pages/components/Notification";
import Picker from "@pages/components/DatePicker";
import {LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";


export default function Customers() {

    const {
        getDebtCustomersSuccess,
        getDebtCustomersError,
        debtCustomers,
    } = useSelector((state) => state.customer, shallowEqual);

    const getDebtCustomersSuccessPrev = usePrevious(getDebtCustomersSuccess);
    const getDebtCustomersErrorPrev = usePrevious(getDebtCustomersError);
    const history = useHistory()
    const [loading,setLoading] = useState(true);
    const [tableData,setTableData] = useState([]);
    const notificationAlert = useRef(null)
    const dispatch = useDispatch()
    const [filter,setFilter] = useState({
    });
    const fetchData = (localFilter = {}) => {
        dispatch({
            type: GET_DEBT_CUSTOMERS_REQUEST,
            payload: {...localFilter}
        });
    }

    useEffect(()=>{
        if (getDebtCustomersSuccess && getDebtCustomersSuccessPrev === false) {
            setTableData(debtCustomers)
            setLoading(false)
        }
    },[getDebtCustomersSuccess,getDebtCustomersSuccessPrev])

    useEffect(()=>{
        if (getDebtCustomersError && getDebtCustomersErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong while getting stats!',notificationAlert);
        }
    },[getDebtCustomersError,getDebtCustomersErrorPrev])

    const keys = [
        {
            key: 'customer_name',
            label: 'Customer'
        } ,
        {
            key: 'mobile_number',
            label: 'Phone Number'
        } ,
        {
            object:'customer_debt',
            key: 'due_date',
            label: 'Due Date'
        },
        {
            object:'customer_debt',
            key: 'overdue_duration',
            label: 'Overdue Duration'
        },
        {
            object:'customer_debt',
            key: 'expected_payment_date',
            label: 'Expected Payment Date'
        },
        {
            object:'customer_debt',
            key: 'debt_status',
            label: 'Status'
        },
        {
            key: 'debt',
            label: 'Total Debt'
        },
    ]

    useEffect(()=>{
        fetchData();
    },[])
    const filterData = ({key,value}) => {
        setLoading(true)
        let tempFilter = {...filter}
        tempFilter[key] = value
        setFilter(tempFilter)
        fetchData(tempFilter)
    }
    const goToDetails = (index) => {
        const data = tableData[index];
        const queryParams = new URLSearchParams({
            'customer_id': data.id,
        }).toString();
        history.push({
            pathname: `/admin/customer-overview`,
            search: queryParams
        })
    }
    const generateItem = ({item,index,key}) => {
        if (key.key === 'customer_name') {
            return <CustomTD key={index}>
                {item.first_name} {item.last_name}
            </CustomTD>
        } else if(key.hasOwnProperty('object')) {
           if (key.key === 'overdue_duration') {
                  const debt = item[key.object][0]
                  const todayDate = new Date();
                  const overdueDuration =  calculateDateDifference({date1: debt.due_date,date2: todayDate})
                  return  <CustomTD color={'#EB5855'}>
                      {overdueDuration} days
                  </CustomTD>
            }
            else if (key.key === 'due_date' || key.key === 'expected_payment_date') {
               let value = parseToWord(item[key.object][0][key.key]?.toString())
               return <CustomTD key={index}>{checkAndFormatShortGb(value)}</CustomTD>
           } else  if (key.key === 'debt_status') {
                const status = item[key.object][0][key.key]
               return (
                   <CustomTD key={index}>
                       <Dot width={'12px'} color={badgeColors(status)}/>
                       <StatusSpan type={status}>
                           {status ? parseToWord(status) : 'N/A'}
                       </StatusSpan>
                   </CustomTD>
               );
           }
            return <CustomTD key={index}>
                {item[key.object][0][key.key] || '-'}
            </CustomTD>
        }

        else if(key.key === 'debt') {
            return  <CustomTD color={'#EB5855'} key={index}>
                £{formatNumber(parseFloat((item.customer_debt_sum_total - item.customer_debt_sum_amount_due)).toFixed(2)) }
            </CustomTD>
        }
        return <CustomTD key={index}>
           {item[key.key] ? item[key.key] : '-'}
        </CustomTD>
    }
    return (

       <div className={'container-fluid debt-background pt-3'}>
           <div className="rna-wrapper">
               <NotificationAlert ref={notificationAlert} />
           </div>
           <CustomHeader lineHeight={'unset'} >Customer List</CustomHeader>
           <div className={'d-flex g-10 flex-wrap debt-datePicker white-background'}>
               <Col lg={3} sm={12} md={5}>
                   <CustomSearch
                       onChange={(e) => {
                           filterData({key:'customer_name',value: e.target.value})
                       }}
                       placeholder="Search"
                   />
               </Col>
               <Col lg={3} sm={12} md={5}>
                   <Picker
                       value={formatDateShortGB(filter.from)}
                       placeholderText={'From'}
                       onChange={(date) => {
                           filterData({key:'from',value:date})
                       }}
                   />
               </Col>
               <Col lg={3} sm={12} md={5}>
                   <Picker
                       value={formatDateShortGB(filter.to)}
                       placeholderText={'To'}
                       onChange={(date) => {
                           filterData({key:'to',value:date})
                       }}
                   />
               </Col>

           </div>
           {
               loading ?  <LoaderContainer>
                       <ClipLoader
                           css={override}
                           size={40}
                           color={`#7B61E4`}
                           loading={loading}
                       />
                   </LoaderContainer> :
                   <div className={'table-container'}>
                       <StyledTable className={'table-borderless'}>
                           <thead className={'header-white-text'}>
                           <tr>
                               {keys.map((item,index)=>{
                                   return <Fragment key={index}>
                                       <StyledTableHeaderCell fontSize='14px' color={'#0000008C'}>
                                           {item.label}
                                       </StyledTableHeaderCell>
                                   </Fragment>
                               })}

                           </tr>
                           </thead>
                           <tbody>
                           {tableData.length ?
                               tableData.map((item,index)=>{
                                   return <CustomTableRow className={'cursor-pointer'} onClick={()=>{
                                       goToDetails(index)
                                   }} key={index}>
                                       {
                                           keys.map((key, keyIndex) => {
                                               return generateItem({item, index: `${index}_${keyIndex}`,key});
                                           })

                                       }
                                   </CustomTableRow>

                               }) : null
                           }
                           </tbody>
                       </StyledTable>
                   </div>
           }
       </div>
    )
}