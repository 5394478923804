import React, {Fragment, useEffect, useRef, useState} from 'react'
import {ButtonContainer, LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";
import {CustomContainer, CustomHeader, CustomTextContainer} from "@pages/new-design-components/custom-components";
import CreateEditDebtModal from "@pages/components/DebtManagement/CustomerOverview/CreateEditDebtModal";
import CreateReminderModal from "@pages/components/DebtManagement/CustomerOverview/CreateReminderModal";
import {
    ADD_SYSTEM_REMINDER_REQUEST, DELETE_SYSTEM_REMINDER_REQUEST, EDIT_SYSTEM_REMINDER_REQUEST,
    GET_SYSTEM_REMINDER_REQUEST,
} from "@redux/Customer/actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import usePrevious from "../../../hooks/useprevious";
import NotificationAlert from "react-notification-alert";
import {Notification} from "@pages/components/Notification";
import {parseToWord} from "../../../constants";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {Modal} from "reactstrap";
import CreateTemplateModal from "@pages/components/DebtManagement/CustomerOverview/CreateTemplateModal";
import axios from "axios";
import * as constants from "../../../constants";

export default function AutomaticReminders() {

    const {
       addSystemReminderSuccess,
       addSystemReminderError,
       editSystemReminderSuccess,
       editSystemReminderError,
       deleteSystemReminderSuccess,
       deleteSystemReminderError,
       getSystemRemindersSuccess,
       getSystemRemindersError,
       systemReminders,
       reminder
    } = useSelector((state) => state.customer, shallowEqual);

    const notificationAlert = useRef(null)
    const [loading,setLoading] = useState(true)
    const [editableIndex,setEditableIndex] = useState(null)
    const [editReminderIndex,setEditReminderIndex] = useState(null)
    const [editableData,setEditableData] = useState(null)
    const [deleteId,setDeleteId] = useState(null)
    const [openReminderModal,setOpenReminderModal] = useState(false)
    const [showDeleteTemplateModal,setShowDeleteTemplateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [remindersData,setRemindersData] = useState([])
    const [emailReminders,setEmailReminders] = useState([])
    const [textReminders,setTextReminders] = useState([])

    const [openTemplateModal,setOpenTemplateModal] = useState(false)
    const [templateModalType,setTemplateModalType] = useState(null)
    const [activeId,setActiveId] = useState(null)

    const dispatch = useDispatch();

    const getSystemRemindersSuccessPrev = usePrevious(getSystemRemindersSuccess)
    const getSystemRemindersErrorPrev = usePrevious(getSystemRemindersError)

    const editSystemReminderSuccessPrev = usePrevious(editSystemReminderSuccess)
    const editSystemReminderErrorPrev =   usePrevious(editSystemReminderError)

    const addSystemReminderSuccessPrev = usePrevious(addSystemReminderSuccess)
    const addSystemReminderErrorPrev = usePrevious(addSystemReminderError)

    const deleteSystemReminderSuccessPrev = usePrevious(deleteSystemReminderSuccess)
    const deleteSystemReminderErrorPrev = usePrevious(deleteSystemReminderError)

    const toggleDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal)
    }
    const fetchData =  () => {
        dispatch({
            type: GET_SYSTEM_REMINDER_REQUEST,
        });
    }

    useEffect( ()=>{
        const getData = async () => {
           axios.get(
                `${constants.API_CORE_URL}/get-system-templates`).then(res=>{
               const templates = res.data.data
               setEmailReminders(templates.filter(item=>item.source_type === 'email'))
               setTextReminders(templates.filter(item=>item.source_type === 'sms'))
               fetchData()
           }).catch(err=>{
               console.log('err',err)
               setLoading(false)
           });
        }
        getData()
    },[])

    useEffect(()=>{
        if (deleteSystemReminderSuccess && deleteSystemReminderSuccessPrev === false) {
            let tempData = [...remindersData]
            tempData = tempData.filter(item=>item.id !== deleteId)
            setRemindersData([...tempData])
            setDeleteId(null)
            setShowDeleteModal(false)
            setLoading(false)
        }
    },[deleteSystemReminderSuccess,deleteSystemReminderSuccessPrev])

    useEffect(()=>{
        if (deleteSystemReminderError && deleteSystemReminderErrorPrev === false) {
            setShowDeleteModal(false)
            setDeleteId(null)
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[deleteSystemReminderError,deleteSystemReminderErrorPrev])

    useEffect(()=>{
        if (editSystemReminderSuccess && editSystemReminderSuccessPrev === false) {
            setEditableIndex(null)
            setEditableData(null)
            setLoading(false)
        }
    },[editSystemReminderSuccess,editSystemReminderSuccessPrev])

    useEffect(()=>{
        if (editSystemReminderError && editSystemReminderErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[editSystemReminderError,editSystemReminderErrorPrev])

    useEffect(()=>{
        if (getSystemRemindersSuccess && getSystemRemindersSuccessPrev === false) {
            setRemindersData([...systemReminders])
            setLoading(false)
        }
    },[getSystemRemindersSuccess,getSystemRemindersSuccessPrev])

    useEffect(()=>{
        if (getSystemRemindersError && getSystemRemindersErrorPrev === false) {
            setLoading(false)
        }
    },[getSystemRemindersError,getSystemRemindersErrorPrev])

    useEffect(()=>{
        if (addSystemReminderSuccess && addSystemReminderSuccessPrev === false) {
            let tempData = [...remindersData]
            tempData.push(reminder)
            setRemindersData([...tempData])

            Notification('success', 'Success!', 'Added successfully',notificationAlert);
        }
    },[addSystemReminderSuccess,addSystemReminderSuccessPrev])

    useEffect(()=>{
        if (addSystemReminderError && addSystemReminderErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[addSystemReminderError,addSystemReminderErrorPrev])

    const addReminder = (data) => {
        if (editableIndex !== null) {
            dispatch({
                type: EDIT_SYSTEM_REMINDER_REQUEST,
                payload: data
            });
            let tempData = [...remindersData]
            tempData[editableIndex] = data
            setRemindersData([...tempData])
        } else {
            dispatch({
                type: ADD_SYSTEM_REMINDER_REQUEST,
                payload: data
            });
        }
         setOpenReminderModal(false)
    }

    const editData = (index) => {
        setEditableData({...remindersData[index]})
        setEditableIndex(index)
        setOpenReminderModal(true)
    }

    const removeItem = (id) => {
        dispatch({
            type: DELETE_SYSTEM_REMINDER_REQUEST,
            id: id
        });
    }
    const deleteData = ({id}) => {
        if (templateModalType === 'email') {
            let tempData = [...emailReminders]
            tempData = tempData.filter(item=>item.id !==id)
            setEmailReminders(tempData)
        } else {
            let tempData = [...textReminders]
            tempData = tempData.filter(item=>item.id !==id)
            setTextReminders(tempData)
        }
    }
    const updateData = ({data,index}) => {
        if (templateModalType === 'email') {
            let tempData = [...emailReminders]
            if (index > -1) {
                tempData[index] = {...data}
            } else {
                tempData.push(data)
            }
            setEmailReminders(tempData)
        } else {
            let tempData = [...textReminders]
            if (index > -1) {
                tempData[index] = {...data}
            } else {
                tempData.push(data)
            }
            setTextReminders(tempData)
        }
    }
    const toggleDeleteTemplateModal = () => {
        setShowDeleteTemplateModal(!showDeleteTemplateModal)
    }
    const deleteReminder = (id) => {
        axios.delete(
            `${constants.API_CORE_URL}/delete-template/${id}`
        ).then(()=>{
            deleteData({id})
            setActiveId(null)
        });
    }
    const deleteTemplate = ({id}) => {
        setActiveId(id)
        toggleDeleteTemplateModal()
    }
    const editReminder = ({index,type}) => {
            setOpenTemplateModal(true)
            setTemplateModalType(type)
            setEditReminderIndex(index)
    }
 return (  loading ? <LoaderContainer>
            <ClipLoader
                css={override}
                size={40}
                color={`#7B61E4`}
                loading={loading}
            />
        </LoaderContainer>
        :
        <div className={'container-fluid debt-background pt-3 pb-3'}>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Modal
                isOpen={showDeleteTemplateModal}
                toggle={toggleDeleteTemplateModal}
                className="modal-dialog-centered"
            >
                <div className={'p-3'}>
                    <h6 className="modal-title main-color">Are you sure you want to delete reminder?</h6>
                </div>

                <div className="modal-footer justify-content-end">
                    <ButtonContainer
                        onClick={() => {
                            setShowDeleteTemplateModal(false)
                            setActiveId(null)
                        }}
                    >
                        {`Close`}
                    </ButtonContainer>
                    <ButtonContainer
                        reverse
                        onClick={() => {
                            deleteReminder(activeId)
                            setShowDeleteTemplateModal(false)
                        }}
                    >
                        {`Delete`}
                    </ButtonContainer>

                </div>
            </Modal>
            <Modal
                isOpen={showDeleteModal}
                toggle={toggleDeleteModal}
                className="modal-dialog-centered"
            >
                <div className={'p-3'}>
                    <h6 className="modal-title main-color">Are you sure you want to delete reminder?</h6>
                </div>

                <div className="modal-footer justify-content-end">
                    <ButtonContainer
                        onClick={() => {
                            setShowDeleteModal(false)
                            setDeleteId(null)
                        }}
                    >
                        {`Close`}
                    </ButtonContainer>
                    <ButtonContainer
                        reverse
                        onClick={() => {
                            removeItem(deleteId)
                            setShowDeleteModal(false)
                        }}
                    >
                        {`Delete`}
                    </ButtonContainer>

                </div>
            </Modal>
            {openReminderModal && <CreateReminderModal
                                    isOpen={openReminderModal}
                                    data={editableData}
                                    emailTemplates={emailReminders}
                                    textTemplates={textReminders}
                                    closeModal={() => {
                                      setEditableIndex(null)
                                      setEditableData(null)
                                      setOpenReminderModal(false)
                                    }}
                                   updateData={addReminder}
            />}
            {(openTemplateModal && templateModalType) &&
                 <CreateTemplateModal localData={templateModalType === 'email' ? emailReminders : textReminders}
                                      type={templateModalType} isOpen={openTemplateModal}
                                      updateData={updateData}
                                      deleteData={deleteTemplate}
                                      editIndex={editReminderIndex}
                                      closeModal={()=>{
                                            setOpenTemplateModal(false)
                                          if (editReminderIndex !== null) {
                                              setEditReminderIndex(null)
                                          }
                                      }}/>
            }


            <CustomHeader weight={'600'} fontSize={'24px'}  color={'#000000'}>
                Automatic Reminders
            </CustomHeader>

            <div className={'d-flex g-10'}>
                <CustomContainer width={'100%'} backgroundColor={'#FFF'} borderRadius="16px" padding="16px" className={'mb-2'}>

                    <div className={'d-flex justify-content-between'}>
                        <div className={'d-flex g-5'}>
                            <IcoMoon
                                className={'cursor-pointer email-icon'}
                                iconSet={IconSet}
                                icon={'ic_message'}
                                size={20}
                            />
                            <CustomHeader weight={'600'} fontSize={'20px'}  color={'#000000'}>
                                Message Templates
                            </CustomHeader>
                        </div>
                        <CustomHeader weight={'600'} fontSize={'14px'}  color={'#3F8CFF'} className={'cursor-pointer text-underline'} onClick={()=>{
                            setTemplateModalType('sms')
                            setOpenTemplateModal(true)
                        }}>
                            View All
                        </CustomHeader>
                    </div>
                    {
                        textReminders.map((reminder,index) => {
                            return <div>
                                <div className={'d-flex justify-content-between'}>
                                    <CustomHeader marginBottom={'6px'} fontSize={'14px'} weight={'400'} color={'#000'}>
                                        {parseToWord(reminder?.subject)}
                                    </CustomHeader>
                                    <div className={'d-flex g05'}>
                                        <IcoMoon
                                            className={'cursor-pointer'}
                                            iconSet={IconSet}
                                            icon={'edit'}
                                            size={20}
                                            onClick={()=>{
                                                editReminder({index,type:'sms'})
                                            }}
                                        />
                                        <IcoMoon
                                            className={'cursor-pointer'}
                                            iconSet={IconSet}
                                            icon={'delete_new_outline'}
                                            size={20}
                                            onClick={()=>{
                                                deleteTemplate({id:reminder.id})
                                                setTemplateModalType('sms')
                                            }}

                                        />
                                    </div>
                                </div>
                                <CustomHeader marginBottom={'6px'} fontSize={'14px'} weight={'400'} color={'#000'}>
                                    {parseToWord(reminder?.message)}
                                </CustomHeader>
                            </div>
                        })
                    }
                </CustomContainer>
                <CustomContainer width={'100%'} backgroundColor={'#FFF'} borderRadius="16px" padding="16px" className={'mb-2'}>
                    <div className={'d-flex justify-content-between'}>
                        <div className={'d-flex g-5'}>
                            <IcoMoon
                                className={'cursor-pointer department-icon'}
                                iconSet={IconSet}
                                icon={'re_email'}
                                size={20}

                            />
                            <CustomHeader weight={'600'} fontSize={'20px'}  color={'#000000'}>
                                Email Templates
                            </CustomHeader>
                        </div>
                        <CustomHeader weight={'600'} fontSize={'14px'}  color={'#3F8CFF'} className={'cursor-pointer text-underline'} onClick={()=>{
                            setTemplateModalType('email')
                            setOpenTemplateModal(true)
                        }}>
                            View All
                        </CustomHeader>
                    </div>

                    {
                        emailReminders.map((reminder,index) => {
                            return <div>
                                <div className={'d-flex justify-content-between'}>
                                    <CustomHeader marginBottom={'6px'} fontSize={'14px'} weight={'400'} color={'#000'}>
                                        {parseToWord(reminder?.subject)}
                                    </CustomHeader>
                                    <div className={'d-flex g05'}>
                                        <IcoMoon
                                            className={'cursor-pointer'}
                                            iconSet={IconSet}
                                            icon={'edit'}
                                            size={20}
                                            onClick={() => {
                                               editReminder({index,type:'email'})
                                            }}
                                        />
                                        <IcoMoon
                                            className={'cursor-pointer'}
                                            iconSet={IconSet}
                                            icon={'delete_new_outline'}
                                            size={20}
                                            onClick={() => {
                                                deleteTemplate({id:reminder.id})
                                                setTemplateModalType('email')
                                            }}

                                        />
                                    </div>
                                </div>
                                <CustomHeader marginBottom={'6px'} fontSize={'14px'} weight={'400'} color={'#000'}>
                                    {parseToWord(reminder?.message)}
                                </CustomHeader>
                            </div>
                        })
                    }
                </CustomContainer>
            </div>

            <div className={'d-flex justify-content-between'}>

                <CustomHeader weight={'600'} fontSize={'20px'}  color={'#000000'}>
                    Reminders Schedule
                </CustomHeader>
                <CustomHeader weight={'600'} fontSize={'16px'}  color={'#3F8CFF'} className={'cursor-pointer'} onClick={()=>{
                    setOpenReminderModal(true)
                }}>
                   + Add New
                </CustomHeader>
            </div>
            {
                remindersData.map((reminder,index)=> {
                   return <Fragment key={index}>
                       <CustomContainer backgroundColor={'#FFF'} borderRadius="16px" padding="16px" className={'mb-2'}>
                              <div className={'d-flex justify-content-between'}>
                                  <div>
                                      <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                          Type:
                                      </CustomTextContainer>
                                      <CustomHeader  marginBottom={'6px'}  fontSize={'14px'} weight={'400'} color={'#000'}>
                                          {parseToWord(reminder?.type)}
                                      </CustomHeader>
                                  </div>

                                 <div className={'d-flex g05'}>
                                     <IcoMoon
                                         className={'cursor-pointer'}
                                         iconSet={IconSet}
                                         icon={'edit'}
                                         size={20}
                                         onClick={()=>{
                                             editData(index)
                                         }}
                                     />
                                     <IcoMoon
                                         className={'cursor-pointer'}
                                         iconSet={IconSet}
                                         icon={'delete_new_outline'}
                                         size={20}
                                         onClick={() => {
                                             setDeleteId(reminder.id)
                                             setShowDeleteModal(true)
                                         }}
                                     />
                                 </div>
                              </div>


                              <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                  Payment Type:
                              </CustomTextContainer>
                              <CustomHeader  marginBottom={'6px'}  fontSize={'14px'} weight={'400'} color={'#000'}>
                                  {parseToWord(reminder?.payment_type)}
                              </CustomHeader>

                           <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                               Overdue time:
                           </CustomTextContainer>
                           <CustomHeader  marginBottom={'6px'}  fontSize={'14px'} weight={'400'} color={'#000'}>
                               {parseToWord(reminder?.overdue_time)}
                           </CustomHeader>

                           <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                               Reminder:
                           </CustomTextContainer>
                           <div className={'d-flex g-5 align-items-center'}>
                               {reminder.source_type === 'email' ?
                                   <IcoMoon
                                       className={'cursor-pointer department-icon'}
                                       iconSet={IconSet}
                                       icon={'re_email'}
                                       size={20}

                                   />
                                   :
                                   <IcoMoon
                                       className={'cursor-pointer email-icon'}
                                       iconSet={IconSet}
                                       icon={'ic_message'}
                                       size={20}
                                   />
                               }
                               <CustomHeader  marginBottom={'6px'} lineHeight={'13px'} fontSize={'14px'} weight={'400'} color={'#000'}>
                                   {reminder?.message}
                               </CustomHeader>
                           </div>
                       </CustomContainer>
                   </Fragment>
                })

            }
        </div>

  )
}