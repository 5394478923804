import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import {
    hasPermission
} from 'views/policies/FleetPolicy'
import { hasFleetAdminPermission } from '../../../utils/dealer';
import * as constants from 'constants/index.js'


import {
     Modal,
} from 'reactstrap'

import { parseToWord } from "constants/index.js";
import { ButtonContainer, LoaderContainer, override } from "../reusable-components/styled-components";
import { ClipLoader } from "react-spinners";
import DepartmentsTable from "@pages/new-design-components/DepartmentsTable";
import {
    Circle,
    CustomTableRow,
    CustomTD,
    DepartmentsBadge, RequiredItemsBadge,
    StatusBadge,
    StyledTable,
    StyledTableHeader,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import { GET_QUICK_DATA_REQUEST } from "@redux/Fleet/actions";
import usePrevious from "../../../hooks/useprevious";

export default function VehicleJobs() {
    const notificationAlert = useRef(null)
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [pagination] = useState('')
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showQuickView, setShowQuickView] = useState(false)
    const [deleteId, setDeleteId] = useState(false)
    const [liveVehicleItems, setLiveVehicleItems] = useState('')
    const [liveVehicleItemsLoaded, setLiveVehicleItemsLoaded] = useState(false)
    const [tableData, setTableData] = useState([])
    const dispatch = useDispatch()
    const roles = useSelector(state => state.getState).roles;
    const permissions = useSelector((state) => state.getState).permissions;

    const {
        getQuickDataSuccess,
        getQuickDataFailure,
        quickViewData
    } = useSelector((state) => state.fleetState, shallowEqual);
    const getQuickDataSuccessPrev = usePrevious(getQuickDataSuccess);
    const getQuickDataFailurePrev = usePrevious(getQuickDataFailure);
    const [activeTab, setActiveTab] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const keys = [
        {
            key: 'make',
            text: 'make'
        },
        {
            key: 'deal_status',
            text: 'Deal Status'
        },
        {
            key: 'deal_type',
            text: 'Deal Type'
        },
        {
            key: 'options',
            text: 'View'
        },
    ];
    const adminKeys = [
        {
            key: 'make',
            text: 'make'
        },
        {
            key: 'deal_status',
            text: 'Deal Status'
        },
        {
            key: 'deal_type',
            text: 'Deal Type'
        },

        {
            key: 'mechanical',
            text: 'Mechanical'
        },
        {
            key: 'valeting',
            text: 'Valeting'
        },
        {
            key: 'body shop',
            text: 'Body Shop'
        },
        {
            key: 'ply lining',
            text: 'Miscellaneous'
        },

        {
            key: 'options',
            text: 'View'
        },
    ];
    const [filter, setFilter] = useState({
        page: 1
    })
    const quickKeys =
        [
            {
                key: 'title',
                text: 'Title'
            },

            {
                key: 'status',
                text: 'Status',
            },
        ];

    useEffect(() => {
        const fetchLiveVehiclesData = async () => {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-live-vehicles`,
                    params: {
                        ...filter,
                        page: 1,
                        type: 'master'
                    }
                })
    
                const data = response.data?.data
    
                let items = data.map(item => {
                    return {
                        ...item,
                        is_edited: false
                    }
                })
                setLiveVehicleItems(items);
                setLiveVehicleItemsLoaded(true);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                }
                notify('warning', 'Warning', 'Error occurred!')
            }
        }

        fetchLiveVehiclesData();
    }, []);

    useEffect(() => {
        if (getQuickDataSuccess && getQuickDataSuccessPrev === false) {
            setShowQuickView(true)
        }
    }, [getQuickDataSuccess, getQuickDataSuccessPrev, quickViewData])

    useEffect(() => {
        if (getQuickDataFailure && getQuickDataFailurePrev === false) {
            notify('warning', 'Warning', 'Error occurred!')
        }
    }, [getQuickDataFailure, getQuickDataFailurePrev])

    useEffect(() => {
        if (roles && liveVehicleItemsLoaded) {
            const admin = hasFleetAdminPermission(roles)
            setIsAdmin(admin)
            let tab = ''
            if (!admin) {
                if (hasPermission(permissions, 'access-mechanical-tasks' )) {
                    tab = 'mechanical'
                }
                else if (hasPermission(permissions, 'access-valeting-tasks')) {
                    tab = 'valeting'
                }

                else if (hasPermission(permissions, 'access-body-shop-tasks')) {
                    tab = 'body shop'
                }
                else if (hasPermission(permissions, 'access-ply-lining-tasks')) {
                    tab = 'ply lining'
                }
            }
            setActiveTab(tab)
            const getData = async (tab) => {
                await fetchData(tab)
            }
            getData(tab)
        }

    }, [roles, liveVehicleItemsLoaded])

    const notify = (type, header, message) => {
        const options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {header}
                    </span>
                    <span data-notify="message">
                        <div dangerouslySetInnerHTML={{ __html: message }} />
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        }
        notificationAlert.current.notificationAlert(options)
    }

    function getDepartmentStatus(vehicle, departmentName) {
        return vehicle.departments.find(dept => dept.name === departmentName)?.status || 'not_found';
    }

    function getPriority(vehicle) {
        const statusOrder = {
            to_do: 0,
            completed: 1
        };
        const valeting = statusOrder[getDepartmentStatus(vehicle, 'valeting')] ?? -1;
        const plyLining = statusOrder[getDepartmentStatus(vehicle, 'ply lining')] ?? -1;
        const mechanical = statusOrder[getDepartmentStatus(vehicle, 'mechanical')] ?? -1;
        const bodyShop = statusOrder[getDepartmentStatus(vehicle, 'body shop')] ?? -1;
        const priorityMap = [
            [0, 1, 1, 1],
            [1, 0, 1, 1],
            [1, 1, 0, 1],
            [0, 0, 1, 1],
            [0, 1, 0, 1],
            [1, 0, 0, 1],
            [0, 0, 0, 1],
            [1, 1, 1, 0],
            [0, 1, 1, 0],
            [1, 0, 1, 0],
            [1, 1, 0, 0],
            [0, 0, 1, 0],
            [0, 1, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 0, 0],
            [1, 1, 1, 1]
        ];

        const statusArray = [valeting, plyLining, mechanical, bodyShop];

        for (let i = 0; i < priorityMap.length; i++) {
            if (JSON.stringify(priorityMap[i]) === JSON.stringify(statusArray)) {
                return i + 1;
            }
        }

        return 20;
    }
    function sortVehicles(vehicles) {
        const dealStatusOrder = ['rented', 'trade_sale', 'Non Active', null];
        return vehicles.sort((a, b) => {
            if (a.deal_status === 'written_off' && b.deal_status !== 'written_off') return 1;
            if (b.deal_status === 'written_off' && a.deal_status !== 'written_off') return -1;

            const priorityDiff = getPriority(a) - getPriority(b);

            if (priorityDiff !== 0) {
                return priorityDiff;
            }

            const dealStatusA = dealStatusOrder.indexOf(a.deal_status);
            const dealStatusB = dealStatusOrder.indexOf(b.deal_status);
            return dealStatusA - dealStatusB;
        });
    }


    const setOrder = async (values) => {
        try {

            let data = {
                order: [...values]
            }

            const orderRequest = await axios.post(
                `${constants.FLEET_MS_API_URL}/fleet-management/fleet/order`,
                data
            );

            if (orderRequest?.status === 200) {
            }
        } catch (error) {
            console.error("Error updating order:", error);
        }
    };

    const filterDataByStatuses = (data, tab) => {
        return data.filter(item => {
            for (const department of item.departments) {
                if ('completed' === department.status && department.name === tab) {
                    return false;
                }
            }
            return true;
        });
    };
    const fetchData = async (tab, search = null) => {

        try {
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet`,
                params: {
                    department: tab,
                    status: 'in_progress',
                    search
                },
            })
            const data = response.data?.data
            if (data.fleet.length > 0) {
                const updatedData = data.fleet.map(item => {
                    const vehicle = liveVehicleItems.find(vehicle => vehicle.vehicle_id === item.vehicle_id);
                    let dealStatus = 'Non Active';

                    if (vehicle?.deal_type === 'sale') {
                        dealStatus = vehicle.sold_type;
                    } else if (vehicle?.deal_type === 'rental' && vehicle.deal_id) {
                        if ([
                            'initial_payment_paid',
                            'paid_order_fee',
                            'renewing',
                        ].includes(vehicle.deal_status)) {
                            dealStatus = 'rented';
                        }
                    }

                    return {
                        ...item,
                        deal_type: vehicle?.deal_type ?? 'N/A',
                        deal_status: dealStatus
                    }
                });
                const tdata = filterDataByStatuses(updatedData, tab)
                let pdd = sortVehicles(tdata)
                setTableData(pdd)
            } else {
                setTableData([])
            }
            setLoading(false)
        } catch (error) {
            if (axios.isCancel(error)) {
                notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
                notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
            }
            setLoading(false)
        }

    }
    const goToDetails = (index) => {
        const data = tableData[index];
        let quote = data?.quotes?.[data.quotes.length - 1]?.id || null;
        let redirectPath = '/admin/job-card'
        if (!isAdmin) {
            switch (activeTab) {
                case 'mechanical':
                    redirectPath = '/admin/fleet-mechanical-details'
                    break;
                default:
                    redirectPath = '/admin/fleet-job-details'
                    break;

            }
            history.push({
                pathname: redirectPath,
                state: {
                    vehicleId: data.vehicle.id,
                    fleetId: data.id,
                    quoteId: quote,
                    department: activeTab
                }
            })
        } else {
            const queryParams = new URLSearchParams({
                vehicleId: data.vehicle.id,
                fleetId: data.id,
                quoteId: quote,
            }).toString();

            history.push({
                pathname: redirectPath,
                search: `?${queryParams}`,
            });
        }

    }

    const quickView = (id) => {
        dispatch({
            type: GET_QUICK_DATA_REQUEST,
            payload: { fleetId: id, status: 'to_do' }
        });
    }
    const removeItem = async (id) => {
        try {
            const orderRequest = await axios({
                method: 'DELETE',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/${id}`,

            })
            if (orderRequest?.status === 200) {
                setDeleteId(null)
            }
        } catch (e) {
            setDeleteId(null)
        }
    }

    const filterVehicle = async (search) => {
        await fetchData(activeTab, search)
    }
    const sortItems = (data) => {
        setTableData(data)
    }
    const searchByText = async (e) => {
        let tempFilter = filter
        tempFilter['search'] = e.target.value
        setFilter(tempFilter)
        await filterVehicle(e.target.value)
    }

    const toggleDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal)
    }

    const toggleShowQuickViewModal = () => {
        setShowQuickView(!showQuickView)
    }
    const selectTab = async (tab) => {
        setActiveTab(tab)
        await fetchData(tab)
    }

    const generateQuickItem = (fleetTask, index) => {
        return quickKeys.map((key) => {
            if (key.key === 'status') {
                return (
                    <CustomTD key={key.key}>
                        <StatusBadge type={fleetTask[key.key]}>
                            {fleetTask[key.key] ? parseToWord(fleetTask[key.key]) : 'N/A'}
                        </StatusBadge>
                    </CustomTD>
                );
            }
            return (
                <CustomTD key={key.key}>
                    {fleetTask[key.key] ? fleetTask[key.key].toString() : 'N/A'}
                </CustomTD>
            );
        })
    };
    const createQuote = (index) => {
        const data = tableData[index];
        const queryParams = new URLSearchParams({
            vehicleId: data.vehicle.id,
            fleetId: data.id,
        }).toString();
        history.push({
            pathname: '/admin/add-vehicle-quote',
            search: `?${queryParams}`,
        });
    };
    const checkIn = (index) => {
        history.push({
            pathname: `/admin/vehicle-check-in`,
            state: {
                fleetId: tableData[index].id,
                vehicleId: tableData[index].vehicle.id
            }
        })
    }
    const checkTasks = (index) => {
        history.push({
            pathname: `/admin/fleet-vehicle-tasks`,
            state: {
                fleetId: tableData[index].id,
                vehicleId: tableData[index].vehicle.id
            }
        })
    }
    const checkInCreate = () => {
        history.push({
            pathname: `/admin/fleet-vehicle/create`,
        })
    }

    return (
        <>
            <Modal isOpen={showQuickView}
                toggle={toggleShowQuickViewModal}
                modalClassName={'slide-in-from-right'}
                className={'modal-dialog-right'}
            >
                <div className={'p-3'}>
                    <h6 className="modal-title main-color">Quick View</h6>
                </div>
                <div className={'modal-body'}>
                    <StyledTable className={'table-borderless'}>
                        <StyledTableHeader className={'header-white-text'}>
                            <tr>
                                {quickKeys.map((key, index) => {
                                    return <StyledTableHeaderCell className={'border-bottom-0'} key={key.key}>
                                        {
                                            key.text
                                        }
                                    </StyledTableHeaderCell>
                                })}

                            </tr>
                        </StyledTableHeader>
                        <tbody>
                            {
                                quickViewData.length && quickViewData.map((task, index) => {
                                    return <CustomTableRow key={index}>
                                        {
                                            generateQuickItem(task)
                                        }

                                    </CustomTableRow>
                                })
                            }


                        </tbody>
                    </StyledTable>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonContainer
                        onClick={toggleShowQuickViewModal}
                    >
                        {`Close`}
                    </ButtonContainer>
                </div>
            </Modal>
            <Modal
                isOpen={showDeleteModal}
                toggle={toggleDeleteModal}
                className="modal-dialog-centered"
            >
                <div className={'p-3'}>
                    <h6 className="modal-title main-color">Are you sure you want to delete vehicle?</h6>
                </div>

                <div className="modal-footer justify-content-end">
                    <ButtonContainer
                        onClick={() => {
                            setShowDeleteModal(false)
                            setDeleteId(null)
                        }}
                    >
                        {`Close`}
                    </ButtonContainer>
                    <ButtonContainer
                        reverse
                        onClick={() => {
                            removeItem(deleteId)
                            setShowDeleteModal(false)
                        }}
                    >
                        {`Delete`}
                    </ButtonContainer>

                </div>
            </Modal>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            {loading ?
                <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> :
                <div className={'container-fluid mt-3'}>
                    <div className={'d-flex g-10 mt-3 pb-5 pt-3 mb-3 mw-100 overflow-auto hidden-scrollbar'}>
                        {isAdmin &&
                        <RequiredItemsBadge width={'fit-content'} onClick={() => selectTab('')} active={activeTab === ''}>
                            All
                        </RequiredItemsBadge>
                        }
                        {hasPermission(permissions, 'access-mechanical-tasks') && <DepartmentsBadge type={'mechanics'} onClick={() => selectTab('mechanical')}
                                           active={activeTab === 'mechanical'}>
                            <Circle type={'mechanics'}/>
                            Mechanical
                        </DepartmentsBadge>}
                        {hasPermission(permissions, 'access-valeting-tasks') && <DepartmentsBadge type={'valeting'} onClick={() => selectTab('valeting')}
                                           active={activeTab === 'valeting'}>
                            <Circle type={'valeting'}/>
                            Valeting
                        </DepartmentsBadge>}

                        {hasPermission(permissions, 'access-body-shop-tasks') && <DepartmentsBadge type={'body_shop'} onClick={() => selectTab('body shop')}
                                           active={activeTab === 'body shop'}>
                            <Circle type={'body_shop'}/>
                            Body shop
                        </DepartmentsBadge>}
                        {hasPermission(permissions, 'access-ply-lining-tasks') && <DepartmentsBadge type={'ply lining'} onClick={() => selectTab('ply lining')}
                                           active={activeTab === 'ply lining'}>
                            <Circle type={'ply lining'}/>
                            Miscellaneous
                        </DepartmentsBadge>}
                    </div>
                    <DepartmentsTable
                        pagination={pagination}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        searchValue={filter?.hasOwnProperty('search') ? filter['search'] : ''}
                        searchPlaceHolder={'Reg No'}
                        checkInCreate={checkInCreate}
                        onSearchChange={e => searchByText(e)}
                        setData={(data) => {
                            sortItems(data)
                        }}
                        setOrder={setOrder}
                        tableData={tableData}
                        createQuote={createQuote}
                        goToDetails={goToDetails}
                        quickView={quickView}
                        checkIn={checkIn}
                        checkTasks={checkTasks}
                        removeItem={(id) => {
                            setDeleteId(id)
                            setShowDeleteModal(true)
                        }}
                        keys={isAdmin ? adminKeys : keys}
                        isAdmin={isAdmin}
                    />
                </div>
            }
        </>
    )
}