import React from 'react'
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import {
    CheckboxContainer,
    Checkmark,
    CustomHeader, CustomInput,
    CustomTD, FleetButton,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";


export default function Mechanics({data, addNewRow, changeData, partsData, changePartsData, addNewPartsRow}) {

    return (
        <Col lg={9}>
            <Card>
                <CardHeader className={'border-bottom-0'}>
                    <CustomHeader fontSize={'16px'}>
                        Work Records
                    </CustomHeader>
                </CardHeader>
                <CardBody>
                    <StyledTable className={'table-borderless'}>
                        <thead className={'header-white-text'}>
                        <tr>
                            <StyledTableHeaderCell className={'text-center'} width={'50%'} color={'#0000008C'}>
                                Job Performed
                            </StyledTableHeaderCell>

                            <StyledTableHeaderCell className={'text-center'} width={'20%'} color={'#0000008C'}>
                                Completion
                            </StyledTableHeaderCell>

                        </tr>
                        </thead>
                        <tbody>
                        {data.length ?
                            data.map((item, index)=>{
                                return <tr key={`4_${index}`}>
                                    <CustomTD width={'50%'}>
                                        <CustomInput radius={'8px'}
                                                     onChange={(e)=>{
                                                         changeData({
                                                             index,
                                                             key: 'title',
                                                             value: e.target.value
                                                         })
                                                     }
                                                     }
                                                     border={'1px solid #C0D9FF59'}
                                                     value={item.title} />
                                    </CustomTD>
                                    <CustomTD className={'text-center'} width={'20%'}>
                                        <CheckboxContainer checked={item.status == 'completed'} onClick={()=>{
                                            changeData({
                                                index,
                                                key: 'status'
                                            })
                                        }}>
                                            <Checkmark checked={item.status == 'completed'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M20.285 6.285a1 1 0 00-1.415 0L10 15.585 5.715 11.3a1 1 0 00-1.415 1.415l5 5a1 1 0 001.415 0l10-10a1 1 0 000-1.415z" />
                                            </Checkmark>
                                        </CheckboxContainer>
                                    </CustomTD>

                                </tr>
                            })
                            : null
                        }
                        <tr>
                            <td colSpan="4" className={'text-center'}>
                                <FleetButton
                                    height={'43px'}
                                    width={'114px'}
                                    backgroundColor={'#FDF0E4'}
                                    color={'#F3994A'}
                                    fontSize={'14px'}
                                    radius={'32px'}
                                    onClick={addNewRow}
                                >
                                    &#43; Add new
                                </FleetButton>
                            </td>
                        </tr>

                        </tbody>
                    </StyledTable>
                </CardBody>
            </Card>

            <Card>
                <CardHeader className={'border-bottom-0'}>
                    <CustomHeader fontSize={'16px'}>
                        Parts List
                    </CustomHeader>
                </CardHeader>
                <CardBody>
                    <StyledTable className={'table-borderless'}>
                        <thead className={'header-white-text'}>
                        <tr>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'25%'} color={'#0000008C'}>
                                Item
                            </StyledTableHeaderCell>

                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'25%'} color={'#0000008C'}>
                                No.
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'15%'} color={'#0000008C'}>
                                Quantity
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'20%'} color={'#0000008C'}>
                                Add To Report
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'20%'} color={'#0000008C'}>
                                Ordered
                            </StyledTableHeaderCell>

                        </tr>
                        </thead>
                        <tbody>
                        {
                            partsData.length ?
                                partsData.map((item,index)=>{
                                    return  <tr key={`3_${index}`}>
                                        <CustomTD className={'text-center'} width={'25%'}>
                                            <CustomInput radius={'8px'}
                                                         onChange={(e)=>{
                                                             changePartsData({
                                                                 index,
                                                                 key: 'part',
                                                                 value: e.target.value
                                                             })
                                                         }
                                                         }
                                                         border={'1px solid #C0D9FF59'}
                                                         value={item.part} />
                                        </CustomTD>
                                        <CustomTD className={'text-center'} width={'25%'}>
                                            <CustomInput radius={'8px'}
                                                         onChange={(e)=>{
                                                             changePartsData({
                                                                 index,
                                                                 key: 'part_number',
                                                                 value: e.target.value
                                                             })
                                                         }
                                                         }
                                                         border={'1px solid #C0D9FF59'}
                                                         value={item.part_number} />
                                        </CustomTD>
                                        <CustomTD className={'text-center'} width={'20%'}>
                                            <CustomInput radius={'8px'}
                                                         onChange={(e)=>{
                                                             changePartsData({
                                                                 index,
                                                                 key: 'quantity',
                                                                 value: e.target.value
                                                             })
                                                         }
                                                         }
                                                         border={'1px solid #C0D9FF59'}
                                                         value={item?.quantity} />
                                        </CustomTD>
                                        <CustomTD width={'10%'} className={'text-center'}>
                                            <CheckboxContainer checked={item?.added_to_report} onClick={()=>{
                                                changePartsData({
                                                    index,
                                                    key: 'added_to_report'
                                                })
                                            }}>
                                                <Checkmark checked={item?.added_to_report} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M20.285 6.285a1 1 0 00-1.415 0L10 15.585 5.715 11.3a1 1 0 00-1.415 1.415l5 5a1 1 0 001.415 0l10-10a1 1 0 000-1.415z" />
                                                </Checkmark>
                                            </CheckboxContainer>
                                        </CustomTD>
                                        <CustomTD width={'10%'} className={'text-center'}>
                                            <CustomCheckbox checked={item.status === 'completed'} onClick={()=>{
                                                changePartsData({
                                                    index,
                                                    key: 'status',
                                                    value: item.status === 'completed' ? 'to_do' : 'completed',
                                                });
                                            }
                                            }/>
                                        </CustomTD>

                                    </tr>
                                })
                                : null
                        }
                        <tr>
                            <td colSpan="5" className={'text-center'}>
                                <FleetButton
                                    height={'43px'}
                                    width={'114px'}
                                    backgroundColor={'#FDF0E4'}
                                    color={'#F3994A'}
                                    fontSize={'14px'}
                                    radius={'32px'}
                                    onClick={addNewPartsRow}
                                >
                                    &#43;  Add new
                                </FleetButton>
                            </td>
                        </tr>
                        </tbody>
                    </StyledTable>
                </CardBody>
            </Card>
        </Col>
    )
}