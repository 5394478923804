import React, {useEffect, useState} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {
    CustomHeader,
    CustomInput,
    CustomTextContainer,
    FleetButton
} from "@pages/new-design-components/custom-components";
import axios from "axios";
import * as constants from "../../../../../constants";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";


export default function CreateEditDebtModal({isOpen,data,updateData,cancel,dataIndex}) {

    const [regNos, setRegNos] = useState([])
    const [localData, setLocalData] = useState(null)
    const promiseOptions = inputValue => {
        if (inputValue.length < 2) {
            return
        } else {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(fetchData(inputValue));
                }, 1000);
            });
        }
    }
    const fetchData = async (reg_no) => {
        try {
            const source = axios.CancelToken.source()
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet`,
                params: {
                    search: reg_no,
                },
                cancelToken: source.token,
            })
            const data = response.data
            let fleets = data.data.fleet;
            if (fleets.length) {
                let _regNos_array = [];
                fleets.map(fleet => {
                    return _regNos_array.push({
                        id: fleet.vehicle.id,
                        fleet_id: fleet.id,
                        label: fleet.vehicle.stock_no,
                    })
                });
                console.log('_regNos_array',_regNos_array)
                setRegNos(_regNos_array);
                return _regNos_array;
            }
            return [];

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }
    useEffect(()=>{
        if (data) {
            setLocalData(data)
        }
    },[data])
    const saveData = () => {
        updateData(localData)
    }
    const updateValue = ({key,value}) => {
        let tempData= {...localData}
        tempData[key] = value
        setLocalData(tempData)
    }
    const updateVehicleValue = (vehicle) => {
        let tempData= {...localData}
        tempData['vehicle'] = vehicle
        tempData['vehicle_id'] = vehicle.id
        setLocalData(tempData)
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={cancel}
            modalClassName={'slide-in-from-right'}
            backdrop={false}
            className={'modal-dialog-right modal-responsible'}
        >
            <ModalHeader toggle={cancel} className={'bg-white centered-title'}>
                <CustomHeader weight={'600'} fontSize={'14px'}  color={'#74787E'}>
                    Edit Debt Information
                </CustomHeader>
            </ModalHeader>
            <ModalBody>

                <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                    Vehicle
                </CustomTextContainer>
                <AsyncSelect
                    loadOptions={promiseOptions}
                    classNamePrefix="select"
                    value={localData?.vehicle && localData?.vehicle?.id ? (regNos.find((element) => element.id === localData?.vehicle.id) || '') : ''}
                    onChange={vehicle => {
                        updateVehicleValue(vehicle)
                    }}
                    placeholder="Search..."
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                />
                <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                    Due
                </CustomTextContainer>
                <CustomInput radius={'8px'}
                             type={'date'}
                             onChange={(e)=>{
                                 updateValue({ key:'due_date', value: e.target.value})
                               }
                             }
                             border={'1px solid #979797'}
                             borderBottom={'1px solid #979797'}
                             value={localData?.due_date} />

                <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                    Amount
                </CustomTextContainer>
                <CustomInput radius={'8px'}
                             onChange={(e)=>{
                                 updateValue({ key:'amount_due', value: e.target.value})
                             }
                             }
                             border={'1px solid #979797'}
                             borderBottom={'1px solid #979797'}
                             value={localData?.amount_due} />

                <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                    Expected Payment Date
                </CustomTextContainer>
                <CustomInput radius={'8px'}
                             type={'date'}
                             onChange={(e)=>{
                                 updateValue({ key:'expected_payment_date', value: e.target.value})
                                }
                              }
                             border={'1px solid #979797'}
                             borderBottom={'1px solid #979797'}
                             value={localData?.expected_payment_date} />

                <CustomTextContainer  marginTop={'12px'} marginBottom={'6px'}  fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                   Status
                </CustomTextContainer>
                <CustomDropdownComponent
                    withoutDot
                    maxWidth={'100%'}
                    border={'1px solid #979797'}
                    selectedTitle={localData?.debt_status}
                    statusArrayKey={'debtStatuses'}
                    noBadge={true}
                    handleChange={(value)=>{
                        updateValue({ key:'debt_status', value})
                    }}
                />

            </ModalBody>
            <ModalFooter>
                <div className={'w-100'}>
                    <FleetButton
                        className={'mb-3'}
                        height={'43px'}
                        width={'90%'}
                        backgroundColor={'#c9c9c978'}
                        hoverColor={'#c9c9c978'}
                        color={'#000'}
                        fontSize={'14px'}
                        radius={'32px'}
                        onClick={saveData}
                    >
                        {dataIndex !== null ? 'Update Charge' : 'Create Charge'}
                    </FleetButton>

                    <FleetButton
                        height={'43px'}
                        width={'90%'}
                        backgroundColor={'#000'}
                        color={'#FFF'}
                        fontSize={'14px'}
                        radius={'32px'}
                        onClick={cancel}
                    >
                        Close
                    </FleetButton>

                </div>
            </ModalFooter>
        </Modal>
    )
}