import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Cookies from "js-cookie";
import NotificationAlert from "react-notification-alert";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
    Container,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Modal,
} from "reactstrap";

import {
    create,
} from 'views/policies/RolePolicy';
import {ButtonContainer, LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";
import Select from "react-select";
import CustomCheckbox from "@pages/components/CustomCheckbox";

export default function Roles() {
    const notificationAlert = useRef(null);
    const permissions = useSelector(state => state.getState).permissions;

    const [permissionGroups, setPermissionGroups] = useState({});
    const [groups, setGroups] = useState([]);
    const [permissionsForView, setPermissionsForView] = useState([]);
    const [role,setRole] = useState(null);
    const [token, setToken] = useState('');
    const [data, setData] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [isNotValid, setIsNotValid] = useState(true);

    const [name, setName] = useState('');

    const [loading, setLoading] = useState(true);
    const [cardLoading, setCardLoading] = useState(false);

    const [errors, setErrors] = useState('');

    const notify = (type, header, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            {message}
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        notificationAlert.current.notificationAlert(options);
    };

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)

        const source = axios.CancelToken.source()

        const fetchData = async () => {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${constants.API_CORE_URL}/role`,
                    headers: {
                        Authorization: `Bearer ${grs_token}`,
                    },
                    cancelToken: source.token,
                })
                console.log('response :: ', response.data)
                const data = response.data
                setData(data.data)

                setLoading(false)
            } catch (error) {
                setLoading(false)
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {

                    console.error('error :: ', error)
                    notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)

                }
            }
        }

        fetchData()

        return () => {
            source.cancel()
        }
    }, [])


    const addRole = () => {
        let data = {
            name: name,
        }
        axios({
            method: 'POST',
            url: constants.API_CORE_URL + '/role',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: data
        })
            .then(response => {
                let data = response.data;
                notify('success', 'Success', data.message);
                setAddModal(false);
                window.location.reload();
            })
            .catch(error => {
                setErrors('');
                if (error.response && error.response.status === 422) {
                    setErrors(error.response.data.errors);
                    return
                }
                notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
            })
    }

    const selectRole = async (role) => {
        setCardLoading(true)
        const roleResponse = await axios({
            method: 'GET',
            url: `${constants.API_CORE_URL}/role/${role.id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        const response = await axios({
            method: 'GET',
            url: `${constants.API_CORE_URL}/permission`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        setRole(role)
        const roleData = roleResponse.data
        setGroups(roleData.data.permission_groups)
        const tempGroups = roleData.permission_to_groups
        if (constants.isObject(tempGroups)) {
            setPermissionGroups(tempGroups)
        } else setPermissionGroups({})
        setPermissionsForView(response.data.data)
        setCardLoading(false)

   }
    const handleToggle = (value,parentGroup) => {
        let tempGroup = {}
        if (Object.keys(permissionGroups).length) {
            tempGroup = {...permissionGroups}
        }
        let newHasPermissions = []
        if (tempGroup[parentGroup]) {
             newHasPermissions = [...tempGroup[parentGroup]];
        }
        let currentIndex = -1
        if (newHasPermissions.length) {
            currentIndex = newHasPermissions.findIndex(permission => {
                return permission.id === value.id
            });
        }


        if (currentIndex === -1) {
            newHasPermissions.push(value);

        } else {
            newHasPermissions.splice(currentIndex, 1);
        }

        tempGroup[parentGroup] = [...newHasPermissions]

        setPermissionGroups(tempGroup)
    }

    const checkPermission = (group,miniGroup,slug) => {

        const namesArray = group?.map(permission => permission.name);

        return  miniGroup.permissions.map((permission) => {

                return <Col lg={3}>

                    <CustomCheckbox onChange={(e)=>handleToggle(permission,slug)} checked={namesArray?.includes(permission.name) }/>

                </Col>
            })
    }
    const savePermissions = () => {
        setCardLoading(true)
        let ids = []
        let groupIds = groups?.map(permission => permission.id);
        Object.values(permissionGroups).forEach(group => {
           return  group?.forEach(permission => {
                ids.push(permission.id)
            });
        })

        axios({
            method: 'POST',
            url: constants.API_URL + '/role/' + role.id + '/permissions',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                permissions: ids,
                permission_groups : groupIds,
            }
        })
            .then(response => {
                if (response.status === 200) {
                    notify('success', 'Success', 'Updated role successfully.');
                    setCardLoading(false)
                }
            })
            .catch(error => {
                setCardLoading(false)
                notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');

            })

    }
    const checkGroupPermission = (group) => {
        const idx = groups.findIndex(gr => gr.slug === group.slug)
        return (
            <CustomCheckbox onChange={()=>toggleParent(group,idx)} checked={idx > -1}/>
        )
    }
    const toggleParent = (selectedGroup,idx) => {
        let tempGroup = [...groups]

        if (idx === -1) {
            tempGroup.push(selectedGroup);
        } else {
            tempGroup.splice(idx, 1);
        }
        setGroups(tempGroup)
    }
    return (
        loading ?  <LoaderContainer>
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={loading}
                />
            </LoaderContainer> :
            <>
                <CardsHeader name="Admin" parentName="Role Management" />
                <div className="rna-wrapper">
                    <NotificationAlert ref={notificationAlert} />
                </div>
                <Container className="mt--6 admin-main-body" fluid >
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col xs={8}>
                                            <h2 className="mb-0">Roles</h2>
                                            <h3 className="mb-0">System Roles Management.</h3>
                                        </Col>
                                        <Col xs={4} className="text-right">
                                            {create(permissions) &&
                                            <ButtonContainer reverse onClick={() => setAddModal(true)}>Add role</ButtonContainer>}
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Col lg={3} className={'mb-3'}>
                                        <Select
                                            onChange={selectRole}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            options={data}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Col>
                                    {
                                        !cardLoading &&
                                        <Row>
                                            {permissionsForView.length > 0 && permissionsForView.map((group, index) => {
                                                return (
                                                    <Col lg={4} >
                                                        <Col className={'outline pt-2 mb-3 roles-group'}>
                                                            <Row className={'roles-group-header mb-2'}>
                                                                <Col lg={5}>
                                                                    <h3>
                                                                        {group?.name}
                                                                    </h3>
                                                                </Col>
                                                                <Col lg={3} >
                                                                    {
                                                                        checkGroupPermission(group)
                                                                    }
                                                                </Col>

                                                            </Row>
                                                            <Row className={'roles-group-header'}>
                                                                <Col lg={5}/>
                                                                <Col lg={3}>
                                                                    <h4>
                                                                        View/Edit
                                                                    </h4>
                                                                </Col>
                                                                <Col lg={3}>
                                                                    <h4>
                                                                        Delete
                                                                    </h4>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                group?.permissions.map((miniGroup)=> {
                                                                    return (
                                                                        <Row className={'roles-group-header pr-1 pl-1 pt-1'}>
                                                                            <Col lg={5}>
                                                                                <h4>
                                                                                    {miniGroup.name}
                                                                                </h4>
                                                                            </Col>
                                                                            {
                                                                                checkPermission(permissionGroups[group?.slug],miniGroup,group?.slug)
                                                                            }
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                        </Col>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div className="modal-footer">
                        {
                            permissionsForView.length > 0 &&
                            <ButtonContainer
                                disabled={groups.length === 0 || permissionGroups.length === 0}
                                reverse
                                onClick={() => savePermissions()}
                            >
                                Save
                            </ButtonContainer>
                        }

                    </div>
                    <Modal
                        isOpen={addModal}
                        toggle={() => setAddModal(false)}
                        className="modal-dialog-centered modal-secondary"
                    >
                        <div className="modal-body">
                            <form className="new-event--form">
                                <InputCustom
                                    required={true}
                                    setIsNotValid={setIsNotValid}
                                    newLabel={`Name`}
                                    placeholder={`Name`}
                                    onChange={(e) => setName(e.target.value)}
                                    invalid={errors && errors.name? true: false}
                                    errorMessage={errors.name}
                                />
                            </form>
                        </div>
                        <div className="modal-footer">
                            <ButtonContainer
                                onClick={() => setAddModal(false)}
                            >
                                Cancel
                            </ButtonContainer>
                            <ButtonContainer
                                reverse
                                disabled={isNotValid}
                                onClick={() => isNotValid ? null : addRole()}
                            >
                                Save
                            </ButtonContainer>
                        </div>
                    </Modal>
                </Container>
            </>
    );
}