import React from 'react'
import {Card, CardBody} from "reactstrap";
import {
    PdfHeader,CustomTD,
    StyledTable, StyledTableHeaderCell, CustomHeader
} from "@pages/new-design-components/custom-components";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import { CURRENCY, getToday } from "../../../../constants";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import axios from "axios";
import * as constants from "../../../../constants";
import { Notification } from "@pages/components/Notification";

const SERVICE_PRICE = 250;

export default function ServiceRecordComponentForPrint({
                                                           partsData,
                                                           vehicle,
                                                           parsable = false,
                                                           total = 0,

                                                       }) {
    return (
            <Card id="pdf-content-component" className={"table-container " + parsable ? "position-absolute" : ""} style={{ width: '1120px', minHeight: '800px', overflow: 'hidden', bottom: parsable ? '200%' : 'unset'}}>
                <CardBody style={{ width: '1120px', minHeight: '800px',  overflow: 'hidden' }}>
                    <div className={'d-flex justify-content-between table-container'} style={{ width: '1120px', overflow: 'hidden' }}>
                        <div className={'d-flex flex-column pt-3 align-items-start'} style={{ width: '33%' }}>
                            <PdfHeader lineHeight={'14px'} fontSize={'16px'} weight={'700'} color={'#000000'} className={'mb-4'}>
                                FLEET INFORMATION:
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'700'} color={'#000000'}>
                                {vehicle.make.name} {vehicle.model.name}
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                {vehicle.derivative}
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Reg no {vehicle.stock_no}
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Color: {vehicle.colour}
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Mileage: {vehicle.current_mileage}
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Inspection Date:  {vehicle.inspection_date ?? getToday()}
                            </PdfHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Service Date: {getToday()}
                            </CustomHeader>
                        </div>
                        <div className={'d-flex flex-column text-center pt-3 align-items-center'} style={{ width: '34%' }}>
                            <img className={'mb-3'} height='100px' width='160px'
                                 src={require(`assets/images/GRSMotorGroupLogo.svg`)} alt="" />

                        </div>
                        <div className={'d-flex flex-column pt-3 align-items-end'} style={{ width: '33%' }}>
                            <PdfHeader lineHeight={'10px'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                GRS Motor Group Ltd
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Unit 12 & 13
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Priory Park
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Thornton
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Milford Haven
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                SA73 2DA
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Tel: 01646 603872
                            </PdfHeader>
                            <PdfHeader lineHeight={'10px'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                Vat No: 249 1787 67
                            </PdfHeader>
                        </div>
                    </div>
                    <div className={'text-center'} style={{width: '742px'}}>
                        <PdfHeader lineHeight={'none'} fontSize={'18px'} weight={'700'} color={'#000000'}>
                            Service Record
                        </PdfHeader>
                    </div>
                    <div className={'table-container'}>
                        <StyledTable>
                            <thead>
                            <tr>
                                <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Work
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Labour(h)
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Cost
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        VAT 20%
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Total
                                    </CustomHeader>
                                </StyledTableHeaderCell>

                            </tr>
                            </thead>
                            <tbody>
                            <tr key={`4_13G`}>
                                <CustomTD border={'none'} radius={'0px'} width={'20%'}  borderBottom={'1px solid #C0D9FF'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                        Service
                                    </CustomHeader>
                                </CustomTD>
                                <CustomTD border={'none'} radius={'0px'} width={'20%'}  borderBottom={'1px solid #C0D9FF'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                        1.5
                                    </CustomHeader>
                                </CustomTD>
                                <CustomTD border={'none'} radius={'0px'}  width={'20%'}  borderBottom={'1px solid #C0D9FF'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                        £250
                                    </CustomHeader>
                                </CustomTD>
                                <CustomTD border={'none'} radius={'0px'}  width={'20%'}  borderBottom={'1px solid #C0D9FF'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                        £50
                                    </CustomHeader>
                                </CustomTD>
                                <CustomTD border={'none'} radius={'0px'} width={'20%'}  borderBottom={'1px solid #C0D9FF'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                        £300
                                    </CustomHeader>
                                </CustomTD>

                            </tr>
                            </tbody>
                        </StyledTable>
                        <StyledTable className={'mb-5'}>
                            <thead>
                            <tr>
                                <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                    <PdfHeader lineHeight={'none'} fontSize={'12px'} weight={'600'} color={'#000000'}>
                                        Part
                                    </PdfHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                    <PdfHeader lineHeight={'none'} fontSize={'12px'} weight={'600'} color={'#000000'}>
                                        Quantity
                                    </PdfHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                    <PdfHeader lineHeight={'none'} fontSize={'12px'} weight={'600'} color={'#000000'}>
                                        Part No.
                                    </PdfHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                    <PdfHeader lineHeight={'none'} fontSize={'12px'} weight={'600'} color={'#000000'}>
                                        Cost per Unit
                                    </PdfHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                    <PdfHeader lineHeight={'none'} fontSize={'12px'} weight={'600'} color={'#000000'}>
                                        Total
                                    </PdfHeader>
                                </StyledTableHeaderCell>
                            </tr>
                            </thead>
                            <tbody className={'w-100'}>
                            {partsData.length ?
                                partsData.map((item, index) => {
                                    return <tr key={`4_${index}`}>
                                        <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'20%'}>
                                            <PdfHeader lineHeight={'none'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                                {item.part}
                                            </PdfHeader>
                                        </CustomTD>
                                        <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'20%'}>
                                            <PdfHeader lineHeight={'none'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                                {item.quantity}
                                            </PdfHeader>
                                        </CustomTD>
                                        <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'20%'}>
                                            <PdfHeader lineHeight={'none'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                                {item.part_number}
                                            </PdfHeader>
                                        </CustomTD>
                                        <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'20%'}>
                                            <PdfHeader lineHeight={'none'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                                {item.cost  || '0'}
                                            </PdfHeader>
                                        </CustomTD>
                                        <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'20%'}>
                                            <PdfHeader lineHeight={'none'} fontSize={'12px'} weight={'400'} color={'#000000'}>
                                                {parseFloat(item.quantity) * (item.cost ?  parseFloat(item.cost) : 0)}
                                            </PdfHeader>
                                        </CustomTD>
                                    </tr>
                                })
                                : null
                            }
                            </tbody>
                        </StyledTable>
                        <div className={'d-flex pt-3 g-10 align-items-start'}>
                            <StyledTable>
                                <thead>
                                <tr>
                                    <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                            Cost
                                        </CustomHeader>
                                    </StyledTableHeaderCell>

                                    <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                            VAT 20%
                                        </CustomHeader>
                                    </StyledTableHeaderCell>
                                    <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                            Total
                                        </CustomHeader>
                                    </StyledTableHeaderCell>

                                </tr>
                                </thead>
                                <tbody>
                                <tr key={`4_13G`}>

                                    <CustomTD border={'none'} radius={'0px'}  width={'20%'} borderBottom={'1px solid #C0D9FF'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                            £{total + SERVICE_PRICE}
                                        </CustomHeader>
                                    </CustomTD>
                                    <CustomTD border={'none'} radius={'0px'}  width={'20%'} borderBottom={'1px solid #C0D9FF'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                            £{(parseFloat(total + SERVICE_PRICE) * 0.20).toFixed(2)}
                                        </CustomHeader>
                                    </CustomTD>
                                    <CustomTD border={'none'} radius={'0px'} width={'20%'} borderBottom={'1px solid #C0D9FF'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                            £{(total + SERVICE_PRICE) + ((SERVICE_PRICE + total) * 0.20)}
                                        </CustomHeader>
                                    </CustomTD>

                                </tr>
                                </tbody>
                            </StyledTable>

                        </div>
                    </div>
                </CardBody>
            </Card>
    )
}
