import React, {Fragment, useState} from "react";
import {
    CustomContainer, CustomDropdown, CustomDropdownButton,
    CustomHeader,
    CustomReactStrapInput, CustomTableRow, CustomTD, FleetButton, FlexInnerComponent, OptionContainer, OptionsContainer,
    RequiredItemsBadge, StyledTable, StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledPopover} from "reactstrap";
import DebtManagementTable from "@pages/components/DebtManagement/DebtManagementTable";
import CustomSearch from "@pages/components/CustomSearch";
import ThreeDots from "@assets/images/mi_options-vertical.svg";
import {checkAndFormatShortGb, parseToWord} from "../../../../../constants";
import Picker from "@pages/components/DatePicker";

export default function CommunicationsLog({
                                              openNewEmail,openNewMessage,tableData = [],
                                              updateFilter,filter,activeCommunicationTab,
                                              customer, tabs = [],setActiveCommunicationTab}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const keys = [
        {
            key: 'created_at',
            label: 'Date'
        } ,
        {
            key: 'source_type',
            label: 'Type'
        } ,
        {
            key: 'recipient',
            label: 'Recipient'
        },
        {
            key: 'outreach_part',
            label: 'Outreach Party'
        },
        {
            key: 'representative',
            label: 'Representative'
        },
    ]
    const generateItem = ({item,index,key, itemIndex}) => {

         if (key === 'source_type') {
            return <CustomTD key={index}>
                {
                    item?.source_type === 'SystemNotes' ?
                        <IcoMoon
                            className={'cursor-pointer email-icon'}
                            iconSet={IconSet}
                            icon={ 'ic_message'}
                            size={20}
                        /> :
                        <IcoMoon
                            className={'cursor-pointer department-icon'}
                            iconSet={IconSet}
                            icon={ 're_email'}
                            size={20}
                        />
                }

            </CustomTD>
        } else if (key === 'outreach_part') {
            return <CustomTD key={index}>
                {   item?.source_type === 'SystemNotes' ?
                    item.source?.noteable?.mobile_number :
                    item.source?.noteable?.length && item.source?.noteable[0]?.email_address
                }
            </CustomTD>
        } else if (key === 'recipient') {
            return <CustomTD key={index}>
                {item.source?.creator?.email}
            </CustomTD>
        }  else if (key === 'created_at') {
            let value = parseToWord(item[key]?.toString())
            return <CustomTD key={index}>{checkAndFormatShortGb(value)}</CustomTD>
        } else if (key === 'representative') {
             return <CustomTD key={index}>
                 {   customer?.full_name }
             </CustomTD>
         }
        return <CustomTD key={index}>
            {item[key] ? item[key] : '-'}
        </CustomTD>
    }

    return (
        <div className={'container-fluid debt-background pt-3'}>
            <div className={'d-flex justify-content-between mb-3 align-items-center'}>
                <CustomHeader lineHeight={'16px'} weight={'700'} color={'#000'} fontSize={'20px'}>Communication Log</CustomHeader>
                <Dropdown  isOpen={dropdownOpen} toggle={toggle}>
                    <CustomDropdownButton radius={'8px'}  backgroundColor={'#000'} color={'#fff'} caret size="lg">
                      + New Log
                    </CustomDropdownButton>
                    <DropdownMenu>
                        <DropdownItem onClick={openNewMessage}>
                            <div className={'d-flex g-5'}>
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'ic_message'}
                                    size={24}
                                />
                                <CustomHeader weight={'400'} color={'#74787E'} fontSize={'14px'}>
                                    New Message
                                </CustomHeader>
                            </div>
                          </DropdownItem>
                        <DropdownItem onClick={openNewEmail}>
                            <div  className={'d-flex g-5'}>
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'ic_email'}
                                    size={24}
                                />
                                <CustomHeader weight={'400'} color={'#74787E'} fontSize={'14px'}>
                                    New Email
                                </CustomHeader>
                            </div>
                         </DropdownItem>
                    </DropdownMenu>
                </Dropdown>

            </div>
            <div className={'d-flex justify-content-between mb-3 flex-wrap'}>
                <div className={'d-flex g-10 mb-3 flex-wrap'}>
                    {
                        tabs.map((tab,index) => {
                            return (
                                <Fragment key={'n_'+index}>
                                    <RequiredItemsBadge  fontSize={'14px'} width={'100px'}  type={tab.key} onClick={()=> {
                                        setActiveCommunicationTab(tab.key);
                                    }
                                    } active={activeCommunicationTab === tab.key}>
                                        {tab.label}
                                    </RequiredItemsBadge>
                                </Fragment>
                            )
                        })
                    }
                </div>
                <div className={'d-flex g-10 debt-datePicker white-background'}>
                    <Picker
                        value={filter?.from}
                        placeholderText={'From'}
                        onChange={(date) => {
                            updateFilter({key:'from',value:date})
                        }}
                    />
                    <Picker
                        value={filter?.to}
                        placeholderText={'To'}
                        onChange={(date) => {
                            updateFilter({key:'to',value:date})
                        }}
                    />
                </div>
            </div>
                <div className={'table-container'}>
                    <StyledTable className={'table-borderless'}>
                        <thead className={'header-white-text'}>
                        <tr>
                            {keys.map((item,index)=>{
                                return  item.key !== 'view' &&  <Fragment key={index}>
                                    <StyledTableHeaderCell fontSize='14px' color={'#0000008C'}>
                                        {item.label}
                                    </StyledTableHeaderCell>
                                </Fragment>
                            })}

                        </tr>
                        </thead>
                        <tbody>
                        {tableData.length ?
                            tableData.map((item,index)=>{
                                return <CustomTableRow key={index}>
                                    {
                                        keys.map(({key}, keyIndex) => {
                                            return generateItem({item, index: `${index}_${keyIndex}`,key, itemIndex: index});
                                        })
                                    }
                                </CustomTableRow>

                            }) : null
                        }
                        </tbody>
                    </StyledTable>
                </div>
        </div>
    );
}