import React, { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';

import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import {ButtonContainer, LoaderContainer, override} from "@pages/reusable-components/styled-components";

import {ClipLoader} from "react-spinners";
import GoogleEventModal from "@pages/reusable-components/GoogleEventModal";

export default function Dashboard() {
  const notificationAlert = useRef(null);
  const authUserTeam = useSelector(state => state.getState).authUserTeam;

  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [token, setToken] = useState('');
  const [customerStatistics, setCustomerStatistics] = useState('');
  const [dealsStatistics, setDealsStatistics] = useState('');
  const [vehiclesStatistics, setVehiclesStatistics] = useState('');
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)
    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/dashboard`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setCustomerStatistics(data.data.customer_statistics)
        setDealsStatistics(data.data.deals_statistics)
        setVehiclesStatistics(data.data.vehicles_statistics)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      setLoading(false)
      source.cancel()
    }
  }, [])

  const createCalendarEvent = async (event) => {
   try {
     setIsCalendarLoading(true)
     setIsOpen(false)
     const response = await axios({
       method: 'POST',
       url: `${constants.API_URL}/create-calendar-event`,
       headers: {
         Authorization: `Bearer ${token}`,
       },
       data:event,
     })
     if (response.status === 200) {
       setIsCalendarLoading(false)
     }
   } catch (e) {
      setIsCalendarLoading(false)
   }
  }
  return (
      loading ?
          <LoaderContainer>
            <ClipLoader
                css={override}
                size={40}
                color={`#7B61E4`}
                loading={loading}
            />
          </LoaderContainer> :
    <>
    <GoogleEventModal
       isOpen={isOpen}
       setIsOpen={setIsOpen}
       setEvent={createCalendarEvent}
    />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <CardsHeader name="Home" parentName="Dashboards">
        <Col xl={authUserTeam ? 4 : 3}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase mb-0"
                  >
                    Delivered deals(Current month)
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {dealsStatistics ? dealsStatistics.deliveredDeals : 0}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                    <i className="ni ni-active-40" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-nowrap mr-2">All time delivered deals</span>{" "}
                <span>
                  {dealsStatistics ? dealsStatistics.allTimeDeliveredDeals : 0}
                </span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xl={authUserTeam ? 4 : 3}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase mb-0"
                  >
                    Waiting for signing
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {dealsStatistics ? dealsStatistics.awaitingForSigningDeals : 0}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                    <i className="ni ni-chart-pie-35" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-nowrap mr-2">Deal in progress</span>{" "}
                <span>
                  {dealsStatistics ? dealsStatistics.inProgressDeals : 0}
                </span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xl={authUserTeam ? 4 : 3}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase mb-0"
                  >
                    Total vehicles
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">
                    {vehiclesStatistics ? vehiclesStatistics.totalVehicles : 0}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                    <i className="ni ni-money-coins" />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-nowrap mr-2">Since last month</span>{" "}
                <span>
                  {vehiclesStatistics ? vehiclesStatistics.availableVehicles : 0}
                </span>
              </p>
            </CardBody>
          </Card>
        </Col>
        {!authUserTeam &&
          <Col xl={authUserTeam ? 4 : 3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase mb-0"
                    >
                      Customers(current month)
                  </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {customerStatistics ? customerStatistics.currentMonthCustomers : 0}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                      <i className="ni ni-chart-bar-32" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                  <span className="text-nowrap mr-2">Since last month</span>{" "}
                  <span>
                    {customerStatistics ? customerStatistics.allCustomers : 0}
                  </span>
                </p>
              </CardBody>
            </Card>
          </Col>}
      </CardsHeader>
      <Container fluid className="mt-7 dashboard" style={{ minHeight: '60vh' }}>

              <Container>
                   <ButtonContainer className={'mb-3'} reverse disabled={isCalendarLoading} onClick={()=>!isCalendarLoading && setIsOpen(true)}> Add Event </ButtonContainer>
                {
                  isCalendarLoading ?
                      <LoaderContainer>
                        <ClipLoader
                            css={override}
                            size={40}
                            color={`#7B61E4`}
                            loading={isCalendarLoading}
                        />
                      </LoaderContainer> :
                      <iframe
                          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Europe%2FLondon&bgcolor=%23ffffff&src=OTY4ODA1NGY2N2M5ZmI4ZjEyYWMwMjk0MDUwMTFmNjllNmQyOTNjYTk4NmVjZTRmMGI2NGRlNzZjZDgzNzM5M0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23F6BF26"
                          style={{border: 0}} width="1000" height="800" frameBorder="0" scrolling="no"></iframe>
                }

              </Container>
      </Container>
    </>
  );
}