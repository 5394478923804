import React from 'react'
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    AddImageButton,
    CheckboxContainer,
    Checkmark, CurrencySymbol,
    CustomHeader, CustomInput, CustomInputWrapper,
    CustomTD, FleetButton, IconContainer,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import TimeFormatInput from "@pages/new-design-components/TimeFormatInput";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import {CURRENCY, parseToWord} from "../../../../constants";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";


export default function QuoteComponent({data, addNewRow,
                                           changeData,
                                           deleteRow,
                                           departments,
                                           partsData = [],
                                           changePartsData,
                                           deletePartsRow,
                                           damages,
                                           addNewPartsRow,
                                           errors = {},
                                           viewImage,
                                           onFileSelect,
                                       }) {
    const isFieldInvalid = (parent,index, fieldName) => {
        return errors.hasOwnProperty(parent) &&  errors[parent].hasOwnProperty(index) && errors[parent][index].includes(fieldName);
    };
    const handleButtonClick = (id) => {
        document.getElementById(id).click();
    };
    const handleFileChange = (event,index) => {
        const file = event.target.files[0];
        if (file) {
            onFileSelect(file,index);
        }
    };
    return (
        <>
            <Card>
                <CardHeader className={'border-bottom-0'}>
                    <CustomHeader fontSize={'16px'}>
                        Work Carried Out
                    </CustomHeader>
                </CardHeader>
                <CardBody className={'overflow-auto'}>
                    <StyledTable className={'table-borderless'}>
                        <thead className={'header-white-text'}>
                        <tr>
                            <StyledTableHeaderCell className={'text-center'} width={'15%'} color={'#0000008C'}>
                                Work Done
                            </StyledTableHeaderCell>

                            <StyledTableHeaderCell className={'text-center'} width={'15%'} color={'#0000008C'}>
                                Department
                            </StyledTableHeaderCell>

                            <StyledTableHeaderCell className={'text-center'} width={'10%'} color={'#0000008C'}>
                                Labour(h)
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'10%'} color={'#0000008C'}>
                                Cost
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'10%'} color={'#0000008C'}>
                                Add to Report
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'20%'} color={'#0000008C'}>
                                Status
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'10%'} color={'#0000008C'}>
                                Image
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'10%'} color={'#0000008C'}>

                            </StyledTableHeaderCell>

                        </tr>
                        </thead>
                        <tbody>
                        {data.length ?
                            data.map((item, index)=>{
                                const images = damages.find(damage=>damage.task_id === item.id)?.images
                                const image = images?.length ? images[0] : null
                                return <tr key={`4_${index}`}>
                                    <CustomTD width={'15%'}>
                                        <CustomInput radius={'8px'}
                                                     onChange={(e)=>{
                                                         changeData({
                                                             index,
                                                             key: 'title',
                                                             value: e.target.value
                                                         })
                                                     }
                                                     }
                                                     borderBottom={isFieldInvalid('data',index, "title") ? '1px solid red' : '1px solid #C0D9FF59'}
                                                     border={isFieldInvalid('data',index, "title") ? '1px solid red' : '1px solid #C0D9FF59'}
                                                     value={parseToWord(item.title)} />
                                    </CustomTD>
                                    <CustomTD width={'15%'}>
                                        <CustomDropdownComponent
                                            withoutDot
                                            border={isFieldInvalid('data',index, "department") ? '1px solid red' : '1px solid #C0D9FF59'}
                                            selectedTitle={item.department?.name === 'workshop' ? 'ply lining' : item.department?.name}
                                            statusArrayKey={'departments'}
                                            handleChange={(value)=>{
                                                const department = departments.find(item=>item.name === value)
                                                changeData({
                                                    index,
                                                    key: 'department',
                                                    value: department
                                                })
                                            }}
                                        />
                                    </CustomTD>
                                    <CustomTD width={'10%'}>
                                        <CustomInput radius={'8px'}
                                                     onChange={(e)=>{
                                                         changeData({
                                                             index,
                                                             key: 'hour',
                                                             value: e.target.value
                                                         })
                                                     }
                                                     }
                                                     borderBottom={'1px solid #C0D9FF59'}
                                                     border={'1px solid #C0D9FF59'}
                                                     value={item.hour} />
                                    </CustomTD>
                                    <CustomTD width={'10%'}>
                                        <CustomInputWrapper>
                                            <CurrencySymbol>{CURRENCY}</CurrencySymbol>
                                            <CustomInput
                                                radius={'8px'}
                                                padding={'10px 10px 10px 30px'}
                                                onChange={(e) => {
                                                    changeData({
                                                        index,
                                                        key: 'cost',
                                                        value: e.target.value,
                                                    });
                                                }}
                                                border={'1px solid #C0D9FF59'}
                                                borderBottom={'1px solid #C0D9FF59'}
                                                value={item.cost}
                                                placeholder="Enter cost"
                                            />
                                        </CustomInputWrapper>
                                    </CustomTD>

                                    <CustomTD width={'10%'} className={'text-center'}>
                                        <CheckboxContainer checked={item?.added_to_report} onClick={()=>{
                                            changeData({
                                                index,
                                                key: 'added_to_report'
                                            })
                                        }}>
                                            <Checkmark checked={item?.added_to_report} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M20.285 6.285a1 1 0 00-1.415 0L10 15.585 5.715 11.3a1 1 0 00-1.415 1.415l5 5a1 1 0 001.415 0l10-10a1 1 0 000-1.415z" />
                                            </Checkmark>
                                        </CheckboxContainer>
                                    </CustomTD>
                                    <CustomTD width={'20%'}>
                                    <CustomDropdownComponent
                                        withoutDot
                                        border={'1px solid #C0D9FF59'}
                                        selectedTitle={item.status}
                                        statusArrayKey={'quotePassStatuses'}
                                        handleChange={(value)=>{
                                            changeData({
                                                index,
                                                key: 'status',
                                                value: value
                                            })
                                        }}
                                    />
                                    </CustomTD>
                                        <CustomTD color={'#3F8CFF'}
                                                  className={'text-center cursor-pointer'}
                                                  width={'10%'}>
                                            {image ? <span onClick={() => {
                                                viewImage(image?.image_link)
                                            }}>View</span> : item?.documents?.length ?
                                                <span onClick={() => {
                                                    viewImage(item?.documents[0]?.image_link)
                                                }}>View</span>
                                            : item?.newDocuments?.length ?
                                                    <img src={item?.newDocuments[0].preview} width={'25px'} alt=""/>
                                                    : item.department?.name === 'body shop' &&  <AddImageButton place={'center'} onClick={()=>handleButtonClick(`input_${index}`)}>
                                                    +
                                                    <input
                                                        type="file"
                                                        id={`input_${index}`}
                                                        style={{ display: 'none' }}
                                                        onChange={(e)=>{
                                                            handleFileChange(e,index)
                                                        }}
                                                    />
                                                </AddImageButton>
                                            }
                                        </CustomTD>
                                    <CustomTD width={'10%'}>
                                        <IconContainer>
                                            <IcoMoon
                                                className={'cursor-pointer'}
                                                iconSet={IconSet}
                                                icon={'delete_new_outline'}
                                                size={20}
                                                onClick={() => {
                                                    deleteRow({index, id: item?.id || null})
                                                }}
                                            />
                                        </IconContainer>

                                    </CustomTD>
                                </tr>
                            })
                            : null
                        }
                        <tr>
                            <td colSpan="8" className={'text-center'}>
                                <FleetButton
                                    height={'43px'}
                                    width={'114px'}
                                    backgroundColor={'#FDF0E4'}
                                    color={'#F3994A'}
                                    fontSize={'14px'}
                                    radius={'32px'}
                                    onClick={addNewRow}
                                >
                                    &#43; Add new
                                </FleetButton>
                            </td>
                        </tr>

                        </tbody>
                    </StyledTable>
                </CardBody>
            </Card>

            <Card>
                <CardHeader className={'border-bottom-0'}>
                    <CustomHeader fontSize={'16px'}>
                        Parts
                    </CustomHeader>
                </CardHeader>
                <CardBody className={'overflow-auto'}>
                    <StyledTable className={'table-borderless'}>
                        <thead className={'header-white-text'}>
                        <tr>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'15%'} color={'#0000008C'}>
                                Part
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'15%'} color={'#0000008C'}>
                                Department
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'15%'} color={'#0000008C'}>
                                No.
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'15%'} color={'#0000008C'}>
                                Cost
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'10%'} color={'#0000008C'}>
                                Add to Report
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'10%'} color={'#0000008C'}>
                                Ordered
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'10%'} color={'#0000008C'}>
                            </StyledTableHeaderCell>

                        </tr>
                        </thead>
                        <tbody>
                        {
                            partsData.length ?
                                partsData.map((item,index)=>{
                                    return  <tr key={`3_${index}`}>
                                        <CustomTD className={'text-center'} width={'15%'}>
                                            <CustomInput radius={'8px'}
                                                         onChange={(e)=>{
                                                             changePartsData({
                                                                 index,
                                                                 key: 'part',
                                                                 value: e.target.value
                                                             })
                                                         }
                                                         }
                                                         border={isFieldInvalid('partsData',index, "part") ? '1px solid red' : '1px solid #C0D9FF59'}
                                                         value={item.part} />
                                        </CustomTD>
                                        <CustomTD width={'15%'}>
                                            <CustomDropdownComponent
                                                withoutDot
                                                border={isFieldInvalid('partsData',index, "department") ? '1px solid red' : '1px solid #C0D9FF59'}
                                                selectedTitle={item.department?.name}
                                                statusArrayKey={'departments'}
                                                handleChange={(value)=>{
                                                    const department = departments.find(item=>item.name === value)
                                                    changePartsData({
                                                        index,
                                                        key: 'department',
                                                        value: department
                                                    })
                                                }}
                                            />
                                        </CustomTD>
                                        <CustomTD className={'text-center'} width={'15%'}>
                                            <CustomInput radius={'8px'}
                                                         onChange={(e)=>{
                                                             changePartsData({
                                                                 index,
                                                                 key: 'part_number',
                                                                 value: e.target.value
                                                             })
                                                         }
                                                         }
                                                         border={'1px solid #C0D9FF59'}
                                                         value={item.part_number} />
                                        </CustomTD>
                                        <CustomTD className={'text-center'} width={'15%'}>
                                            <CustomInputWrapper>
                                                <CurrencySymbol>{CURRENCY}</CurrencySymbol>
                                                <CustomInput
                                                    radius={'8px'}
                                                    padding={'10px 10px 10px 30px'}
                                                    onChange={(e) => {
                                                        changePartsData({
                                                            index,
                                                            key: 'cost',
                                                            value: e.target.value,
                                                        });
                                                    }}
                                                    border={'1px solid #C0D9FF59'}
                                                    value={item.cost}
                                                    placeholder="Enter cost"
                                                />
                                            </CustomInputWrapper>
                                        </CustomTD>
                                        <CustomTD className={'text-center'} width={'10%'}>
                                            <CheckboxContainer checked={item?.added_to_report} onClick={()=>{
                                                changePartsData({
                                                    index,
                                                    key: 'added_to_report'
                                                })
                                            }}>
                                                <Checkmark checked={item?.added_to_report} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M20.285 6.285a1 1 0 00-1.415 0L10 15.585 5.715 11.3a1 1 0 00-1.415 1.415l5 5a1 1 0 001.415 0l10-10a1 1 0 000-1.415z" />
                                                </Checkmark>
                                            </CheckboxContainer>
                                        </CustomTD>
                                        <CustomTD className={'text-center'} width={'10%'}>
                                            <CustomCheckbox checked={item.status === 'completed'} onClick={()=>{
                                                changePartsData({
                                                    index,
                                                    key: 'status',
                                                    value: item.status === 'completed' ? 'to_do' : 'completed',
                                                });
                                            }
                                            }/>
                                        </CustomTD>
                                        <CustomTD width={'10%'}>
                                            <IconContainer>
                                                <IcoMoon
                                                    className={'cursor-pointer'}
                                                    iconSet={IconSet}
                                                    icon={'delete_new_outline'}
                                                    size={20}
                                                    onClick={() => {
                                                        deletePartsRow({index, id: item?.id || null})
                                                    }}
                                                />
                                            </IconContainer>

                                        </CustomTD>

                                    </tr>
                                })
                                : null
                        }
                        <tr>
                            <td colSpan="7" className={'text-center'}>
                                <FleetButton
                                    height={'43px'}
                                    width={'114px'}
                                    backgroundColor={'#FDF0E4'}
                                    color={'#F3994A'}
                                    fontSize={'14px'}
                                    radius={'32px'}
                                    onClick={addNewPartsRow}
                                >
                                    &#43;  Add new
                                </FleetButton>
                            </td>
                        </tr>
                        </tbody>
                    </StyledTable>
                </CardBody>
            </Card>
        </>
    )
}