import React, {useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from "react-router-dom";
import axios from "../../../config/axios";
import * as constants from "../../../constants";

import QuotePreviewComponent from "@pages/components/JobDetailsComponents/QuotePreviewComponent";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import QuotePreviewComponentForPrint from "@pages/components/JobDetailsComponents/QuotePreviewComponentForPrint";
import {Notification} from "@pages/components/Notification";
import NotificationAlert from "react-notification-alert";

export default function VehicleQuotePreview() {
    const location = useLocation();
    const history = useHistory();
    const notificationAlert = useRef(null);
    const [loading,setLoading] = useState(true);
    const [generating,setGenerating] = useState(false);
    const [data,setData] = useState([]);
    const [inspectionData,setInspectionData] = useState([]);
    const [damages,setDamages] = useState([]);
    const [vehicle,setVehicle] = useState({});
    const [quote,setQuote] = useState({
        sum: null,
        discount: null,
        total: null
    });
    const { vehicleId, quoteId = null } = location.state || {};
    const calculateSum = () => {
        const dataSum = data.reduce((acc, item) => {
            const cost = parseFloat(item.cost);
            return acc + (isNaN(cost) ? 0 : cost);
        }, 0);


        return dataSum;
    };
    useEffect(() => {
        const sum = calculateSum();
        const total = calculateTotalWithDiscount(sum,quote.discount)
        setQuote({...quote,sum:sum,total:total })
    }, [data]);

    useEffect(() => {
        if (quoteId) {
            axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quote-details`,
                params: {
                    quoteId,
                },
            }).then(res=>{
                const q = res.data.data
                fetchData(q.vehicle_id,q.fleet_id)
                setQuote(q)
                setData(q.work.filter(item=>item.added_to_report))
            }).catch(err=>{

            })

        }
    },[quoteId])
    const fetchData = (vehicleId,fleetId) => {
        axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-vehicle`,
            params: {
                vehicleId,
                fleetId,
            }
        }).then(res=>{
            const tempData = res.data.data
            axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/get-required-inspection`,
            }).then(resource=>{
                setDamages(tempData.damages)
                let requiredData = resource.data.data;
                requiredData = requiredData.map(item => {
                    item.list = item.list.map(listItem => {
                        tempData.requiredInspectionResult.map(res=>{
                            if (res?.required_inspection_id === listItem.id) {
                                listItem.option_id = res.id;
                                listItem.required = res?.required === 1;
                            }
                        })
                        return listItem;
                    });
                    return item;
                });
                setInspectionData(requiredData)
                setLoading(false)
            }).catch(err=>{
                setLoading(false)
            })
        }).catch(err=>{
            setLoading(false)
        })
    }
    useEffect(()=>{
        if (vehicleId) {
            try {
                axios({
                    method: 'GET',
                    url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quote-vehicle`,
                    params: {
                        vehicleId,
                    },
                }).then(res=>{
                    const data = res.data.data
                    setVehicle(data.vehicle)
                }).catch(err=>{
                })

            } catch (error) {
                setLoading(false)
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                }
            }
        }
    },[vehicleId])

    const backToList = () => {
        history.goBack();
    }
    const calculateTotalWithDiscount = (sum, discount = 0) => {
        return sum + (sum * (discount / 100));
    };
    const handleGeneratePDF = () => {
        const input = document.getElementById('pdf-content'); // The content you want to convert to PDF

        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png',0.5);

            const pdf = new jsPDF('p', 'mm', [220, 500]);  // Custom height (increase the height to 400mm)
            let pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgWidth = pdfWidth;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;

            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
            }

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('generated-pdf.pdf');
        });
    };
    const generateQuote = async () => {
        const response = await axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/generate-quote`,{
            id: quote?.id
        })
        if (response.status === 200)
        {
            Notification('success', 'Success', 'Successfully Created ',notificationAlert);
            setGenerating(false)
        } else {
            setGenerating(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    }
    const sendPdf = () => {
        setGenerating(true)
        const input = document.getElementById('pdf-content'); // The content you want to convert to PDF
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png',0.5);

            const pdf = new jsPDF('p', 'mm', [220, 500]);
            let pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgWidth = pdfWidth;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;

            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
            }

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            const pdfBlob = pdf.output('blob');

            const formData = new FormData();

            const pdfFileName = `vehicle_${vehicleId}_generated.pdf`;
            formData.append('vehicle_id', vehicleId);
            formData.append('email', vehicle?.email);
            formData.append('pdf', pdfBlob, pdfFileName);
            formData.append('type', 'repair_report');
            axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/save-report`,
                data: formData
            }).then(res=>{
                generateQuote()
            }).catch(err=>{
                setGenerating(false)
                Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
            })
        });
    };

    return (
        loading ? <></>
            :
            <div className={'container-fluid mt-3'}>
                   <NotificationAlert ref={notificationAlert}/>
                    <QuotePreviewComponent
                        handleGeneratePDF={handleGeneratePDF}
                        sendPdf={sendPdf}
                        generating={generating}
                        data={data}
                        quote={quote}
                        vehicle={vehicle}
                        backToList={backToList}/>
                    <QuotePreviewComponentForPrint
                        data={data}
                        quote={quote}
                        vehicle={vehicle}
                        inspectionData={inspectionData}
                        damages={damages}
                        parsable={true}/>

            </div>
    )
}