import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import {
    hasPermission
} from 'views/policies/DocumentPolicy';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function CustomPdfsList() {
    const notificationAlert = useRef(null);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState([]);

    const notify = (type, header, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        notificationAlert.current.notificationAlert(options);
    };

    useEffect(() => {

        const source = axios.CancelToken.source()

        const fetchData = async () => {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${constants.API_URL}/get-custom-documents`,

                    cancelToken: source.token,
                })

                setDocuments(response.data)

                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } catch (error) {
                setLoading(false)
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                    notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
                }
            }
        }

        fetchData()

        return () => {
            source.cancel()
        }
    }, [])

    const addDocument = () => {
        history.push({
            pathname: `/admin/settings/edit-pdf`
        })
    }

    const goEdit = (documentId) => {
        history.push({
            pathname: `/admin/settings/edit-pdf/${documentId}`
        })
    }
    const handleFileFromUrl = async (fileUrl = 'https://api.grsmotorgroup.co.uk/generated.pdf',documentName = 'downloaded-file.pdf') => {

        try {
            const response = await fetch(fileUrl);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();

            const file = new File([blob], documentName, { type: blob.type });

            return file;

        } catch (error) {
            console.error("Error fetching the file from URL:", error);
        }
    };
    const previewCustomDocument = async ({url,id}) => {
        const file = await handleFileFromUrl(url)
        const formData = new FormData();
        formData.append("pdf", file);
        formData.append("id", id);

        try {
            const response = await axios.post(`${constants.API_CORE_URL}/preview-created-document`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType: "blob",
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "processed-document.pdf");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Upload failed", error);
        }
    }
    const previewCustomDocuments = async (documentId) => {

            try {
                const response = await axios.get(`${constants.API_CORE_URL}/preview-created-document/${documentId}`);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "processed-document.pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error("Upload failed", error);
            }
    }
    return (
        <>
            <CardsHeader name="Settings" parentName="Document Management" currentName="List" />
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Container className="mt--5 admin-main-body" fluid >
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs={8}>
                                        <h1 className="mb-0">Documents</h1>
                                        <p className="text-sm mb-0">You can manage your own documents and contracts.</p>
                                    </Col>
                                    <Col xs={4} className="text-right">
                                        <ButtonContainer onClick={() => addDocument()}>Add Document</ButtonContainer>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <TableCustom
                                    loading={loading}
                                    items={documents}
                                    keyField={`id`}
                                    searchField={false}
                                    columns={[
                                        {
                                            dataField: "document_name",
                                            text: "Name",
                                            sort: true
                                        },
                                        {
                                            dataField: "description",
                                            text: "Description",
                                            sort: true
                                        },

                                        {
                                            dataField: "",
                                            text: "Action",
                                            formatter: (rowContent, row) => {
                                                return (
                                                    <>
                                                        {
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    className="btn-icon-only text-light"
                                                                    color=""
                                                                    role="button"
                                                                    size="sm"
                                                                >
                                                                    <i className="fas fa-ellipsis-v" />
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                                    <DropdownItem onClick={() => goEdit(row.id)}
                                                                    >
                                                                        Edit
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => previewCustomDocument({url:row.url,id:row.id})}
                                                                    >
                                                                        Preview
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>}
                                                    </>
                                                )
                                            }
                                        }
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}