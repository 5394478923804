import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as taskActions from '@redux/Lead/actions';
import NotificationAlert from 'react-notification-alert';
import axios from 'axios';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import Pagination from 'react-js-pagination';
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { deletee, hasPermission } from 'views/policies/LeadPolicy';

import classnames from 'classnames';
import moment from 'moment';
import Filter from '@pages/lead/Filter';
import useNotify from '../../../hooks/notify';
import ConfirmModal from '@pages/lead/ConfirmModal';
import {GET_VEHICLE_MAKES_REQUEST} from "@redux/Vehicle/actions";
import vehicle from "@redux/Vehicle/reducer";
import {SelectWithoutInput} from "@pages/components/InputSelect";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

export default function Lead() {
  // const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const [leads, setLeads] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [items, setItems] = useState('');
  const [selectedLead, setSelectedLead] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [modelValue, setModelValue] = useState('')
  const [makeValue, setMakeValue] = useState('')
  const [models, setModels] = useState([])
  const { ref, notify } = useNotify();
  const dispatch = useDispatch();
  const history = useHistory();

  const profilePermissions = useSelector((state) => state.getState).permissions;
  const authUserTeam = useSelector(state => state.getState).authUserTeam;
  const leadState = useSelector((state) => state.leadState, shallowEqual);
  const {vehicleMakes} = useSelector((state) => state.vehicle, shallowEqual);
  const {
    getLeadSuccess,
    getLeadError,
    getLeadErrorMessage,
    deleteLeadError,
    deleteLeadErrorMessage,
    deleteLeadSuccess,
    wonLeadErrorMessage,
    wonLeadSuccess,
    wonLeadError,
    lostLeadErrorMessage,
    lostLeadSuccess,
    lostLeadError,
  } = useSelector((state) => state.leadState, shallowEqual);

  useEffect(() => {
    if (getLeadError) {
      notify(getLeadErrorMessage, 'warning', 'Warning');
    }
  }, [getLeadError]);

  useEffect(() => {
    if (deleteLeadError) {
      notify(deleteLeadErrorMessage, 'warning', 'Warning');
    }
  }, [deleteLeadError]);

  useEffect(() => {
    if (wonLeadError) {
      notify(wonLeadErrorMessage, 'warning', 'Warning');
    }
  }, [wonLeadError]);

  useEffect(() => {
    if (lostLeadError) {
      notify(lostLeadErrorMessage, 'warning', 'Warning');
    }
  }, [lostLeadError]);

  useEffect(() => {
    let savedPageNumber = 1;
    let savedFilter = '';

    // let grs_token = Cookies.get('grs_token');
    // setToken(grs_token);
    // if (localStorage.getItem('grms_lead_page_number') != null) {
    //   savedPageNumber = localStorage.getItem('grms_lead_page_number');
    // }
    // if (localStorage.getItem('grms_lead_search_filter')) {
    //   savedFilter = JSON.parse(localStorage.getItem('grms_lead_search_filter'));
    //   setFilter(savedFilter);
    // }
    dispatch({
      type: GET_VEHICLE_MAKES_REQUEST,
    });
    const source = axios.CancelToken.source();
    fetchData(savedPageNumber, savedFilter);

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (getLeadSuccess) {
      setFilter({
        ...leadState.filter,
        from: leadState.filter.from
          ? moment(leadState.filter.from).format('YYYY-MM-DD')
          : '',
        to: leadState.filter.to
          ? moment(leadState.filter.to).format('YYYY-MM-DD')
          : '',
      });
      setLeads(leadState.leads.data);
      setItems(leadState.leads);

      if (!authUserTeam) {
        setDealers(leadState.dealers);
      }

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [getLeadSuccess]);

  useEffect(() => {
    if (deleteLeadSuccess) {
      setConfirmModal(false);
      notify('Deleted Successfully', 'success', 'success');
      fetchData(items?.current_page ?? 1, filter);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [deleteLeadSuccess]);

  useEffect(() => {
    if (wonLeadSuccess) {
      setLeads(leadState.leads.data);
      notify('Lead was successfully marked won!', 'success', 'success');
    }
  }, [wonLeadSuccess]);

  useEffect(() => {
    if (lostLeadSuccess) {
      setLeads(leadState.leads.data);
      notify('Lead was successfully marked lost!', 'success', 'success');
    }
  }, [lostLeadSuccess]);

  const fetchData = (pageNumber = 1, filter) => {
    // localStorage.setItem('grms_lead_page_number', pageNumber);
    // localStorage.setItem('grms_lead_search_filter', JSON.stringify(filter));
    setLoading(true);
    dispatch({
      type: taskActions.GET_LEADS_REQUEST,
      data: {
        ...filter,
        page: pageNumber,
      },
    });

  };

  const resetLeads = () => {
    setFilter('');
    // localStorage.removeItem('grms_lead_page_number');
    // localStorage.removeItem('grms_lead_search_filter');

    // const source = axios.CancelToken.source();
    fetchData(1, '');
  };

  const deleteLead = () => {
    dispatch({
      type: taskActions.DELETE_LEAD_REQUEST,
      uuid: selectedLead.uuid,
    });
  };

  const editLead = (leadId) => {
    history.push({
      pathname: '/admin/leads/' + leadId + '/edit',
    });
  };

  const markWonLead = (leadId) => {
    dispatch({
      type: taskActions.WON_LEAD_REQUEST,
      id: leadId,
    });
  };

  const markLostLead = (leadId) => {
    dispatch({
      type: taskActions.LOST_LEAD_REQUEST,
      id: leadId,
    });
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
    },
    {
      dataField: 'created_at',
      text: 'Date',
      sort: true,
      formatter: (rowContent, row) => {
        return constants.formatDateFullGB(row.created_at);
      },
    },
    {
      dataField: 'assignedTo',
      text: 'Assigned to',
      sort: true,
      formatter: (rowContent, row) => {
        return row.assigned_to && row.assigned_to.name
          ? row.assigned_to.name
          : '-';
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (rowContent, row) => {
        return leadState.leadsStatuses[row.status];
      },
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      formatter: (rowContent, row) => {
        return leadState.leadsTypes[row.lead_type];
      },
    },
    {
      dataField: 'dealer',
      text: !authUserTeam ? 'Dealer' : '',
      sort: true,
      formatter: (rowContent, row) => {
        if (!authUserTeam) {
          return row?.dealer_id ? dealers[row.dealer_id] : '-';
        }
      },
    },
    {
      dataField: '',
      text: hasPermission(profilePermissions, 'access-applications') ? 'Action' : '',
      formatter: (rowContent, row) => {
        if (hasPermission(profilePermissions, 'access-applications')) {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  color=""
                  role="button"
                  size="sm"
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem onClick={() => editLead(row.uuid)}>
                    View
                  </DropdownItem>
                  <DropdownItem onClick={() => markWonLead(row.uuid)}>
                    Mark Won
                  </DropdownItem>
                  <DropdownItem onClick={() => markLostLead(row.uuid)}>
                    Mark Lost
                  </DropdownItem>
                  {deletee(profilePermissions, authUserTeam, row) && (
                    <DropdownItem
                      onClick={() => {
                        setSelectedLead(row);
                        setConfirmModal(true);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        }
      },
    },
  ];
  const setMakeFilter = (e) => {
    setModels(e.vehicle_models)
    setMakeValue(e.name)
    setModelValue('')
    setFilter({
      ...filter,
      model: null,
    })
    let tempFilter = filter
    tempFilter.model = null
    setFilter(tempFilter)
  }
  const setModelFilter = async (e) => {
    setModelValue(e.name)
    let tempFilter = filter
    tempFilter.vehicle_model_id = e.id
    setFilter(tempFilter)
    fetchData(1,tempFilter)
  }
  return (
    <>
      <CardsHeader
        name="Lead Applications"
        parentName="Leads Management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={ref} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Leads</h1>
                  </div>
                </div>
                <Filter showStatusFilter={false} showTypeFilter={false} fetchData={fetchData} resetLeads={resetLeads} />
                <div className={'ml-3 d-flex flex-wrap g-10'}>
                  <Col className='align-self-center' lg={3} md={4} sm={12}>
                    <SelectWithoutInput
                        showClear={true}
                        label={makeValue ? makeValue : `Make`}
                        onSelect={setMakeFilter}
                        options={vehicleMakes}
                        defaultOption={true}
                        labelKey={`name`}
                        valueKey={`id`}
                        imageLink={'directions_car_grey.png'}
                    />
                  </Col>
                  <Col className='align-self-center' lg={3} md={4} sm={12}>
                    <SelectWithoutInput
                        showClear={true}
                        label={modelValue ? modelValue : `Model`}
                        onSelect={(e) => {
                          setModelFilter(e)
                        }}
                        options={models}
                        defaultOption={true}
                        labelKey={`name`}
                        valueKey={`id`}
                        imageLink={'loyalty.png'}
                    />
                  </Col>
                </div>
              </CardHeader>
              <CardBody>
                <React.Fragment>
                  <div className="py-4 table-responsive">
                    {!loading && leads.length > 0 ? (
                      <div className="react-bootstrap-table">
                        <table className="table">
                          <thead>
                            <tr>
                              {columns &&
                                columns.length > 0 &&
                                columns.map((column, index) => {
                                  return (
                                    <th
                                      tabIndex="0"
                                      key={index}

                                      style={
                                        column.headerStyle
                                          ? column.headerStyle
                                          : {}
                                      }
                                    >
                                      {column.text}
                                    </th>
                                  );
                                })}
                            </tr>
                          </thead>
                          <tbody>
                            {leads &&
                              leads.length > 0 &&
                              leads.map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className={classnames({
                                      'empty-assignees-background': item.assigned_to == null,
                                      'alert-no-contact': item.status === 'new',
                                      'no-contact alert-danger':
                                        item.status === 'new' &&
                                        moment().diff(item.created_at, 'days') >
                                          2,
                                    })}
                                  >
                                    {columns &&
                                      columns.length > 0 &&
                                      columns.map((column, index2) => {
                                        return (
                                          <td
                                            key={index2}
                                            style={
                                              column.style ? column.style : {}
                                            }
                                          >
                                            {column.formatter
                                              ? column.formatter('', item)
                                              : item[column.dataField]}
                                          </td>
                                        );
                                      })}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                      />
                    )}
                  </div>
                  {items && leads.length > 0 && (
                    <Row className="react-bootstrap-table-pagination">
                      <Col lg={6}>
                        <p className="text-sm text-black">
                          {`Showing rows ${
                            (items.current_page - 1) * items.per_page + 1
                          } to ${
                            items.per_page * items.current_page > items.total
                              ? items.total
                              : items.per_page * items.current_page
                          } of ${items.total}`}
                        </p>
                      </Col>
                      <Col
                        lg={6}
                        className="react-bootstrap-table-pagination-list"
                      >
                        <Pagination
                          activePage={items.current_page}
                          hideDisabled
                          totalItemsCount={items.total}
                          itemsCountPerPage={items.per_page}
                          onChange={(pageNumber) =>
                            fetchData(pageNumber, filter)
                          }
                          itemClass="page-item"
                          linkClass="page-link"
                          innerClass="pagination react-bootstrap-table-page-btns-ul"
                        />
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ConfirmModal
          confirmModal={confirmModal}
          setConfirmModal={setConfirmModal}
          deleteLead={deleteLead}
        />
      </Container>
    </>
  );
}
