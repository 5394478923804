import React, {useEffect, useState} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {
    CustomArea,
    CustomHeader,
    CustomInput,
    CustomTextContainer,
    FleetButton
} from "@pages/new-design-components/custom-components";
import axios from "axios";
import * as constants from "../../../../../constants";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";
import CustomRadio from "@pages/components/JobDetailsComponents/CustomRadio";
import {parseToWord} from "../../../../../constants";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {ButtonContainer} from "@pages/reusable-components/styled-components";


export default function CreateTemplateModal({isOpen, localData = null,  closeModal, selectReminder}) {



    return (
        <Modal
            isOpen={isOpen}
            toggle={closeModal}
            modalClassName={'slide-in-from-right'}
            backdrop={false}
            className={'modal-dialog-right modal-responsible'}
        >

            <ModalHeader toggle={closeModal} className={'bg-white centered-title'}>
                <CustomHeader weight={'600'} fontSize={'14px'} color={'#74787E'}>
                    Choose template
                </CustomHeader>
            </ModalHeader>
            <ModalBody>

                {
                        localData.map((reminder,index) => {
                            return <div>
                                <div className={'d-flex justify-content-between'}>
                                    <CustomHeader marginBottom={'6px'} fontSize={'14px'} weight={'400'} color={'#000'}>
                                        {reminder?.subject ? parseToWord(reminder?.subject) : '-'}
                                    </CustomHeader>
                                    <div className={'cursor-pointer'}>
                                        <CustomHeader color={'#3F8CFF'} fontSize={'14px'} weight={'600'} onClick={()=>{selectReminder(index)}}>
                                            Choose
                                        </CustomHeader>
                                    </div>
                                </div>
                                <CustomHeader marginBottom={'6px'} fontSize={'14px'} weight={'400'} color={'#000'}>
                                    {parseToWord(reminder?.message)}
                                </CustomHeader>
                            </div>
                        })
                }

            </ModalBody>

        </Modal>
    )
}