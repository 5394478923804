export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';

export const SEND_SYSTEM_NOTE_REQUEST = 'SEND_SYSTEM_NOTE_REQUEST';
export const SEND_SYSTEM_NOTE_SUCCESS = 'SEND_SYSTEM_NOTE_SUCCESS';
export const SEND_SYSTEM_NOTE_ERROR = 'SEND_SYSTEM_NOTE_ERROR';

export const GET_SYSTEM_NOTES_REQUEST = 'GET_SYSTEM_NOTES_REQUEST';
export const GET_SYSTEM_NOTES_SUCCESS = 'GET_SYSTEM_NOTES_SUCCESS';
export const GET_SYSTEM_NOTES_ERROR = 'GET_SYSTEM_NOTES_ERROR';

export const ADD_SYSTEM_REMINDER_REQUEST = 'ADD_SYSTEM_REMINDER_REQUEST';
export const ADD_SYSTEM_REMINDER_SUCCESS = 'ADD_SYSTEM_REMINDER_SUCCESS';
export const ADD_SYSTEM_REMINDER_ERROR =   'ADD_SYSTEM_REMINDER_ERROR';

export const EDIT_SYSTEM_REMINDER_REQUEST = 'EDIT_SYSTEM_REMINDER_REQUEST';
export const EDIT_SYSTEM_REMINDER_SUCCESS = 'EDIT_SYSTEM_REMINDER_SUCCESS';
export const EDIT_SYSTEM_REMINDER_ERROR =   'EDIT_SYSTEM_REMINDER_ERROR';

export const DELETE_SYSTEM_REMINDER_REQUEST = 'DELETE_SYSTEM_REMINDER_REQUEST';
export const DELETE_SYSTEM_REMINDER_SUCCESS = 'DELETE_SYSTEM_REMINDER_SUCCESS';
export const DELETE_SYSTEM_REMINDER_ERROR =   'DELETE_SYSTEM_REMINDER_ERROR';

export const GET_SYSTEM_REMINDER_REQUEST = 'GET_SYSTEM_REMINDER_REQUEST';
export const GET_SYSTEM_REMINDER_SUCCESS = 'GET_SYSTEM_REMINDER_SUCCESS';
export const GET_SYSTEM_REMINDER_ERROR =   'GET_SYSTEM_REMINDER_ERROR';

export const GET_CUSTOMERS_DEBT_REQUEST = 'GET_CUSTOMERS_DEBT_REQUEST';
export const GET_CUSTOMERS_DEBT_SUCCESS = 'GET_CUSTOMERS_DEBT_SUCCESS';
export const GET_CUSTOMERS_DEBT_ERROR =   'GET_CUSTOMERS_DEBT_ERROR';

export const GET_DEBT_STATS_REQUEST = 'GET_DEBT_STATS_REQUEST';
export const GET_DEBT_STATS_SUCCESS = 'GET_DEBT_STATS_SUCCESS';
export const GET_DEBT_STATS_ERROR =   'GET_DEBT_STATS_ERROR';

export const GET_DEBT_CUSTOMERS_REQUEST = 'GET_DEBT_CUSTOMERS_REQUEST';
export const GET_DEBT_CUSTOMERS_SUCCESS = 'GET_DEBT_CUSTOMERS_SUCCESS';
export const GET_DEBT_CUSTOMERS_ERROR =   'GET_DEBT_CUSTOMERS_ERROR';

export const GET_CUSTOMER_DEBT_REQUEST = 'GET_CUSTOMER_DEBT_REQUEST';
export const GET_CUSTOMER_DEBT_SUCCESS = 'GET_CUSTOMER_DEBT_SUCCESS';
export const GET_CUSTOMER_DEBT_ERROR =   'GET_CUSTOMER_DEBT_ERROR';

export const UPDATE_CUSTOMER_DEBT_REQUEST = 'UPDATE_CUSTOMER_DEBT_REQUEST';
export const UPDATE_CUSTOMER_DEBT_SUCCESS = 'UPDATE_CUSTOMER_DEBT_SUCCESS';
export const UPDATE_CUSTOMER_DEBT_ERROR =   'UPDATE_CUSTOMER_DEBT_ERROR';

export const GET_COMMUNICATION_LOGS_REQUEST = 'GET_COMMUNICATION_LOGS_REQUEST';
export const GET_COMMUNICATION_LOGS_SUCCESS = 'GET_COMMUNICATION_LOGS_SUCCESS';
export const GET_COMMUNICATION_LOGS_ERROR =   'GET_COMMUNICATION_LOGS_ERROR';