import React, {Fragment, useMemo} from 'react'
import {
    CustomArea,
    CustomBadge,
    CustomContainer,
    CustomHeader, CustomInput, FleetButton,
    IconContainer, ProfileImage,
    RequiredItemsBadge
} from "@pages/new-design-components/custom-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import ThreeDots from "@assets/images/mi_options-vertical.svg";
import {formatDateToText, formatTimestamp, getHours} from "../../../../constants";
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";

export default function MessagingComponent({notes,newMessage,setNewMessage,sendMessage,setError,error,closeComponent}) {

    const groupedData = useMemo(() => {
        return notes.reduce((acc, curr) => {
            const date = curr.created_at.split('T')[0];

            if (!acc[date]) {
                acc[date] = [];
            }

            acc[date].push(curr);

            return acc;
        }, {});
    }, [notes]);
    return (
        <CustomContainer>
             <div className={'d-flex g-10'}>
                 <BackActionComponent backAction={closeComponent} text={''}/>
                 <CustomHeader weight={'700'} color={'#000'} fontSize={'20px'}>
                     Send New Text
                 </CustomHeader>
             </div>
            <CustomContainer backgroundColor={'#FFF'} borderRadius="16px" padding="16px">
                <div className={'d-flex align-items-center justify-content-between messaging-underline mb-3'}>
                    <div className={'d-flex g-5'}>
                        <img src="" width={'40px'} alt=""/>
                        <div>
                            <CustomHeader weight={'700'} fontSize={'16px'} lineHeight={'10px'} color={'#000000'}>Customer Name</CustomHeader>
                            <CustomHeader weight={'400'} fontSize={'14px'} lineHeight={'20px'} color={'#000000'}>+78854877878</CustomHeader>
                        </div>
                    </div>
                    <div className={'d-flex align-items-center g-5'}>
                        <IconContainer width={'40px'} height={'40px'} backgroundColor={'#C0D9FFC7'}>
                            <IcoMoon
                                className={'cursor-pointer '}
                                iconSet={IconSet}
                                icon={'ic_search'}
                                size={20}
                                onClick={()=>{}}
                            />
                        </IconContainer>
                        <IconContainer width={'40px'} height={'40px'} backgroundColor={'#C0D9FFC7'}>
                            <img className={'cursor-pointer '} src={ThreeDots} width={'25px'} />
                        </IconContainer>

                    </div>

                </div>
                <>

                    {
                        Object.keys(groupedData).map((date,idx)=>{
                            return  <div>
                                <div className={'d-flex align-items-center'}>
                                    <div className={'w-100 messaging-underline'}/>
                                    <CustomContainer width={'max-content'} className={'text-nowrap'} border={'1px solid #0000008C'} borderRadius="24px" padding="4px 16px">
                                        <CustomHeader weight={'400'} color={'#0000008C'} fontSize={'14px'}>
                                            {formatDateToText(date)}
                                        </CustomHeader>
                                    </CustomContainer>
                                    <div className={'w-100 messaging-underline'}/>
                                </div>
                                {
                                    groupedData[date].map((item,index)=>{
                                        return <Fragment key={`nt_${idx}_${index}`}>
                                            <CustomContainer backgroundColor={'#FFF'} borderRadius="8px" padding="10px 16px" margin={'6px 0px 6px 0px'}>
                                                <div  className={'d-flex justify-content-between'}>
                                                    <div>
                                                        <div className={'d-flex g-5'}>
                                                            <CustomHeader weight={'600'} color={'#000'} fontSize={'14px'}>{item.noteable?.name || item.noteable?.full_name}</CustomHeader>
                                                            <CustomHeader weight={'400'} color={'#979797'} fontSize={'14px'}>{getHours(item.created_at)}</CustomHeader>
                                                        </div>
                                                        <CustomHeader weight={'400'} color={'#979797'} fontSize={'14px'}>{item.note}</CustomHeader>
                                                    </div>
                                                </div>

                                            </CustomContainer>
                                        </Fragment>
                                    })

                                }
                            </div>
                        })
                    }

                    <div className={'d-flex g-10'}>
                        <form
                            className={'w-100'}
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (newMessage?.message.length > 3) {
                                    sendMessage();
                                    setError({...error,message: {show: false,message: ''}})
                                } else {
                                    setError({...error,message: {show: true,message: 'Too short. Please write more than 3 letters'}})
                                }
                            }}
                        >
                            <CustomContainer className={'text-right'} padding={'5px'} border={'1px solid #0000008C'} borderRadius={'8px'}>
                                <CustomArea
                                    onChange={(e) => {
                                        setNewMessage({
                                            ...newMessage,
                                            message: e.target.value
                                        });
                                    }}
                                    radius={'0px'}
                                    borderBottom={'1px solid #0000008C'}
                                    value={newMessage?.message}
                                />
                                <FleetButton     height={'43px'}
                                                 width={'154px'}
                                                 backgroundColor={'#F3994A'}
                                                 color={'#fff'}
                                                 fontSize={'14px'}
                                                 radius={'8px'}
                                                 type={'submit'}
                                >
                                    Send
                                </FleetButton>
                            </CustomContainer>
                            {error.message.show && <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}> {error.message.message}</div>}

                        </form>
                    </div>
                </>
            </CustomContainer>
        </CustomContainer>

    )
}