import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
    CardHeader,
    Row,
    Col
} from "reactstrap";

import TableCustom from 'views/pages/components/TableCustom';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import { getDocument } from '@api/email-document.api';
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import {useHistory} from "react-router-dom";
import * as emailTemplateActions from "@redux/Email/actions";

export default function EmailsTab({customerId,showSendButton = true}) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [customerEmails, setCustomerEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const emailTemplateState = useSelector(
        (state) => state.emailTemplateState,
        shallowEqual
    );

    useEffect(() => {
        if (customerId) {
            setLoading(true);
            dispatch({
                type: emailTemplateActions.GET_RESOURCE_EMAILS_REQUEST,
                payload: {
                    resource: 'customer',
                    id: customerId
                }
            });
        }
    }, [customerId]);

    useEffect(() => {
        if (
            emailTemplateState.resourceEmails
        ) {
            setCustomerEmails(emailTemplateState.resourceEmails)
            setLoading(false);
        }
    }, [emailTemplateState.resourceEmails]);


    const loadEmailItems = (type) => {
        if (selectedDetails.recipients && selectedDetails.recipients.filter((item) => item.recipient_type === type).length === 0) {
            return <p>-</p>
        }

        return selectedDetails.recipients && selectedDetails.recipients.filter((item) => item.recipient_type === type).map((i) => {
            return (
                <p key={i.id}>{i.email_address}</p>
            )
        })
    }

    return (
        <>
            <CardHeader>
                <div className="d-flex justify-content-between">
                    <div>
                        <h2 className="title mb-0">Emails</h2>
                    </div>
                    <Col className={'text-right'}>
                        {customerId && showSendButton &&
                        <ButtonContainer
                            onClick={() =>
                                history.push(`/admin/send-email?resource=customer&id=${customerId}`)
                            }> Send Email</ButtonContainer>
                        }
                    </Col>
                </div>
            </CardHeader>
            {customerEmails.length > 0 ? <TableCustom
                    loading={loading}
                    items={customerEmails}
                    keyField={`task`}
                    searchField={true}
                    columns={[
                        {
                            dataField: "subject",
                            text: "Subject"
                        },
                        {
                            dataField: "subject",
                            text: "Sent By",
                            formatter: (rowContent, row) => {
                                return row?.user?.name;
                            }
                        },
                        {
                            dataField: "created_at",
                            text: "Sent At",
                            sort: true,
                            formatter: (rowContent, row) => {
                                return '-'
                            }
                        },
                        {
                            dataField: "opened_at",
                            text: "Opened At",
                            sort: true,
                            formatter: (rowContent, row) => {
                                const result = row.events && row.events.find(element => {
                                    return element.event_type === 'open';
                                });

                                return '-'
                            }
                        },
                        {
                            dataField: "text",
                            text: "",
                            formatter: (rowContent, row) => {
                                return (
                                    <>
                                        <Button className="h-50 btn btn-sm" color="success" type="button" onClick={() => {
                                            setSelectedDetails(row)
                                            setIsOpen(true)
                                        }}>Details</Button>
                                    </>
                                )
                            }
                        }
                    ]}
                /> :
                <h3>No emails sent yet!</h3>}
            <Modal
                isOpen={isOpen}
                toggle={() => setIsOpen(false)}
                className="modal-dialog-centered modal-lg"
            >
                <div className="modal-header">
                    <h6 className="modal-title">Email details</h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setIsOpen(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <dl className="row">
                        <dt className="col-sm-3">Subject</dt>
                        <dd className="col-sm-9">{selectedDetails.subject}</dd>

                        <dt className="col-sm-3">Body</dt>
                        <dd style={{overflowWrap: "break-word"}} className="col-sm-9 shadow">
                            <div dangerouslySetInnerHTML={{
                                __html: selectedDetails.message
                            }} />
                        </dd>

                        {selectedDetails?.attachments?.length > 0 &&
                        <>
                            <dt className="col-sm-3">Attachments</dt>
                            <dd className="col-sm-9">
                                <Row>
                                    {selectedDetails.attachments.map((item, index) => {
                                        return (
                                            <Col sm={12} key={index}>
                                                <a href={item.url} onClick={(e) => {
                                                    e.preventDefault();
                                                    getDocument(item.newUuid, item.originalName, selectedDetails.id)
                                                }}>{item.originalName}</a>
                                            </Col>
                                        )
                                    })
                                    }
                                </Row>
                            </dd>
                        </>
                        }
                        <dt className="col-sm-3">TO</dt>
                        <dd className="col-sm-9">{loadEmailItems('to')}</dd>

                        <dt className="col-sm-3">CC</dt>
                        <dd className="col-sm-9">{loadEmailItems('cc')}</dd>

                        <dt className="col-sm-3">BCC</dt>
                        <dd className="col-sm-9">{loadEmailItems('bcc')}</dd>

                        <dt className="col-sm-3">Stats</dt>
                        <dd className="col-sm-9">
                            {selectedDetails.events &&
                            <>
                                <dl className="row">
                                    <dt className="col-sm-4">Email Address</dt>
                                    <dd className="col-sm-6">Event</dd>
                                    <dd className="col-sm-2">Occurred At</dd>
                                </dl>
                                <hr />
                            </>}
                            {
                                selectedDetails.events && selectedDetails.events.map(item => {
                                    return (
                                        <dl className="row" key={item.id}>
                                            <dt className="col-sm-4">{item.email_address}</dt>
                                            <dd className="col-sm-6">{item.event_type}</dd>
                                            <dd className="col-sm-2">{'-'}</dd>
                                        </dl>
                                    )
                                })
                            }
                        </dd>
                    </dl>
                </div>
                <div className="modal-footer">
                    <Button
                        className="w-100"
                        color="danger"
                        type="button"
                        onClick={() => {
                            setSelectedDetails(false);
                            setIsOpen(false)
                        }
                        }
                    >
                        {`Close`}
                    </Button>
                </div>
            </Modal>
        </>
    );
}