import React, { useState, useMemo } from 'react'
import styled from "styled-components";
import {
    Col,

} from 'reactstrap'

import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";

import { hasRole } from 'views/policies/PermissionPolicy';
import { useSelector } from 'react-redux';
import CustomSearch from "@pages/components/CustomSearch";
import {
     FleetButton,
    StyledTable,
    StyledTableHeader,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import {SortableTableItem} from "@pages/new-design-components/NewDrag/SortableItem";
import {StaticTableRow} from "@pages/new-design-components/NewDrag/StaticRow";

export const CalendarInput = styled.input`
  cursor: pointer;
  text-align: center;
  align-items: center;
  border: 1px solid #F8F8F9;
  gap: 10px;
  font-size: 1rem;
  padding: 10px;
  color: #808087;
  outline: none;
`

export default function DepartmentsTable({
                                             setOrder,
                                             setData,
                                             tableData,
                                             keys,
                                             searchPlaceHolder = 'Search',
                                             onSearchChange,
                                             searchValue,
                                             checkInCreate,
                                             goToDetails,
                                             quickView,
                                             removeItem,
                                             createQuote,
                                             isAdmin,
                                             showAddNew = true,
                                             checkIn,
                                             checkTasks,
                                             disableDetailsPage = false,
                                         }) {
    const [clearInput, setClearInput] = useState(false);

    const roles = useSelector(state => state.getState).roles;
    const [activeDevice, setActiveDevice] = useState(null);
    const [activeId, setActiveId] = useState();


    const searchData = (event) => {
        onSearchChange({ target: { value: event } })
    }


    const items = useMemo(() => {
        if (tableData && tableData.length) {
            return tableData?.map(({ id }) => id)
        } else return []
    }, [tableData]);

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );

    function handleDragStart(event) {
        setActiveId(event.active.id);
        setActiveDevice(tableData.find(item => item.id.toString() === event.active.id.toString()));
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = items.indexOf(parseInt(active.id));
            const newIndex = items.indexOf(parseInt(over.id));
            let ordered = arrayMove(tableData, oldIndex, newIndex);
            let newPosition = null;

            if (newIndex === 0) {
                newPosition =  0.001;
                setOrder([
                    { id: active.id, position: newPosition },
                    { id: over.id, position: newPosition+0.001 }
                ]);
            }
            else if (newIndex > oldIndex) {
                newPosition = tableData[newIndex].sort + 0.001;
                setOrder([{ id: active.id, position: newPosition }]);
            }
            else {
                newPosition = tableData[newIndex].sort - 0.001;
                setOrder([{ id: active.id, position: newPosition }]);
            }

            setData(ordered)
        }
        setActiveId(null);
        setActiveDevice(null);
    }
    function handleDragOver(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
            // const oldIndex = items.indexOf(parseInt(active.id));
            // const newIndex = items.indexOf(parseInt(over.id));
            // let x = arrayMove(tableData, oldIndex, newIndex);
            // setData(x)
        }

    }
    function handleDragCancel() {
        setActiveId(null);
        setActiveDevice(null);
    }

    return (
        <>

            <div className={'d-flex justify-content-between'}>
                <Col lg={4} sm={6}>
                    <CustomSearch
                        width={'100%'}
                        clear={clearInput}
                        setClear={() => setClearInput(false)}
                        defaultValue={searchValue}
                        onChange={(e) => searchData(e.target.value)}
                        placeholder={searchPlaceHolder}
                    />
                </Col>
                {
                    (isAdmin && showAddNew)  && <FleetButton
                        height={'43px'}
                        width={'214px'}
                        backgroundColor={'#000000'}
                        color={'#FFFFFF'}
                        fontSize={'14px'}
                        radius={'8px'}
                        onClick={checkInCreate}
                    >
                        &#43; New Vehicle
                    </FleetButton>
                }
            </div>
            <DndContext
                                sensors={sensors}
                                onDragEnd={handleDragEnd}
                                onDragStart={handleDragStart}
                                onDragOver={handleDragOver}
                                onDragCancel={handleDragCancel}
                                collisionDetection={closestCenter}
                                modifiers={[restrictToVerticalAxis]}
           >
                               <div className={'fleet-table-container'}>
                                   <StyledTable className={'table-borderless'}>
                                       <StyledTableHeader  className={'header-white-text'}>
                                           <tr>
                                               {(isAdmin && showAddNew) && <StyledTableHeaderCell className={'border-bottom-0 fleet-table-header'} key={'header'}
                                                                       tabIndex="0">Order</StyledTableHeaderCell>}
                                               {
                                                   keys.map((column, index) => {
                                                       return <StyledTableHeaderCell  className={'border-bottom-0 fleet-table-header'} key={index + ''} tabIndex="0">{column?.text}
                                                       </StyledTableHeaderCell>
                                                   })

                                               }
                                           </tr>
                                       </StyledTableHeader>
                                       <tbody>
                                       <SortableContext items={items} strategy={verticalListSortingStrategy}>
                                           {
                                               tableData && tableData.length > 0 &&
                                               tableData.map((device, index) => {
                                                   return <React.Fragment key={index}>
                                                       <SortableTableItem
                                                           device={device}
                                                           index={index}
                                                           disable={(!isAdmin || !showAddNew)}
                                                           keys={keys}
                                                           disableDetailsPage={disableDetailsPage}
                                                           goToDetails={goToDetails}
                                                           createQuote={createQuote}
                                                           checkIn={checkIn}
                                                           quickView={quickView}
                                                           checkTasks={checkTasks}
                                                           deleteFleet={(e)=>{
                                                               removeItem(e)
                                                           }
                                                           }
                                                           deleteItem={hasRole(roles, 'dealer_admin') || hasRole(roles, 'Fleet Manager')}
                                                       />

                                                   </React.Fragment>
                                               })
                                           }

                                       </SortableContext>
                                       </tbody>
                                   </StyledTable>
                               </div>
                                 <DragOverlay>
                                     {activeId && (
                                         <table style={{ width: "100%" }}>
                                             <tbody>
                                             <StaticTableRow isNewFleet={true} device={activeDevice} keys={keys} />
                                             </tbody>
                                         </table>
                                     )}
                                 </DragOverlay>
                            </DndContext>

        </>
    )
}