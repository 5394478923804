import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import {
    PdfHeader, CustomTD,
    StyledTable,
    StyledTableHeaderCell, InspectionReportItemsContainer, FlexInnerComponent, Dot
} from "@pages/new-design-components/custom-components";
import { CURRENCY, getToday, parseToWord } from "../../../../constants";

export default function QuotePreviewComponentForPrint({ data, quote, vehicle, parsable = false, inspectionData, damages }) {
    const [imageUrls, setImageUrls] = useState([]);

    const fetchImage = async (imageLink) => {
        try {
            const response = await fetch(imageLink);
            const blob = await response.blob();
            return URL.createObjectURL(blob);
        } catch (error) {
            console.error("Error fetching image:", error);
            return null;
        }
    };

    // Loading images for each damage item
    useEffect(() => {
        const loadImages = async () => {
            const imageUrlsForDamages = await Promise.all(
                damages.map(async (item) => {
                    const urls = await Promise.all(
                        item.images.map((img) => fetchImage(img.image_link))
                    );
                    return { id: item.id, urls };
                })
            );
            setImageUrls(imageUrlsForDamages);
        };
        loadImages();
    }, [damages]);
    const currentTotal = data.filter(item => item.added_to_report)?.reduce((accumulator, part) => {
        return accumulator + (part.cost);
    }, 0)
    return (
        <Card id="pdf-content" className={"table-container " + parsable ? "position-absolute" : ""}
            style={{ width: '900px', minHeight: '1520px', overflow: 'hidden', bottom: parsable ? '200%' : 'unset' }}>
            <CardHeader>
                <PdfHeader className={'text-center'} lineHeight={'none'} fontSize={'24px'} weight={'700'} color={'#000000'}>
                    Repair Quote</PdfHeader>
            </CardHeader>
            <CardBody>
                <InspectionReportItemsContainer>
                    <PdfHeader
                        borderRadius={'16px 16px 0px 0px'}
                        backgroundColor={'#000'}
                        color={'#fff'}
                        lineHeight={'none'}
                        fontSize={'20px'}
                        weight={'700'}
                        className={'text-center mb-0'}>
                        CUSTOMER INFORMATION
                    </PdfHeader>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Name
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'14px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.first_name}   {vehicle.last_name}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Location
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'9px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.address}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    borderRadius={'0 0 0px 16px'}
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Phone
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'14px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.mobile_number}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Email
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    borderRadius={'0 0 16px 0px'}
                                    lineHeight={'none'} fontSize={'14px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.email}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                </InspectionReportItemsContainer>
            </CardBody>
            <CardBody>
                <InspectionReportItemsContainer>
                    <PdfHeader
                        borderRadius={'16px 16px 0px 0px'}
                        backgroundColor={'#000'}
                        color={'#fff'}
                        lineHeight={'none'}
                        fontSize={'20px'}
                        weight={'700'}
                        className={'text-center mb-0'}>
                        VEHICLE INFORMATION
                    </PdfHeader>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#000'} color={'#fff'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Inspection date
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.inspection_date ?? getToday()}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#000'} color={'#fff'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Mot Due
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.administrative?.mot_date ?? 'N/A'}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Reg no
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.stock_no}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Make
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.make.name}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Model
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.model.name}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    borderRadius={'0 0 0px 16px'}
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Mileage
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.current_mileage}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Engine Size
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {(vehicle?.features.engine_capacity / 1000).toFixed(1)}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Fuel Type
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    borderRadius={'0 0 16px 0px'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle?.features?.vehicle_fuel?.name}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                </InspectionReportItemsContainer>
            </CardBody>
            <CardBody>
                <InspectionReportItemsContainer>
                    <PdfHeader
                        borderRadius={'16px 16px 0px 0px'}
                        backgroundColor={'#000'}
                        color={'#fff'}
                        lineHeight={'none'}
                        fontSize={'24px'}
                        weight={'700'}
                        className={'text-center mb-1'}>
                        INSPECTION RESULTS
                    </PdfHeader>
                    {
                        inspectionData.map((inspection, index) => {
                            if (inspection.name !== 'Parts') {
                                return (
                                    <Fragment key={index}>
                                        <PdfHeader
                                            backgroundColor={'#000'}
                                            color={'#fff'}
                                            lineHeight={'none'}
                                            fontSize={'20px'}
                                            weight={'700'}
                                            className={'text-center mb-0'}>
                                            {
                                                inspection.name
                                            }
                                        </PdfHeader>
                                        <div className={'d-flex'}>
                                            <FlexInnerComponent width={'30'}>
                                                <PdfHeader className={'text-center  mb-0'} fontSize={'13px'} weight={'600'} lineHeight={'none'} backgroundColor={'#F2F2F2'} color={'#0000008C'}>
                                                    Item
                                                </PdfHeader>
                                            </FlexInnerComponent>
                                            <FlexInnerComponent width={'70'}>
                                                <PdfHeader className={'text-center  mb-0'} fontSize={'13px'} weight={'600'} lineHeight={'none'} backgroundColor={'#FFF'} color={'#0000008C'}>
                                                    Status
                                                </PdfHeader>
                                            </FlexInnerComponent>
                                        </div>
                                        {
                                            inspection.list.map((item, idx) => {
                                                return (
                                                    <Fragment key={`${index}_${idx}`}>
                                                        <div className={'d-flex'}>
                                                            <FlexInnerComponent width={'30'}>
                                                                <PdfHeader
                                                                    borderRadius={(inspection[index + 1] === undefined && inspection.list[idx + 1] == undefined) ?
                                                                        '0 0 0px 16px' : ''}
                                                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                                                    lineHeight={'none'} fontSize={'13px'}
                                                                    weight={'600'} className={'text-center  mb-0'}>
                                                                    {item.name}
                                                                </PdfHeader>
                                                            </FlexInnerComponent>
                                                            <FlexInnerComponent width={'70'}>
                                                                <PdfHeader
                                                                    color={'#000'}
                                                                    backgroundColor={item.required ? '#EB575740' : '#FFF'}
                                                                    borderRadius={(inspection[index + 1] === undefined && inspection.list[idx + 1] == undefined)
                                                                        ? '0 0 16px 0px' : ''}
                                                                    lineHeight={'none'} fontSize={'14px'}
                                                                    weight={'500'} className={'text-center  mb-0'}>
                                                                    <Dot color={item.required ? 'red' : 'green'} />
                                                                    {item.required ? 'Fail' : 'Pass'}
                                                                </PdfHeader>
                                                            </FlexInnerComponent>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }

                                    </Fragment>
                                )
                            }

                        })
                    }

                </InspectionReportItemsContainer>
            </CardBody>
            <CardBody>
                <InspectionReportItemsContainer>
                    <PdfHeader
                        borderRadius={'16px 16px 0px 0px'}
                        backgroundColor={'#000'}
                        color={'#fff'}
                        lineHeight={'none'}
                        fontSize={'20px'}
                        weight={'700'}
                        className={'text-center mb-0'}>
                        DAMAGE RECORDS
                    </PdfHeader>

                    <div className={'d-flex flex-wrap'}>
                        {damages.map((item, idx) => {
                            const itemImageUrls = imageUrls.find((img) => img.id === item.id)?.urls || [];

                            return <Fragment key={'d_' + idx}>
                                <FlexInnerComponent width={'50'}>
                                    <div className={'d-flex'}>
                                        <FlexInnerComponent width={'50'}>
                                            <PdfHeader
                                                lineHeight={'none'} fontSize={'13px'}
                                                weight={'600'} className={'text-center  mb-0'}>
                                                Position
                                            </PdfHeader>
                                        </FlexInnerComponent>
                                        <FlexInnerComponent width={'50'}>
                                            <PdfHeader
                                                lineHeight={'none'} fontSize={'13px'}
                                                weight={'500'} className={'text-center  mb-0'}>
                                                {parseToWord(item.position)}
                                            </PdfHeader>
                                        </FlexInnerComponent>
                                    </div>
                                    <div className={'d-flex'}>
                                        <FlexInnerComponent width={'50'}>
                                            <PdfHeader
                                                lineHeight={'none'} fontSize={'13px'}
                                                weight={'600'} className={'text-center  mb-0'}>
                                                Damage
                                            </PdfHeader>
                                        </FlexInnerComponent>
                                        <FlexInnerComponent width={'50'}>
                                            <PdfHeader
                                                lineHeight={'none'} fontSize={'14px'}
                                                weight={'500'} className={'text-center  mb-0'}>
                                                {parseToWord(item.damage)}
                                            </PdfHeader>
                                        </FlexInnerComponent>
                                    </div>
                                    <div className={'d-flex g-5 justify-content-center'}>
                                        {itemImageUrls.length
                                            ? itemImageUrls.map((url, ix) => (
                                                <div key={`${idx}_${ix}`}>
                                                    <img
                                                        src={url}
                                                        alt={`damage-${idx}-image-${ix}`}
                                                        width={'250px'}
                                                        className={'p-3'}
                                                    />
                                                </div>
                                            ))
                                            : null}
                                    </div>
                                </FlexInnerComponent>

                            </Fragment>
                        })}
                    </div>

                </InspectionReportItemsContainer>
            </CardBody>
            <CardHeader>
                <PdfHeader lineHeight={'none'} fontSize={'24px'} weight={'700'} color={'#000000'}>
                    Invoice NO:  {quote?.invoice_no ? quote?.invoice_no : `GRS ${1000 + quote.id}`}</PdfHeader>
            </CardHeader>
            <CardBody>
                <div className={'d-flex justify-content-between'} style={{ width: '742px', overflow: 'hidden' }}>
                    <div className={'d-flex flex-column'}>
                        <PdfHeader lineHeight={'none'} fontSize={'20px'} weight={'700'} color={'#000000'} className={'mb-4'}>
                            BILLED TO:
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                            {vehicle.first_name}   {vehicle.last_name}
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            {vehicle.address}
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            {vehicle.mobile_number}
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            {vehicle.email}
                        </PdfHeader>
                    </div>
                    <div className={'d-flex flex-column'}>
                        <PdfHeader lineHeight={'none'} fontSize={'20px'} weight={'700'} color={'#000000'} className={'mb-4'}>
                            FLEET INFORMATION:
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                            {vehicle.make.name} {vehicle.model.name}
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            {vehicle.derivative}
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            Reg no {vehicle.stock_no}
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            Color: {vehicle.colour}
                        </PdfHeader>
                    </div>
                    <div className={'d-flex flex-column'}>
                        <PdfHeader lineHeight={'none'} fontSize={'20px'} weight={'700'} color={'#000000'} className={'mb-4'}>
                            ISSUED BY:
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                            GRS Motor Group
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            Unit 12 & 13,
                        </PdfHeader>
                        <PdfHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            Priory Park
                        </PdfHeader>
                        <PdfHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            Milford Haven
                        </PdfHeader>
                        <PdfHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            SA73 2DA
                        </PdfHeader>
                        <PdfHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            01646 603872
                        </PdfHeader>
                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                            accounts@grsmotorgroup.co.uk
                        </PdfHeader>
                    </div>
                </div>
                <StyledTable className={'mt-5 mb-5'}>
                    <thead>
                        <tr>
                            <StyledTableHeaderCell border={'none'} width={'33%'} color={'#0000008C'}>
                                <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                    Item
                                </PdfHeader>
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell border={'none'} width={'33%'} color={'#0000008C'}>
                                <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                    Labour (£50/hour)
                                </PdfHeader>
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell border={'none'} width={'33%'} color={'#0000008C'}>
                                <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                    Price
                                </PdfHeader>
                            </StyledTableHeaderCell>

                        </tr>
                    </thead>
                    <tbody>
                        {data.length ?
                            data.map((item, index) => {
                                return <tr key={`4_${index}`}>
                                    <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'33%'}>
                                        {item.title}
                                    </CustomTD>

                                    <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'33%'}>
                                        {item.hour}
                                    </CustomTD>
                                    <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'33%'}>
                                        {item.cost}
                                    </CustomTD>
                                </tr>
                            })
                            : null
                        }
                    </tbody>
                </StyledTable>
                <div className={'d-flex justify-content-between'} style={{ width: '742px', overflow: 'hidden' }}>
                    <Col lg={4}>
                        <div className={'d-flex flex-column'}>
                            <PdfHeader lineHeight={'none'} fontSize={'20px'} weight={'700'} color={'#000000'} className={'mb-4'}>
                                PAYMENT INFORMATION:
                            </PdfHeader>
                            <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Bank Name:   Monzo
                            </PdfHeader>
                            <PdfHeader className={'text-nowrap'} lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Account Name:   GRS Motor Group Ltd
                            </PdfHeader>
                            <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Sort Code:  04 00 03
                            </PdfHeader>
                            <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                Account Number:  4603 6895
                            </PdfHeader>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <StyledTable>
                            <tbody>
                                <tr>
                                    <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                            Sum
                                        </PdfHeader>
                                    </CustomTD>
                                    <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                            {CURRENCY}  {currentTotal}
                                        </PdfHeader>
                                    </CustomTD>

                                </tr>
                                <tr>
                                    <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                            VAT 20 %
                                        </PdfHeader>
                                    </CustomTD>
                                    <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                            {CURRENCY}  {(currentTotal * 0.2).toFixed(2)}
                                        </PdfHeader>
                                    </CustomTD>
                                </tr>
                                <tr>
                                    <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                            TOTAL
                                        </PdfHeader>
                                    </CustomTD>
                                    <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                        <PdfHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                            {CURRENCY}  {currentTotal + currentTotal * 0.2}
                                        </PdfHeader>
                                    </CustomTD>
                                </tr>
                            </tbody>
                        </StyledTable>
                    </Col>
                </div>

            </CardBody>
        </Card>
    )
}