import React from "react";
import {CheckboxContainer, Checkmark} from "@pages/new-design-components/custom-components";

export default function CustomCheckbox({   checked,
                                           onClick,
                                           backgroundColor = null,
                                           height = null,
                                           width = null,
                                           checkMarkHeight = null,
                                           checkMarkWidth = null,
                                           checkMarkTop = null,
                                           checkMarkLeft = null,
                                           noPointer = false,
                                       }) {
    return (
        <CheckboxContainer noPointer={noPointer} onClick={onClick} height={height} width={width} checked={checked} backgroundColor={backgroundColor}>
            <Checkmark height={checkMarkHeight} width={checkMarkWidth} checked={checked} left={checkMarkLeft} top={checkMarkTop} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M20.285 6.285a1 1 0 00-1.415 0L10 15.585 5.715 11.3a1 1 0 00-1.415 1.415l5 5a1 1 0 001.415 0l10-10a1 1 0 000-1.415z" />
            </Checkmark>
        </CheckboxContainer>

    )
}