import {
    LoaderContainer, override,
    TaskDetailsContainer,
} from "@pages/reusable-components/styled-components";
import {Button, Col, Input, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import {formatDateShortGB, parseToWord} from "../../../constants";
import cloneDeep from 'lodash/cloneDeep';
import {ClipLoader} from "react-spinners";
import axios from "axios";
import * as constants from "../../../constants";

export default function CreateEditDelivery({
                                               setShowCreateNew,
                                               jobData = {},
                                               checkData,
                                               drivers = [],
                                               allTasks = [],
                                               index = 0,
                                               creating = true,
                                               updateCreated = false,
                                               save,
                                               exit,
                                               loading,
                                               addToCollection,
                                               updateCollectionData,
                                           }) {
    const [selectedDay, setSelectedDay] = useState(null);
    const [isGrsCollection, setIsGrsCollection] = useState(false);
    const [errors, setErrors] = useState('');
    const [lookupAddresses, setLookupAddresses] = useState([]);
    const [isSmall, setIsSmall] = useState(window.innerWidth < 833)
    const [currentIndex] = useState(null);
    const [isDriverDelivery, setIsDriverDelivery] = useState(false);
    const [isDriverCollect, setIsDriverCollect] = useState(false);
    const [isDriverWork, setIsDriverWork] = useState(false);
    const [selectedCollectionDay, setSelectedCollectionDay] = useState(null);
    const [sendData, setSendData] = useState({
        colour: '',
        current_mileage: '',
        vehicle_option: [],
        customer_name: '',
        customer_email: '',
        customer_address: '',
        customer_postal_code: '',
        customer_phone: '',
        task_type: '',
        execution_date: '',
        grs_collection_date: '',
        driver_id: '',
        description: '',
        customer_id: null,
        driver_getter_id: null,
        driver_getter_name: null,
        driver_getter_address: null,
        driver_getter_phone: null,
        driver_getter_email: '',

    });
    const [keys] = useState(
        [
            {
                key: 'task_type',
                text: 'Task Type'
            },
            {
                key: 'execution_date',
                text: 'Execution Date'
            },
            {
                key: 'driver',
                text: 'Driver'
            },
            {
                key: 'customer_address',
                text: 'Collect From'
            },
            {
                key: 'customer_name',
                text: 'Deliver To'
            },
            {
                key: 'customer_phone',
                text: 'Phone Number'
            },
            {
                key: 'customer_postal_code',
                text: 'Post code'
            },


        ]
    )
    const [usedTaskTypes, setUsedTaskTypes] = useState([])

    const [taskTypes] = useState([
        {
            label: 'Delivery',
            value: 'delivery',
        },
        {
            label: 'Collect',
            value: 'collect',
        },
        {
            label: 'Grs Collection',
            value: 'grs_collection',
        },

    ])


    const generateCustomItem = (task, key, tableKey) => {

        if (key === 'execution_date') {
            if (task.task_type === 'grs_collection') {
                return <td key={tableKey}>{task.grs_collection_date}</td>
            }
        }
        if (key === 'customer_address') {
            if (task.task_type === 'grs_collection') {
                return <td key={tableKey}>GRS Motor Group Ltd, Unit 12 & 13, Priory Park, Milford Haven, SA73 2DA</td>
            } else if (task.task_type !== 'delivery' && task.task_type !== 'collect') {
                return <td key={tableKey}>{task.driver_getter_address}</td>
            }
        }

        if (key === 'customer_postal_code' && task.task_type !== 'delivery' && task.task_type !== 'collect') {
            return '-'
        }

        if (key === 'driver') {
            return <td
                key={tableKey}>{task.driver_id ? drivers.find(driver => driver.id === task.driver_id).name : '-'}</td>
        }
        if (key === 'task_type') {
            return <td key={tableKey}>{parseToWord(task[key])}</td>
        }
        return <td key={tableKey}>{task[key] ? task[key] : '-'}</td>
    }

    const initData = () => {
        if (Object.values(jobData).length) {
            setSelectedDay(null)
            let tempData = cloneDeep(sendData)
            tempData.driver_id = null
            tempData.execution_date = null
            Object.keys(tempData).forEach(key => {
                tempData[key] = jobData[key]
            })
            if (creating) {
                tempData['task_type'] = Object.values(taskTypes)[0].value
            }
            setSendData({...sendData, ...tempData})
            if (!creating) {
                setSelectedDay(jobData.execution_date)
                setSelectedCollectionDay(jobData?.grs_collection_date)

            }
        }
    }
    useEffect(() => {
        setIsSmall(window.innerWidth < 833)
    }, [window.innerWidth])
    useEffect(() => {
        initData()
    }, [jobData])
    useEffect(() => {
         if (sendData.task_type === 'delivery') {
            setIsDriverWork(false)
            setIsDriverDelivery(false)
            setIsDriverCollect(false)
            setIsGrsCollection(false)
        } else if (sendData.task_type === 'collect') {
            setIsDriverWork(false)
            setIsDriverDelivery(false)
            setIsDriverCollect(false)
            setIsGrsCollection(false)
        } else if (sendData.task_type === 'grs_collection') {
             setIsGrsCollection(true)
             setIsDriverWork(true)
             setIsDriverCollect(false)
             setIsDriverDelivery(false)
         }
        checkData(sendData)
    }, [sendData])
    const selectSendingDriver = (e) => {
        setSendData({
            ...sendData,
            driver_getter_id: e.id,
            driver_getter_name: e.name,
            driver_getter_email: e.email,
            driver_getter_address: e.address,
            driver_getter_phone: e.phone_number ?? ''
        })
    }

    const selectTaskType = (e) => {
        if (!isDriverWork) {
            initData()
        }
        if (e.value === 'grs_collection') {
            setSendData({
                ...sendData,
                task_type: e.value,
                driver_getter_address: 'GRS Motor Group Ltd, Unit 12 & 13, Priory Park, Milford Haven, SA73 2DA',
            })
        } else {
            setSendData({...sendData, task_type: e.value})
        }
    }

    const selectDriver = (e) => {
        if (isGrsCollection) {
            setSendData({
                ...sendData,
                driver_getter_id: e.id,
                driver_id: e.id,
                driver_getter_name: e.name,
                driver_getter_email: e.email,
                driver_getter_phone: e.phone_number ?? ''
            })
        } else {
            setSendData({...sendData, driver_id: e.id})
        }
    }
    const updateCollection = () => {
        if (updateCreated) {
            updateCollectionData(currentIndex)
            let tempTypes = usedTaskTypes
            tempTypes[currentIndex].type = sendData.task_type
            setUsedTaskTypes(tempTypes)
        } else {
            setUsedTaskTypes([...usedTaskTypes, {item: index, type: sendData.task_type}])
            addToCollection()
        }

        initData()
    }
    const setSelectedAddress = (address) => {
        const a = address.FormattedAddressLines

        if (a) {
            const addr = `${a.Street ? a.Street : ''}${a.Premises ? `, ${a.Premises}` : ''}${a.PostTown ? `, ${a.PostTown}` : ''}${a.Organisation ? `, ${a.Organisation}` : ''}${a.Locality ? `, ${a.Locality}` : ''}${a.County ? `, ${a.County}` : ''}`
            setSendData({...sendData, customer_address: addr})

        }
    }
    const getAddressByPostcode = (postcode) => {
        axios({
            method: 'GET',
            url: `${constants.UK_VEHICLE_DATA_API_URL}/PostcodeLookup?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_POSTCODE=${postcode}`,
        })
            .then(response => {
                const data = response.data

                if (data.Response) {

                    if (data.Response.StatusCode === 'Success') {

                        const addressList = data.Response.DataItems.AddressDetails.AddressList
                        if (addressList.length === 1) {

                            setSendData({...sendData, customer_postal_code: postcode})
                            setErrors({
                                ...errors,
                                postal_code: ''
                            })
                            setSelectedAddress(addressList[0]);
                        } else if (addressList.length > 1) {
                            setSendData({...sendData, customer_postal_code: postcode})

                            setErrors({
                                ...errors,
                                postal_code: ''
                            })
                            setLookupAddresses(addressList);
                        }
                    } else {
                        setErrors({
                            ...errors,
                            postal_code: data.Response.StatusMessage
                        })
                    }
                }
            })
            .catch(error => {
                console.error('error :: ', error)
            })
    }
    //todo can be useful
    // const setItem = (index) => {
    //     setCurrentIndex(index)
    //     setSendData(selectItem(index))
    // }
    return (
        loading ? <LoaderContainer>
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={loading}
                />
            </LoaderContainer> :
            <>
                 <Row className={'mb-3'}>
                     <Col sm={12} lg={4}>
                         <label className="form-control-label">Vehicle</label>
                         <Input disabled value={`${jobData.vehicle_make}, ${jobData.vehicle_model}`}/>
                     </Col>
                     <Col sm={12} lg={4}>
                         <label className="form-control-label">Color</label>
                         <Input
                             value={sendData.colour}
                             onChange={(e) => setSendData({...sendData, colour: e.target.value})}
                         />
                     </Col>
                     <Col sm={12} lg={4}>
                         <label className="form-control-label">Mileage</label>
                         <Input
                             value={sendData.current_mileage}
                             onChange={(e) => setSendData({...sendData, current_mileage: e.target.value})}
                         />
                     </Col>
                 </Row>
                 <Row className={'mb-3'}>
                     <Col sm={12} lg={4}>
                         <label className="form-control-label">Task Type</label>
                         <Select
                             styles={{menu: provided => ({...provided, zIndex: 9999})}}
                             options={taskTypes}
                             value={taskTypes.find(item => item.value === sendData.task_type)}
                             onChange={(e) => selectTaskType(e)}
                         />
                     </Col>
                     <Col sm={12} lg={4}>
                         {
                             isGrsCollection ?
                                 <div className={'job-booker-dates'}>
                                 <label className="form-control-label">GRS collection date</label>
                                     <DatePicker
                                         value={selectedCollectionDay ? formatDateShortGB(selectedCollectionDay) : ''}
                                         onChange={(e) => {
                                             setSendData({
                                                 ...sendData,
                                                 grs_collection_date: moment(e).format('YYYY-MM-DD')
                                             })
                                             setSelectedCollectionDay(e)
                                         }}
                                         shouldHighlightWeekends
                                     />
                                 </div>
                                 :
                                 <div className={'job-booker-dates'}>
                                     <label className="form-control-label">Date of execution</label>
                                     <DatePicker
                                         value={selectedDay ? formatDateShortGB(selectedDay) : ''}
                                         wrapperClassName={{width: '100%'}}
                                         onChange={(e) => {
                                             setSendData({
                                                 ...sendData,
                                                 execution_date: moment(e).format('YYYY-MM-DD')
                                             })
                                             setSelectedDay(e)
                                         }}

                                         shouldHighlightWeekends
                                     />
                                 </div>
                         }

                     </Col>
                     <Col sm={12} lg={4}>
                         <label className="form-control-label">Driver</label>
                         <Select
                             options={drivers}
                             value={drivers.find(item => item.id === sendData.driver_id) || null}
                             getOptionLabel={option => option.name}
                             onChange={(e) => selectDriver(e)}
                             getOptionValue={option => option.id}
                         />
                     </Col>
                 </Row>
                {
                    !isGrsCollection && <Row>
                        <Col>
                            <h2>Customer Info</h2>
                        </Col>
                    </Row>
                }
                {
                    !isGrsCollection &&
                    <Row className={'mb-3'}>
                        <Col sm={12} lg={4}>
                            {isDriverDelivery ?
                                <label className="form-control-label">To Driver:</label> :
                                isDriverCollect ?
                                    <label className="form-control-label">From Driver: </label>
                                    : !isGrsCollection && <label className="form-control-label">Contact Name</label>
                            }
                            {(isDriverDelivery || isDriverCollect) ?
                                <Select
                                    options={drivers}
                                    value={drivers.find(item => item.id === sendData.driver_getter_id) || null}
                                    getOptionLabel={option => option.name}
                                    onChange={(e) => selectSendingDriver(e)}
                                    getOptionValue={option => option.id}
                                />
                                : !isGrsCollection &&
                                <Input
                                    value={sendData.customer_name}
                                    disabled={jobData.hasOwnProperty('customer_id') && jobData.customer_id !== null}
                                    onChange={(e) => setSendData({...sendData, customer_name: e.target.value})}
                                />
                            }
                        </Col>
                        <Col sm={12} lg={4}>
                            <label className="form-control-label">Contact Phone</label>
                            {(!isDriverWork) ?
                                <Input
                                    value={sendData.customer_phone}
                                    onChange={(e) => setSendData({...sendData, customer_phone: e.target.value})}
                                /> : isDriverDelivery || isDriverCollect ?
                                    <Input
                                        value={sendData.driver_getter_phone}
                                        onChange={(e) => setSendData({
                                            ...sendData,
                                            driver_getter_phone: e.target.value
                                        })}
                                    /> : null

                            }
                        </Col>
                        <Col sm={12} lg={4}>
                            <label className="form-control-label">Contact Email</label>
                            {(!isDriverWork) ?
                                <Input
                                    defaultValue={sendData.customer_email}
                                    disabled
                                /> : isDriverDelivery || isDriverCollect ?
                                    <Input
                                        disabled
                                        defaultValue={sendData.driver_getter_email}
                                    /> : null

                            }
                        </Col>
                    </Row>
                }

                {isDriverWork ?
                     <Row>
                         <Col className={'mb-3'}>
                             <label  className="form-control-label">{sendData.task_type === 'grs_collection' ? 'Grs Location:' : 'Address:'}</label>
                             {isGrsCollection ?
                                 <Input
                                     value={'GRS Motor Group Ltd, Unit 12 & 13, Priory Park, Milford Haven, SA73 2DA'}
                                     disabled={true}
                                 />
                                 :
                                 <Input
                                     value={sendData.driver_getter_address}
                                     onChange={(e) =>
                                         setSendData({
                                             ...sendData,
                                             driver_getter_address: e.target.value
                                         })}
                                 />
                             }
                         </Col>
                     </Row>
                    :
                    <>
                        <Row column className={'mb-3'}>
                            {(!isDriverWork) &&
                            <Col lg={4}>
                                <label className="form-control-label">{'Post code:'}</label>
                                <Input
                                    placeholder="Postal Code"
                                    type="text"
                                    value={sendData.customer_postal_code}
                                    onChange={(e) => {
                                        getAddressByPostcode(e.target.value)
                                        setSendData({...sendData, customer_postal_code: e.target.value})
                                    }}
                                    invalid={errors && errors.postal_code ? true : false}
                                />
                                <div className="invalid-feedback">
                                    {errors.postal_code}
                                </div>
                            </Col>

                            }
                            <Col>
                                <label className="form-control-label">{'Correspondent address'}</label>
                                <Input
                                    placeholder="Select the correspondent address"
                                    type="select"
                                    onChange={(e) => {
                                        let selectedAddress = lookupAddresses.find(item => item.Udprn.toString() === e.target.value.toString());
                                        setSelectedAddress(selectedAddress);
                                    }}
                                >
                                    {lookupAddresses.length === 0 && <option>No lookup address to select from</option>}
                                    {lookupAddresses.length > 0 &&
                                    <>
                                        <option>-</option>
                                        {lookupAddresses.map((item, index) => {
                                            return <option value={item.Udprn} key={index}>{item.SummaryAddress}</option>;
                                        })}
                                    </>
                                    }
                                </Input>
                            </Col>

                        </Row>
                        <Row className={'mb-3'}>
                            <Col>
                                <label className="form-control-label">{'Address:'}</label>
                                <Input
                                    value={sendData.customer_address}
                                    onChange={(e) =>
                                        setSendData({
                                            ...sendData,
                                            customer_address: e.target.value
                                        })}
                                />
                            </Col>
                        </Row>
                    </>
                }


                   <Row className={'mb-3'}>
                            <Col>
                                <label className="form-control-label">Notes:</label>
                                <Input
                                    type='textarea'
                                    value={sendData.description}
                                    onChange={(e) => setSendData({...sendData, description: e.target.value})}
                                />
                            </Col>
                    </Row>

                {creating &&
                <>
                    <TaskDetailsContainer direction={'column'}>
                        <Button style={{
                            backgroundColor: "#ec6409",
                            color: 'white',
                            height: '50px',
                            width: '200px',
                            cursor: 'pointer',
                        }} onClick={updateCollection}>{updateCreated ? 'Update Created' : 'Add new task'}</Button>

                        <table className="table mobile-table">
                            <thead>
                            <tr>
                                {
                                    keys.map((column, index) => {
                                        return <th key={index + ''} tabIndex="0">{column?.text}</th>
                                    })

                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                allTasks && allTasks.length > 0 &&
                                allTasks.map((task, index) => {
                                    return <tr key={index}>
                                        {
                                            keys.map((item, idx) => {
                                                return generateCustomItem(task, item.key, index + '_' + idx)
                                            })
                                        }

                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </TaskDetailsContainer>

                </>
                }

                <TaskDetailsContainer float='right' width={!isSmall && '40%'}>
                    <Button
                        style={{
                            backgroundColor: "white",
                            color: '#ec6409',
                            border: '1px solid #ec6409',
                            marginTop: '25px'
                        }}
                        type="button" onClick={() => {
                        creating ?
                            setShowCreateNew(false) : exit()
                    }}>Cancel</Button>
                    {
                        creating ? <Button
                                style={{
                                    backgroundColor: "#ec6409",
                                    color: 'white',

                                    marginTop: '25px'
                                }}
                                disabled={allTasks?.length < 1}
                                type="button" onClick={() => {
                                save()
                            }}>Create Task</Button> :
                            <Button style={{
                                backgroundColor: "#ec6409",
                                color: 'white',
                                marginTop: '25px'
                            }} type="button" onClick={() => {
                                save()
                            }}>Update</Button>
                    }
                </TaskDetailsContainer>
            </>
    )
}