import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

export default function AuthHeader() {
  return (
    <>
      <div className="header bg-gradient-info py-3 py-lg-5 pt-lg-7" style={{height: "35vh"}}>
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col className="px-5" lg="6" md="8" xl="5">
                <img
                  alt="..."
                  style={{height: '110px'}}
                  src={require("assets/img/RentalHub.png").default}
                />
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="fill-default"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string
};