import * as actionTypes from './actions';
import produce from 'immer';
import moment from 'moment';

const initialState = {
  payments: {},
  reports: {},
  paymentCrons: [],
  getTasksStatus: null,
  getPaymentsCronStatus: null,
  addedPaymentSuccess: false,
  addedPaymentError: false,
  deletePaymentSuccess: false,
  deletePaymentError: false,
  getPaymentsListSuccess: false,
  getPaymentsListError: false,
  updatePaymentSuccess: false,
  updatePaymentError: false,
  updatePaymentNotesSuccess: false,
  updatePaymentNotesError: false,
  updatePartialPaymentSuccess: false,
  updatePartialPaymentError: false,
  updateExpectedPaymentSuccess: false,
  updateExpectedPaymentError: false,
  getPaymentReportsListSuccess: false,
  getPaymentReportsListError: false,
  uploadedPayments: null,
  savedPayments: null,
  loadingResults: false,
  apiError: null,
  getPaymentNotesSuccess: false,
  getPaymentNotesError: false,
  getCustomerPaymentNotesSuccess: false,
  getCustomerPaymentNotesError: false,
  deleteAllPaymentSuccess: false,
  updateGroupPaymentsError: false,
  updateGroupPaymentsSuccess: false,
  getPaymentsPlansError: false,
  getPaymentsPlansSuccess: false,
  deleteAllPaymentError: false,
  uploadPaymentPlansToGoogleDriveError: false,
  uploadPaymentPlansToGoogleDriveSuccess: false,
  uploadMissedPaymentsToGoogleDriveError: false,
  uploadMissedPaymentsToGoogleDriveSuccess: false,
  approvementUpdatedSuccess: false,
  approvementUpdatedError: false,

  paymentNotes: [],
  customerPaymentNotes: [],
  paymentPlans: {},
  filter: {
    end: '',
    start: '',
    search: ''
  },
  paymentDescriptorsArray: {},
};

const paymentStore = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_LIST_REQUEST:
      draft.getPaymentsListSuccess = false;
      draft.getPaymentsListError = false;
      break;
    case actionTypes.GET_PAYMENT_REPORTS_REQUEST:
      draft.getPaymentReportsListSuccess = false;
      draft.getPaymentReportsListError = false;
      break;
    case actionTypes.GET_PAYMENT_LIST_SUCCESS:
      draft.getPaymentsListSuccess = true;
      draft.getPaymentsListError = false;
      draft.payments = { ...action }
      break;
    case actionTypes.GET_PAYMENT_LIST_ERROR:
      draft.getPaymentReportsListSuccess = false;
      draft.getPaymentReportsListError = true;
      draft.payments = []
      break;

    case actionTypes.GET_PAYMENT_REPORTS_SUCCESS:
      draft.getPaymentReportsListSuccess = true;
      draft.getPaymentReportsListError = false;
      draft.reports = { ...action.data }
      break;
    case actionTypes.GET_PAYMENT_REPORTS_ERROR:
      draft.getPaymentsListSuccess = false;
      draft.getPaymentsListError = true;
      draft.reports = []
      break;
    case actionTypes.GET_CRON_PAYMENT_LIST_REQUEST:
      draft.getPaymentsCronStatus = false;
      draft.apiError = null;
      break;
    case actionTypes.GET_CRON_PAYMENT_LIST_SUCCESS:
      draft.paymentCrons = { ...action.data.items };
      draft.filter = {
        from: action.data.filters?.start ? moment(action.data.filters.start).format('YYYY-MM-DD') : '',
        to: action.data.filters?.end ? moment(action.data.filters.end).format('YYYY-MM-DD') : '',
        search: action.data.filters?.search ?? '',
      };
      draft.getPaymentsCronStatus = true;
      break;
    case actionTypes.GET_CRON_PAYMENT_LIST_ERROR:
      draft.getPaymentsCronStatus = false;
      break;

    case actionTypes.ADD_PAYMENT_REQUEST:
      draft.addedPaymentSuccess = false;
      draft.addedPaymentError = false;
      break;
    case actionTypes.ADD_PAYMENT_SUCCESS:
      draft.addedPaymentSuccess = true;
      draft.addedPaymentError = false;
      break;
    case actionTypes.ADD_PAYMENT_ERROR:
      draft.addedPaymentSuccess = false;
      draft.addedPaymentError = true;
      break;

    case actionTypes.UPDATE_PAYMENT_REQUEST:
      draft.updatePaymentSuccess = false;
      draft.updatePaymentError = false;
      break;
    case actionTypes.UPDATE_PAYMENT_SUCCESS:
      draft.updatePaymentSuccess = true;
      draft.updatePaymentError = false;
      break;
    case actionTypes.UPDATE_PAYMENT_ERROR:
      draft.updatePaymentSuccess = false;
      draft.updatePaymentError = true;
      break;

    case actionTypes.UPDATE_PAYMENT_NOTES_REQUEST:
      draft.updatePaymentNotesSuccess = false;
      draft.updatePaymentNotesError = false;
      break;
    case actionTypes.UPDATE_PAYMENT_NOTES_SUCCESS:
      draft.updatePaymentNotesSuccess = true;
      draft.updatePaymentNotesError = false;
      break;
    case actionTypes.UPDATE_PAYMENT_NOTES_ERROR:
      draft.updatePaymentNotesSuccess = false;
      draft.updatePaymentNotesError = true;
      break;
    case actionTypes.DELETE_PAYMENT_REQUEST:
      draft.deletePaymentSuccess = false;
      draft.deletePaymentError = false;
      break;
    case actionTypes.DELETE_PAYMENT_SUCCESS:
      draft.deletePaymentSuccess = true;
      draft.deletePaymentError = false;
      break;
    case actionTypes.DELETE_PAYMENT_ERROR:
      draft.deletePaymentSuccess = false;
      draft.deletePaymentError = true;
      break;
    case actionTypes.UPLOAD_PAYMENT_DOCUMENT_REQUEST:
      draft.loadingResults = true;
      draft.uploadedPayments = null;
      break;
    case actionTypes.SAVE_PAYMENTS_REQUEST:
      draft.savedPayments = null;
      draft.loadingResults = true;
      break;
      case actionTypes.API_PAYMENTS_SUCCESS:
        if (action.data.requestType === actionTypes.UPLOAD_PAYMENT_DOCUMENT_REQUEST) {
        draft.uploadedPayments = {
          payments: action.data.payments
        };
        draft.completedRequest = actionTypes.UPLOAD_PAYMENT_DOCUMENT_REQUEST;
        draft.loadingResults = false;
      } else if (action.data.requestType === actionTypes.SAVE_PAYMENTS_REQUEST) {
        draft.savedPayments = {
          payments: action.data.payments,
          error: action.data.error,
        };
        draft.completedRequest = actionTypes.SAVE_PAYMENTS_REQUEST;
        draft.loadingResults = false;
      }
      break;
    case actionTypes.CLEANUP_PAYMENTS:
      draft.loadingResults = false;
      draft.uploadedPayments = null;
      draft.savedPayments = null;
      break;
    case actionTypes.API_PAYMENTS_ERROR:
      draft.completedRequest = action.data.requestType;
      draft.loadingResults = false;
      draft.apiError = action.data.error;
      break;
    case actionTypes.UPDATE_PARTIAL_PAYMENT_REQUEST:
      draft.updatePartialPaymentSuccess = false;
      draft.updatePartialPaymentError = false;
      break;
    case actionTypes.UPDATE_PARTIAL_PAYMENT_SUCCESS:
      draft.updatePartialPaymentSuccess = true;
      draft.updatePartialPaymentError = false;
      break;
    case actionTypes.UPDATE_PARTIAL_PAYMENT_ERROR:
      draft.updatePartialPaymentSuccess = false;
      draft.updatePartialPaymentError = true;
      break;
    case actionTypes.UPDATE_EXPECTED_PAYMENT_REQUEST:
      draft.updateExpectedPaymentSuccess = false;
      draft.updateExpectedPaymentError = false;
      break;
    case actionTypes.UPDATE_EXPECTED_PAYMENT_SUCCESS:
      draft.updateExpectedPaymentSuccess = true;
      draft.updateExpectedPaymentError = false;
      break;
    case actionTypes.UPDATE_EXPECTED_PAYMENT_ERROR:
      draft.updateExpectedPaymentSuccess = false;
      draft.updateExpectedPaymentError = true;
      break;
    case actionTypes.GET_PAYMENTS_NOTES_REQUEST:
      draft.getPaymentNotesSuccess = false;
      draft.getPaymentNotesError = false;
      break;
    case actionTypes.GET_PAYMENTS_NOTES_SUCCESS:
      draft.getPaymentNotesSuccess = true;
      draft.getPaymentNotesError = false;
      draft.paymentNotes = [...action.data]
      break;
    case actionTypes.GET_PAYMENTS_NOTES_ERROR:
      draft.getPaymentNotesSuccess = false;
      draft.getPaymentNotesError = true;
      draft.paymentNotes = []
      break;

    case actionTypes.GET_CUSTOMER_PAYMENTS_NOTES_REQUEST:
      draft.getCustomerPaymentNotesSuccess = false;
      draft.getCustomerPaymentNotesError = false;
      break;
    case actionTypes.GET_CUSTOMER_PAYMENTS_NOTES_SUCCESS:
      draft.getCustomerPaymentNotesSuccess = true;
      draft.getCustomerPaymentNotesError = false;
      draft.customerPaymentNotes = [...action.data]
      break;
    case actionTypes.GET_CUSTOMER_PAYMENTS_NOTES_ERROR:
      draft.getCustomerPaymentNotesSuccess = false;
      draft.getCustomerPaymentNotesError = true;
      draft.customerPaymentNotes = []
      break;

    case actionTypes.DELETE_ALL_PAYMENT_REQUEST:
      draft.deleteAllPaymentSuccess = false;
      draft.deleteAllPaymentError = false;
      break;
    case actionTypes.DELETE_ALL_PAYMENT_SUCCESS:
      draft.deleteAllPaymentSuccess = true;
      draft.deleteAllPaymentError = false;
      break;
    case actionTypes.DELETE_ALL_PAYMENT_ERROR:
      draft.deleteAllPaymentSuccess = false;
      draft.deleteAllPaymentError = true;
      break;
    case actionTypes.UPDATE_GROUP_PAYMENTS_STATUS_REQUEST:
      draft.updateGroupPaymentsSuccess = false;
      draft.updateGroupPaymentsError = false;
      break;
    case actionTypes.UPDATE_GROUP_PAYMENTS_STATUS_SUCCESS:
      draft.updateGroupPaymentsSuccess = true;
      draft.updateGroupPaymentsError = false;
      break;
    case actionTypes.UPDATE_GROUP_PAYMENTS_STATUS_ERROR:
      draft.updateGroupPaymentsSuccess = false;
      draft.updateGroupPaymentsError = true;
      break;

    case actionTypes.GET_PAYMENTS_PLANS_REQUEST:
      draft.getPaymentsPlansSuccess = false;
      draft.getPaymentsPlansError = false;
      break;
    case actionTypes.GET_PAYMENTS_PLANS_SUCCESS:
      draft.getPaymentsPlansSuccess = true;
      draft.getPaymentsPlansError = false;
      draft.paymentPlans  = {...action.data}
      break;
    case actionTypes.GET_PAYMENTS_PLANS_ERROR:
      draft.getPaymentsPlansSuccess = false;
      draft.getPaymentsPlansError = true;
      break;
    case actionTypes.UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_REQUEST:
      draft.uploadPaymentPlansToGoogleDriveSuccess = false;
      draft.uploadPaymentPlansToGoogleDriveError = false;
      break;
    case actionTypes.UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_SUCCESS:
      draft.uploadPaymentPlansToGoogleDriveSuccess = true;
      draft.uploadPaymentPlansToGoogleDriveError = false;
      break;
    case actionTypes.UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_ERROR:
      draft.uploadPaymentPlansToGoogleDriveSuccess = false;
      draft.uploadPaymentPlansToGoogleDriveError = true;
      break;
    case actionTypes.UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_REQUEST:
      draft.uploadMissedPaymentsToGoogleDriveSuccess = false;
      draft.uploadMissedPaymentsToGoogleDriveError = false;
      break;
    case actionTypes.UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_SUCCESS:
      draft.uploadMissedPaymentsToGoogleDriveSuccess = true;
      draft.uploadMissedPaymentsToGoogleDriveError = false;
      break;
    case actionTypes.UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_ERROR:
      draft.uploadMissedPaymentsToGoogleDriveSuccess = false;
      draft.uploadMissedPaymentsToGoogleDriveError = true;
      break;
    case actionTypes.UPDATE_APPRROVEMENT_DATE_REQUEST:
      draft.approvementUpdatedSuccess = false;
      draft.approvementUpdatedError = false;
      break;
    case actionTypes.UPDATE_APPRROVEMENT_DATE_SUCCESS:
      draft.approvementUpdatedSuccess = true;
      draft.approvementUpdatedError = false;
      break;
    case actionTypes.UPDATE_APPRROVEMENT_DATE_ERROR:
      draft.approvementUpdatedSuccess = false;
      draft.approvementUpdatedError = true;
      break;
    default:
      return draft;
  }
});

export const uploadPaymentsDocument = (document) => {
  return {
    type: actionTypes.UPLOAD_PAYMENT_DOCUMENT_REQUEST,
    payload: {
      document
    }
  };
};

export const savePayments = (payments) => {
  return {
    type: actionTypes.SAVE_PAYMENTS_REQUEST,
    payload: {
      payments
    }
  };
};

export const cleanupPayments = () => {
  return {
    type: actionTypes.CLEANUP_PAYMENTS
  };
};

export default paymentStore;