import React, {Fragment} from "react";
import {
    CustomBadge,
    CustomContainer,
    CustomHeader, CustomInput, DepartmentsBadge,
    ProfileImage,
    RequiredItemsBadge
} from "@pages/new-design-components/custom-components";
import {formatTimestamp} from "../../../../../constants";

export default function NotesComponent({notes,noteTabs,activeNoteTab,setActiveNoteTab,picture,newMessage,setNewMessage,sendMessage,setError,error}) {

    return (
        <>
            <CustomHeader weight={'700'} color={'#000'} fontSize={'20px'}>Notes</CustomHeader>
            <div className={'d-flex g-10 mb-3 flex-wrap'}>
            {
                noteTabs.map((tab,index) => {
                    return (
                        <Fragment key={'n_'+index}>
                            <RequiredItemsBadge  fontSize={'14px'} width={'100px'}  type={tab.key} onClick={()=> {
                                setActiveNoteTab(tab.key);
                            }
                            } active={activeNoteTab === tab.key}>
                                {tab.label}
                            </RequiredItemsBadge>
                        </Fragment>
                    )
                })
            }
            </div>

            <CustomContainer backgroundColor={'#FFF'} borderRadius="8px" padding="10px 16px">
                <CustomHeader weight={'600'} color={'#000'} fontSize={'14px'}>Create Note:</CustomHeader>
                <div className={'d-flex g-10'}>
                    <ProfileImage src={picture} alt=""/>
                    {/*<ProfileImage src={'https://ashnik.com/wp-content/uploads/2021/07/open-source-101-everything.jpg'} alt=""/>*/}

                    <form
                        className={'w-100'}
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (newMessage?.message.length > 3) {
                                sendMessage();
                                setError({...error,message: {show: false,message: ''}})
                            } else {
                                setError({...error,message: {show: true,message: 'Too short. Please write more than 3 letters'}})
                            }
                        }}
                    >
                        <CustomInput
                            onChange={(e) => {
                                setNewMessage({
                                    ...newMessage,
                                    message: e.target.value
                                });
                            }}
                            borderBottom={error.message.show ? '1px solid red' : '1px solid #0000008C'}
                            radius={'none'}
                            value={newMessage?.message}
                        />
                        {error.message.show && <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}> {error.message.message}</div>}

                        <button type="submit" style={{ display: 'none' }} />
                    </form>
                </div>
            </CustomContainer>
            {
                notes.map((item,idx)=>{
                    return <Fragment key={`nt_${idx}`}>
                        <CustomContainer backgroundColor={'#FFF'} borderRadius="8px" padding="10px 16px" margin={'6px 0px 6px 0px'}>
                            <div  className={'d-flex justify-content-between'}>
                                <div>
                                    <CustomHeader weight={'600'} color={'#000'} fontSize={'14px'}>{item.noteable?.name || item.noteable?.full_name}</CustomHeader>
                                    <CustomBadge type={item?.noteable_type}>
                                        {item?.noteable_type === '\\App\\User' ? 'Admin' : item?.noteable_type }
                                    </CustomBadge>
                                    <CustomHeader weight={'400'} color={'#979797'} fontSize={'14px'}>{item.note}</CustomHeader>
                                </div>
                                <CustomHeader weight={'400'} color={'#8D8D8D'} fontSize={'12px'}>
                                    {formatTimestamp(item?.created_at)}
                                </CustomHeader>
                            </div>

                        </CustomContainer>
                    </Fragment>
                })
            }


        </>
    )
}