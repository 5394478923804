export const LIVE_VEHICLE_EDIT_REQUEST = 'LIVE_VEHICLE_EDIT_REQUEST';
export const LIVE_VEHICLE_EDIT_SUCCESS = 'LIVE_VEHICLE_EDIT_SUCCESS';
export const LIVE_VEHICLE_EDIT_ERROR = 'LIVE_VEHICLE_EDIT_ERROR';

export const FLEET_MANAGEMENT_VEHICLE_JOB_REQUEST = 'FLEET_MANAGEMENT_VEHICLE_JOB_REQUEST';
export const FLEET_MANAGEMENT_VEHICLE_JOB_SUCCESS = 'FLEET_MANAGEMENT_VEHICLE_JOB_SUCCESS';
export const FLEET_MANAGEMENT_VEHICLE_JOB_ERROR = 'FLEET_MANAGEMENT_VEHICLE_JOB_ERROR';

export const UPDATE_VEHICLE_REQUEST = 'UPDATE_VEHICLE_REQUEST';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_ERROR = 'UPDATE_VEHICLE_ERROR';

export const LIVE_VEHICLE_STATS_REQUEST = 'LIVE_VEHICLE_STATS_REQUEST';
export const LIVE_VEHICLE_STATS_SUCCESS = 'LIVE_VEHICLE_STATS_SUCCESS';
export const LIVE_VEHICLE_STATS_ERROR = 'LIVE_VEHICLE_STATS_ERROR';

export const LIVE_VEHICLE_SAVE_STATS_REQUEST = 'LIVE_VEHICLE_SAVE_STATS_REQUEST';
export const LIVE_VEHICLE_SAVE__STATS_SUCCESS = 'LIVE_VEHICLE_SAVE__STATS_SUCCESS';
export const LIVE_VEHICLE_SAVE_STATS_ERROR = 'LIVE_VEHICLE_SAVE_STATS_ERROR';

export const MONTHLY_TARGETS_REQUEST = 'MONTHLY_TARGETS_REQUEST';
export const MONTHLY_TARGETS_SUCCESS = 'MONTHLY_TARGETS_SUCCESS';
export const MONTHLY_TARGETS_ERROR = 'MONTHLY_TARGETS_ERROR';

export const SAVE_MONTHLY_TARGETS_REQUEST = 'SAVE_MONTHLY_TARGETS_REQUEST';
export const SAVE_MONTHLY_TARGETS_ERROR = 'SAVE_MONTHLY_TARGETS_ERROR';
export const SAVE_MONTHLY_TARGETS_SUCCESS = 'SAVE_MONTHLY_TARGETS_SUCCESS';

export const FETCH_MONTHLY_SUMMARY_REQUEST = 'FETCH_MONTHLY_SUMMARY_REQUEST';
export const FETCH_MONTHLY_SUMMARY_SUCCESS = 'FETCH_MONTHLY_SUMMARY_SUCCESS';
export const FETCH_MONTHLY_SUMMARY_ERROR = 'FETCH_MONTHLY_SUMMARY_ERROR';

export const FETCH_VEHICLE_REPORT_REQUEST = 'FETCH_VEHICLE_REPORT_REQUEST';
export const FETCH_VEHICLE_REPORT_SUCCESS = 'FETCH_VEHICLE_REPORT_SUCCESS';
export const FETCH_VEHICLE_REPORT_ERROR = 'FETCH_VEHICLE_REPORT_ERROR';

export const UPLOAD_MONTHLY_REPORT_TO_DRIVE_REQUEST = 'UPLOAD_MONTHLY_REPORT_TO_DRIVE_REQUEST';
export const UPLOAD_MONTHLY_REPORT_TO_DRIVE_SUCCESS = 'UPLOAD_MONTHLY_REPORT_TO_DRIVE_SUCCESS';
export const UPLOAD_MONTHLY_REPORT_TO_DRIVE_ERROR = 'UPLOAD_MONTHLY_REPORT_TO_DRIVE_ERROR';

export const UPLOAD_VEHICLES_REPORT_TO_DRIVE_REQUEST = 'UPLOAD_VEHICLES_REPORT_TO_DRIVE_REQUEST';
export const UPLOAD_VEHICLES_REPORT_TO_DRIVE_SUCCESS = 'UPLOAD_VEHICLES_REPORT_TO_DRIVE_SUCCESS';
export const UPLOAD_VEHICLES_REPORT_TO_DRIVE_ERROR = 'UPLOAD_VEHICLES_REPORT_TO_DRIVE_ERROR';

export const UPLOAD_DELIVERY_REPORT_TO_DRIVE_REQUEST = 'UPLOAD_DELIVERY_REPORT_TO_DRIVE_REQUEST';
export const UPLOAD_DELIVERY_REPORT_TO_DRIVE_SUCCESS = 'UPLOAD_DELIVERY_REPORT_TO_DRIVE_SUCCESS';
export const UPLOAD_DELIVERY_REPORT_TO_DRIVE_ERROR = 'UPLOAD_DELIVERY_REPORT_TO_DRIVE_ERROR';

export const FLEET_STATS_REPORT_TO_DRIVE_REQUEST = 'FLEET_STATS_REPORT_TO_DRIVE_REQUEST';
export const FLEET_STATS_REPORT_TO_DRIVE_SUCCESS = 'FLEET_STATS_REPORT_TO_DRIVE_SUCCESS';
export const FLEET_STATS_REPORT_TO_DRIVE_ERROR = 'FLEET_STATS_REPORT_TO_DRIVE_ERROR';

export const UPLOAD_VEHICLE_CHECK_TO_DRIVE_REQUEST = 'UPLOAD_VEHICLE_CHECK_TO_DRIVE_REQUEST';
export const UPLOAD_VEHICLE_CHECK_TO_DRIVE_SUCCESS = 'UPLOAD_VEHICLE_CHECK_TO_DRIVE_SUCCESS';
export const UPLOAD_VEHICLE_CHECK_TO_DRIVE_ERROR = 'UPLOAD_VEHICLE_CHECK_TO_DRIVE_ERROR';

export const GENERATE_TASKS_REQUEST = 'GENERATE_TASKS_REQUEST';
export const GENERATE_TASKS_SUCCESS = 'GENERATE_TASKS_SUCCESS';
export const GENERATE_TASKS_ERROR = 'GENERATE_TASKS_ERROR';

export const SET_FILTERS = 'SET_FILTERS';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';

export const SET_REFERER = 'SET_REFERER';
export const CLEAN_REFERER = 'CLEAN_REFERER';

export const GET_VEHICLE_MAKES_REQUEST = 'GET_VEHICLE_MAKES_REQUEST';
export const GET_VEHICLE_MAKES_SUCCESS = 'GET_VEHICLE_MAKES_SUCCESS';
export const GET_VEHICLE_MAKES_ERROR  =   'GET_VEHICLE_MAKES_ERROR';

export function fetchLiveVehicle(id, vehicle) {
    return {
        type: LIVE_VEHICLE_EDIT_REQUEST,
        payload: { id, vehicle }
    };
}

export function fetchIntervalStats(min, max) {
    return {
        type: LIVE_VEHICLE_STATS_REQUEST,
        payload: { min, max }
    };
}

export function saveIntervalStats(stats) {
    return {
        type: LIVE_VEHICLE_SAVE_STATS_REQUEST,
        payload: { stats }
    };
}

export function fetchVehicleJobs(vehicleId) {
    return {
        type: FLEET_MANAGEMENT_VEHICLE_JOB_REQUEST,
        payload: { vehicleId }
    };
}

export function updateVehicle(liveId, vehicleId, data) {
    return {
        type: UPDATE_VEHICLE_REQUEST,
        payload: {
            vehicleId, data, liveId
        }
    };
}

export function setFilters(filters, type) {
    return {
        type: SET_FILTERS,
        payload: {
            filters, type
        }
    };
}
export function cleanFilters(type) {
    return {
        type: CLEAN_FILTERS,
        payload: {
            type
        }
    };
}

export function fetchMonthlyTargets(month, year) {
    return {
        type: MONTHLY_TARGETS_REQUEST,
        payload: { month, year }
    };
}

export function saveMonthlyTargets(inputs, month, year) {
    return {
        type: SAVE_MONTHLY_TARGETS_REQUEST,
        payload: {
            inputs, month, year
        }
    };
}

export function fetchMonthSummary(month, year) {
    return {
        type: FETCH_MONTHLY_SUMMARY_REQUEST,
        payload: {
            month, year
        }
    };
}

export function fetchVehiclesReport(month, year) {
    return {
        type: FETCH_VEHICLE_REPORT_REQUEST,
        payload: {
            month, year
        }
    };
}

export function setReferer(referer) {
    return {
        type: SET_REFERER,
        payload: {
            referer
        }
    };
}

export function cleanReferer() {
    return {
        type: CLEAN_REFERER
    };
}