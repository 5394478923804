import React, {useEffect, useRef, useState} from 'react'
import { Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    CustomHeader, CustomInput,
    FleetButton,
} from "@pages/new-design-components/custom-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {useHistory, useLocation} from "react-router-dom";
import * as constants from "../../../constants";
import axios from "axios";
import Mechanics from "@pages/components/JobDetailsComponents/Mechanics";
import {convertMinutesToHoursAndMinutes, paginateArray} from "../../../constants";
import JobCard from "@pages/components/JobDetailsComponents/JobCard";
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";
import InspectionReport from "@pages/components/JobDetailsComponents/InspectionReport";
import ServiceRecordPreviewComponent from "@pages/components/JobDetailsComponents/ServiceRecordPreviewComponent";

import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import NotificationAlert from "react-notification-alert/index";
import {Notification} from "@pages/components/Notification";
import ServiceRecordComponentForPrint from "@pages/components/JobDetailsComponents/ServiceRecordComponentForPrint";


export default function MechanicalDetails() {
    const [loading,setLoading] = useState(true);
    const [showServiceReport,setShowServiceReport] = useState(false);
    const [generatingReport,setGeneratingReport] = useState(false);
    const [inspection,setShowInspection] = useState(false);
    const notificationAlert = useRef(null);
    const [data,setData] = useState({});
    const [fleetTasks,setFleetTasks] = useState([]);
    const [fleetReportTasks,setFleetReportTasks] = useState([]);
    const [partsData,setPartsData] = useState([]);
    const [deletePartIds,setDeletePartIds] = useState([]);
    const [vehicle,setVehicle] = useState({});
    const [fleetNote,setFleetNote] = useState([]);
    const [existingInspections,setExistingInspections] = useState([]);
    const [deleteIds,setDeleteIds] = useState([]);
    const location = useLocation();
    const history = useHistory();
    const [selectedInspection,setSelectedInspection] = useState(0);
    const [inspectionItems,setInspectionItems] = useState([]);
    const [changedValues, setChangedValues] = useState([]);
    const { vehicleId, fleetId, department } = location.state || {};
    const fetchData = () => {
        axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-task/vehicle`,
            params: {
                vehicleId,
                fleetId,
                department
            }
        }).then(res=>{
            const response = {...res.data.data[0]}
            setData(response)
            setFleetNote(response.fleet_vehicle.note)
            let tasks = response.fleet_vehicle?.tasks
            setExistingInspections(response.fleet_vehicle.inspections)
            setPartsData(response.parts)
            tasks = tasks.map(item => {
                const { hours, minutes } = convertMinutesToHoursAndMinutes(item.total_worked_time);
                return {
                    ...item,
                    hours,
                    minutes
                };
            });
                axios({
                    method: 'GET',
                    url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quote-vehicle`,
                    params: {
                        vehicleId,
                    },
                }).then(res=>{
                    const data = res.data.data
                    setVehicle(data.vehicle)
                    setFleetTasks(tasks)
                    setLoading(false)
                })

        }).catch(err=>{
            setLoading(false)
        })
    }
    useEffect(() => {
        if (vehicleId && fleetId && department) {
            fetchData()
        }
    }, [vehicleId, fleetId,department]);

    const saveVehicleDetails = (e) => {
        let newData = {...e}
        newData.derivative = data.derivative;
        newData.vehicle_model_id = data.model.id
        axios.put(`${constants.API_URL}/vehicle/${vehicleId}`,newData).then(res=>{
            console.log('res',res);
        }).catch(err=>{
            console.log('err',err)
        })
    }

    const selectInspectionItem = (index) => {
        setSelectedInspection(index)
    }
    const nextInspectionItem = () => {
        setSelectedInspection(selectedInspection + 1)
    }

    const addNewValetingRow = (inspection = 0) => {
        let tempData = [...fleetTasks]
        let row =  {
            fleet_id: data.fleet_vehicle?.id,
            title: '',
            total_worked_time: 0,
            hours: 0,
            minutes: 0,
            "description": "",
            "priority": "medium",
            "added_to_report": inspection,
            job_category: department,
            status: 'to_do',
            "completed_at": null,
            "started_at": null,
            "note": "",
            "price": 0,
            "options": null,
            is_auto_generated: false,
            "fleet_category_option_id": null,
            "department_id": data.department_id,
        }
        tempData.push(row)
        if (inspection) {
            row.originalIndex = tempData.length - 1
            setFleetReportTasks(prevFleetReportTasks => [...prevFleetReportTasks, row]);
        }
        editValetingData(tempData)
    }

    const editValetingData = (tempData) => {
        setFleetTasks(tempData);
    }

    const changeValetingData = ({index,key,value = null, inspection = false}) => {
        let tempData = [...fleetTasks]
        let tempReportData = [...fleetReportTasks];
        if(key === 'status') {
            tempData[index].status = (tempData[index].status === 'completed' ? 'to_do' : 'completed')
        } else if(key === 'added_to_report') {
            tempData[index].added_to_report = tempData[index].added_to_report === 0 ? 1 : 0
        } else {
              if (inspection) {
                  tempReportData[index][key] = value;
                  setFleetReportTasks(tempReportData);
                  if (tempReportData[index].id) {
                      const originalIndex = tempData.findIndex(item=>item.id = tempReportData[index].id)
                      tempData[originalIndex][key] = value
                      tempData[originalIndex]['edited'] = true
                  } else {
                      tempData[tempReportData[index].originalIndex][key] = value
                  }
              } else {
                  tempData[index][key] = value
              }
        }

        if (tempData[index]?.id && !inspection) {
             tempData[index]['edited'] = true
        }

        editValetingData(tempData)
    }

    const deleteValetingRow = (index) => {
        let tempData = [...fleetTasks];
        let taskToDelete = tempData[index];
        let deleteIdsTemp = [...deleteIds];
        if (taskToDelete?.id) {
            deleteIdsTemp.push(taskToDelete.id);
        }
        tempData.splice(index, 1);
        setDeleteIds(deleteIdsTemp)
        editValetingData(tempData)
    }

    const deleteTasks = async (deleted) => {
        const promises = deleted.map(item =>
            axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/fleet/task/${item}/archive`)
        );

        try {
            await Promise.all(promises);
            setDeleteIds([])
            console.log('All delete tasks have been archived successfully.');
        } catch (error) {
            console.error('Error while deleting tasks:', error);
        }
    }

    const updateTasks = async (updated) => {
        if (updated.length) {
            const promises = updated.map(async (item) => {
                item.total_worked_time = (item.hours * 60) + item.minutes
                const formData = new FormData();
                for (const key in item) {
                    if (item.hasOwnProperty(key)) {
                        if (key === 'documents') continue;
                        formData.append(key, item[key]);
                    }
                }
                if (item.documents && item.documents.length > 0) {
                    for (const document of item.documents) {
                        if (document.file) {
                            formData.append('documents[]', document.file);
                        }
                    }
                }
                try {
                    const response = await axios.post(
                        `${constants.FLEET_MS_API_URL}/fleet-management/fleet/task/${item?.id}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data', // Important for file uploads
                            },
                        }
                    );
                    return response.data;
                } catch (error) {
                    console.error('Error while updating task:', error);
                    throw error;
                }
            });
            try {
                await Promise.all(promises);
                console.log('All tasks have been updated successfully.');
            } catch (error) {
                console.error('Error while updating tasks:', error);
            }
        }
    };

    const deleteParts = async (deleted) => {
        try {
            const response = await axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/fleet/parts/delete`,{
                ids: deleted
            })
            if (response.status == '200') {
                setDeletePartIds([]);
            }
        } catch (error) {
            console.error('Error while deleting parts:', error);
        }
    }

    const createTasks = async (created) => {
        if (created.length) {
            const promises = created.map(async (item) => {
                item.total_worked_time = (item.hours * 60) + item.minutes

                try {
                    const response = await axios.post(
                        `${constants.FLEET_MS_API_URL}/fleet-management/fleet/${data.fleet_vehicle?.id}/task`,
                        item,  // Send the task data directly as JSON
                        {
                            headers: {
                                'Content-Type': 'application/json',  // Set the header for JSON payload
                            },
                        }
                    );
                    return response.data;
                } catch (error) {
                    console.error('Error while creating task:', error);
                    throw error;
                }
            });

            try {
                await Promise.all(promises);
                console.log('All new tasks have been created successfully.');
            } catch (error) {
                console.error('Error while creating tasks:', error);
            }
        }
    };

    const createParts = async (created) => {
        const promises = created.map(item => {
            item.department_id = data.department_id;
            axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/fleet/parts`, item)
        });

        try {
            await Promise.all(promises);
            console.log('All new tasks have been created successfully.');
        } catch (error) {
            console.error('Error while creating tasks:', error);
        }
    }

    const updateParts = async (updated) => {
        const promises = updated.map(item =>
            axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/fleet/parts/update`, item)
        );

        try {
            await Promise.all(promises);
            console.log('All new tasks have been created successfully.');
        } catch (error) {
            console.error('Error while creating tasks:', error);
        }
    }

    const updateWorkingRecords = async () => {
        setLoading(true);
        const created = fleetTasks.filter(item => item.id === undefined);
        const updated = fleetTasks.filter(item => item.edited === true);
        const createdPartsData = partsData.filter(item => item.id === undefined);
        const updatedPartsData = partsData.filter(item => item.edited === true);
        try {
            if (deletePartIds.length) {
                await deleteParts(deletePartIds)
            }
            await Promise.all([
                updateTasks(updated),
                createTasks(created),
                deleteTasks(deleteIds),
                createParts(createdPartsData),
                updateParts(updatedPartsData),
            ]);

            await fetchData()
        } catch (error) {
            console.error('Error while processing tasks:', error);
        } finally {
            setLoading(false);
        }
    }

    const redirectToManagement = () => {
        history.push({
            pathname: '/admin/fleet-vehicles-list'
        })
    }

    const changePartsData = ({index,key,value = null}) => {
        let tempData = [...partsData]
        if(key === 'added_to_report') {
            tempData[index].added_to_report = tempData[index].added_to_report === 0 ? 1 : 0
        } else {
            tempData[index][key] = value
        }

        if (tempData[index]?.id) {
            tempData[index]['edited'] = true
        }
        setPartsData(tempData)
    }

    const deletePartsRow = (index) => {
        let tempData = [...partsData];
        let taskToDelete = tempData[index];
        let deleteIdsTemp = [...deletePartIds];
        if (taskToDelete?.id) {
            deleteIdsTemp.push(taskToDelete.id);
        }
        tempData.splice(index, 1);
        setDeletePartIds(deleteIdsTemp)
        setPartsData(tempData)
    }

    const addNewPartsRow = () => {
        let tempData = [...partsData]
        let row =  {
            fleet_id: data.fleet_vehicle?.id,
            vehicle_id: vehicleId,
            status: 'to_do'
        }
        tempData.push(row)
        setPartsData(tempData)
    }

    const addInspectionImages = async (documents,inspectionId,vehicleId) => {
        if (documents.length) {
            let formData = new FormData()
            for (const document of documents) {
                if (document.file) {
                    formData.append('documents[]', document.file);
                }
            }
            formData.append('fleet_id',inspectionId)
            formData.append('vehicle_id',vehicleId)
            try {
                const response = await axios.post(
                    `${constants.FLEET_MS_API_URL}/fleet-management/add-inspection-images`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                return response.data;
            } catch (error) {
                console.error('Error while updating task:', error);
                throw error;
            }
        }
    }
    const updateInspectionResult = async () => {

        const created = fleetTasks.filter(item => item.id === undefined);
        const updated = fleetTasks.filter(item => item.edited === true);
        try {
          await axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/add-inspection-form`, {
                inspectionValues: [...changedValues],
                fleetId: data.fleet_vehicle.id,
                fleetNote,
                vehicleId,
            }).then(async (res)=>{
                setLoading(true)
                await Promise.all([
                    createTasks(created),
                    updateTasks(updated),
                    deleteTasks(deleteIds),
                    addInspectionImages(inspectionItems[inspectionItems.length - 1].options,data.fleet_vehicle.id,vehicleId)
                ]);
                await fetchData()
                await getReportForm()
            }).catch((err)=>{
                console.log('err:',err)
                Notification('warning', 'error', 'Error ',notificationAlert);
                setLoading(false)
                setShowServiceReport(false)
            })

        } catch (error) {
            console.error('Error while processing tasks:', error);
        } finally {
            setLoading(false);
        }

    }

    const getReportForm = () => {
        axios.get(`${constants.FLEET_MS_API_URL}/fleet-management/inspection-form`).then(res=>{
            let tempData = res.data.data?.categories;
            if (existingInspections.length) {
                existingInspections.map(existing=>{
                    tempData.map(item=>{
                        return item.options.map(option=>{
                            if (option.id === existing.option_id) {
                                option.status = existing.status
                                option.existing_id = existing.id
                            }
                        })
                    })
                })
            }

            setFleetReportTasks(fleetTasks.filter(item=>item.added_to_report))
            tempData.push({
                name: 'Damage Photos',
                options: data.fleet_vehicle.inspection_images,
                type: 'image'
            })
            setInspectionItems(tempData)
            setShowServiceReport(true)
        }).catch(err=>{
            console.log(err)
        })
    }
    const handleChange = (values) => {
        setChangedValues(values);
    };
    const deleteDocument = (fileIndex,index) => {
        let tempData = [...inspectionItems];
        if (tempData[index] && tempData[index].options) {
            tempData[index].options = tempData[index].options.filter((_, idx) => idx !== fileIndex);
        }
        setInspectionItems(tempData);
    }
    const handleFileSelect = async (files, index) => {
        if (files && files.length > 0) {
            let tempData = [...inspectionItems];
            if (!tempData[index].options) {
                tempData[index].options = [];
            }
            Array.from(files).forEach((file) => {
                let reader = new FileReader();
                reader.readAsDataURL(file);

                const preview = URL.createObjectURL(file);
                tempData[index].options.push({
                    preview,
                    file,
                });
            });

            setInspectionItems(tempData);
        }
    }
    const sendServiceReport = () => {
        const input = document.getElementById('pdf-content-component');

        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png',0.5);
            const pdf = new jsPDF('l', 'mm', [220, 500]);
            let pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgWidth = pdfWidth;
            let imgHeight = (canvas.height * pdfWidth) / canvas.width;

            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
            }
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            const pdfBlob = pdf.output('blob');
            const formData = new FormData();
            const pdfFileName = `vehicle_${vehicleId}_generated.pdf`;
            formData.append('vehicle_id', vehicleId);
            formData.append('type', 'service_report');
            formData.append('pdf', pdfBlob, pdfFileName);
            axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/save-report`,
                data: formData
            }).then(res=>{
                setShowInspection(false)
                Notification('success', '', 'Successfully generated',notificationAlert);
            }).catch(err=>{
                setShowInspection(false)
                Notification('warning', '', 'Service Report did not generated!',notificationAlert);
            })
        });
    };
    const sendPdf = () => {
        setGeneratingReport(true)
        const input = document.getElementById('pdf-content');
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png',0.5);

            const pdf = new jsPDF('p', 'mm', [220, 500]);
            let pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgWidth = pdfWidth;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;

            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
            }
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            const pdfBlob = pdf.output('blob');
            const formData = new FormData();

            const pdfFileName = `vehicle_${vehicleId}_generated.pdf`;
            formData.append('vehicle_id', vehicleId);
            formData.append('email', vehicle?.email);
            formData.append('pdf', pdfBlob, pdfFileName);
            formData.append('type', 'inspection_report');
            axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/save-report`,
                data: formData
            }).then(res=>{
                setGeneratingReport(false)
                Notification('success', 'Success', 'Created Successfully',notificationAlert);

            }).catch(err=>{
                setGeneratingReport(false)
                Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);

            })
        });
    };

    const handleGeneratePDF = () => {
        const input = document.getElementById('pdf-content-component');
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');

            const pdf = new jsPDF('l', 'mm', 'a4');
            let pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgWidth = pdfWidth;
            let imgHeight = (canvas.height * pdfWidth) / canvas.width;

            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
            }

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('generated-pdf.pdf');
        });
    };
    return (
        loading ? <div/> :
            inspection ?
                <> <ServiceRecordPreviewComponent backToList={()=>{setShowInspection(false)}}
                                                  vehicle={vehicle}
                                                  vehicleId={vehicleId}
                                                  sendPdf={sendServiceReport}
                                                  handleGeneratePDF={handleGeneratePDF}
                                                  partsData={partsData.filter(item=>item.added_to_report)}
                                                  total={partsData.filter(item=>item.added_to_report)?.reduce((accumulator, part) => {
                                                      return accumulator + part.cost;
                                                  }, 0)}
                />
                    <ServiceRecordComponentForPrint
                        parsable={true}
                        vehicle={vehicle}
                        partsData={partsData.filter(item=>item.added_to_report)}
                        total={partsData.filter(item=>item.added_to_report)?.reduce((accumulator, part) => {
                            return accumulator + (part.cost);
                        }, 0)}
                    />
                </>
               :
            <div className={'container-fluid mt-3'}>
                <NotificationAlert ref={notificationAlert}/>
                <BackActionComponent backAction={redirectToManagement} text={'Back to overview'}/>
                <div className={'d-flex justify-content-between'}>
                    <CustomHeader lineHeight={'unset'} >Job Card</CustomHeader>
                    <div className={'d-flex'}>
                        {
                            showServiceReport ?
                                <>
                                    <FleetButton
                                        height={'40px'}
                                        width={'164px'}
                                        disabled={generatingReport}
                                        backgroundColor={'#ec3030'}
                                        radius={'8px'}
                                        onClick={()=>{setShowServiceReport(false)}}
                                    >
                                        Cancel
                                    </FleetButton>
                                    <FleetButton
                                        height={'40px'}
                                        width={generatingReport ? '264px' : '164px'}
                                        backgroundColor={'#F3994A'}
                                        radius={'8px'}
                                        disabled={generatingReport}
                                        onClick={()=>{
                                            if (selectedInspection === inspectionItems.length - 1) {
                                                 sendPdf()
                                                updateInspectionResult()
                                            } else {
                                                nextInspectionItem()
                                            }
                                        }}
                                    >
                                        {generatingReport ? 'Generating Report' : selectedInspection === inspectionItems.length - 1 ? 'Save' : 'Next'}
                                    </FleetButton>
                                </>
                                :
                                <>
                                    <FleetButton
                                        height={'40px'}
                                        width={'224px'}
                                        padding={'0 7px 0 0'}
                                        backgroundColor={'#F3994A'}
                                        radius={'8px'}
                                        onClick={()=>{
                                            setShowInspection(true)
                                        }}
                                    >
                                        <IcoMoon
                                            className={'cursor-pointer'}
                                            iconSet={IconSet}
                                            icon={'icont-wrench'}
                                            width={'50px'}
                                            height={'20px'}
                                        />
                                        View Service Record
                                    </FleetButton>
                                    <FleetButton
                                        height={'40px'}
                                        width={'164px'}
                                        backgroundColor={'#219852'}
                                        radius={'8px'}
                                        onClick={updateWorkingRecords}
                                    >
                                        Save
                                    </FleetButton>
                                </>
                        }
                    </div>
                </div>
                <Row className={'mt-4'}>
                    <Col lg={3} >
                        <JobCard data={data} saveVehicleDetails={saveVehicleDetails}/>
                        {
                            showServiceReport ?
                                <Card className={'fleet-details-container'}>
                                    <CardHeader className={'border-bottom-0'}>
                                        <div className={'d-flex g-5'}>
                                            <img  height='25px' width='25px'
                                                 onClick={()=>{}} src={require(`assets/images/edit.svg`)} alt=""/>
                                            <CustomHeader fontSize={'16px'}>
                                                Technician Comment
                                            </CustomHeader>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                            <CustomInput
                                                type="textarea"
                                                rows={5}
                                                placeholder={'Type here...'}
                                                name="current_mileage"
                                                value={fleetNote}
                                                onChange={(e)=>{
                                                    setFleetNote(e.target.value)
                                                }}
                                            />
                                    </CardBody>
                                </Card>

                                :
                                    <div className={'d-flex flex-column g-10 fleet-details-container'}>
                                        <FleetButton
                                            height={'40px'}
                                            padding={'0 7px 0 0'}
                                            width={'100%'}
                                            backgroundColor={'#000000'}
                                            radius={'8px'}
                                            onClick={getReportForm}
                                        >
                                            <IcoMoon
                                                className={'cursor-pointer'}
                                                iconSet={IconSet}
                                                icon={'icon_eye'}
                                                width={'50px'}
                                                height={'20px'}
                                            />
                                          Inspection Report
                                        </FleetButton>
                                    </div>
                        }
                    </Col>
                    {
                        showServiceReport ?
                            <InspectionReport
                                selectedInspection={selectedInspection}
                                inspectionItems={inspectionItems}
                                vehicle={vehicle}
                                data={fleetReportTasks}
                                selectInspectionItem={selectInspectionItem}
                                handleUpdate={handleChange}
                                deleteRow={deleteValetingRow}
                                changedValues={changedValues}
                                addNewRow={()=>{
                                    addNewValetingRow(1)
                                }}
                                changeData={changeValetingData}
                                activeIndex={selectedInspection}
                                deleteDocument={deleteDocument}
                                onFileSelect={handleFileSelect}
                                inspectionValues={inspectionItems[selectedInspection].options?.length ? paginateArray(inspectionItems[selectedInspection].options,8) : []}
                            /> :
                            <Mechanics
                                data={fleetTasks}
                                addNewRow={()=>{addNewValetingRow()}}
                                changeData={changeValetingData}
                                deleteRow={deleteValetingRow}
                                partsData={partsData}
                                changePartsData={changePartsData}
                                deletePartsRow={deletePartsRow}
                                addNewPartsRow={addNewPartsRow}
                            />
                    }

                </Row>

            </div>
    )
}