import React, {Fragment} from "react";
import {
    CustomBadge,
    CustomHeader,
    CustomTableRow,
    CustomTD, Dot, OptionContainer, OptionsContainer, StatusSpan,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import ThreeDots from "@assets/images/mi_options-vertical.svg";
import {UncontrolledPopover} from "reactstrap";
import {badgeColors, checkAndFormatShortGb, formatNumber, parseToWord} from "../../../../constants";
import {LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function DebtManagementTable({keys,tableData = [],viewDetails, optionValues, loading = false,download}) {

    const generateItem = ({item,index,key, itemIndex}) => {
        if (key === 'customer_name') {
            return <CustomTD key={index}>
                {item.first_name} {item.last_name}
            </CustomTD>
        } else if (key === 'customer_name_deep') {
            return <CustomTD key={index}>
                {item.customer.first_name} {item.customer.last_name}
            </CustomTD>
        } else if (key === 'view') {
            return  <CustomTD color={'#3F8CFF'} onClick={()=>{ viewDetails(itemIndex) }}
                              className={'text-center cursor-pointer'}
            >  View </CustomTD>
        } else if (key === 'debt_status') {
            return  <CustomTD color={'#3F8CFF'} onClick={()=>{ viewDetails(itemIndex) }}
                              className={'cursor-pointer'}
            >
                <Dot width={'12px'} color={badgeColors(item.debt_status)}/>
                <StatusSpan type={item.debt_status}>
                    {parseToWord(item.debt_status) || 'N/A'}
                </StatusSpan>
            </CustomTD>
        } else if (key === 'amount_due') {
            return <CustomTD key={index} color={'#219852'}>
                £{formatNumber(item.amount_due)}
            </CustomTD>
        }  else if (key === 'vehicle') {
            return <CustomTD key={index}>
               {item?.vehicle?.derivative || '-'}
            </CustomTD>
        }  else if (key === 'download') {

            return <CustomTD key={index}>
                <IcoMoon
                    onClick={()=>{
                        download(itemIndex)
                    }}
                    className={'cursor-pointer'}
                    iconSet={IconSet}
                    icon={'download'}
                    size={24}
                />
            </CustomTD>
        } else if (key === 'overdue_duration') {
            const dueDate = new Date(item.due_date);
            const todayDate = new Date();
            const overdueDuration = Math.floor((todayDate - dueDate) / (1000 * 60 * 60 * 24));
            return  <CustomTD color={'#EB5855'}>
                      {overdueDuration} days
            </CustomTD>
        }  else if (key === 'options') {
            return  <CustomTD color={'#3F8CFF'}
                              className={'text-center cursor-pointer'}
            >    <img src={ThreeDots} id={`PopoverLegacy_${index}`} />
                <UncontrolledPopover
                    placement="left"
                    className="fleet-popover"
                    target={`PopoverLegacy_${index}`}
                    trigger="legacy"
                >
                    <OptionsContainer className={'cursor-pointer'}>
                        {optionValues.map(item=>{
                            return (   <OptionContainer
                                    onClick={(e)=>{
                                        item['action'](itemIndex)
                                    }}
                                >
                                    <CustomHeader  fontSize={'14px'} weight={'400'}>
                                        {item['label']}
                                    </CustomHeader>

                                </OptionContainer>

                            )
                        })}
                    </OptionsContainer>
                </UncontrolledPopover>
            </CustomTD>

        } else if (key === 'due_date' || key === 'expected_payment_date' || key === 'date') {
            let value = parseToWord(item[key]?.toString())
            return  <CustomTD key={index}>
                    <IcoMoon
                        iconSet={IconSet}
                        icon={'ic_calendar'}
                        size={24}
                    />
                    {checkAndFormatShortGb(value)}</CustomTD>
        }
        return <CustomTD key={index}>
            {item[key] ? item[key] : '-'}
        </CustomTD>
    }
    return (
        loading ?
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={loading}
                />
            :
        <StyledTable className={'table-borderless'}>

            <thead className={'header-white-text'}>
            <tr>
                {keys.map((item,index)=>{
                    return  item.key !== 'view' &&  <Fragment key={index}>
                        <StyledTableHeaderCell fontSize='14px' color={'#0000008C'}>
                            {item.label}
                        </StyledTableHeaderCell>
                    </Fragment>
                })}

            </tr>
            </thead>
            <tbody>
            {tableData.length ?
                tableData.map((item,index)=>{
                    return <CustomTableRow key={index}>
                        {
                            keys.map(({key}, keyIndex) => {
                                return generateItem({item, index: `${index}_${keyIndex}`,key, itemIndex: index});
                            })
                        }
                    </CustomTableRow>

                }) : null
            }
            </tbody>
        </StyledTable>
    )
}