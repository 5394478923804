import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import {
  hasPermission
} from 'views/policies/DocumentPolicy';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function Documents() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const permissions = useSelector(state => state.getState).permissions;
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [activeStatuses, setActiveStatuses] = useState([]);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/dealer/documents`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setDocuments(data.data.documents)
        setActiveStatuses(data.data.activeStatuses)

        setTimeout(() => {
          setLoading(false)
        }, 500);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const addDocument = () => {
    history.push({
      pathname: `/admin/settings/documents/create`
    })
  }

  const goEdit = (documentId) => {
    history.push({
      pathname: `/admin/settings/documents/${documentId}/edit`
    })
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="Document Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Documents</h1>
                    <p className="text-sm mb-0">You can manage your own documents and contracts.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer onClick={() => addDocument()}>Add Document</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={documents}
                  keyField={`id`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "document_name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "description",
                      text: "Description",
                      sort: true
                    },
                    {
                      dataField: "enabled",
                      text: "Enabled",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return <span className={`badge badge-${row.is_active ? 'success' : 'dark'}`}>{activeStatuses[row.is_active? 1: 0]}</span>
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            { (hasPermission(permissions, 'read-dealer-documents') || hasPermission(permissions, 'delete-dealer-documents')) &&
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem onClick={() => goEdit(row.id)}
                                >
                                  Edit
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>}
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}