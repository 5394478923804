import styled from "styled-components";
import {badgeColors} from "../../../constants";
import {Button, Dropdown, DropdownToggle, Input} from "reactstrap";
import DatePicker from "react-datepicker";
const  CustomBadge = styled.div`
 width: fit-content;
 color: ${props => badgeColors(props.type)};
 background-color: ${props => badgeColors(props.type, 0.15)};
 border-radius: 16px;
 padding: 4px 10px;
 border: ${props => props.active ? `1px solid ${badgeColors(props.type)}` : 'none' };
 box-shadow: ${props => props.active ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none' };
 transition: transform 0.3s ease;
 cursor: pointer;
 &:hover {
       transform: translateY(-2px);
 }
  @media (max-width: 768px) {
    padding: 6px 12px; 
    font-size: 14px;
    border-radius: 12px;
    width: auto;
    white-space: nowrap;
  }

  @media (max-width: 480px) {
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 10px;
    white-space: nowrap;
  }
}`
const  DepartmentsBadge = styled.div`
 width: fit-content;
 color: #000000;
 background-color: ${props => props.active ? badgeColors(props.type, 0.15) : '#FFFFFF'};
 border-radius: 25px;
 padding: 8px 12px;
 border: ${props => props.active ? `1px solid ${badgeColors(props.type)}` : 'none' };
 box-shadow: ${props => props.active ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none' };
 transition: transform 0.3s ease;
 cursor: pointer;
 display: flex;
 justify-content: space-between;
 gap: 5px;
 align-items: center;
 &:hover {
       transform: translateY(-2px);
 }
   @media (max-width: 768px) {
    padding: 6px 12px; 
    font-size: 14px;
    border-radius: 12px;
    width: auto;
    white-space: nowrap;
  }

  @media (max-width: 480px) {
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 10px;
    white-space: nowrap;
  }
}`
const RequiredItemsBadge = styled.div`
 width: ${props => props.width ? props.width : '220px' }; 
 font-size: ${props => props.fontSize}; 
 align-content: center;
 height: 37px;
 text-align: center;
 color:  ${props => props.active ? `#ffffff` : '#000000' };
 background-color: ${props => props.active ? `#000000` : (props.backgroundColor || '#ffffff') };
 border-radius: 16px;
 padding: 4px 10px;
 box-shadow: ${props => props.active ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none' };
 transition: transform 0.3s ease;
 cursor: pointer;
 &:hover {
       transform: translateY(-2px);
 }
   @media (max-width: 768px) {
    padding: 6px 12px; 
    font-size: 14px;
    border-radius: 12px;
    width: auto;
    white-space: nowrap;
  }

  @media (max-width: 480px) {
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 10px;
    white-space: nowrap;
  }
}`
const PriorityBadge = styled.div`
 width: fit-content;
 color: #FFFFFF;
 background-color: ${props => badgeColors(props.type)};
 border-radius: 16px;
 padding: 2px 12px;
 border: ${props => props.active ? `1px solid ${badgeColors(props.type)}` : 'none' };
 transition: transform 0.3s ease;
 cursor: pointer;
 &:hover {
       transform: translateY(-2px);
 }
   @media (max-width: 768px) {
    padding: 6px 12px; 
    font-size: 14px;
    border-radius: 12px;
    width: auto;
    white-space: nowrap;
  }

  @media (max-width: 480px) {
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 10px;
    white-space: nowrap;
  }
}`

const StatusBadge = styled.div`
 width: fit-content;
 color: ${props => badgeColors(props.type)};
 border-radius: 32px;
 padding: 4px 10px;
 border: 1px solid ${props => badgeColors(props.type)};
 box-shadow: ${props => props.active ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none' };
 transition: transform 0.3s ease;
 cursor: pointer;
 &:hover {
       transform: translateY(-2px);
 }
   @media (max-width: 768px) {
    padding: 6px 12px; 
    font-size: 14px;
    border-radius: 12px;
    width: auto;
    white-space: nowrap;
  }

  @media (max-width: 480px) {
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 10px;
    white-space: nowrap;
  }
}`
const UploadedDocumentInformationContainer = styled.div`
  background-color: #FFFFFF; 
  border-radius: 8px;
  padding: 10px;
  width: 250px;
  height: 75px;
  align-content: center;
`

const CustomHeader = styled.h3`
  font-weight: ${props => props.weight ? props.weight : '700'} ;
  font-size:  ${props => props.fontSize ? props.fontSize : '24px'};
  line-height: ${props => props.lineHeight ? props.lineHeight : '29px'};
  color: ${props => props.color ? props.color : '#000000'};
  max-width: ${props => props?.maxWidth};
  overFlow: ${props => props?.overFlow};
  @media (max-width: 1024px) {
    font-size: ${props => props.fontSize ? `calc(${props.fontSize} * 0.9)` : '22px'};
  }

  @media (max-width: 768px) {
    font-size: ${props => props.fontSize ? `calc(${props.fontSize} * 0.8)` : '20px'};
  }

  @media (max-width: 480px) {
    font-size: ${props => props.fontSize ? `calc(${props.fontSize} * 0.7)` : '18px'};
  }
}`;
const PdfHeader = styled.h3`
  font-weight: ${props => props.weight ? props.weight : '700'} ;
  font-size:  ${props => props.fontSize ? props.fontSize : '24px'};
  line-height: ${props => props.lineHeight ? props.lineHeight : '29px'};
  color: ${props => props.color ? props.color : '#000000'};
  border-radius: ${props => props.borderRadius};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
`
const InspectionReportItemsContainer = styled.div`
  border: 1px solid #000;
  border-radius: 16px
`
const CustomTextContainer = styled.div`
  font-weight: ${props => props.weight ? props.weight : '700'} ;
  font-size:  ${props => props.fontSize ? props.fontSize : '24px'};
  color: ${props => props.color ? props.color : '#000000'};
  margin-bottom: ${props => props.marginBottom}; 
  margin-top: ${props => props.marginTop}; 
}`;

const CustomContainer = styled.div`
  background-color: ${props => props?.backgroundColor} ;
  border-radius:  ${props => props.borderRadius};
  border:  ${props => props.border};
  box-shadow: ${props => props.shadow};
  margin:  ${props => props.margin};
  padding:  ${props => props.padding};
  height:  ${props => props.height};
  width:  ${props => props.width};
}`;

const ProfileImage = styled.img`
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
`
const PartsContainer = styled.div`
  border: 1px solid #C0D9FFD1;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 16px;
}`;


const CurrencySymbol = styled.span`
  position: absolute;
  left: 10px; /* Adjust the position of the symbol */
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #aaa;
`;
const CustomInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const CustomInput = styled.input`
   width: 100%;
   padding: ${props => props.padding ? props.padding : '10px'};
   text-align: left;
   border: ${props => props.border ? props.border : 'none'};
   border-bottom: ${props => props.borderBottom ? props.borderBottom : 'none'};
   border-radius: ${props => props.radius ? props.radius : '16px'};
   &::placeholder {
        color: #aaa;
        opacity: 1;
    }
`
const CustomReactStrapInput = styled(Input)`
   width: 100%;
   padding: ${props => props.padding ? props.padding : '10px'};
   background-color: ${props => props?.backgroundColor};
   text-align: left;
   border: ${props => props.border ? props.border : 'none'};
   border-bottom: ${props => props.borderBottom ? props.borderBottom : 'none'};
   border-radius: ${props => props.radius ? props.radius : '16px'};
   &::placeholder {
        color: #aaa;
        opacity: 1;
    }
`
const CustomArea = styled.textarea`
   width: 100%;
   padding: ${props => props.padding ? props.padding : '10px'};
   text-align: left;
   border: ${props => props.border ? props.border : 'none'};
   border-bottom: ${props => props.borderBottom};
   border-radius: ${props => props.radius ? props.radius : '16px'};
   &::placeholder {
        color: #aaa;
        opacity: 1;
    }
`

const FilterIconContainer = styled.div`
 padding: 8px;
 width: fit-content;
 height: fit-content;
 background-color: #ffffff;
 border-radius: 8px;
`

const FlexInnerComponent = styled.div`
 flex: 0 0 ${props => props.width}%;
`

const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px; 
    margin-bottom: 1rem;
    background-color: transparent;
    min-width: ${props => props.minWidth};
`;

const StyledTableHeader = styled.thead`
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
`;

const CustomTableRow = styled.tr`
    background-color: #ffffff; 
    height: 75px;
`;

const OptionsContainer = styled.div `
 display: flex;
 flex-direction: column;
 border-radius: 16px;
 background-color: #FFFFFF;
 overflow: hidden;
  & > .child-component:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  & > .child-component:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`
const OptionContainer = styled.div`
 width: 240px;
 padding: 16px;
 &:hover {
     background-color: #00000026;
 }
`
const CustomTD = styled.td`
    padding: 12px 15px;
    font-size: 14px !important;
    border: ${props=> props.border || '1px solid #dee2e6'};
    border-bottom: ${props=> props.borderBottom || 'none'};
    color: ${props=> props.color} !important;
    text-transform: capitalize;
    position: relative;
    width: ${props=>props.width};
    max-width: ${props=>props.maxWidth};
    
     &:first-child {
        border-top-left-radius:  ${props=>props.radius ||' 8px'};
        border-bottom-left-radius: ${props=>props.radius ||' 8px'};
    }

    &:last-child {
        border-top-right-radius: ${props=>props.radius ||' 8px'};
        border-bottom-right-radius: ${props=>props.radius ||' 8px'};
    }
`;

const StyledTableHeaderCell = styled.th`
    padding: 12px 15px;
    border: ${props=> props.border || '1px solid #dee2e6'};
    text-align: left; 
    font-weight: bold;
    font-size:  ${props => props.fontSize};
    width: ${props=>props.width};
    color: ${props=>props.color || '#0000008C'} !important;
`;
const StatusSpan = styled.span`
    color: ${props=>badgeColors(props.type)}
`;
const Circle = styled.div`
  background-color: ${props=>badgeColors(props.type)};
  width: 12px;
  height: 12px;
  border-radius: 6px;
`
const CheckboxContainer = styled.div`
    height:  ${props => props.height || '18px'};
    width:  ${props => props.width || '18px'};
    gap: 0px;
    border-radius: 2px;
    border:${(props) => (props.checked ? 'none' : ' 2px solid #49454F')};
    background-color: ${(props) => (props.checked ? (props.backgroundColor || '#219852') : 'white')};
    opacity: 1;
    display: inline-block;
    cursor:  ${(props) => (props.noPointer ? '' : 'pointer')};
    position: relative;
`;
const IconContainer = styled.div`
    height:  ${props => props.height || '32px'};
    width:  ${props => props.width || '32px'};
    border-radius: 50%;
    background-color: ${props => props.backgroundColor ? props => props.backgroundColor : '#F5F5F5'};
    text-align: center;
    align-content: center;
`;

const Checkmark = styled.svg`
    display: ${(props) => (props.checked ? 'block' : 'none')};
    height:  ${props => props.height || '24px'};
    width:  ${props => props.width || '20px'};
    position: absolute;
    top:  ${props => props.top || '-1px'};
    left:  ${props => props.left || '-3px'};
    fill: white;
`;

const FleetButton = styled(Button)`
  border-radius: ${props => props.radius};
  background-color: ${props => props.backgroundColor};
  width: ${props => props.width};
  height:  ${props => props.height};
  padding:  ${props => props.padding};
  font-size:  ${props => props.fontSize};
  color: ${props => props.color ? props.color : '#ffffff'};
  border: none;
  &:hover {
    color: #ffffff;
    background-color: ${props => props.hoverColor || props.backgroundColor};
  }
   &:disabled {
    color: #ffffff;
    background-color: ${props => props.hoverColor || props.backgroundColor};
  }
`;

const InspectionButton = styled(Button)`
  border-radius: ${props => props.radius};
  background-color: ${props => props.backgroundColor ? props => props.backgroundColor : '#ffffff'};
  width: ${props => props.width};
  height:  ${props => props.height};
  font-size:  ${props => props.fontSize};
  color: ${props => props.color ? props.color : '#000000'};
  &:hover {
       background-color: #000000;
       color: #ffffff;
 }
  border: none;
`;
const ArrowIcon = styled.img`
  margin-left: 10px;
  width: 12px;
  height: 12px;
  transition: transform 0.3s ease-in-out; 

  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
const StyledImage = styled.img`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 5px;
  object-fit: contain; 
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); 
  
`;
const SignatureContainer = styled.div`
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #C0D9FF;
    border-radius: 16px;
`
const AddImageButton = styled.div`
  width: ${props=>props.width || '40px'};
  height: ${props=>props.width || '40px'};
  place-self: ${props=>props.place};
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); 
  background-color: ${props=>props.backgroundColor || '#F3994A26;'};
  color: #F3994A;
  font-size: 30px;
  line-height: ${props=>props.width || '40px'};
  cursor: pointer;
  text-align: center;
`;

const CrossIconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 9px;
  right: -9px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor:pointer;
  background-color: #fafafa;
  transition: opacity 0.3s ease;
`

const TimeInputContainer = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  border: 1px solid #C0D9FF59;
  border-radius: ${props => props.radius ? props.radius : '8px'};
  padding: 10px;
`;

const StyledInput = styled.input`
  width: 40px;
  text-align: center;
  border: none;
  &:focus {
    box-shadow: none;
    border: none;
  }

  &::placeholder {
    color: #888;
  }
`;

const TimeLabel = styled.span`
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
`;

const CustomDropdown = styled(Dropdown)`
  width: 100%;
  min-width: ${props => props.minWidth};
`;
const CustomDropdownButton = styled(DropdownToggle)`
 border-radius: ${props => props.radius};
 background-color: ${props => props.backgroundColor};
 width: ${props => props.width};
 height:  ${props => props.height};
 padding:  ${props => props.padding};
 font-size:  ${props => props.fontSize};
 color: ${props => props.color};
  &:hover {
      opacity: 1;
      color: ${props => props.color};
 }
`;

const DropdownButton = styled(DropdownToggle)`
  height: ${props => props.buttonHeight || '40px'};
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  border: ${props => props.border || '1px solid #ccc'};
  box-shadow: none !important;
  background-color: white;
  cursor: pointer;
  width: 100%;
  max-width: ${props => props.maxWidth || '270px'};
  &:hover {
   background-color: ${props => props.hoverColor};
  }
`;

const Dot = styled.span`
  display: inline-block;
  width:  ${(props) => props?.width || '8px'};
  height:  ${(props) => props?.width || '8px'};
  border-radius: 50%;
  background-color: ${(props) => props.color || 'transparent'};
  margin-right: 8px;  // Space between the dot and the label
`;

const RecomendedInspectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px;
`
const ServiceCard = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #C0D9FF;
  border-radius: 8px;
  background-color: #fff;
`
const DamageIconContainer = styled.div`
  padding: 13px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 2px solid;
  border-color: ${prop=>prop.active ? '#F3994A' : '#00000073'}
`
export {
    TimeInputContainer,
    StyledInput,
    TimeLabel,
    //select
    DropdownButton,
    CustomDropdownButton,
    CustomDropdown,
    Dot,
    ArrowIcon,

    InspectionButton,
    StatusSpan,
    CustomHeader,
    UploadedDocumentInformationContainer,
    PdfHeader,
    InspectionReportItemsContainer,
    CustomTextContainer,
    PartsContainer,
    CustomBadge,
    DepartmentsBadge,
    CustomInput,
    FilterIconContainer,
    FlexInnerComponent,
    CheckboxContainer,
    Checkmark,
    FleetButton,
    IconContainer,
    StyledImage,
    CrossIconContainer,
    AddImageButton,
    //table
    CustomTableRow,
    CustomTD,
    StyledTable,
    StyledTableHeader,
    StyledTableHeaderCell,
    //_
    RecomendedInspectionContainer,
    ServiceCard,
    SignatureContainer,
    DamageIconContainer,
    CurrencySymbol,
    CustomInputWrapper,
    CustomArea,
    StatusBadge,
    PriorityBadge,
    RequiredItemsBadge,
    Circle,
    OptionsContainer,
    OptionContainer,
    CustomContainer,
    ProfileImage,
    CustomReactStrapInput
}