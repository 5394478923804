import React, {useEffect, useState} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {
    CustomArea,
    CustomHeader,
    CustomInput,
    CustomTextContainer,
    FleetButton
} from "@pages/new-design-components/custom-components";
import axios from "axios";
import * as constants from "../../../../../constants";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";
import CustomRadio from "@pages/components/JobDetailsComponents/CustomRadio";
import {parseToWord} from "../../../../../constants";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {ButtonContainer} from "@pages/reusable-components/styled-components";


export default function CreateTemplateModal({isOpen, localData = null, updateData, closeModal, type, deleteData, editIndex = null}) {
    const [activeData, setActiveData] = useState({})
    const [showAddTemplate, setShowAddTemplate] = useState(false)
    const [disableButtons, setDisableButtons] = useState(false)
    const [activeId, setActiveId] = useState(null)

    useEffect(()=>{
        if (editIndex !== null) {
            let tempData = [...localData]
            setActiveData({...tempData[editIndex]})
            setActiveId(tempData[editIndex].id)
            setShowAddTemplate(true)
        }
    },[editIndex])

    const saveData =  () => {
        if (activeId) {
             axios.post(
                `${constants.API_CORE_URL}/edit-template/${activeId}`, {
                    ...activeData,
                },
            ).then(()=>{
                 let tempData = [...localData]
                 const idx = tempData.findIndex(item=>item.id === activeId)
                 updateData({data:activeData,index: idx})
                 setActiveId(null)
                 setActiveData({})
                 setShowAddTemplate(false)
                 setDisableButtons(false)
                 if (editIndex !== null) {
                     closeModal()
                 }
             });
        } else {
             axios.post(
                `${constants.API_CORE_URL}/add-template`, {
                    ...activeData,
                    source_type: type === 'email' ? type : 'sms'
                },
            ).then(()=>{
                 updateData({data:activeData,index: -1})
                 setActiveData({})
                 setShowAddTemplate(false)
                 setDisableButtons(false)
             });
        }

    }
    const updateValue = ({key, value}) => {
        let tempData = {...activeData}
        tempData[key] = value
        setActiveData(tempData)
    }
    const editData = (index) => {
        let tempData = [...localData]
        setActiveData({...tempData[index]})
        setActiveId(tempData[index].id)
        setShowAddTemplate(true)
    }
    const openDeleteModal = (index) => {
        let tempData = [...localData]
        deleteData({id:tempData[index].id})
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={closeModal}
            modalClassName={'slide-in-from-right'}
            backdrop={false}
            className={'modal-dialog-right modal-responsible'}
        >

            <ModalHeader toggle={closeModal} className={'bg-white centered-title'}>
                <CustomHeader weight={'600'} fontSize={'14px'} color={'#74787E'}>
                    Add template
                </CustomHeader>
            </ModalHeader>
            <ModalBody>

                {
                    showAddTemplate ?
                        <>

                            <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'}
                                                 weight={'600'} color={'#0000008C'}>
                                Subject
                            </CustomTextContainer>
                            <CustomInput radius={'8px'}
                                         onChange={(e) => {
                                             updateValue({key: 'subject', value: e.target.value})
                                         }
                                         }
                                         border={'1px solid #979797'}
                                         borderBottom={'1px solid #979797'}
                                         value={activeData?.subject}/>

                            <CustomTextContainer marginTop={'12px'} marginBottom={'6px'} fontSize={'14px'}
                                                 weight={'600'} color={'#0000008C'}>
                                Message
                            </CustomTextContainer>
                            <CustomArea
                                onChange={(e) => {
                                    updateValue({key: 'message', value: e.target.value})
                                }}
                                radius={'8px'}
                                border={'1px solid #979797'}
                                value={activeData?.message}
                            />
                        </> :
                        localData.map((reminder,index) => {
                            return <div>
                                <div className={'d-flex justify-content-between'}>
                                    <CustomHeader marginBottom={'6px'} fontSize={'14px'} weight={'400'} color={'#000'}>
                                        {parseToWord(reminder?.subject)}
                                    </CustomHeader>
                                    <div className={'d-flex g05'}>
                                        <IcoMoon
                                            className={'cursor-pointer'}
                                            iconSet={IconSet}
                                            icon={'edit'}
                                            size={20}
                                            onClick={() => {
                                                editData(index)
                                            }}
                                        />
                                        <IcoMoon
                                            className={'cursor-pointer'}
                                            iconSet={IconSet}
                                            icon={'delete_new_outline'}
                                            size={20}
                                            onClick={() => {
                                                openDeleteModal(index)
                                            }}
                                        />
                                    </div>
                                </div>
                                <CustomHeader marginBottom={'6px'} fontSize={'14px'} weight={'400'} color={'#000'}>
                                    {parseToWord(reminder?.message)}
                                </CustomHeader>
                            </div>
                        })
                }
                {
                    !showAddTemplate &&
                    <CustomHeader weight={'600'} fontSize={'16px'} color={'#3F8CFF'} className={'cursor-pointer'}
                                  onClick={() => {
                                      setShowAddTemplate(true)
                                  }}>
                        + Add Template
                    </CustomHeader>
                }
            </ModalBody>
            <ModalFooter>
                {
                    showAddTemplate &&
                    <div className={'w-100'}>
                        <FleetButton
                            className={'mb-3'}
                            height={'43px'}
                            disabled={disableButtons}
                            width={'90%'}
                            backgroundColor={'#F3994A'}
                            hoverColor={'#F3994A'}
                            color={'#fff'}
                            fontSize={'14px'}
                            radius={'32px'}
                            onClick={saveData}
                        >
                            Save
                        </FleetButton>

                        <FleetButton
                            height={'43px'}
                            width={'90%'}
                            disabled={disableButtons}
                            backgroundColor={'#000'}
                            color={'#FFF'}
                            fontSize={'14px'}
                            radius={'32px'}
                            onClick={() => setShowAddTemplate(false)}
                        >
                            Close
                        </FleetButton>

                    </div>
                }
            </ModalFooter>
        </Modal>
    )
}