import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { useSortable } from "@dnd-kit/sortable";
import { UncontrolledPopover } from "reactstrap";
import { parseToWord } from "../../../../constants";
import styled from "styled-components";
import { CSS } from "@dnd-kit/utilities";
import {
    CustomBadge,
    CustomHeader,
    CustomTableRow,
    CustomTD, Dot, OptionContainer, OptionsContainer, StatusBadge
} from "@pages/new-design-components/custom-components";
import { DragHandle } from "@pages/components/drag/DragHandle";
import ThreeDots from "@assets/images/mi_options-vertical.svg";
import {
    hasPermission
} from 'views/policies/FleetPolicy'

const DraggingRow = styled.td`
  background: rgba(127, 207, 250, 0.3);
`;

export const SortableTableItem = ({ device, index, keys, goToDetails, quickView, disable = false, deleteFleet }) => {
    const {
        attributes,
        listeners,
        transform,
        transition,
        setNodeRef,
        isDragging
    } = useSortable({
        id: `${device.id}`
    });
    const permissions = useSelector((state) => state.getState).permissions;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
        backgroundColor: device.insureance_written_off ? '#ffebea' : ''
    };
    const [popoverOpen, setPopoverOpen] = useState(false);
    const togglePopover = () => setPopoverOpen(!popoverOpen);

    const generateDepartmentItem = (value, key, id, index) => {
        if (key === 'make') {
            const vehicle = value?.vehicle
            return <CustomTD width={'450px'} key={key + '' + id} tabIndex="0">
                <div className={'d-flex g-5'}>
                    <CustomHeader fontSize={'14px'} weight={'600'}>
                        {vehicle.vehicle_make.name}
                    </CustomHeader>
                    <CustomHeader fontSize={'14px'} weight={'400'}>
                        {vehicle.vehicle_model.name}
                    </CustomHeader>
                    <CustomHeader fontSize={'14px'} weight={'400'}>
                        {vehicle.derivative}
                    </CustomHeader>
                </div>
                <div className={'d-flex align-items-center'}>
                    <Dot width={'12px'} color={vehicle.colour} />
                    <span >
                        {vehicle.colour}
                    </span>
                </div>
                <CustomHeader fontSize={'14px'} weight={'400'}>
                    {vehicle.stock_no}
                </CustomHeader>
            </CustomTD>
        }

        else if (key === 'deal_status') {
            return <CustomTD key={key.key}>
                <StatusBadge>
                    {value.deal_status ? parseToWord(value.deal_status) : 'Non active'}
                </StatusBadge>
            </CustomTD>

        } else if (key === 'deal_type') {
            return (
                <CustomTD key={key}>
                    <CustomBadge type={value.deal_type}>
                        {parseToWord(value.deal_type)}
                    </CustomBadge>
                </CustomTD>
            );
        }
        else if (key === 'vehicle_model_name_nested') {
            return <CustomTD key={key + '' + id} tabIndex="0" >{value?.vehicle?.vehicle_model?.name}</CustomTD>
        } else if (key === 'vehicle_make_name_nested') {
            return <CustomTD key={key + '' + id} tabIndex="0" >{value?.vehicle?.vehicle_make?.name}</CustomTD>
        } else if (key === 'options') {
            return (
                <CustomTD key={key.key} className={'cursor-pointer'}>
                    <img src={ThreeDots} id={`PopoverLegacy_${index}`} />
                    <UncontrolledPopover
                        placement="left"
                        className="fleet-popover"
                        target={`PopoverLegacy_${index}`}
                        trigger="legacy"
                        isOpen={popoverOpen}
                        toggle={togglePopover}
                    >
                        <OptionsContainer className={'cursor-pointer'}>
                            {/*<OptionContainer  onClick={()=>{*/}
                            {/*    checkIn(index)*/}
                            {/*}}>*/}
                            {/*    <CustomHeader fontSize={'14px'} weight={'400'}>*/}
                            {/*        Check In*/}
                            {/*    </CustomHeader>*/}
                            {/*</OptionContainer>*/}
                            <OptionContainer
                                onClick={() => {
                                    quickView(id)
                                    togglePopover()
                                }}
                            >
                                <CustomHeader fontSize={'14px'} weight={'400'}>
                                    Quick View
                                </CustomHeader>

                            </OptionContainer>
                            {/*<OptionContainer*/}
                            {/*    onClick={()=>{*/}
                            {/*        createQuote(index)*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <CustomHeader  fontSize={'14px'} weight={'400'}>*/}
                            {/*        Create Quote*/}
                            {/*    </CustomHeader>*/}

                            {/*</OptionContainer>*/}

                            <OptionContainer
                                onClick={() => goToDetails(index)}
                            >
                                <CustomHeader fontSize={'14px'} weight={'400'}>
                                    View
                                </CustomHeader>
                            </OptionContainer>
                            {
                                hasPermission(permissions, 'delete-fleet-vehicles') ?  <OptionContainer
                                    onClick={() => {
                                        deleteFleet(id)
                                        togglePopover()
                                    }}
                                >
                                    <CustomHeader fontSize={'14px'} weight={'400'}>
                                        Delete
                                    </CustomHeader>

                                </OptionContainer> : null
                            }


                        </OptionsContainer>
                    </UncontrolledPopover>
                </CustomTD>
            );
        } else if (key === 'parts' || key === 'ply lining' || key === 'body shop'
            || key === 'workshop' || key === 'valeting' || key === 'mechanical') {
            const departments = value.departments;

            const item = departments.find(item => item.name === key)
            return (
                <CustomTD key={key} className={'text-center'}>
                       <CustomHeader lineHeight={'none'} fontSize={'14px'} weight={'600'} color={item.status == 'completed' ? '#219852' : '#000000'}>
                           {item.status == 'completed' ? 'Completed' : "Needed"}
                       </CustomHeader>
                </CustomTD>
            );
        }
        let val = value.hasOwnProperty(key) ? value[key] : '-'
        value = parseToWord(val?.toString());
        return <CustomTD key={key + '' + id} tabIndex="0"> {value}</CustomTD>
    }
    return (
        <CustomTableRow ref={setNodeRef} style={style} className={'cursor-pointer'} key={`${index}_device`}>
            {isDragging ?
                <DraggingRow colSpan={11}>&nbsp;</DraggingRow>
                :
                <>
                    {!disable && <CustomTD key={`${index}_device_${device?.make}`} width={'50px'}><DragHandle {...attributes} {...listeners} /></CustomTD>}
                    {
                        keys.map((item, keyIndex) => {
                            return generateDepartmentItem(device, item.key, device?.id, index)
                        })
                    }
                </>
            }
        </CustomTableRow>
    )
}