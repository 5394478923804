import React from "react";
import styled from "styled-components";
import {Button, UncontrolledPopover} from "reactstrap";
import {parseToWord} from "../../../../constants";
import {
    CustomHeader,
    CustomTD,
    Dot,
} from "@pages/new-design-components/custom-components";
import {DragHandle} from "@pages/components/drag/DragHandle";
import ThreeDots from "@assets/images/mi_options-vertical.svg";

const StyledStaticTableRow = styled.tr`
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
    rgb(0 0 0 / 30%) 0px 10px 10px -5px;
  outline: #3e1eb3 solid 1px;
`;

export const StaticTableRow = ({device, keys, isNewFleet = false}) => {
    const generateDepartmentItem = (value, key, id,keyIndex) => {
        if (key === 'toDetails') {
            return <th key={keyIndex} style={{padding: '5px'}}><Button outline color="success">View</Button></th>
        } else if (key === 'vehicle_make_name' || key === 'vehicle_model_name' || key === 'stock_no' || key === 'current_mileage'|| key === 'colour') {
                return <CustomTD key={key + '' + id} tabIndex="0"> {value.vehicle[key]}</CustomTD>
        } else if (key === 'vehicle_model_name_nested') {
                return <CustomTD key={key + '' + id} tabIndex="0" >{value?.vehicle?.vehicle_model?.name}</CustomTD>
        } else if (key === 'vehicle_make_name_nested') {
                return <CustomTD key={key + '' + id} tabIndex="0" >{value?.vehicle?.vehicle_make?.name}</CustomTD>
        }else if (key === 'reg_no') {
              return <th  key={key + '' + id} tabIndex="0"> {value.vehicle.stock_no}</th>
        }
        let val =value.hasOwnProperty(key) ?  value[key] : '-'
        value = parseToWord(val?.toString());
        return <th key={key + '' + id} tabIndex="0"> {value}</th>
    }
    const generateNewFleetDepartmentItem = (value, key, id, index) => {
        if (key === 'make') {
            const vehicle = value?.vehicle
            return <CustomTD width={'450px'} key={key + '' + id} tabIndex="0">
                <div className={'d-flex g-5'}>
                    <CustomHeader fontSize={'14px'} weight={'600'}>
                        {vehicle.vehicle_make.name}
                    </CustomHeader>
                    <CustomHeader fontSize={'14px'} weight={'400'}>
                        {vehicle.vehicle_model.name}
                    </CustomHeader>
                    <CustomHeader fontSize={'14px'} weight={'400'}>
                        {vehicle.derivative}
                    </CustomHeader>
                </div>
                <div className={'d-flex align-items-center'}>
                    <Dot width={'12px'} color={vehicle.colour}/>
                    <span >
                        {vehicle.colour}
                    </span>
                </div>
                <CustomHeader fontSize={'14px'} weight={'400'}>
                    {vehicle.stock_no}
                </CustomHeader>
            </CustomTD>
        } else if (key === 'vehicle_model_name_nested') {
            return <CustomTD key={key + '' + id} tabIndex="0" >{value?.vehicle?.vehicle_model?.name}</CustomTD>
        } else if (key === 'vehicle_make_name_nested') {
            return <CustomTD key={key + '' + id} tabIndex="0" >{value?.vehicle?.vehicle_make?.name}</CustomTD>
        } else if (key === 'options') {
            return (
                <CustomTD key={key.key} className={'cursor-pointer'}>
                    <img src={ThreeDots} id={`PopoverLegacy_${index}`} />
                    <UncontrolledPopover
                        placement="left"
                        className="fleet-popover"
                        target={`PopoverLegacy_${index}`}
                        trigger="legacy"
                    >
                    </UncontrolledPopover>
                </CustomTD>
            );
        }  else if (key === 'parts' || key === 'ply lining' || key === 'body shop'
            || key === 'workshop' || key=== 'valeting' || key === 'mechanical' ) {
            const departments = value.departments;

            const item = departments.find(item=>item.name === key)
            return (
                <CustomTD key={key} className={'text-center'}>
                    <CustomHeader lineHeight={'none'} fontSize={'14px'} weight={'500'} color={item.status == 'completed' ? '#219852' : '#000000'}>
                        {item.status == 'completed' ? 'Completed' : "Needed"}
                    </CustomHeader>
                </CustomTD>
            );
        }
        let val = value.hasOwnProperty(key) ? value[key] : '-'
        value = parseToWord(val?.toString());
        return <CustomTD key={key + '' + id} tabIndex="0"> {value}</CustomTD>
    }

    return (
        <StyledStaticTableRow>
                    <th><DragHandle /></th>
                    {
                        isNewFleet ?
                            keys.map((item, keyIndex) => {
                                return generateNewFleetDepartmentItem(device, item.key, device?.id, keyIndex)
                            })
                          :
                        keys.map((item, keyIndex) => {
                            return generateDepartmentItem(device, item.key, device?.id, keyIndex)
                        })
                    }
        </StyledStaticTableRow>
    )
}