import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react'
import {
    CustomHeader,
    CustomTD,
    CustomTableRow,
    StyledTable,
    StyledTableHeaderCell, StyledTableHeader, Dot, CustomContainer, RequiredItemsBadge, CustomReactStrapInput
} from "@pages/new-design-components/custom-components";
import CustomSearch from "@pages/components/CustomSearch";
import {Col, DropdownItem, Modal} from "reactstrap";
import { API_URL } from 'constants/index.js';
import axios from "axios";
import {useHistory} from "react-router-dom";
import {ButtonContainer, LoaderContainer, override} from "@pages/reusable-components/styled-components";
import DebtManagementTable from "@pages/components/DebtManagement/DebtManagementTable";
import {ClipLoader} from "react-spinners";
import SendQuoteEmail from "@pages/components/DebtManagement/SendQuoteEmail";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {GET_EMAIL_TEMPLATES_REQUEST, SEND_EMAIL_REQUEST} from "@redux/Email/actions";
import usePrevious from "../../../hooks/useprevious";
import * as constants from "../../../constants";
import {Doughnut, Line} from "react-chartjs-2";
import {GET_CUSTOMERS_DEBT_REQUEST, GET_DEBT_STATS_REQUEST} from "@redux/Customer/actions";
import {Notification} from "@pages/components/Notification";
import Picker from "@pages/components/DatePicker";


export default function ReportingAnalytics() {

    const [showSendEmail, setShowSendEmail] = useState(false)
    const dispatch = useDispatch();
    const notificationAlert = useRef(null)
    const [loading,setLoading] = useState(true);
    const [tableLoading,setTableLoading] = useState(true);
    const [tableData,setTableData] = useState([]);
    const [activeData,setActiveData] = useState(null);
    const [activeTab, setActiveTab] = useState('today');
    const [overviewFilter,setOverviewFilter] = useState({from: '',to:''});
    const [filter,setFilter] = useState({
        page: 1
    });
    const {templates} = useSelector(
        (state) => state.emailTemplateState,
        shallowEqual
    );
    const {
        customersDebts,
        getCustomersDebtError,
        getCustomersDebtSuccess,
        getDebtStatsSuccess,
        getDebtStatsError,
        debtStats,
    } = useSelector((state) => state.customer, shallowEqual);
    const getDebtStatsSuccessPrev = usePrevious(getDebtStatsSuccess);
    const getDebtStatsErrorPrev = usePrevious(getDebtStatsError);
    const getCustomersDebtSuccessPrev = usePrevious(getCustomersDebtSuccess);
    const getCustomersDebtErrorPrev = usePrevious(getCustomersDebtError);
    const tabs = [
        {
            key: 'today',
            label: 'Day',
        },
        {
            key: 'month',
            label: 'Month',
        },
        {
            key: 'year',
            label: 'Year',
        },

    ]
    const keys = [
        {
            key: 'customer_name_deep',
            label: 'Customer'
        } ,
        {
            key: 'amount_due',
            label: 'Amount'
        } ,
        {
            key: 'due_date',
            label: 'Due Date'
        },

        {
            key: 'overdue_duration',
            label: 'Overdue duration'
        },

        {
            key: 'options',
        },
    ]
    const fetchData = (localFilter = {}) => {
        dispatch({
            type: GET_CUSTOMERS_DEBT_REQUEST,
            payload: {...localFilter}
        });
    }

    useEffect(()=>{
        fetchData(filter)
        dispatch({
            type: GET_EMAIL_TEMPLATES_REQUEST,
            data: {
                forSelection: true,
                mail_type: 'Deal'
            }
        });

    },[])

    useEffect(()=>{
        if (getCustomersDebtSuccess && getCustomersDebtSuccessPrev === false) {
            setTableData(customersDebts?.data)
            setTableLoading(false)
            if (loading) {
                getOverviewData({range: activeTab})
            }
        }
    },[getCustomersDebtSuccess,getCustomersDebtSuccessPrev])
    useEffect(()=>{
        if (getCustomersDebtError && getCustomersDebtErrorPrev === false) {
            setLoading(false)
            setTableLoading(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    },[getCustomersDebtError,getCustomersDebtErrorPrev])

    useEffect(()=>{
            if (getDebtStatsSuccess && getDebtStatsSuccessPrev === false) {
                setLoading(false)
            }
        },
        [getDebtStatsSuccessPrev,getDebtStatsSuccess])
    useEffect(()=>{
        if (getDebtStatsError && getDebtStatsErrorPrev === false) {
            setLoading(false)
            Notification('warning', 'Warning', 'Something went wrong while getting stats!',notificationAlert);
        }
    },[getDebtStatsError,getDebtStatsErrorPrev])
    const getOverviewData = (localFilter = {}) => {
        dispatch({
            type: GET_DEBT_STATS_REQUEST,
            payload: {...localFilter}
        });
    }

    const filterData = ({key,value}) => {
        let tempFilter = {...filter}
        tempFilter[key] = value
        setFilter(tempFilter)
        setTableLoading(true)
        fetchData(tempFilter)
    }
    const filterOverviewStats = ({key,value}) => {
        let overviewTemp = {...overviewFilter}
        overviewTemp[key] = value
        setOverviewFilter(overviewTemp)
        getOverviewData(overviewTemp)
    }

    const viewDetails = (index) => {
        const data = tableData[index];
        setActiveData(data)
        setShowSendEmail(true)
    }
    const deleteDetail = (index) => {
        console.log('deleteDetail',index)
    }

    const sendEmail = ({data}) => {
        let formData = new FormData();
        formData.append('message',data?.message);
        formData.append('subject',data?.subject);
        formData.append('to[]',data.email);
        if (data.hasOwnProperty('documents') && data.documents.length) {
            for (const document of data?.documents) {
                if (document.file) {
                    formData.append('fileLists[]', document.file);
                }
            }
        }

        formData.append('resource_id',activeData.id);
        formData.append('resource_type','Customer');
        axios({
            method: 'POST',
            url: constants.API_URL + '/processor-emails',
            headers: {
                'content-type': 'multipart/form-data',
            },
            data: formData
        }).then(res=>{
            console.log('res:',res)
        }).catch(err=>{
            console.log('err:',err)
        })

    }

    const dealsStats = useMemo(() =>
    {
        if(debtStats) {

            let chartData = {
                labels: ['Total Collected','Total Debt','Total Overdue'],
                datasets: [
                    {
                        label: debtStats,
                        data: [
                            parseFloat(debtStats.total_collected).toFixed(2),
                            parseFloat(debtStats.total_debt).toFixed(2),
                            parseFloat(debtStats.total_overdue).toFixed(2)
                        ],
                        backgroundColor: [
                            '#48c029',
                            '#ed7d31',
                            '#f85151',
                            '#ffc000',

                        ],
                        borderColor: [
                            '#48c029',
                            '#ed7d31',
                            '#f85151',
                            '#ffc000',
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            const chartOptions = {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem) {
                                const values = [
                                    parseFloat(debtStats.total_collected).toFixed(2),
                                    parseFloat(debtStats.total_debt).toFixed(2),
                                    parseFloat(debtStats.total_overdue).toFixed(2)
                                ];
                                return `${tooltipItem.label}: ${values[tooltipItem.dataIndex]}`;
                            }
                        }
                    },
                    legend: {
                        labels: {
                            usePointStyle: true,
                            font: {
                                size: 25,
                            },
                            toUpperCase(): string {

                            }
                        },
                    },
                },
            };
            return  <Doughnut height={1} width={1} data={chartData} options={chartOptions} />
        }
    }, [debtStats]);

    const dealsStatsAera = useMemo(() => {
        if (debtStats) {
            const chartData = {
                labels: ['Total Collected','Total Debt','Total Overdue'],
                datasets: [
                    {
                        label: 'Payments Stats', // Label for the dataset
                        data: Object.values(debtStats), // Values corresponding to follow_up, paid, ignored
                        backgroundColor: '#AACAFFFF', // Semi-transparent color for the area
                        borderColor: '#3080EB', // Line color
                        borderWidth: 2,
                        fill: true, // This fills the area beneath the line
                        tension: 0.4, // Smooth curve for the line
                    },
                ],
            };

            const chartOptions = {
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const label = tooltipItem.label;
                            const value = tooltipItem.raw; // Tooltip value (e.g., 30200 for follow_up)
                            return `${label}: ${value.toLocaleString()}`; // Custom tooltip format
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: 'top',
                        labels: {
                            font: {
                                size: 14,
                            },
                        },
                    },
                },
                responsive: true,
                scales: {
                    x: {
                        beginAtZero: true,
                    },
                    y: {
                        beginAtZero: true,
                    },
                },
            };

            return <Line height={1} width={1} data={chartData} options={chartOptions} />;
        }
    }, [debtStats]);

    return (
        loading ?
            <LoaderContainer>
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={loading}
                />
            </LoaderContainer> :
            <div className={'container-fluid debt-background pt-3'}>
                <CustomContainer backgroundColor={'#FFF'} borderRadius="16px" padding="16px" className={'mb-2'}>
                    <CustomHeader fontSize={'20px'} weight={'600'} lineHeight={'unset'} >Analytics</CustomHeader>
                     <div className={'d-flex justify-content-between flex-wrap'}>
                         <div className={'d-flex g-5 mb-3'}>
                             {
                                 tabs.map((tab,index) => {
                                     return (
                                         <Fragment key={'idx_'+index}>
                                             <RequiredItemsBadge backgroundColor={'#F2F2F2'} type={tab.key} onClick={()=> {
                                                 setActiveTab(tab.key);
                                                 getOverviewData({range: tab.key})
                                             }
                                             } active={activeTab === tab.key}>
                                                 {tab.label}
                                             </RequiredItemsBadge>
                                         </Fragment>
                                     )
                                 })
                             }
                         </div>
                         <div className={'d-flex g-10 debt-datePicker'}>
                             <Picker
                                 value={overviewFilter?.from}
                                 placeholderText={'From'}
                                 onChange={(date) => {
                                     filterOverviewStats({key:'from',value:date})
                                 }}
                             />
                             <Picker
                                 value={overviewFilter?.to}
                                 placeholderText={'To'}
                                 onChange={(date) => {
                                     filterOverviewStats({key:'to',value:date})
                                 }}
                             />
                         </div>
                     </div>
                    <div className={'d-flex justify-content-between flex-wrap'}>
                        <div className={'container-350 text-center'}>
                            {dealsStatsAera}
                        </div>
                        <div className={'container-350 text-center'}>
                            {dealsStats}
                        </div>

                    </div>

                </CustomContainer>
                {
                    showSendEmail ?
                        <SendQuoteEmail sendEmail={sendEmail} templates={templates} data={activeData} setShowSendQuoteEmail={()=>{setShowSendEmail(false)}}/> :
                        <>
                            <CustomHeader fontSize={'20px'} weight={'600'} lineHeight={'unset'} >Debt List</CustomHeader>
                            <Col lg={4} sm={12} md={5}>
                                <CustomSearch
                                    onChange={(e) => {
                                        filterData({key:'customer_name',value: e.target.value})
                                    }}
                                    placeholder="Customer Name"
                                />
                            </Col>
                            <div className={'table-container'}>
                                <DebtManagementTable keys={keys} loading={tableLoading} tableData={tableData} viewDetails={viewDetails} deleteDetail={deleteDetail} optionValues={[
                                    {
                                        label: 'Send to Debt Recovery',
                                        action: viewDetails
                                    },
                                    {
                                        label: 'Resend Statement',
                                        action: deleteDetail
                                    }
                                ]}/>
                            </div>
                        </>
                }

            </div>
    )
}